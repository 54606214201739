import { SvgIcon } from "@mui/material";

const Twitter = () => (
  <SvgIcon
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.65061 9.6665C1.2725 9.67042 0.382908 11.2403 1.23191 12.4298C2.44776 14.133 4.65255 15.5 8.3334 15.5C14.0376 15.5 18.6165 10.8514 17.9755 5.35925L18.9146 3.48108C19.5658 2.17857 18.4341 0.69685 17.0061 0.98245L15.7655 1.23057C15.4366 1.05702 15.0961 0.926058 14.8052 0.831175C14.2382 0.646267 13.552 0.5 12.9167 0.5C11.7756 0.5 10.7908 0.792758 10.0081 1.37994C9.2344 1.96053 8.79856 2.72354 8.5594 3.42421C8.44915 3.74717 8.37573 4.07112 8.32862 4.38203C7.8859 4.24269 7.43447 4.05522 6.99252 3.82629C5.98986 3.30693 5.17906 2.64852 4.72169 2.07126C3.95433 1.10275 2.32813 1.17456 1.75748 2.43351C0.953197 4.20789 1.17382 6.31122 1.89677 8.01075C2.13909 8.58033 2.4543 9.14342 2.83939 9.66525C2.77346 9.666 2.7104 9.66633 2.65061 9.6665ZM8.33331 13.8333C5.11386 13.8333 3.44914 12.6672 2.58835 11.4614C2.54967 11.4072 2.58867 11.3333 2.65523 11.3332C3.53093 11.3307 5.32843 11.2892 6.51393 10.5954C6.57524 10.5595 6.56175 10.4691 6.49441 10.4465C3.73094 9.51733 2.16008 5.58216 3.2754 3.12158C3.30097 3.06517 3.37681 3.05772 3.41528 3.10628C4.68934 4.71432 7.47501 6.28933 9.89865 6.33242C9.95123 6.33333 9.99098 6.28558 9.98298 6.23361C9.88531 5.60044 9.5124 2.16667 12.9166 2.16667C13.7296 2.16667 14.9391 2.56319 15.3846 2.96942C15.4051 2.98819 15.4331 2.99672 15.4604 2.99125L17.3329 2.61675C17.4009 2.60315 17.4548 2.67371 17.4238 2.73573L16.2624 5.05849C16.2543 5.07462 16.2518 5.09312 16.2549 5.11088C17.0683 9.68333 13.3233 13.8333 8.33331 13.8333Z"
      fill="#777E91"
    />
  </SvgIcon>
);

export default Twitter;
