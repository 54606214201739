import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import useSharedLink from "../../../hooks/useSharedLink";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import backgroundVideoExchangeLG from "../../../imagens/backgroundVideoExchangeLG.png";
import Video_Section_mobile from "../../../imagens/Video_Section_mobile.png";
import "./ExchangeVideo.styles.css";

export const ExchangeVideo = () => {
  useSharedLink();
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  return (
    <>
      {width > 900 ? (
        <Grid
          position="relative"
          container
          item
          alignItems="center"
          justifyContent="center"
          pt={5}
        >
          <Grid>
            <img
              src={backgroundVideoExchangeLG}
              width="100%"
              alt="Fundo da Tela Exchange Video"
            />
          </Grid>
          <Grid container position="absolute">
            <Grid
              maxWidth={1536}
              container
              alignItems="center"
              justifyContent="space-between"
              position="relative"
              p={6}
            >
              <Grid
                display="flex"
                flexDirection="column"
                mt={{ md: -30, lg: -45, xl: -50 }}
              >
                <Typography
                  fontWeight={400}
                  fontSize={{ md: 24, lg: 42, xl: 58 }}
                  color="#232340"
                  mb={{ md: 2, lg: 8, xl: 5 }}
                  lineHeight={1.1}
                  textAlign="start"
                  dangerouslySetInnerHTML={{
                    __html: t("simplePracticalAndSafeWeb"),
                  }}
                />

                <Typography
                  width={"40%"}
                  fontWeight={400}
                  fontSize={{ md: 15, lg: 18, xl: 24 }}
                  color="#999FAE"
                  textAlign="start"
                  lineHeight={1.1}
                  dangerouslySetInnerHTML={{
                    __html: t("exchangeDescriptionWeb"),
                  }}
                />
              </Grid>

              <iframe
                className="responsiveIframe"
                src="https://player.vimeo.com/video/703991107"
              />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid container pt="5rem" justifyContent="center">
          <img
            src={Video_Section_mobile}
            width={{ xs: "100%", sm: "60%" }}
            alt="Fundo Fundo da Tela Exchange Video resolução para celular"
          />

          <Grid
            container
            position="absolute"
            alignItems="center"
            justifyContent="center"
            item
            xs={12}
            sm={7}
          >
            <Typography
              fontWeight={400}
              px={1}
              maxWidth={310}
              fontSize={44}
              lineHeight="1.2em"
              color="#232340"
              textAlign="center"
              pt="3rem"
              dangerouslySetInnerHTML={{
                __html: t("simplePracticalAndSafeMobile"),
              }}
            />
            <Grid container>
              <iframe
                class="responsiveIframe"
                src="https://player.vimeo.com/video/703991107?autoplay=false"
              />
            </Grid>
          </Grid>

          <Grid container alignItems="center" justifyContent="center">
            <Typography
              fontWeight={400}
              fontSize="6.5vw"
              color="#494949"
              lineHeight="1.3em"
              dangerouslySetInnerHTML={{
                __html: t("exchangeDescriptionMobile"),
              }}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};
