import { useState } from "react";

import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import { Box, Grid, InputBase, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import useSharedLink from "../../../hooks/useSharedLink";
import "./HomeVideoKahsh.styles.css";

import useWindowDimensions from "../../../hooks/useWindowDimensions";
import backgroundVideo from "../../../imagens/backgroundVideo.png";
import maozinha from "../../../imagens/maozinha.png";

import MailchimpSubscribe from "react-mailchimp-subscribe";
import { SaibaMaisButton } from "../../buttons/SaibaMaisButton";

export const HomeVideoKahsh = () => {
  useSharedLink();
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  const styles = {
    heroContainer: {
      height: 680,
      backgroundImage: `url(${backgroundVideo})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      marginBottom: -32,
    },
  };

  const url =
    "https://kahsh.us20.list-manage.com/subscribe/post?u=54b69f70d58fe07e4622e7074&id=c21c16f685";

  const CustomForm = ({ status, onValidated }) => {
    const [email, setEmail] = useState("");
    const submit = () => {
      email &&
        email.indexOf("@") > -1 &&
        onValidated({
          EMAIL: email,
        });

      if (status === "success") {
        setEmail(t("subscribeSuccess"));
      } else if (status === "pending") {
        setEmail(t("subscribePending"));
      } else {
        setEmail(t("subscribeError"));
      }
    };

    return (
      <Grid component="form" justifyContent="center" container>
        <Grid container alignItems="center" item xs={8} flexDirection="row">
          <Grid container sm={10}>
            <Grid container p={width > 600 ? null : "5vw"}>
              {width > 600 ? (
                <Grid
                  container
                  sm={2}
                  alignItems="center"
                  justifyContent="center"
                >
                  <EmailRoundedIcon />
                </Grid>
              ) : (
                <Grid
                  container
                  sm={2}
                  width="10%"
                  alignItems="center"
                  justifyContent="center"
                >
                  <EmailRoundedIcon />
                </Grid>
              )}

              <InputBase
                sx={{
                  width: width < 600 ? "90%" : "80%",
                  padding: width < 600 ? "0vw 5vw" : null,
                }}
                placeholder={t("informYourEmail")}
                inputProps={{ "aria-label": "search" }}
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                onClick={(e) => setEmail("")}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid className="button-container" container item xs={4} my={1} pr={1}>
          <SaibaMaisButton width="100%" onClick={(e) => submit(e)}>
            <Typography fontSize={15} fontWeight={700}>
              {t("register")}
            </Typography>
          </SaibaMaisButton>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid height={720} bgcolor="#E2E3E8" container>
      <Grid
        container
        alignSelf="flex-end"
        pt={3}
        pb={8}
        bgcolor="#E2E3E8"
        px={width > 900 ? 0 : 4}
        height={680}
        style={styles.heroContainer}
      >
        <Grid
          container
          justifyContent="center"
          mt={5}
          zIndex={1}
          position="relative"
        >
          <Grid position="absolute" justifyContent="center" zIndex={1.2}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              ml={-5}
              marginTop={-7}
              className="homeVideo"
            >
              <Typography
                color={"#4733FF"}
                fontSize={60}
                className="homeVideoText"
                mt={-9}
                ml={2}
                fontWeight={700}
              >
                {t("discoverTheNew")}
              </Typography>
              <Typography
                color={"#FFFFFF"}
                fontSize={60}
                fontWeight={700}
                marginTop={-2}
                mb={2}
                ml={2}
                className="homeVideoSubtext"
              >
                {t("kahshUniverse")}
              </Typography>
              <Box
                zIndex={2.0}
                className="video"
                mt={7}
                mr={1}
                borderRadius={10}
                width="90%"
              >
                <iframe
                  width="44%"
                  height="22%"
                  className="kahshVideo"
                  src="https://player.vimeo.com/video/703991107?autoplay=false"
                  frameBorder="0"
                  allow="fullscreen; picture-in-picture"
                  allowFullScreen
                  title=" "
                ></iframe>
              </Box>
              <Typography
                color={"#000000"}
                fontSize={12}
                ml={2}
                mt={
                  width > 1100
                    ? -4
                    : width > 900 && width <= 1100
                    ? 8
                    : width < 600
                    ? -6
                    : -12
                }
                fontWeight={700}
              >
                {t("readyToStarted")}
              </Typography>
              <Typography
                color={"#000000"}
                fontSize={12}
                marginTop={0}
                mb={2}
                ml={2}
              >
                {t("signUpEmail")}
              </Typography>
              <Grid
                container
                zIndex={2.0}
                mt={width < 900 ? 1 : 0}
                ml={2}
                justifyContent="center"
              >
                <Box bgcolor="#FFFFFF" borderRadius="20px" width="100%">
                  <MailchimpSubscribe
                    url={url}
                    render={({ subscribe, status }) => (
                      <CustomForm
                        status={status}
                        onValidated={(formData) => subscribe(formData)}
                      />
                    )}
                  />
                </Box>
              </Grid>
            </Box>
          </Grid>
          <Grid
            container
            height={width < 900 ? "60%" : "100%"}
            position="absolute"
            justifyContent="flex-end"
            zIndex={0.9}
            mt={width < 900 ? 40 : 30}
          >
            <img src={maozinha} alt="" height={width < 900 ? "100%" : "200%"} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
