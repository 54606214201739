import { Grid, Typography, IconButton } from "@mui/material";

import useWindowDimensions from "../../../hooks/useWindowDimensions";

import { useTranslation } from "react-i18next";
import i18n from "i18next";

import SmallCircleButton from "../../../icons/SmallCircleButton";

import CafeKahshStoreWeb from "../../../imagens/CafeKahshStoreWeb.png";
import LojaKahshStoreWeb from "../../../imagens/LojaKahshStoreWeb.png";
import SapatoKahshStoreWeb from "../../../imagens/SapatoKahshStoreWeb.png";
import FogueteKahshStoreWeb from "../../../imagens/FogueteKahshStoreWeb.png";

export const KahshStoreItens = () => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  return (
    <>
      {width > 1024 ? (
        <>
          <Grid container mt={8} xs={12} id="kahshStoreItens">
            <Grid
              item
              xs={12}
              pb={16}
              pr="10vw"
              display="flex"
              alignItems="end"
              justifyContent="end"
              flexDirection="column"
            >
              <Grid
                item
                xs={6}
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
              >
                <Grid display="flex">
                  <img src={FogueteKahshStoreWeb} width={"100%"} alt="" />
                  <Grid mt="37rem" ml="-5rem">
                    <IconButton className="kahshPayAcesseAgoraIcon">
                      <SmallCircleButton />
                    </IconButton>
                  </Grid>
                </Grid>

                {i18n.language.substring(0,2) == "pt" ? (
                  <Typography
                    mt={3}
                    pl={20.75}
                    fontSize="1.2vw"
                    color="#001C57"
                    fontWeight={700}
                    fontFamily="Montserrat"
                    alignSelf="flex-start"
                  >
                    Mais de 40 marcas, e subindo!
                  </Typography>
                ) : (
                  <Typography
                    mt={3}
                    pl={20.75}
                    fontSize="1.2vw"
                    color="#001C57"
                    fontWeight={700}
                    fontFamily="Montserrat"
                    alignSelf="flex-start"
                  >
                    Over 40 brands, and going up!
                  </Typography>
                )}

                {i18n.language.substring(0,2) == "pt" ? (
                  <Typography
                    mt={3}
                    pl={20.75}
                    fontSize="1.2vw"
                    color="#606060"
                    fontWeight={400}
                    fontFamily="Montserrat"
                    alignSelf="flex-start"
                  >
                    Produtos esportivos, estéticos, calçados, roupas, <br />
                    mundo tech, gamer, e muito mais.
                  </Typography>
                ) : (
                  <Typography
                    mt={3}
                    pl={20.75}
                    fontSize="1.2vw"
                    color="#606060"
                    fontWeight={400}
                    fontFamily="Montserrat"
                    alignSelf="flex-start"
                  >
                    Sporting products, aesthetics, footwear, clothing, <br />
                    tech world, gamer, and much more.
                  </Typography>
                )}
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              pl="10vw"
              pb={6}
              mt="-10rem"
              display="flex"
              alignItems="start"
              justifyContent="end"
              flexDirection="column"
            >
              <Grid
                item
                xs={6}
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
              >
                <Grid display="flex">
                  <img src={SapatoKahshStoreWeb} width={"100%"} alt="" />
                  <Grid mt="37rem" ml="-5rem">
                    <IconButton className="kahshPayAcesseAgoraIcon">
                      <SmallCircleButton />
                    </IconButton>
                  </Grid>
                </Grid>

                {i18n.language.substring(0,2) == "pt" ? (
                  <Typography
                    mt={3}
                    pl={20.75}
                    fontSize="1.2vw"
                    color="#001C57"
                    fontWeight={700}
                    fontFamily="Montserrat"
                    alignSelf="flex-start"
                  >
                    Fique livre de conversões em <br />
                    produtos importados.
                  </Typography>
                ) : (
                  <Typography
                    mt={3}
                    pl={20.75}
                    fontSize="1.2vw"
                    color="#001C57"
                    fontWeight={700}
                    fontFamily="Montserrat"
                    alignSelf="flex-start"
                  >
                    Stay free of conversions on <br />
                    imported products.
                  </Typography>
                )}

                {i18n.language.substring(0,2) == "pt" ? (
                  <Typography
                    mt={3}
                    pl={20.75}
                    fontSize="1.2vw"
                    color="#606060"
                    fontWeight={400}
                    fontFamily="Montserrat"
                    alignSelf="flex-start"
                  >
                    Você não precisa pagar taxas de câmbio para <br />
                    produtos cotados em dólar, a Kahsh é aceita
                    <br /> globalmente.
                  </Typography>
                ) : (
                  <Typography
                    mt={3}
                    pl={20.75}
                    fontSize="1.2vw"
                    color="#606060"
                    fontWeight={400}
                    fontFamily="Montserrat"
                    alignSelf="flex-start"
                  >
                    You don't need to pay exchange fees for <br />
                    products quoted in dollars, Kahsh is accepted
                    <br /> globally.
                  </Typography>
                )}
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              pb={16}
              pr="10vw"
              display="flex"
              alignItems="end"
              justifyContent="end"
              flexDirection="column"
            >
              <Grid
                item
                xs={6}
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
              >
                <Grid display="flex">
                  <img src={LojaKahshStoreWeb} width={"100%"} alt="" />
                  <Grid mt="37rem" ml="-5rem">
                    <IconButton className="kahshPayAcesseAgoraIcon">
                      <SmallCircleButton />
                    </IconButton>
                  </Grid>
                </Grid>

                {i18n.language.substring(0,2) == "pt" ? (
                  <Typography
                    mt={3}
                    pl={12.5}
                    fontSize="1.2vw"
                    color="#001C57"
                    fontWeight={700}
                    fontFamily="Montserrat"
                    alignSelf="flex-start"
                  >
                    Ingresso crescente de parceiros.
                  </Typography>
                ) : (
                  <Typography
                    mt={3}
                    pl={12.5}
                    fontSize="1.2vw"
                    color="#001C57"
                    fontWeight={700}
                    fontFamily="Montserrat"
                    alignSelf="flex-start"
                  >
                    Growing ingress of partners.
                  </Typography>
                )}

                {i18n.language.substring(0,2) == "pt" ? (
                  <Typography
                    mt={3}
                    pl={12.5}
                    fontSize="1.2vw"
                    color="#606060"
                    fontWeight={400}
                    fontFamily="Montserrat"
                    alignSelf="flex-start"
                  >
                    Com a ascensão dos dois mundos: cripto e <br />
                    e-commerce; não se surpreenda com a variedade <br />
                    de segmentos que vai encontrar.
                  </Typography>
                ) : (
                  <Typography
                    mt={3}
                    pl={12.5}
                    fontSize="1.2vw"
                    color="#606060"
                    fontWeight={400}
                    fontFamily="Montserrat"
                    alignSelf="flex-start"
                  >
                    With the rise of the two worlds: crypto and <br />
                    e-commerce; don't be surprised by the variety <br />
                    of segments you will find.
                  </Typography>
                )}
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              pl="10vw"
              mt="-10rem"
              display="flex"
              alignItems="start"
              justifyContent="end"
              flexDirection="column"
            >
              <Grid
                item
                xs={6}
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
              >
                <Grid display="flex">
                  <img src={CafeKahshStoreWeb} width={"100%"} alt="" />
                  <Grid mt="37rem" ml="-5rem">
                    <IconButton className="kahshPayAcesseAgoraIcon">
                      <SmallCircleButton />
                    </IconButton>
                  </Grid>
                </Grid>

                {i18n.language.substring(0,2) == "pt" ? (
                  <Typography
                    mt={3}
                    pl={12.5}
                    fontSize="1.2vw"
                    color="#001C57"
                    fontWeight={700}
                    fontFamily="Montserrat"
                    alignSelf="flex-start"
                  >
                    De compras casuais às especiais.
                  </Typography>
                ) : (
                  <Typography
                    mt={3}
                    pl={12.5}
                    fontSize="1.2vw"
                    color="#001C57"
                    fontWeight={700}
                    fontFamily="Montserrat"
                    alignSelf="flex-start"
                  >
                    From casual to special purchases.
                  </Typography>
                )}

                {i18n.language.substring(0,2) == "pt" ? (
                  <Typography
                    mt={3}
                    pl={12.5}
                    fontSize="1.2vw"
                    color="#606060"
                    fontWeight={400}
                    fontFamily="Montserrat"
                    alignSelf="flex-start"
                  >
                    Das compras mais corriqueiras, do dia a dia,
                    <br /> até aquela que você vinha pesquisando a<br /> tempos.
                    Confira.
                  </Typography>
                ) : (
                  <Typography
                    mt={3}
                    pl={12.5}
                    fontSize="1.2vw"
                    color="#606060"
                    fontWeight={400}
                    fontFamily="Montserrat"
                    alignSelf="flex-start"
                  >
                    From the most common purchases, from day to day,
                    <br /> even the one you've been researching for a while.
                    Check out.
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid
            container
            mt={8}
            xs={12}
            alignItems="center"
            justifyContent="center"
            id="kahshStoreItens"
          >
            <Grid
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              <Grid xs={10} display="flex">
                <img src={FogueteKahshStoreWeb} width={"100%"} alt="Sapatos" />
                <Grid mt="23rem" ml="-5rem">
                  <IconButton className="kahshPayAcesseAgoraIcon">
                    <SmallCircleButton />
                  </IconButton>
                </Grid>
              </Grid>

              {i18n.language.substring(0,2) == "pt" ? (
                <Typography
                  mt={3}
                  pl={20.75}
                  fontSize="3vw"
                  color="#001C57"
                  fontWeight={700}
                  fontFamily="Montserrat"
                  alignSelf="flex-start"
                >
                  Mais de 40 marcas, e subindo!
                </Typography>
              ) : (
                <Typography
                  mt={3}
                  pl={20.75}
                  fontSize="3vw"
                  color="#001C57"
                  fontWeight={700}
                  fontFamily="Montserrat"
                  alignSelf="flex-start"
                >
                  Over 40 brands, and going up!
                </Typography>
              )}

              {i18n.language.substring(0,2) == "pt" ? (
                <Typography
                  mt={3}
                  pl={20.75}
                  fontSize="3vw"
                  color="#606060"
                  fontWeight={400}
                  fontFamily="Montserrat"
                  alignSelf="flex-start"
                >
                  Produtos esportivos, estéticos, calçados, roupas, <br />
                  mundo tech, gamer, e muito mais.
                </Typography>
              ) : (
                <Typography
                  mt={3}
                  pl={20.75}
                  fontSize="3vw"
                  color="#606060"
                  fontWeight={400}
                  fontFamily="Montserrat"
                  alignSelf="flex-start"
                >
                  Sporting products, aesthetics, footwear, clothing, <br />
                  tech world, gamer, and much more.
                </Typography>
              )}
            </Grid>

            <Grid
              pt={10}
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              <Grid xs={10} display="flex">
                <img src={SapatoKahshStoreWeb} width={"100%"} alt="Sapatos" />
                <Grid mt="23rem" ml="-5rem">
                  <IconButton className="kahshPayAcesseAgoraIcon">
                    <SmallCircleButton />
                  </IconButton>
                </Grid>
              </Grid>

              {i18n.language.substring(0,2) == "pt" ? (
                <Typography
                  mt={3}
                  pl={20.75}
                  fontSize="3vw"
                  color="#001C57"
                  fontWeight={700}
                  fontFamily="Montserrat"
                  alignSelf="flex-start"
                >
                  Fique livre de conversões em <br />
                  produtos importados.
                </Typography>
              ) : (
                <Typography
                  mt={3}
                  pl={20.75}
                  fontSize="3vw"
                  color="#001C57"
                  fontWeight={700}
                  fontFamily="Montserrat"
                  alignSelf="flex-start"
                >
                  Stay free of conversions on <br />
                  imported products.
                </Typography>
              )}

              {i18n.language.substring(0,2) == "pt" ? (
                <Typography
                  mt={3}
                  pl={20.75}
                  fontSize="3vw"
                  color="#606060"
                  fontWeight={400}
                  fontFamily="Montserrat"
                  alignSelf="flex-start"
                >
                  Você não precisa pagar taxas de câmbio para <br />
                  produtos cotados em dólar, a Kahsh é aceita
                  <br /> globalmente.
                </Typography>
              ) : (
                <Typography
                  mt={3}
                  pl={20.75}
                  fontSize="3vw"
                  color="#606060"
                  fontWeight={400}
                  fontFamily="Montserrat"
                  alignSelf="flex-start"
                >
                  You don't need to pay exchange fees for <br />
                  products quoted in dollars, Kahsh is accepted
                  <br /> globally.
                </Typography>
              )}
            </Grid>

            <Grid
              py={10}
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              <Grid xs={10} display="flex">
                <img src={LojaKahshStoreWeb} width={"100%"} alt="Lojas" />
                <Grid mt="23rem" ml="-5rem">
                  <IconButton className="kahshPayAcesseAgoraIcon">
                    <SmallCircleButton />
                  </IconButton>
                </Grid>
              </Grid>

              {i18n.language.substring(0,2) == "pt" ? (
                <Typography
                  mt={3}
                  pl={12.5}
                  fontSize="3vw"
                  color="#001C57"
                  fontWeight={700}
                  fontFamily="Montserrat"
                  alignSelf="flex-start"
                >
                  Ingresso crescente de parceiros.
                </Typography>
              ) : (
                <Typography
                  mt={3}
                  pl={12.5}
                  fontSize="3vw"
                  color="#001C57"
                  fontWeight={700}
                  fontFamily="Montserrat"
                  alignSelf="flex-start"
                >
                  Growing ingress of partners.
                </Typography>
              )}

              {i18n.language.substring(0,2) == "pt" ? (
                <Typography
                  mt={3}
                  pl={12.5}
                  fontSize="3vw"
                  color="#606060"
                  fontWeight={400}
                  fontFamily="Montserrat"
                  alignSelf="flex-start"
                >
                  Com a ascensão dos dois mundos: cripto e <br />
                  e-commerce; não se surpreenda com a variedade <br />
                  de segmentos que vai encontrar.
                </Typography>
              ) : (
                <Typography
                  mt={3}
                  pl={12.5}
                  fontSize="3vw"
                  color="#606060"
                  fontWeight={400}
                  fontFamily="Montserrat"
                  alignSelf="flex-start"
                >
                  With the rise of the two worlds: crypto and <br />
                  e-commerce; don't be surprised by the variety <br />
                  of segments you will find.
                </Typography>
              )}
            </Grid>

            <Grid
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              <Grid xs={10} display="flex">
                <img src={CafeKahshStoreWeb} width="100%" alt="Café" />
                <Grid mt="23rem" ml="-5rem">
                  <IconButton className="kahshPayAcesseAgoraIcon">
                    <SmallCircleButton />
                  </IconButton>
                </Grid>
              </Grid>

              {i18n.language.substring(0,2) == "pt" ? (
                <Typography
                  mt={3}
                  pl={12.5}
                  fontSize="3vw"
                  color="#001C57"
                  fontWeight={700}
                  fontFamily="Montserrat"
                  alignSelf="flex-start"
                >
                  De compras casuais às especiais.
                </Typography>
              ) : (
                <Typography
                  mt={3}
                  pl={12.5}
                  fontSize="3vw"
                  color="#001C57"
                  fontWeight={700}
                  fontFamily="Montserrat"
                  alignSelf="flex-start"
                >
                  From casual to special purchases.
                </Typography>
              )}

              {i18n.language.substring(0,2) == "pt" ? (
                <Typography
                  mt={3}
                  pl={12.5}
                  fontSize="3vw"
                  color="#606060"
                  fontWeight={400}
                  fontFamily="Montserrat"
                  alignSelf="flex-start"
                >
                  Das compras mais corriqueiras, do dia a dia,
                  <br /> até aquela que você vinha pesquisando a<br /> tempos.
                  Confira.
                </Typography>
              ) : (
                <Typography
                  mt={3}
                  pl={12.5}
                  fontSize="3vw"
                  color="#606060"
                  fontWeight={400}
                  fontFamily="Montserrat"
                  alignSelf="flex-start"
                >
                  From the most common purchases, from day to day,
                  <br /> even the one you've been researching for a while. Check
                  out.
                </Typography>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};
