import { Grid, Typography } from "@mui/material";

import useWindowDimensions from "../../../hooks/useWindowDimensions";

import { useTranslation } from "react-i18next";

import ArrowsPhone from "../../../imagens/Arrows&Phone.png";
import LogoKahshSomenteIconRedondo2 from "../../../imagens/LogoKahshSomenteIconRedondo3.svg";

export const Transacoes = (props) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  return (
    <>
      {width > 900 ? (
        <Grid display="flex" alignItems="center">
          <Grid
            display="flex"
            px={{ md: 8, lg: 19 }}
            pb={{ md: "8vw", xl: 35 }}
          >
            <img
              src={LogoKahshSomenteIconRedondo2}
              width="53px"
              alt="Logo Kahsh"
            />
            <Grid pl={5}>
              <Typography
                fontFamily="Montserrat"
                color="#292930"
                fontWeight={400}
                fontSize={{ md: "3.9vw", xl: 48 }}
                lineHeight="1em"
                dangerouslySetInnerHTML={{
                  __html: t("uncomplicatedTransactionsExchange"),
                }}
              />
              <Typography
                pt={3}
                fontFamily="Montserrat"
                color="#575D6B"
                fontWeight={300}
                fontSize={{ md: "1.5vw", xl: 20 }}
                dangerouslySetInnerHTML={{
                  __html: t("kahshSimplePraticalSafe"),
                }}
              />
            </Grid>
          </Grid>

          <Grid container item justifyContent="right">
            <img src={ArrowsPhone} width="100%" alt="Imagem de Celular" />
          </Grid>
        </Grid>
      ) : (
        <Grid
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid ml="25rem" mb="-4rem">
            <img
              src={LogoKahshSomenteIconRedondo2}
              width="100%"
              alt="Logo Kahsh"
            />
          </Grid>

          <Grid container px={4}>
            <Typography
              fontFamily="Montserrat"
              color="#292930"
              fontWeight={400}
              fontSize="10vw"
              lineHeight="1.2em"
              dangerouslySetInnerHTML={{
                __html: t("uncomplicatedTransactionsExchange"),
              }}
            />

            <Typography
              pt={1}
              fontFamily="Montserrat"
              color="#575D6B"
              fontWeight={300}
              fontSize="6.5vw"
              lineHeight="1.2em"
              dangerouslySetInnerHTML={{
                __html: t("kahshSimplePraticalSafe"),
              }}
            />
          </Grid>

          <Grid container mt={4}>
            <img src={ArrowsPhone} width="100%" alt="Imagem de Celular" />
          </Grid>
        </Grid>
      )}
    </>
  );
};
