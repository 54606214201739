import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Exchange } from "./pages/Exchange";
import { Home } from "./pages/Home";
import { KahshPay } from "./pages/KahshPay";
import { KahshStoreNovo } from "./pages/KahshStoreNovo";
import { useAlert } from "./hooks/useAlert";
import { Autentication2 } from "./pages/autentication/Autentication2.tsx";
import { CalculadoraLogada } from "./pages/CalculadoraLogada";
import { CaptureScreen } from "./pages/captureScreen/CaptureScreen";
import { Extrato } from "./pages/extrato/Extrato";
import { FastBuyPage } from "./pages/FastBuyPage";
import { FastBuyPaid } from "./pages/FastBuyPaid";
import { InvestNovo } from "./pages/InvestNovo";
import { KahshFoundation } from "./pages/KahshFoundation";
import { KahshStore } from "./pages/KahshStore";
import { Novidades } from "./pages/Novidades";
import { OscarFormulario } from "./pages/oscarFormulario/OscarFormulario";
import { RegisterAccount } from "./pages/registerAccount/RegisterAccount";
import { StakingNOVO } from "./pages/Staking2";
import { StakingPage } from "./pages/StakingPage";
import { KahshCard } from "./pages/kahshCard/kahshCard";
import { KahshCardRegister } from "./pages/kahshCardRegister/Register";
import Staking from "./pages/staking/Staking";
import ReferAFriend from "./pages/referAFriend/ReferAFriend";
import KahshLabs from "./pages/kahshLabs/KahshLabs";

const App = () => {
  const { Alert } = useAlert();
  return (
    <BrowserRouter>
      <Alert />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/exchange" exact component={Exchange} />
        <Route path="/kahshstore" exact component={KahshStore} />
        <Route path="/KahshStoreNovo" exact component={KahshStoreNovo} />
        <Route path="/Kahshpay" exact component={KahshPay} />
        <Route path="/foundation" exact component={KahshFoundation} />
        <Route path="/news" exact component={Novidades} />
        {/* <Route path="/stakingNEW" exact component={Staking} /> */}
        <Route path="/referrer" exact component={ReferAFriend} />
        <Route path="/staking" exact component={StakingPage} />
        <Route path="/staking2" exact component={StakingNOVO} />
        <Route
          path="/invest/:markGet?/:monthGet?"
          exact
          component={CalculadoraLogada}
        />
        <Route path="/cardvisa" exact component={KahshCard} />
        <Route path="/cardvisa/request" exact component={KahshCardRegister} />
        <Route path="/invest2" exact component={InvestNovo} />
        <Route path="/extrato" exact component={Extrato} />
        <Route path="/authentication" exact component={Autentication2} />
        <Route path="/fastbuy" exact component={FastBuyPage} />
        <Route path="/fastbuypaid" exact component={FastBuyPaid} />
        <Route path="/oscarformulario" exact component={OscarFormulario} />
        <Route
          path="/startse"
          component={() => {
            window.location.href = "https://exchange.kahsh.com/startse";
            return null;
          }}
        />
        <Route path="/promo" exact component={CaptureScreen} />
        <Route path="/register" exact component={RegisterAccount} />
        <Route path="/kahshlabs" exact component={KahshLabs} />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
