import { Container, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import useSharedLink from "../../../hooks/useSharedLink";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import "./ExchanceProducts.styles.css";

import backgroundExchangeProductsMobile from "../../../imagens/backgroundExchangeProductsMobile.png";
import backgroundExchangeProductsWeb from "../../../imagens/backgroundExchangeProductsWeb.png";
import { Button } from "../../Button/Button";

export const ExchanceProducts = () => {
  useSharedLink();
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  return (
    <>
      {width > 900 ? (
        <Container maxWidth="xl">
          <Grid container item alignItems="center" justifyContent="center">
            <img
              src={backgroundExchangeProductsWeb}
              width="90%"
              alt="Fundo azul web"
            />
            <Grid
              container
              position="absolute"
              direction="column"
              alignItems="center"
              py={10}
            >
              <Typography
                fontSize={{ md: "2.8vw", xl: 42 }}
                color="#FFFFFF"
                fontWeight={400}
                dangerouslySetInnerHTML={{
                  __html: t("buyProductsPayingKahsh"),
                }}
              />

              <Typography
                py={{ md: 2, lg: 5 }}
                textAlign="center"
                fontSize={{ md: "2.8vw", xl: 42 }}
                color="#FFFFFF"
                fontWeight={400}
                dangerouslySetInnerHTML={{
                  __html: t("TradeCryptoAssets"),
                }}
              />

              <Typography
                fontSize={32}
                fontWeight={300}
                color="#FFFFFF"
                pb={5}
                dangerouslySetInnerHTML={{
                  __html: t("DigitalWalletComplete"),
                }}
              />

              <Button
                size="medium"
                sx={{
                  backgroundColor: "#FFFFFF",
                  padding: "20px",
                  width: { md: "22%", lg: "25%" },
                  maxWidth: 550,
                  color: "#2522BA",
                  fontWeight: "700",
                  fontSize: { md: "1.2vw", lg: "24px" },
                  "&:hover": {
                    color: "#ffffff",
                  },
                }}
                onClick={() =>
                  (window.location.href = "https:exchange.kahsh.com/login")
                }
              >
                {t("mountWalletExhange")}
              </Button>
            </Grid>
          </Grid>
        </Container>
      ) : (
        <Grid container alignItems="center" justifyContent="center" pb={6}>
          <img
            src={backgroundExchangeProductsMobile}
            width="100%"
            alt="Fundo Azul Mobile"
          />

          <Grid
            container
            position="absolute"
            alignItems="center"
            justifyContent="center"
            direction="column"
          >
            <Typography
              fontWeight={300}
              fontSize={{ xs: "8vw" }}
              textAlign="center"
              color="#FFFFFF"
              lineHeight="1.2em"
              dangerouslySetInnerHTML={{
                __html: t("buyProductsPayingKahshMobile"),
              }}
            />

            <Typography
              textAlign="center"
              fontWeight={400}
              fontSize={{ xs: "4vw" }}
              color="#FFFFFF"
              lineHeight="1.2em"
              pt="4rem"
              pb="8rem"
              dangerouslySetInnerHTML={{
                __html: t("TradeCryptoAssetsMobile"),
              }}
            />

            <Typography
              textAlign="center"
              fontWeight={300}
              fontSize={{ xs: "7vw" }}
              color="#FFFFFF"
              lineHeight="1.3em"
              pb="4rem"
              dangerouslySetInnerHTML={{
                __html: t("DigitalWalletCompleteMobile"),
              }}
            />

            <Button
              sx={{
                backgroundColor: "#FFFFFF",
                padding: { xs: "3.7rem", sm: "4rem" },
                width: "70%",
                fontWeight: "700",
                color: "#2522BA",
                fontSize: { xs: "1.5rem", sm: "2rem" },
              }}
              onClick={() =>
                (window.location.href = "https:exchange.kahsh.com/login")
              }
            >
              {t("mountWalletExhange")}
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};
