import { Typography, Box, Stepper, Step, StepLabel } from "@mui/material";
import { useState } from "react";
import { FieldValues } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FooterMini } from "../../components/footerMini/FooterMini";
import { PersonalData } from "./PersonalData";
import { Address } from "./Address";
import { Deposit } from "./Deposit";
import { Login } from "./Login";
import axios from "axios";
import { urlKahshCardExchange } from "../../services/global";
import { useAlert } from "../../hooks/useAlert";

interface CardRegisterResponse {
  success: boolean;
  msg: string;
  pix: {
    txid: string;
    qrcode: string;
    img: string;
  };
}

const steps = ["stepLogin", "stepPersonalData", "stepAddress", "stepDeposit"];

export const KahshCardRegister = () => {
  const [data, setData] = useState<FieldValues>({});
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);

  const { t } = useTranslation();
  const { AlertMessage } = useAlert();

  const handleSubmit = async (stepData: FieldValues) => {
    if (step < steps.length - 2) {
      setStep((prev) => prev + 1);
      return setData((prev) => ({ ...prev, ...stepData }));
    }
    try {
      setLoading(true);

      const { data: res } = await axios.post<CardRegisterResponse>(
        urlKahshCardExchange,
        {
          ...data,
          ...stepData,
        },
        {
          headers: {},
        }
      );
      if (res?.pix?.txid) {
        localStorage.setItem("cardVisaTxId", res?.pix?.txid);
        AlertMessage("success", "Cadastro realizado com sucesso");
        setStep((prev) => prev + 1);
      } else {
        AlertMessage("error", res?.msg || "defaultMessage");
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      AlertMessage("error", "defaultMessage", err);
    }
  };

  const handleBack = () => {
    setStep((prev) => prev - 1);
  };

  const alreadyExists = () => {
    setStep(steps?.length - 2);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      height="calc(100% - 32px)"
      mt={4}
    >
      <Stepper
        activeStep={step}
        connector={<Typography mx={1}>|</Typography>}
        sx={{ mb: 4, mx: "auto" }}
      >
        {steps.map((label, index) => (
          <Step
            key={label}
            completed={index < step}
            sx={{
              p: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            <StepLabel
              sx={{
                ".MuiStepIcon-root": {
                  color: (theme) =>
                    index <= step ? theme.palette.primary.main : "#fff",
                  width: 30,
                  height: 30,
                },
                ".MuiStepIcon-text": {
                  fill: (theme) =>
                    index !== step ? theme.palette.primary.main : "#fff",
                  fontWeight: "500",
                  fontSize: "1rem",
                },
              }}
            />

            <Typography color="grey.400">{t(label)}</Typography>
          </Step>
        ))}
      </Stepper>
      {step === 0 && (
        <Login alreadyExists={alreadyExists} onSubmit={handleSubmit} />
      )}
      {step === 1 && (
        <PersonalData
          onSubmit={handleSubmit}
          defaultValues={data}
          onBack={handleBack}
        />
      )}
      {step === 2 && (
        <Address
          onSubmit={handleSubmit}
          onBack={handleBack}
          defaultValues={data}
          loading={loading}
        />
      )}
      {step === 3 && <Deposit />}
      <br />
      <FooterMini />
    </Box>
  );
};
