import { Grid, Typography } from "@mui/material";

import useWindowDimensions from "../../../hooks/useWindowDimensions";

import i18n from "i18next";
import { useTranslation } from "react-i18next";

import CelularComMaoMobileExchange from "../../../imagens/CelularComMaoMobileExchange.png";
import CelularComMaoWebExchange from "../../../imagens/CelularComMaoWebExchange.png";
import MoedasESetas from "../../../imagens/MoedasESetas.svg";

export const MonteSuaCarteira = (props) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  return (
    <>
      {width > 1024 ? (
        <>
          <Grid
            container
            justifyContent="center"
            mt={14}
            mb={26}
            position="relative"
          >
            <Grid item md={11} lg={9} container flexDirection="column">
              <Typography
                fontFamily="DM Sans"
                color="#292930"
                fontWeight={600}
                fontSize={{ xs: "5vw", lg: "48px" }}
              >
                {t("monteSuaCarteira")}
              </Typography>
              {i18n.language.substring(0, 2) === "pt" ? (
                <Typography
                  pt={3}
                  fontFamily="Poppins"
                  color="#27272E"
                  fontWeight={300}
                  fontSize={{ xs: "1.7vw", lg: "20px" }}
                  lineHeight="2vw"
                >
                  Cadastre sua conta, adquira moedas Kahsh e passe a <br />
                  integrar todo o nosso Ecossistema.
                </Typography>
              ) : (
                <Typography
                  pt={3}
                  fontFamily="Poppins"
                  color="#27272E"
                  fontWeight={300}
                  fontSize={{ xs: "1.7vw", lg: "20px" }}
                  lineHeight="2vw"
                >
                  Register your account, purchase Kahsh coins and pass the{" "}
                  <br />
                  integrate our entire ecosystem.
                </Typography>
              )}

              {i18n.language.substring(0, 2) === "pt" ? (
                <Typography
                  pt={4.25}
                  mb={4}
                  fontWeight={300}
                  fontFamily="Montserrat"
                  color="#292930"
                  fontSize={{ xs: "5vw", lg: "48px" }}
                  lineHeight="1.1"
                >
                  A <b>maneira</b> mais <b>fácil</b>
                  <br />
                  de <b>converter Real</b>
                  <br />
                  em <b>Kahsh</b>
                </Typography>
              ) : (
                <Typography
                  pt={4.25}
                  mb={4}
                  fontWeight={300}
                  fontFamily="Montserrat"
                  color="#292930"
                  fontSize={{ xs: "5vw", lg: "48px" }}
                  lineHeight="1.1"
                >
                  The <b>easiest</b> <b>way</b>
                  <br />
                  <b>convert Real</b>
                  <br />
                  in <b></b>Kahsh
                </Typography>
              )}

              {i18n.language.substring(0, 2) === "pt" ? (
                <Typography
                  fontFamily="Poppins"
                  color="#292930"
                  fontWeight={400}
                  fontSize={{ xs: "1.7vw", lg: "20px" }}
                  lineHeight="2vw"
                >
                  Com a sua carteira Kahsh, você usa livremente nosso <br />
                  ecossistema para qualquer operação que desejar.
                </Typography>
              ) : (
                <Typography
                  fontFamily="Poppins"
                  color="#292930"
                  fontWeight={400}
                  fontSize={{ xs: "1.7vw", lg: "20px" }}
                  lineHeight="2vw"
                >
                  With your Kahsh wallet, you freely use our <br />
                  ecosystem for any operation you want.
                </Typography>
              )}

              <Grid my={7} pr="5rem">
                <img src={MoedasESetas} alt="" width="450" />
              </Grid>
            </Grid>

            <Grid
              container
              position="absolute"
              justifyContent="flex-end"
              zIndex={0.1}
              ml={-6}
            >
              <img src={CelularComMaoWebExchange} width="45%" alt="" />
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid
            pt={8}
            container
            justifyContent="center"
            id="monteSuaCarteiraContainer"
          >
            <Grid
              item
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              {i18n.language.substring(0, 2) === "pt" ? (
                <Typography
                  fontFamily="DM Sans"
                  fontStyle="normal"
                  color="#292930"
                  fontWeight={600}
                  fontSize="6rem"
                  lineHeight="7rem"
                  letterSpacing="-0.125rem"
                >
                  Monte sua
                  <br />
                  Carteira
                </Typography>
              ) : (
                <Typography
                  fontFamily="DM Sans"
                  fontStyle="normal"
                  color="#292930"
                  fontWeight={600}
                  fontSize={"6rem"}
                  letterSpacing="-0.025rem"
                >
                  Build your
                  <br />
                  Wallet
                </Typography>
              )}

              {i18n.language.substring(0, 2) === "pt" ? (
                <Typography
                  pt="5rem"
                  pb="5rem"
                  fontFamily="Poppins"
                  fontStyle="normal"
                  color="#27272E"
                  fontWeight={300}
                  fontSize="2.5rem"
                  lineHeight="3.5rem"
                >
                  Cadastre sua conta, <br />
                  adquira moedas Kahsh e <br />
                  passe a integrar todo o <br />
                  nosso Ecossistema.
                </Typography>
              ) : (
                <Typography
                  pt="5rem"
                  pb="5rem"
                  fontFamily="Poppins"
                  fontStyle="normal"
                  color="#27272E"
                  fontWeight={300}
                  fontSize="2.5rem"
                  lineHeight="3.5rem"
                >
                  Register your account, <br />
                  purchase Kahsh coins and <br />
                  pass the integrate <br />
                  our entire ecosystem.
                </Typography>
              )}
              <Grid>
                <img src={CelularComMaoMobileExchange} width="100%" alt="" />
              </Grid>

              {i18n.language.substring(0, 2) === "pt" ? (
                <Typography
                  fontFamily="Montserrat"
                  color="#292930"
                  fontWeight={300}
                  fontSize="4rem"
                  lineHeight="6rem"
                >
                  A <b>maneira</b>
                  mais
                  <br />
                  <b> fácil</b> de <b>converter </b>
                  <br />
                  <b>Real</b> em <b>Kahsh!</b>
                </Typography>
              ) : (
                <Typography
                  fontFamily="Montserrat"
                  color="#292930"
                  fontWeight={300}
                  fontSize="4rem"
                  lineHeight="6rem"
                >
                  The <b>easiest </b>way
                  <br /> to <b>convert</b> <br />
                  <b> Real (BRL)</b> in <br />
                  <b>Kahsh!</b>
                </Typography>
              )}

              <Grid my={7}>
                <img src={MoedasESetas} alt="" width="325rem" />{" "}
              </Grid>

              {i18n.language.substring(0, 2) === "pt" ? (
                <Typography
                  fontFamily="Poppins"
                  fontStyle="normal"
                  color="#292930"
                  fontWeight={400}
                  fontSize="2.3rem"
                  pb="10rem"
                >
                  Com a sua carteira Kahsh, você <br />
                  usa livremente nosso <br />
                  ecossistema para qualquer <br />
                  operação que desejar.
                </Typography>
              ) : (
                <Typography
                  fontFamily="Poppins"
                  fontStyle="normal"
                  color="#292930"
                  fontWeight={400}
                  fontSize="2.3rem"
                  pb="10rem"
                >
                  With your Kahsh wallet, you <br />
                  freely use our <br />
                  ecosystem for any <br />
                  operation you want.
                </Typography>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};
