import { Box, Grid, Typography } from "@mui/material";

import useWindowDimensions from "../../../hooks/useWindowDimensions";

import i18n from "i18next";
import { useTranslation } from "react-i18next";

import PessoasExchange from "../../../imagens/PessoasExchange.png";
import SetaPraCima from "../../../imagens/SetaPraCima.png";
import UserExchangeIcon from "../../../imagens/UserExchangeIcon.png";
import LogoKahshInclinada from "../../../imagens/LogoKahshInclinada.png";
import DollarSign from "../../../imagens/DollarSign.png";
import { Button } from "../../Button/Button";

import Arrow from "../../../imagens/SetaPraCima.png";
import User from "../../../imagens/UserExchangeIcon.png";
import Kahsh from "../../../imagens/LogoKahshInclinada.png";
import Dollar from "../../../imagens/DollarSign.png";

export const PrimeirosPassos = (props) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  const items = [
    { id: 0, img: Arrow, body: t("AcesseAPlataforma") },
    { id: 1, img: User, body: t("ConecteSe") },
    { id: 2, img: Kahsh, body: t("comeceANegociar") },
    { id: 3, img: Dollar, body: t("ganheDinheiro") },
  ];

  return (
    <>
      {width > 1024 ? (
        <>
          <Grid
            container
            my={12}
            justifyContent="space-around"
            id="primeirosPassosContainer"
          >
            <Grid
              item
              xs={6}
              md={5}
              display="flex"
              alignItems="end"
              flexDirection="column"
            >
              <Grid>
                {i18n.language.substring(0, 2) == "pt" ? (
                  <Typography
                    pt={14}
                    fontFamily="Montserrat"
                    color="#292930"
                    fontWeight={300}
                    fontSize={36}
                  >
                    Primeiros <b>passos</b>
                  </Typography>
                ) : (
                  <Typography
                    pt={14}
                    fontFamily="Montserrat"
                    color="#292930"
                    fontWeight={300}
                    fontSize={36}
                  >
                    First <b>steps</b>
                  </Typography>
                )}
                <Typography
                  pt={2}
                  fontFamily="Montserrat"
                  color="#575D6B"
                  fontWeight={400}
                  fontSize={18}
                >
                  {t("comeceEmPoucosCliques")}
                </Typography>

                {items.map((item, index) => {
                  return (
                    <Grid
                      key={item.id}
                      item
                      container
                      mt={index === 0 ? 5 : 1}
                      mb={2}
                      lg={8}
                      alignItems="center"
                      borderRadius={6}
                      bgcolor="#FFFFFF"
                      border="1px solid #DBD9D9"
                    >
                      <Grid py={1} px={1} mr={1}>
                        <img src={item.img} alt="" />
                      </Grid>

                      <Typography
                        fontFamily="Montserrat"
                        color="#292930"
                        fontWeight={600}
                        fontSize={18}
                      >
                        {item.body}
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>

            <Grid xs={6} container justifyContent="center">
              <img src={PessoasExchange} width="100%" alt="" />
            </Grid>
            <Button
              sx={{
                marginTop: 5,
                width: { md: "25%", xl: "25%" },
                padding: 5,
                color: "#FFFFFF",
                fontWeight: "700",
                fontSize: 24,
              }}
              onClick={() =>
                (window.location.href = "https://exchange.kahsh.com/register")
              }
            >
              {t("criarContaTrade")}
            </Button>
          </Grid>
        </>
      ) : (
        <>
          <Grid
            container
            mb={12}
            justifyContent="center"
            alignItems="center"
            pt={{ sm: 8 }}
          >
            <Grid>
              <Grid
                container
                px={4}
                alignItems="center"
                justifyContent="center"
              >
                {i18n.language.substring(0, 2) == "pt" ? (
                  <Typography
                    pt={1}
                    fontFamily="Montserrat"
                    color="#292930"
                    fontWeight={300}
                    fontSize={{ xs: "36px", sm: "56px" }}
                    textAlign="center"
                  >
                    Primeiros <b>passos</b>
                  </Typography>
                ) : (
                  <Typography
                    pt={1}
                    fontFamily="Montserrat"
                    color="#292930"
                    fontWeight={300}
                    fontSize={{ xs: "36px", sm: "56px" }}
                  >
                    First <b>steps</b>
                  </Typography>
                )}

                <Typography
                  pt={1}
                  textAlign="center"
                  fontFamily="Montserrat"
                  color="#575D6B"
                  fontWeight={400}
                  fontSize={{ xs: "5vw", sm: 30 }}
                >
                  {t("startwithfew")}
                </Typography>

                {items.map((item, index) => {
                  return (
                    <Grid
                      key={item.id}
                      item
                      container
                      mt={index === 0 ? 5 : 1}
                      mb={2}
                      lg={8}
                      alignItems="center"
                      borderRadius={6}
                      bgcolor="#FFFFFF"
                      border="1px solid #DBD9D9"
                    >
                      <Grid py={1} px={1} mr={1}>
                        <img src={item.img} alt="" />
                      </Grid>

                      <Typography
                        fontFamily="Montserrat"
                        color="#292930"
                        fontWeight={600}
                        fontSize={{ xs: "3.7vw", sm: 30 }}
                      >
                        {item.body}
                      </Typography>
                    </Grid>
                  );
                })}
                <Button
                  size="medium"
                  sx={{
                    marginTop: 5,
                    marginBottom: 5,
                    padding: "3.5rem",
                    width: "95%",
                    color: "#FFFFFF",
                    fontWeight: 700,
                    fontSize: { xs: "3.8vw", sm: "3vw" },
                  }}
                  onClick={() =>
                    (window.location.href =
                      "https://exchange.kahsh.com/register")
                  }
                >
                  {t("criarContaTrade")}
                </Button>
              </Grid>
              <Grid container justifyContent="center">
                <img src={PessoasExchange} width="80%" alt="" />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};
