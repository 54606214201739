import { Grid, Typography, IconButton, Button, Divider } from "@mui/material";

import useWindowDimensions from "../../../hooks/useWindowDimensions";

import { useTranslation } from "react-i18next";
import i18n from "i18next";

import SmallCircleButton from "../../../icons/SmallCircleButton";

import Chat from "../../../imagens/Chat.png";
import logoKahshBranco from "../../../imagens/logoKahshBranco.png";
import AcesseAgoraBackground2 from "../../../imagens/AcesseAgoraBackground2.png";
import fundoAcesseAgoraMobile from "../../../imagens/fundoAcesseAgoraMobile.png";

import "./AcesseAgora.styles.css";

export const AcesseAgora = (props) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  const AcesseAgoraIMG = {
    height: 1014,
    backgroundImage: `url(${AcesseAgoraBackground2})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: "100%",
    borderRadius: 40,
  };

  return (
    <>
      {width > 1024 ? (
        <>
          <Grid
            container
            mt={12}
            mb={8}
            justifyContent="center"
            id="kahshPayAcesseAgora"
          >
            <Grid
              item
              md={10}
              lg={11}
              style={AcesseAgoraIMG}
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              pt={22}
            >
              <Grid
                item
                xs={12}
                pb={18}
                container
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
              >
                <Grid>
                  <img src={logoKahshBranco} alt="Logo Kahsh" />
                </Grid>

                {i18n.language.substring(0, 2) == "pt" ? (
                  <Typography
                    pt={3}
                    pb={3}
                    fontFamily="Montserrat"
                    textAlign="center"
                    color="#FFFFFF"
                    fontWeight={500}
                    fontSize={44}
                  >
                    <b>Kahsh Store, </b>compras com
                    <br /> criptomoedas com <b>praticidade.</b>
                  </Typography>
                ) : (
                  <Typography
                    pt={3}
                    pb={3}
                    fontFamily="Montserrat"
                    textAlign="center"
                    color="#FFFFFF"
                    fontWeight={500}
                    fontSize={44}
                  >
                    <b>Kahsh Store, </b>shopping with
                    <br /> Cryptocurrencies with <b>practicality.</b>
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12} pb={2} display="flex">
                <Grid
                  item
                  xs={6}
                  display="flex"
                  alignItems="end"
                  justifyContent="center"
                  flexDirection="column"
                >
                  {i18n.language.substring(0, 2) == "pt" ? (
                    <Typography
                      pt={14}
                      pr={30}
                      fontFamily="Montserrat"
                      textAlign="center"
                      color="#FFFFFF"
                      fontWeight={500}
                      fontSize={48}
                    >
                      Acesse
                      <br />
                      <b>Agora!</b>
                    </Typography>
                  ) : (
                    <Typography
                      pt={14}
                      pr={30}
                      fontFamily="Montserrat"
                      textAlign="center"
                      color="#FFFFFF"
                      fontWeight={500}
                      fontSize={48}
                    >
                      Access
                      <br />
                      <b>Now!</b>
                    </Typography>
                  )}
                </Grid>

                <Grid
                  item
                  xs={6}
                  display="flex"
                  alignItems="start"
                  justifyContent="center"
                  flexDirection="column"
                >
                  <Grid pt="0rem" pl={15} className="chatAcesseAgora">
                    <img
                      src={Chat}
                      alt="Logo Kahsh"
                      style={{ maxWidth: 150 }}
                    />
                  </Grid>

                  {i18n.language.substring(0, 2) === "pt" ? (
                    <>
                      <Grid display="flex">
                        <Typography
                          pl={15}
                          mr={5}
                          fontFamily="Montserrat"
                          textAlign="justify"
                          color="#FFFFFF"
                          fontWeight={500}
                          fontSize={30}
                        >
                          <b>
                            Cadastre-se e
                            <br />
                            comece a aproveitar
                            <br />
                            os benefícios.
                          </b>
                        </Typography>
                        <IconButton className="kahshPayAcesseAgoraIcon">
                          <SmallCircleButton />
                        </IconButton>
                      </Grid>
                    </>
                  ) : (
                    <Typography
                      pl={15}
                      mr={22}
                      fontFamily="Montserrat"
                      textAlign="justify"
                      color="#FFFFFF"
                      fontWeight={500}
                      fontSize={30}
                    >
                      <b>
                        Log in and check out
                        <br />
                        the varieties and
                        <br />
                        discounts.
                      </b>
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid container id="kahshPayAcesseAgora" my={15} px={2}>
            <Grid
              py={5}
              borderRadius={10}
              bgcolor="#4733FF"
              alignItems="center"
              justifyContent="center"
              display="flex"
              flexDirection="column"
            >
              <Grid>
                <img src={logoKahshBranco} alt="Logo Kahsh" />
              </Grid>

              {i18n.language.substring(0, 2) == "pt" ? (
                <Typography
                  pt={3}
                  pb={10}
                  fontFamily="Montserrat"
                  textAlign="center"
                  color="#FFFFFF"
                  fontWeight={300}
                  fontSize="3.2rem"
                  lineHeight="4rem"
                  px={5}
                >
                  A <b>KahshPay</b> te traz a maior <b>praticidade</b> de
                  ingresso no <b>mundo cripto.</b>
                </Typography>
              ) : (
                <Typography
                  pt={3}
                  pb={10}
                  fontFamily="Montserrat"
                  textAlign="center"
                  color="#FFFFFF"
                  fontWeight={300}
                  fontSize="3.2rem"
                  lineHeight="4rem"
                  px={4.5}
                >
                  <b>KahshPay</b> brings the biggest <b>practicality</b> of
                  entry into the <b>crypto world.</b>
                </Typography>
              )}

              <Divider className="acesseAgoraDiviverMobile" />

              {i18n.language.substring(0, 2) == "pt" ? (
                <Typography
                  fontFamily="Montserrat"
                  textAlign="justify"
                  color="#FFFFFF"
                  fontWeight={500}
                  fontSize="2rem"
                  lineHeight="3rem"
                >
                  Cadastre-se e comece a <br />
                  aproveitar os benefícios.
                </Typography>
              ) : (
                <Typography
                  pt={5}
                  fontFamily="Montserrat"
                  textAlign="justify"
                  color="#FFFFFF"
                  fontWeight={500}
                  fontSize="2rem"
                  lineHeight="3rem"
                >
                  Sign up and start <br />
                  enjoy the benefits.
                </Typography>
              )}

              {i18n.language.substring(0, 2) == "pt" ? (
                <Typography
                  pt={5}
                  fontFamily="Montserrat"
                  textAlign="center"
                  color="#FFFFFF"
                  fontWeight={400}
                  fontSize="6rem"
                  lineHeight="6rem"
                >
                  Acesse
                  <br />
                  <b>Agora!</b>
                </Typography>
              ) : (
                <Typography
                  pt={5}
                  fontFamily="Montserrat"
                  textAlign="center"
                  color="#FFFFFF"
                  fontWeight={400}
                  fontSize="6rem"
                  lineHeight="6rem"
                >
                  Access
                  <br />
                  <b>Now!</b>
                </Typography>
              )}

              <Grid mt={5} p="1.5rem" bgcolor="#FFFFFF" borderRadius={3}>
                <Button
                  onClick={() =>
                    (window.location.href =
                      "https://exchange.kahsh.com/register")
                  }
                >
                  <Typography
                    fontFamily="Montserrat"
                    textAlign="center"
                    color="#2522ba"
                    fontWeight={700}
                    fontSize="2rem"
                  >
                    {t("montarCarteira")}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};
