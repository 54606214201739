import { styled } from '@mui/material';
import Input from 'react-phone-number-input';
import { TextFieldStyled } from '../text-field/text-field.styles';

interface PhoneInputStyledProps {
  error: any;
}

export const PhoneInputStyled = styled(Input)<PhoneInputStyledProps>`
  .PhoneInputCountry {
    margin-left: 18px;
    margin-top: ${({ error }) => (error ? -24 : 0)}px;
  }
`;

interface PhoneTextFieldProps {
  value: string | undefined;
}
export const PhoneTextField = styled(TextFieldStyled)<PhoneTextFieldProps>`
  .MuiOutlinedInput-notchedOutline {
    margin-left: -59px;
    border-radius: 15px
  }

  .MuiInputLabel-root {
    margin-left: ${({ value }) => (value ? -59 : 0)}px;
    &.Mui-focused {
      margin-left: -59px;
    }
  }

  .MuiFormHelperText-root {
    margin-left: -47px;
  }
`;
