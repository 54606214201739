import { Grid, Typography, Box, Button } from "@mui/material";
import Background from "./../../../imagens/Escudo.svg";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";

import "./SobreAKahsh.styles.css";
import useSharedLink from "../../../hooks/useSharedLink";

export const SobreAKahsh = (props) => {
  useSharedLink();
  const { t } = useTranslation();
  const matches = useMediaQuery("(min-width:1148px)");

  return (
    <>
      {!matches ? (
        <Grid
          item
          xs={12}
          py={12}
          container
          justifyContent="center"
          alignItems="center"
          id="valorKahsh"
        >
          <Grid item xs={12}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              <Box ml={10} mt={4}>
                <Typography variant="h2" className="valorKahshHeader">
                  {t("aboutKash")}
                </Typography>
              </Box>
              <Box width={326}>
                <Typography className="valorKahshDescription">
                  {" "}
                  {t("integratedFinancial_p1")}{" "}
                  <font>
                    <i>blockchain </i>
                  </font>
                  {t("integratedFinancial_p2")}{" "}
                  <font>
                    <i>staking </i>
                  </font>
                  {t("integratedFinancial_p3")}
                </Typography>
              </Box>
              <Box className="ButtonSobreKahsh">
                <Button href="#ganheCripto">{t("kahshPayStartNow")}</Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              pt={5}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <img className="imgvalorKahsh" src={Background} alt="" />
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Grid
          item
          xs={12}
          container
          py={12.5}
          display="flex"
          alignItems="center"
          id="valorKahsh"
        >
          <Grid item xs={6} pl={12.5}>
            <Box>
              <img className="imgvalorKahsh" src={Background} alt="" />
            </Box>
          </Grid>
          <Grid item xs={6} pr={12.5}>
            <Box>
              <Box display="flex" flexDirection="column">
                <Typography
                  variant="h2"
                  align="right"
                  className="valorKahshHeader"
                >
                  {t("aboutKash")}
                </Typography>
                <Box>
                  <Typography align="right" className="valorKahshDescription">
                    {t("integratedFinancial_p1")}{" "}
                    <font>
                      <i>blockchain </i>
                    </font>
                    {t("integratedFinancial_p2")}{" "}
                    <font>
                      <i>staking </i>
                    </font>
                    {t("integratedFinancial_p3")}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  className="ButtonSobreKahsh"
                  ml={"78%"}
                >
                  <Button href="#ganheCripto">{t("kahshPayStartNow")}</Button>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
};
