// import { useState, useEffect } from "react";
import {  Grid, Box, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
// import { ButtonBorder } from "../../buttonsBorderWhite/ButtonBorder";
import Dollar from "../../../imagens/Dollar.png";
import Bag from "../../../imagens/ShoppingBag.png";
import Tags from "../../../imagens/Tags.png";
import Sales from "../../../imagens/Sale.png";

import "./KahshStoreFacilita.styles.css";
import useSharedLink from "../../../hooks/useSharedLink";

export const KahshStoreFacilita = () => {
  useSharedLink();
  // const [isWidth, setIsWidth] = useState(false);
  const matches = useMediaQuery("(min-width:767px)");
  const { t } = useTranslation();

  // useEffect(() => {
  //   console.log(matches);
  //   setIsWidth(matches);
  // }, [window.innerWidth]);

  return (
    <>
      {!matches ? (
        <Grid
        item
          xs={12}
          container
          justifyContent="center"
          alignItems="center"
          paddingTop="40px"
          paddingBottom="40px"
          flexDirection="column"
        >
          <Grid
          item
            xs={10}
            pb={10}
            className="section-title"
            display="flex"
            justifyContent="center"
            alignItems="left"
            flexDirection="column"
          >
            <Typography
              style={{
                marginBottom: "30px",
                fontFamily: "DM Sans",
                fontWeight: "700",
                fontSize: "48px",
                color: "#242831",
              }}
            >
              {t("kahshStoreFacilitaTitulo")}
            </Typography>
            <Typography
              className="subtitleGallery"
              style={{ width: "55%", textAlign: "left" }}
            >
              {t("kahshStoreFacilitaDescricaoMobile")}
            </Typography>
          </Grid>

          <Grid
            sx={{
              backgroundColor: "#FCFCFC",
              borderRadius: "24px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "24px",
            }}
          >
            <Box
              sx={{
                borderRadius: "16px",
              }}
            >
              <Box className="Sale">
                <img src={Sales} alt="" />
                <Typography
                  variant="h4"
                  style={{
                    fontWeight: 500,
                    fontFamily: "Montserrat",
                    marginTop: 7,
                  }}
                >
                  {t("kahshStoreFacilitaBaixoFrete")}
                </Typography>
                <Typography
                  variant="h6"
                  style={{
                    width: "40%",
                    color: "#838383",
                    textAlign: "center",
                    fontWeight: 400,
                    fontFamily: "Montserrat",
                    marginTop: 7,
                  }}
                >
                  {t("kahshStoreFacilitaBaixoFreteDescricao")}
                </Typography>
              </Box>
              <Box className="Discount">
                <img src={Tags} alt="" />
                <Typography
                  variant="h4"
                  style={{
                    fontWeight: 500,
                    fontFamily: "Montserrat",
                    marginTop: 7,
                  }}
                >
                  {t("kahshStoreFacilitaDesconto")}
                </Typography>
                <Typography
                  variant="h6"
                  style={{
                    width: "60%",
                    color: "#838383",
                    textAlign: "center",
                    fontWeight: 400,
                    fontFamily: "Montserrat",
                    marginTop: 7,
                  }}
                >
                  {t("kahshStoreFacilitaDescontoDescricaoMobile")}
                </Typography>
              </Box>
              <Box className="Pix">
                <img src={Bag} alt="" />
                <Typography
                  variant="h4"
                  style={{
                    fontWeight: 500,
                    fontFamily: "Montserrat",
                    marginTop: 7,
                  }}
                >
                  {t("kahshStoreFacilitaPix")}
                </Typography>
                <Typography
                  variant="h6"
                  style={{
                    width: "40%",
                    color: "#838383",
                    textAlign: "center",
                    fontWeight: 400,
                    fontFamily: "Montserrat",
                    marginTop: 7,
                  }}
                >
                  {t("kahshStoreFacilitaPixDescricaoMobile")}
                </Typography>
              </Box>
              <Box className="Safe">
                <img src={Dollar} alt="" style={{ marginBottom: 35 }} />
                <Typography
                  variant="h4"
                  style={{
                    fontWeight: 500,
                    fontFamily: "Montserrat",
                  }}
                >
                  {t("kahshStoreFacilitaSeguro")}
                </Typography>
                <Typography
                  variant="h6"
                  style={{
                    width: "80%",
                    color: "#838383",
                    textAlign: "center",
                    fontWeight: 400,
                    fontFamily: "Montserrat",
                    marginTop: 7,
                  }}
                >
                  {t("kahshStoreFacilitaSeguroDescricao")}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Grid
        item
          xs={12}
          container
          justifyContent="center"
          alignItems="center"
          paddingTop="40px"
          paddingBottom="40px"
          flexDirection="column"
        >
          <Grid
          item
            xs={10}
            pb={10}
            className="section-title"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Typography pt={2} pb={2.5} varian="h2" className="titleGallery">
              {t("kahshStoreFacilitaTitulo")}
            </Typography>
            <Typography
              className="subtitleGallery"
              style={{ width: "50%", textAlign: "center" }}
            >
              {t("kahshStoreFacilitaDescricao")}
            </Typography>
          </Grid>

          <Grid
            sx={{
              backgroundColor: "#FCFCFC",
              borderRadius: "24px",
              width: "80%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              padding: "24px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                borderRadius: "16px",
              }}
              className="BoxContainer"
            >
              <Box className="Sale">
                <img
                  src={Sales}
                  alt=""
                  style={{ width: "40%", height: "82px" }}
                />
                <Typography
                  variant="h4"
                  style={{
                    fontWeight: 500,
                    fontFamily: "Montserrat",
                    marginTop: 7,
                  }}
                >
                  {t("kahshStoreFacilitaBaixoFrete")}
                </Typography>
                <Typography
                  variant="h6"
                  style={{
                    width: "60%",
                    color: "#838383",
                    textAlign: "center",
                    fontWeight: 400,
                    fontFamily: "Montserrat",
                    marginTop: 7,
                  }}
                >
                  {t("kahshStoreFacilitaBaixoFreteDescricao")}
                </Typography>
              </Box>
              <Box className="Discount">
                <img
                  src={Tags}
                  alt=""
                  style={{ width: "30%", height: "82px" }}
                />
                <Typography
                  variant="h4"
                  style={{
                    fontWeight: 500,
                    fontFamily: "Montserrat",
                    marginTop: 7,
                  }}
                >
                  {t("kahshStoreFacilitaDesconto")}
                </Typography>
                <Typography
                  variant="h6"
                  style={{
                    width: "70%",
                    color: "#838383",
                    textAlign: "center",
                    fontWeight: 400,
                    fontFamily: "Montserrat",
                    marginTop: 7,
                  }}
                >
                  {t("kahshStoreFacilitaDescontoDescricao")}
                </Typography>
              </Box>
              <Box className="Pix">
                <img
                  src={Bag}
                  alt=""
                  style={{ width: "40%", height: "82px" }}
                />
                <Typography
                  variant="h4"
                  style={{
                    fontWeight: 500,
                    fontFamily: "Montserrat",
                    marginTop: 7,
                  }}
                >
                  {t("kahshStoreFacilitaPix")}
                </Typography>
                <Typography
                  variant="h6"
                  style={{
                    width: "60%",
                    color: "#838383",
                    textAlign: "center",
                    fontWeight: 400,
                    fontFamily: "Montserrat",
                    marginTop: 7,
                  }}
                >
                  {t("kahshStoreFacilitaPixDescricao")}
                </Typography>
              </Box>
              <Box className="Safe">
                <img
                  src={Dollar}
                  alt=""
                  style={{ width: "50%", height: "50.83px", marginBottom: 35 }}
                />
                <Typography
                  variant="h4"
                  style={{
                    fontWeight: 500,
                    fontFamily: "Montserrat",
                  }}
                >
                  {t("kahshStoreFacilitaSeguro")}
                </Typography>
                <Typography
                  variant="h6"
                  style={{
                    width: "80%",
                    color: "#838383",
                    textAlign: "center",
                    fontWeight: 400,
                    fontFamily: "Montserrat",
                    marginTop: 7,
                  }}
                >
                  {t("kahshStoreFacilitaSeguroDescricao")}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
};
