import { Grid, Typography, Container } from "@mui/material";

import { useTranslation } from "react-i18next";
import i18n from "i18next";

import BackgroundKahshStoreWeb from "../../../imagens/BackgroundKahshStoreWeb.png";
import BackgroundKahshStoreMobile from "../../../imagens/BackgroundKahshStoreMobile.png";

import useWindowDimensions from "../../../hooks/useWindowDimensions";

import "./KahshStoreVideo.styles.css";

export const KahshStoreVideo = () => {
  const { width } = useWindowDimensions();

  const BackgroundKahshStoreMobileIMG = {
    height: "70rem",
    backgroundImage: `url(${BackgroundKahshStoreMobile})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: "100%",
    borderRadius: 15,
  };

  return (
    <>
      {width > 1200 ? (
        <>
          <Container fixed className="kahshStoreContainer">
            <Grid
              container
              direction="row"
              className="kahsh-informations"
              item
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                className="kahshStoreTitleContainer"
                xs={6}
                mt={-6}
                ml="-8vw"
                lg={4}
                container
                item
                direction="column"
              >
                {i18n.language.substring(0, 2) == "pt" ? (
                  <Typography
                    fontWeight={600}
                    color={"#232340"}
                    className="kahshStoreTitle"
                    fontSize="4.5rem"
                    lineHeight="6rem"
                  >
                    Quer <b>vender </b>
                    <br /> ou <b>comprar?</b>
                  </Typography>
                ) : (
                  <Typography
                    fontWeight={600}
                    color={"#232340"}
                    className="kahshStoreTitle"
                    fontSize="4.5rem"
                    lineHeight="6rem"
                  >
                    Want <b>seller </b>
                    <br /> or <b>buy?</b>
                  </Typography>
                )}

                {i18n.language.substring(0, 2) == "pt" ? (
                  <Typography
                    fontFamily="Montserrat"
                    color="#232340"
                    fontWeight={400}
                    fontSize="1.6rem"
                    lineHeight="3rem"
                  >
                    As melhores taxas para quem vende e os
                    <br />
                    melhores descontos para quem compra.
                  </Typography>
                ) : (
                  <Typography
                    fontFamily="Montserrat"
                    color="#232340"
                    fontWeight={400}
                    fontSize="1.6rem"
                    lineHeight="3rem"
                  >
                    The best rates for those who sell and the
                    <br />
                    best discounts for those who buy.
                  </Typography>
                )}
              </Grid>
              <Grid
                className="kahshStoreViceoContainer"
                xs={6}
                lg={4}
                container
                item
                direction="column"
              >
                <Grid className="storeVideoContainer">
                  <Grid
                    zIndex={2.0}
                    className="video"
                    mt={-5}
                    ml={-3}
                    borderRadius={10}
                    width="90%"
                  >
                    <iframe
                      width="44%"
                      height="22%"
                      className="kahsh-video"
                      src="https://player.vimeo.com/video/703991107?autoplay=false"
                      frameBorder="0"
                      allow="fullscreen; picture-in-picture"
                      allowFullScreen
                      title=" "
                    />
                  </Grid>
                </Grid>
              </Grid>
              <img src={BackgroundKahshStoreWeb}
                height={600}
                width={1350}
              />
            </Grid>
          </Container>
        </>
      ) : (
        <>
          <Grid
            pb={10}
            container
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Container fixed>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
              >
                <Grid>
                  {i18n.language.substring(0, 2) == "pt" ? (
                    <Typography
                      fontWeight={600}
                      color={"#232340"}
                      className="kahshStoreTitle"
                      fontSize="5rem"
                      lineHeight="6rem"
                    >
                      Quer <b>vender </b>
                      <br /> ou <b>comprar?</b>
                    </Typography>
                  ) : (
                    <Typography
                      fontWeight={600}
                      color={"#232340"}
                      className="kahshStoreTitle"
                      fontSize="5rem"
                      lineHeight="6rem"
                    >
                      Want <b>seller </b>
                      <br /> or <b>buy?</b>
                    </Typography>
                  )}

                  {i18n.language.substring(0, 2) == "pt" ? (
                    <Typography
                      fontFamily="Montserrat"
                      color="#232340"
                      fontWeight={400}
                      fontSize="1.8rem"
                      lineHeight="3rem"
                    >
                      As melhores taxas para quem vende e os
                      <br />
                      melhores descontos para quem compra.
                    </Typography>
                  ) : (
                    <Typography
                      fontFamily="Montserrat"
                      color="#232340"
                      fontWeight={400}
                      fontSize="1.8rem"
                      lineHeight="3rem"
                    >
                      The best rates for those who sell and the
                      <br />
                      best discounts for those who buy.
                    </Typography>
                  )}
                </Grid>

                <Grid pt={6} container className="storeVideoContainer">
                  <Grid
                    container
                    className="videoKahshStore"
                    borderRadius={10}
                    width="100%"
                    style={BackgroundKahshStoreMobileIMG}
                  >
                    <iframe
                      width="44%"
                      height="22%"
                      className="kahsh-video"
                      src="https://player.vimeo.com/video/703991107?autoplay=false"
                      frameBorder="0"
                      allow="fullscreen; picture-in-picture"
                      allowFullScreen
                      title=" "
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </Grid>
        </>
      )}
    </>
  );
};
