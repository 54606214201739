import { Grid, Typography, Button } from "@mui/material";

import { useTranslation } from "react-i18next";

import useSharedLink from "../../../hooks/useSharedLink";

import Trapezium from "../../../imagens/trapezium-background.png";
import aboutKahshImg from "../../../imagens/about-kahsh.png";
import coinBlueAndWhite from "../../../imagens/coinBlueAndWhite.png";

import useWindowDimensions from "../../../hooks/useWindowDimensions";

import "./CaptureGuaranteeBonus.styles.css";

export const CaptureGuaranteeBonus = () => {
  useSharedLink();
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  return (
    <Grid container xs={12} mt={4}>
      <Grid
        container
        xs={12}
        height={"95%"}
        bgcolor="#232340"
        px={width > 900 ? 12 : 4}
        pb={8}
        justifyContent="center"
        alignItems="cente"
      >
        <Grid mt={10} item xs={12} container alignItems="center">
          <Grid container item xs={12} sm={6} direction="column">
            <Typography
              lineHeight="1.2em"
              fontSize={55}
              fontWeight={700}
              color="#FFFFFF"
              zIndex={10}
            >
              {t("captureGuaranteeBonus")}
            </Typography>

            <Typography
              mt={3}
              fontFamily="Montserrat"
              fontSize={25}
              color="#CBCBCB"
              fontWeight={400}
              lineHeight="1.2em"
              zIndex={10}
            >
              {t("captureCheckAccount")}
            </Typography>
          </Grid>

          <Grid
            xs={12}
            sm={6}
            container
            borderRadius={3}
            justifyContent="center"
            mt={width < 900 ? 4 : 0}
            zIndex={10}
          >
            <img src={coinBlueAndWhite} alt="Imagem" width="100%" />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        xs={12}
        height={"100%"}
        position="absolute"
        justifyContent="flex-end"
        zIndex={0.1}
      >
        <img
          src={Trapezium}
          alt=""
          height={"80%"}
          width={width < 600 ? "35%" : "52%"}
        />
      </Grid>
    </Grid>
  );
};
