import { Button, Box } from "@mui/material";
import "./SaibaMaisButton.styles.css";

export const SaibaMaisButton = (props) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      className="boxSaibaMais"
      width={props.width}
      height={props.height}
    >
      <Button
        onClick={props.onClick}
        size="medium"
        {...props}
        className="buttonSaibaMais"
      />
    </Box>
  );
};
