import { useState, useEffect } from "react";
import { Grid } from "@mui/material";

import SmoothScroll from "smooth-scroll";

import { NavigationStaking } from "../components/navigationStaking/NavigationStaking";
import { Calculadora } from "../components/Staking2/calculadora/CalculadoraNova";
import { StakePorqueKahsh } from "../components/Staking2/stakePorqueKahsh/StakePorqueKahsh";
import { EmUmContrato } from "../components/Staking2/emUmContrato/EmUmContrato";
import { MelhoresFormas } from "../components/Staking2/melhoresFormas/MelhoresFormas";

import { Footer } from "../components/footer/Footer2";
import { IntroStaking } from "../components/Staking2/introStaking/IntroStaking";

import JsonData from "../data/data.json";

import "./Pages.styles.css";
import { KahshPayVejaQuantoBlackTable } from "../components/Staking2/kahshPayVejaQuantoBlackTable/KahshPayVejaQuantoBlackTable";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

export function StakingNOVO() {
  const [landingPageData, setLandingPageData] = useState({});

  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div className="stakingNovoPage">
      {/* <Grid pt={4}>
        <NavigationStaking data={landingPageData.NavigationStaking} />
      </Grid> */}
      <IntroStaking data={landingPageData.IntroStaking} />
      <MelhoresFormas data={landingPageData.MelhoresFormas} />
      <EmUmContrato data={landingPageData.EmUmContrato} />
      <KahshPayVejaQuantoBlackTable data={landingPageData.KahshPayVejaQuantoBlack} />
      <Calculadora data={landingPageData.Calculadora}/>
      <StakePorqueKahsh data={landingPageData.StakePorqueKahsh} />
      <Footer data={landingPageData.Footer} />
    </div>
  );
}
