import { Grid, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import { Calculadora } from "../../calculadora/Calculadora";
import "./KahshPayVejaQuantoBlack.styles.css";
import stakingImg from "../../../imagens/stakingImg.png";
import { useEffect, useState } from "react";
import { accordionValues } from "./accordionValues";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import upArrow from "../../../imagens/upArrow.png";
import FlechaHorizontal from "../../../imagens/FlechaHorizontal.svg";
import useSharedLink from "../../../hooks/useSharedLink";

export const KahshPayVejaQuantoBlack = () => {
  useSharedLink();
  const { t, i18n } = useTranslation();
  const matches = useMediaQuery("(min-width:1271px)");
  const [coin, setCoin] = useState(10000000000000000000);

  useEffect(() => {
    var fetchData = async () => {
      const data = await fetch("https://exchange.kahsh.com/cotacao/USD");
      const json = await data.json();
      setCoin(Number(json[2].cotacao));
    };

    fetchData();
  }, []);

  const getCurrencyFormat = (currency, currencyText) => {
    currency = currency / coin;
    currency = Math.ceil(currency);
    var currencyText = "";
    var currencyAbreviado = 0;

    if (currency < 1000000) {
      currency = currency.toString();
      currencyAbreviado = Math.round((10 * currency) / 1000) / 10;
      currencyText = `${currencyAbreviado} mil`;
    } else if (currency >= 1000000) {
      currency = currency.toString();
      currencyAbreviado = Math.round((10 * currency) / 1000000) / 10;
      currencyText = `${currencyAbreviado} ${t("mi")}`;
    }

    if (i18n.language === "pt") {
      return currencyText.replace(".", ",");
    } else {
      return currencyText;
    }
  };

  return (
    <>
      {!matches ? (
        <Grid
          width="96%"
          borderRadius={6}
          ml={2.2}
          // height="1700px"
          id="ganheCripto"
          container
          item
          xs={12}
        >
          <Grid container className="seeHowMuchYouCanEarnBox">
            <Grid item flex={1} pl={2}>
              <Grid>
                {i18n.language === "pt" || i18n.language === "pt-BR" ? (
                  <Typography variant="h2">
                    Staking é uma das{" "}
                    <span style={{ color: "#4733FF" }}>
                      melhores formas de obter renda passiva
                    </span>{" "}
                    utilizando suas criptomoedas.
                  </Typography>
                ) : (
                  <Typography variant="h2">
                    Staking is one of{" "}
                    <span style={{ color: "#4733FF" }}>
                      the best ways of earning passive income
                    </span>{" "}
                    using crypto.
                  </Typography>
                )}
              </Grid>
              <Grid item mt={4}>
                <Typography classes="stakingParagraph" variant="h3">
                  {t("stakingParagraphOne")}
                </Typography>
                <Typography classes="stakingParagraph" variant="h3" mt={4}>
                  {t("stakingParagraphTwo")}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid className="seeHowMuchYouCanEarnBox" mb={5} pl={2} mt={4}>
            <Typography classes="stakingParagraph" variant="h3">
              {t("stakingParagraphThree")}
            </Typography>
          </Grid>
          <Grid justifyContent="center" textAlign="center" width="100%">
            <img src={stakingImg} alt="" width={253} />
          </Grid>
          <Grid className="seeHowMuchYouCanEarnBox" mb={5} pl={2} mt={5}>
            {i18n.language === "pt" || i18n.language === "pt-BR" ? (
              <Typography variant="h2">
                Agora é só escolher o{" "}
                <span style={{ color: "#4733FF" }}>
                  contrato que melhor atende seus objetivos
                </span>
              </Typography>
            ) : (
              <Typography variant="h2">
                Now just choose the{" "}
                <span style={{ color: "#4733FF" }}>
                  contract that best meets your goals
                </span>
              </Typography>
            )}
          </Grid>
          <Accordion
            defaultExpanded
            sx={{
              paddingLeft: 2,
              width: "98%",
              marginBottom: 5,
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                background:
                  "linear-gradient(90.16deg, #4733FF 27%, #FFFFFF 125.09%)",
                boxShadow:
                  "0px 1px 2px rgba(97, 97, 97, 0.2), 0px 2px 4px rgba(97, 97, 97, 0.2)",
                borderRadius: "8px",
              }}
            >
              <Typography className="accordion-header">
                {t("checkNowBetterContracts")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                backgroundColor: "#FAFAFA",
                borderRadius: "0px 0px 16px 16px",
              }}
            >
              <Grid display="flex">
                <Grid flex={1} mt={6}>
                  {accordionValues.map((e) => {
                    return (
                      <>
                        <section class="grid grid-template-columns-1">
                          <Typography class="grid-header">
                            {t("coinQuantity1")} {t("coinQuantity2")}
                          </Typography>
                          <Typography class="grid-header">
                            {t("value")} (US$)
                          </Typography>
                          <Typography class="item item-light item1">{`${getCurrencyFormat(
                            e.value1
                          )} ${t("to")} ${getCurrencyFormat(
                            e.value2
                          )}`}</Typography>
                          <Typography class="item item-light item2">{`${
                            e.value3
                          } ${t("to")} ${e.value4}`}</Typography>
                        </section>
                        <section class="grid grid-template-columns-2">
                          <Typography class="grid-header">
                            12 {t("months")}
                          </Typography>
                          <Typography class="grid-header">
                            24 {t("months")}
                          </Typography>
                          <Typography class="grid-header">
                            36 {t("months")}
                          </Typography>
                          <Typography class="item item-bold item3">
                            {e.value5}
                          </Typography>
                          <Typography class="item item-bold item4">
                            {e.value6}
                          </Typography>
                          <Typography class="item item-bold item5">
                            {e.value7}
                          </Typography>
                        </section>
                      </>
                    );
                  })}
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Calculadora />
        </Grid>
      ) : (
        <Grid
          container
          item
          xs={12}
          pt={7}
          id="ganheCripto"
          justifyContent="center"
        >
          <Grid width={"90%"} container className="seeHowMuchYouCanEarnBox">
            <Grid item flex={1}>
              <Grid>
                {i18n.language === "pt" || i18n.language === "pt-BR" ? (
                  <Typography variant="h2">
                    Staking é uma das{" "}
                    <span style={{ color: "#4733FF" }}>
                      melhores formas de obter renda passiva
                    </span>{" "}
                    utilizando suas criptomoedas.
                  </Typography>
                ) : (
                  <Typography variant="h2">
                    Staking is one of{" "}
                    <span style={{ color: "#4733FF" }}>
                      the best ways of earning passive income
                    </span>{" "}
                    using crypto.
                  </Typography>
                )}
              </Grid>
              <Grid item mt={4}>
                <Typography classes="stakingParagraph" variant="h3">
                  {t("stakingParagraphOne")}
                </Typography>
                <Typography classes="stakingParagraph" variant="h3" mt={4}>
                  {t("stakingParagraphTwo")}
                </Typography>
              </Grid>
            </Grid>
            <Grid mt={-1}>
              <img src={stakingImg} alt="" />
            </Grid>
          </Grid>
          <Grid className="seeHowMuchYouCanEarnBox" width={"90%"} mb={5}>
            <Typography classes="stakingParagraph" variant="h3">
              {t("stakingParagraphThree")}
            </Typography>
          </Grid>
          <Grid className="seeHowMuchYouCanEarnBox" width="90%" mb={5}>
            {i18n.language === "pt" || i18n.language === "pt-BR" ? (
              <Typography variant="h2">
                Agora é só escolher o{" "}
                <span style={{ color: "#4733FF" }}>
                  contrato que melhor atende seus objetivos
                </span>
              </Typography>
            ) : (
              <Typography variant="h2">
                Now just choose the{" "}
                <span style={{ color: "#4733FF" }}>
                  contract that best meets your goals
                </span>
              </Typography>
            )}
          </Grid>
          <Accordion
            defaultExpanded
            sx={{
              width: "90%",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                background:
                  "linear-gradient(90.16deg, #4733FF 27%, #FFFFFF 125.09%)",
                boxShadow:
                  "0px 1px 2px rgba(97, 97, 97, 0.2), 0px 2px 4px rgba(97, 97, 97, 0.2)",
                borderRadius: "8px",
              }}
            >
              <Typography className="accordion-header">
                {t("checkNowBetterContracts")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                backgroundColor: "#FAFAFA",
                borderRadius: "0px 0px 16px 16px",
              }}
            >
              <Grid display="flex">
                <Grid flex={1} mt={6}>
                  <section class="grid grid-template-columns-1">
                    <Typography class="grid-header">
                      {t("coinQuantity1")} <br />
                      {t("coinQuantity2")}
                    </Typography>
                    <Typography class="grid-header">
                      {t("value")} (US$)
                    </Typography>
                    <Typography class="grid-header">
                      12 {t("months")}
                    </Typography>
                    <Typography class="grid-header">
                      24 {t("months")}
                    </Typography>
                    <Typography class="grid-header">
                      36 {t("months")}
                    </Typography>
                  </section>
                  {accordionValues.map((e) => {
                    return (
                      <section class="grid grid-template-columns-1">
                        <Typography class="item item-light item1">{`${getCurrencyFormat(
                          e.value1
                        )} ${t("to")} ${getCurrencyFormat(
                          e.value2
                        )}`}</Typography>
                        <Typography class="item item-light item2">{`${
                          e.value3
                        } ${t("to")} ${e.value4}`}</Typography>
                        <Typography class="item item-bold item3">
                          {e.value5}
                        </Typography>
                        <Typography class="item item-bold item4">
                          {e.value6}
                        </Typography>
                        <Typography class="item item-bold item5">
                          {e.value7}
                        </Typography>
                      </section>
                    );
                  })}
                </Grid>
                <Grid pl={3} pt={17}>
                  <img src={upArrow} alt="" />
                </Grid>
              </Grid>

              {/* horizontal */}
              <Grid pl={40}>
                <img src={FlechaHorizontal} alt="" />
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Grid
            className="seeHowMuchYouCanEarnBox"
            width="90%"
            mb={5}
            mt={5}
            display="flex"
          >
            <Grid>
              {i18n.language === "pt" || i18n.language === "pt-BR" ? (
                <Typography variant="h2">
                  Quanto você pode receber fazendo staking
                  <span style={{ color: "#4733FF" }}> na Kahsh:</span>
                </Typography>
              ) : (
                <Typography variant="h2">
                  How much you can earn by staking{" "}
                  <span style={{ color: "#4733FF" }}> on Kahsh:</span>
                </Typography>
              )}
            </Grid>
            {/* <Grid
              display="flex"
              justifyContent="center"
              flex={0.2}
              alignItems="center"
            >
              <Button className="startNow" sx={{ borderRadius: "100px" }}>
                <Typography className="buttonTyp">{t("startNow")}</Typography>
              </Button>
            </Grid> */}
          </Grid>
          <Calculadora />
        </Grid>
      )}
    </>
  );
};
