import { Grid, Typography, Box } from "@mui/material"
import { SaibaMaisButton } from "../../buttons/SaibaMaisButton"
import ImgMaiorClube3 from './../../../imagens/imgMaiorClube3.png'
// import { useState, useEffect } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import "./KahshStoreMaiorClube.styles.css"
import { useTranslation } from "react-i18next";
import useSharedLink from "../../../hooks/useSharedLink";

export const KahshStoreMaiorClube = () => {
  useSharedLink();

  // const [isWidth, setIsWidth] = useState(false);
  const matches = useMediaQuery('(min-width:1330px)');
  const { t } = useTranslation();

  // useEffect(() => {
  //   setIsWidth(matches)
  // }, [window.innerWidth])

  const ImgMaiorClubeBackground = {
    width: "100%",
    height: "826px",
    backgroundPosition: '100% -20px',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${ImgMaiorClube3})`
  };

  return (
    <>
      {!matches ?
        (
          <Grid container justifyContent="center" alignItems="center" id="KahshStoreMaiorClube">
            <Grid width="80%">
              <Typography className="theBigClubBox" variant="h2"> {t("buyWithKahshStore")}</Typography>
              <Typography py={4} className="theBestPricesBox">{t("theBestPrices")}</Typography>
              <SaibaMaisButton href="#KahshStoreMaiorClube">{t("knowMore")}</SaibaMaisButton>
            </Grid>
            <Grid className="imgMaiorClube">
              <img src={ImgMaiorClube3} alt='' />
            </Grid>
          </Grid>
        ) : (
          <Grid container id="KahshStoreMaiorClube">
            <Box justifyContent="flex-end" style={ImgMaiorClubeBackground}>
              <Box pt={29} pl={12.5}>
                <Typography className="theBigClubBox" variant="h2">
                  {t("kahshStore")}<br />
                  {t("theBigClub1")}<br />
                  {t("theBigClub2")}
                </Typography>
                <Box width={500}>
                  <Typography py={4} className="theBestPricesBox">{t("theBestPrices")}</Typography>
                </Box>
                <SaibaMaisButton href="#KahshStoreMaiorClube">{t("knowMore")}</SaibaMaisButton>
              </Box>
            </Box>
          </Grid>
        )
      }
    </>
  )
}


