import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Logout } from "@mui/icons-material";
import { AddReaction } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Avatar,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Select,
  Toolbar,
  Typography,
} from "@mui/material";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { useAuthState } from "react-firebase-hooks/auth";
import { Br, Us } from "react-flags-select";
import { initReactI18next, useTranslation } from "react-i18next";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import logoKKahsh from "../../imagens/logoKKahsh.svg";
import metaverso from "../../imagens/metaverso.png";
import { auth, logout } from "../../services/firebase";
import { TRANSLATIONS_EN } from "../../translate/en";
import { TRANSLATIONS_PT } from "../../translate/pt";
import "./Navigation.styles.css";
import { accountsCallBack } from "../../services/accountsCallBack";
import axios from "axios";
import { ACCOUNTS_API_KEY, BASE_API_URL_ACCOUNTS } from "../../services/global";
import { signInWithCustomToken } from "firebase/auth";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      pt: {
        translation: TRANSLATIONS_PT,
      },
      en: {
        translation: TRANSLATIONS_EN,
      },
    },
  });

export const Navigation = (props) => {
  const { t, i18n } = useTranslation();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const open = Boolean(anchorElNav);
  const { width } = useWindowDimensions();
  const [user, loading] = useAuthState(auth);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const handleClickUser = (e) => {
    setAnchorElNav(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorElNav(null);
  };

  const callLogout = () => {
    logout();
    history.push("/");
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOnclickPT = () => {
    i18n.changeLanguage("pt");
  };

  const handleOnclickEN = () => {
    i18n.changeLanguage("en");
  };

  const pages = [
    { title: `${t("ecosystem")}`, target: "_self", href: "/#ecosystem" },
    { title: `${t("exchange")}`, target: "_self", href: "/exchange" },
    // { title: `${t("Card Visa")}`, target: "_self", href: "/cardvisa" },
    { title: `${t("Kahsh Labs")}`, target: "_self", href: "/kahshLabs" },
    {
      title: `${t("kahshStore")}`,
      target: "_blank",
      href: "https://www.kahshstore.com.br/",
    },
    { title: `${t("kahshPay")}`, target: "_self", href: "/kahshpay" },
    {
      title: `${t("foundation")}`,
      target: "_self",
      href: "/foundation",
    },
    { title: `${t("stake")}`, target: "_self", href: "/staking" },
    { title: `${t("extract")}`, target: "_self", href: "/extrato" },
    {
      title: `${t("news")}`,
      target: "_blank",
      href: "https://kahsh.medium.com/",
    },
  ];

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("isLogin"))) {
      return;
    } else if (window.location.search) {
      let token;
      if (new URLSearchParams(window.location.search).get("remoteToken")) {
        setIsLoading(true);
        token = new URLSearchParams(window.location.search).get("remoteToken");

        const fetchRemoteToken = async (token) => {
          try {
            let res = await axios.post(
              BASE_API_URL_ACCOUNTS + "/login/remote",
              {
                token: token,
              },
              {
                headers: {
                  Authorization: ACCOUNTS_API_KEY,
                  "Content-Type": "application/json;charset=utf-8",
                  "Access-Control-Allow-Origin": "*",
                },
              }
            );

            await signInWithCustomToken(auth, res.data.customToken);
            setIsLoading(false);
            return localStorage.setItem("isLogin", true);
          } catch (error) {
            setIsLoading(false);
            return error;
          }
        };
        fetchRemoteToken(token);
      }
    }
  }, [user]);

  return (
    <>
      {width >= 1200 ? (
        <>
          <Grid
            {...props}
            container
            justifyContent="space-evenly"
            alignItems="center"
            mb={5}
            py={1}
            className="headerNavigation"
            boxShadow={"0px 4px 12px rgba(0, 0, 0, 0.09)"}
          >
            <Grid item className="containerNavigationLogo">
              <a href="/">
                <img src={logoKKahsh} alt="" />
              </a>
            </Grid>

            <Grid
              display="flex"
              flex={{ md: 0.58, lg: 0.8, xl: 0.58 }}
              alignItems="center"
              justifyContent="space-evenly"
            >
              {Array.from(
                pages,
                (page, index) =>
                    <a href={page.href} rel="noreferrer" key={index}>
                      <Typography
                        fontSize={"1.5rem"}
                        fontWeight={700}
                        color={props.color || "#838383"}
                      >
                        {page.title}
                      </Typography>
                    </a>
              )}
            </Grid>

            <Grid display="flex">
              <Grid className="kahshIoNavigationSelectTranslate">
                <Select
                  className="translateSelect"
                  labelId="translate"
                  id="translate"
                  defaultValue={
                    i18n.language.substring(0, 2) === "pt" ? "PTB" : "EN"
                  }
                >
                  <MenuItem
                    onClick={handleOnclickPT}
                    className="translate"
                    value="PTB"
                  >
                    <Br fontSize="2.5rem" />
                    <Typography
                      pl={1}
                      fontSize="1.3rem"
                      fontFamily="Montserrat"
                      fontWeight={700}
                      color="#838383"
                    >
                      Português (BR)
                    </Typography>
                  </MenuItem>

                  <MenuItem
                    onClick={handleOnclickEN}
                    className="translate"
                    value="EN"
                  >
                    <Us fontSize="2.5rem" />
                    <Typography
                      pl={1}
                      fontSize="1.3rem"
                      fontFamily="Montserrat"
                      fontWeight={700}
                      color="#838383"
                    >
                      English (US)
                    </Typography>
                  </MenuItem>
                </Select>
              </Grid>

              <Grid className="kahshIoNavigationyWrapperAuthentication">
                {isLoading ? (
                  <>
                    <Grid pl={5}>
                      <CircularProgress size={24} />
                    </Grid>
                  </>
                ) : !user && !loading ? (
                  <>
                    <Grid className="kahshIoNavigationMetaverso">
                      <Button>
                        <a
                          rel="noreferrer"
                          href="https://kahsh.land/#/"
                          target="_blank"
                        >
                          <img src={metaverso} alt="" />
                        </a>
                      </Button>
                    </Grid>

                    <Grid className="kahshIoNavigationCadastrar">
                      <Button
                        onClick={() => {
                          accountsCallBack("register");
                        }}
                      >
                        {t("register")}
                      </Button>
                    </Grid>

                    <Grid className="kahshIoNavigationEnter">
                      <Button
                        onClick={() => {
                          accountsCallBack("login");
                        }}
                      >
                        {t("enter")}
                      </Button>
                    </Grid>
                  </>
                ) : user ? (
                  <>
                    <Grid className="kahshIoNavigationyWrapperAuthentication">
                      <Grid className="kahshIoNavigationMetaverso">
                        <Button>
                          <a
                            rel="noreferrer"
                            href="https://kahsh.land/#/"
                            target="_blank"
                          >
                            <img src={metaverso} alt="" />
                          </a>
                        </Button>
                      </Grid>
                      <IconButton
                        onClick={handleClickUser}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? "account-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                      >
                        <Avatar sx={{ width: 32, height: 32 }}>
                          {user.email[0].toUpperCase()}
                        </Avatar>
                      </IconButton>
                    </Grid>
                  </>
                ) : null}
              </Grid>
            </Grid>
            <Menu
              anchorEl={anchorElNav}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              className="newMenu"
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem onClick={() => (window.location.href = "/referrer")}>
                <ListItemIcon>
                  <AddReaction fontSize="small" />
                </ListItemIcon>
                {t("referrerAFriend")}
              </MenuItem>
              <MenuItem onClick={() => callLogout()}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                {t("logoutAccount")}
              </MenuItem>
            </Menu>
          </Grid>
        </>
      ) : width < 1200 && width >= 1024 ? (
        <>
          <Grid
            container
            justifyContent="space-evenly"
            alignItems="center"
            py={1}
            className="headerNavigation"
            boxShadow={"0px 4px 12px rgba(0, 0, 0, 0.09)"}
          >
            <Grid item className="containerNavigationLogo">
              <a href="/">
                <img src={props.logo || logoKKahsh} alt="" />
              </a>
            </Grid>

            <Grid className="kahshIoNavigationBtns">
              <a href="/">
                <Typography>{t("ecosystem")}</Typography>
              </a>
              <a href="/exchange" target="_self">
                <Typography>{t("exchange")}</Typography>
              </a>
              <a href="/cardvisa" target="_self">
                <Typography>{t("Card Visa")}</Typography>
              </a>
              <a href="/kahshLabs" target="_self">
                <Typography>{t("Kahsh Labs")}</Typography>
              </a>
              <a
                rel="noreferrer"
                href="https://www.kahshstore.com.br"
                target="_blank"
              >
                <Typography>{t("kahshStore")}</Typography>
              </a>
              <a href="/kahshpay" target="_self">
                <Typography>{t("kahshPay")}</Typography>
              </a>
              <a href="/foundation" target="_self">
                <Typography>{t("foundation")}</Typography>
              </a>
              <Typography>{t("Smart Kahsh")}</Typography>
              <Grid>
                <Select id="stakeSelect">
                  <MenuItem>
                    <a href="/staking" target="_self">
                      <Typography pb={1}>{t("stake")}</Typography>
                    </a>
                  </MenuItem>
                  <MenuItem>
                    <a onClick={() => (window.location.href = "/extrato")}>
                      <Typography pt={1}>{t("extract")}</Typography>
                    </a>
                  </MenuItem>
                </Select>
              </Grid>
              <a
                rel="noreferrer"
                href="https://kahsh.medium.com/"
                target="_blank"
              >
                <Typography>{t("news")}</Typography>
              </a>
            </Grid>
            <Grid display="flex">
              <Grid className="kahshIoNavigationSelectTranslate">
                <Select
                  className="translateSelect"
                  labelId="translate"
                  id="translate"
                  defaultValue={
                    i18n.language.substring(0, 2) === "pt" ? "PTB" : "EN"
                  }
                >
                  <MenuItem
                    onClick={handleOnclickPT}
                    className="translate"
                    value="PTB"
                  >
                    <Br fontSize="2.5rem" />
                    <Typography
                      pl={1}
                      fontSize="1.3rem"
                      fontFamily="Montserrat"
                      fontWeight={700}
                      color="#838383"
                    >
                      Português (BR)
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={handleOnclickEN}
                    className="translate"
                    value="EN"
                  >
                    <Us fontSize="2.5rem" />
                    <Typography
                      pl={1}
                      fontSize="1.3rem"
                      fontFamily="Montserrat"
                      fontWeight={700}
                      color="#838383"
                    >
                      English (US)
                    </Typography>
                  </MenuItem>
                </Select>
              </Grid>

              <Grid className="kahshIoNavigationyWrapperAuthentication">
                {isLoading ? (
                  <>
                    <Grid pl={5}>
                      <CircularProgress size={24} />
                    </Grid>
                  </>
                ) : !user && !loading ? (
                  <>
                    <Grid className="kahshIoNavigationMetaverso">
                      <Button>
                        <a
                          rel="noreferrer"
                          href="https://kahsh.land/#/"
                          target="_blank"
                        >
                          <img src={metaverso} alt="" />
                        </a>
                      </Button>
                    </Grid>

                    <Grid className="kahshIoNavigationCadastrar">
                      <Button onClick={() => accountsCallBack("register")}>
                        {t("register")}
                      </Button>
                    </Grid>

                    <Grid className="kahshIoNavigationEnter">
                      <Button onClick={() => accountsCallBack("login")}>
                        {t("enter")}
                      </Button>
                    </Grid>
                  </>
                ) : (
                  <>
                    {user ? (
                      <Grid className="kahshIoNavigationyWrapperAuthentication">
                        <Grid className="kahshIoNavigationMetaverso">
                          <Button>
                            <a
                              rel="noreferrer"
                              href="https://kahsh.land/#/"
                              target="_blank"
                            >
                              <img src={metaverso} alt="" />
                            </a>
                          </Button>
                        </Grid>
                        <IconButton
                          onClick={handleClickUser}
                          size="small"
                          sx={{ ml: 2 }}
                          aria-controls={open ? "account-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                        >
                          <Avatar sx={{ width: 32, height: 32 }}>
                            {user.email[0].toUpperCase()}
                          </Avatar>
                        </IconButton>
                      </Grid>
                    ) : null}
                  </>
                )}
              </Grid>
            </Grid>
            {/* menu dentro do avatar */}
            <Menu
              anchorEl={anchorElNav}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              className="newMenu"
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem onClick={() => (window.location.href = "/referrer")}>
                <ListItemIcon>
                  <AddReaction fontSize="small" />
                </ListItemIcon>
                {t("referrerAFriend")}
              </MenuItem>
              <MenuItem onClick={() => callLogout()}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                {t("logoutAccount")}
              </MenuItem>
            </Menu>
          </Grid>
        </>
      ) : (
        <>
          <Grid
            {...props}
            container
            justifyContent="space-evenly"
            boxShadow={"0px 4px 12px rgba(0, 0, 0, 0.09)"}
            mb={4}
          >
            <AppBar position="fixed" elevation={0}>
              <Toolbar disableGutters className="toolbar">
                <Grid
                  item
                  width="100%"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid ml={3} item>
                    <a href="#page-top">
                      <img className="logoMobile" src={logoKKahsh} alt="" />
                    </a>
                  </Grid>
                  <Grid item pr={4} className="containerBtnHamburguer">
                    <IconButton
                      size="large"
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={handleOpenNavMenu}
                    >
                      <MenuIcon />
                    </IconButton>
                  </Grid>
                  <Menu
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                  >
                    <Menu
                      anchorEl={anchorElNav}
                      id="account-menu"
                      open={open}
                      onClose={handleClose}
                      onClick={handleClose}
                      className="newMenu"
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          overflow: "visible",
                          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                          mt: 1.5,
                          "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                          },
                          "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                          },
                        },
                      }}
                      transformOrigin={{ horizontal: "right", vertical: "top" }}
                      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    >
                      <Grid
                        item
                        px={3}
                        display="flex"
                        flexDirection="column"
                        className="containerNavigationUser"
                      >
                        <Grid
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          flexDirection="column"
                          pb={2}
                        >
                          <Grid pt={1}>
                            {!user && !loading ? (
                              <>
                                <Grid
                                  mb={1}
                                  className="kahshIoNavigationCadastrarMobile"
                                >
                                  <Button
                                    onClick={() => accountsCallBack("register")}
                                  >
                                    {t("register")}
                                  </Button>
                                </Grid>

                                <Grid
                                  mb={1}
                                  className="kahshIoNavigationEnterMobile"
                                >
                                  <Button
                                    onClick={() => accountsCallBack("login")}
                                  >
                                    {t("enter")}
                                  </Button>
                                </Grid>

                                <Grid className="kahshIoNavigationMetaversoMobile">
                                  <Button>
                                    <a
                                      rel="noreferrer"
                                      href="https://kahsh.land/#/"
                                      target="_blank"
                                    >
                                      <img src={metaverso} alt="" />
                                    </a>
                                  </Button>
                                </Grid>
                              </>
                            ) : (
                              <>
                                {user ? (
                                  <Grid
                                    container
                                    alignItems="center"
                                    justifyContent="center"
                                  >
                                    <IconButton
                                      onClick={handleClickUser}
                                      size="small"
                                      sx={{ ml: 2 }}
                                      aria-controls={
                                        open ? "account-menu" : undefined
                                      }
                                      aria-haspopup="true"
                                      aria-expanded={open ? "true" : undefined}
                                    >
                                      <Avatar sx={{ width: 32, height: 32 }}>
                                        {user.email[0].toUpperCase()}
                                      </Avatar>
                                    </IconButton>
                                  </Grid>
                                ) : null}
                              </>
                            )}
                          </Grid>
                          <Grid className="kahshIoNavigationSelectTranslate">
                            <Select
                              className="translateSelect"
                              labelId="translate"
                              id="translate"
                              defaultValue={
                                i18n.language.substring(0, 2) === "pt"
                                  ? "PTB"
                                  : "EN"
                              }
                            >
                              <MenuItem
                                onClick={handleOnclickPT}
                                className="translate"
                                value="PTB"
                              >
                                <Br fontSize="2.5rem" />
                                <Typography
                                  pl={1}
                                  fontSize="1.3rem"
                                  fontFamily="Montserrat"
                                  fontWeight={700}
                                  color="#838383"
                                >
                                  Português (BR)
                                </Typography>
                              </MenuItem>

                              <MenuItem
                                onClick={handleOnclickEN}
                                className="translate"
                                value="EN"
                              >
                                <Us fontSize="2.5rem" />
                                <Typography
                                  pl={1}
                                  fontSize="1.3rem"
                                  fontFamily="Montserrat"
                                  fontWeight={700}
                                  color="#838383"
                                >
                                  English (US)
                                </Typography>
                              </MenuItem>
                            </Select>

                            {user && (
                              <Grid className="kahshIoNavigationMetaverso">
                                <Button>
                                  <a
                                    rel="noreferrer"
                                    href="https://kahsh.land/#/"
                                    target="_blank"
                                  >
                                    <img src={metaverso} alt="" />
                                  </a>
                                </Button>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        pb={2}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                      >
                        {pages.map((page, index) => (
                          <MenuItem
                            key={page.title}
                            target={page.target}
                            href={page.href}
                          >
                            <Button
                              key={page.title}
                              target={page.target}
                              onClick={() =>
                                index === 0
                                  ? document.body.scrollTo({
                                      top: document.getElementById("ecosystem")
                                        .offsetTop,
                                      behavior: "smooth",
                                    })
                                  : (window.location.href = page.href)
                              }
                              className="kahshIoNavigationBtnsMobile"
                            >
                              {page.title}
                            </Button>
                          </MenuItem>
                        ))}
                      </Grid>
                      {!user && !loading ? null : (
                        <Grid
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          className="sairButton"
                          flexDirection="column"
                        >
                          <Button
                            onClick={() => (window.location.href = "/referrer")}
                          >
                            {t("referrerAFriend")}
                          </Button>
                          <Button onClick={() => callLogout()} size="medium">
                            {t("logoutAccount")}
                          </Button>
                        </Grid>
                      )}
                    </Menu>
                  </Menu>
                </Grid>
              </Toolbar>
            </AppBar>
          </Grid>
        </>
      )}
    </>
  );
};
