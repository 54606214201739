import { useState } from "react";

import {
    Grid,
} from "@mui/material";

import { CaptureIntrodution } from "../../components/capture/captureIntrodution/CaptureIntrodution";
import { CaptureGuaranteeBonus } from "../../components/capture/captureGuaranteeBonus/CaptureGuaranteeBonus";
import { CaptureDigitalWallet } from "../../components/capture/captureDigitalWallet/CaptureDigitalWallet";
import { LogoAndTranslate } from "../../components/logoAndTranslate/LogoAndTranslate";
import { FooterSmaller } from "../../components/footerSmaller/FooterSmaller";

export function CaptureScreen() {
    const [landingPageData, setLandingPageData] = useState({});

    return (
        <Grid width="100%">
            <LogoAndTranslate/>
            <CaptureIntrodution data={landingPageData.Monetize} />
            <CaptureGuaranteeBonus data={landingPageData.HomeAboutKahsh} />
            <CaptureDigitalWallet data={landingPageData.CaptureDigitalWallet} />
            <FooterSmaller />
        </Grid>
    );
}