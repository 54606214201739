import { createTheme } from '@mui/material';
const theme = createTheme({
  palette: {
    primary: {
      main: '#4733FF'
    },
    secondary: {
      main: '#1C1439'
    },
    error: {
      main: '#ff0000'
    }
  },
    components: {
   
    
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none'
        }
      },
      variants: [
        {
          props: { variant: 'outlined', color: 'primary' },
          style: {
            color: '#000',
            borderColor: '#000',
            '&:hover': { borderColor: '#000' }
          }
        }
      ]
    }
  }
});
export default theme;