import { Grid, Typography } from "@mui/material";

import i18n from "i18next";
import { useTranslation } from "react-i18next";

import useSharedLink from "../../../hooks/useSharedLink";

import aboutKahshImg from "../../../imagens/about-kahsh.png";
import kahshLogo from "../../../imagens/kahsh-logo.png";
import Trapezium from "../../../imagens/trapezium-background.png";

import useWindowDimensions from "../../../hooks/useWindowDimensions";

import { Button } from "../../Button/Button";
import "./HomeAboutKahsh.styles.css";
import { accountsCallBack } from "../../../services/accountsCallBack";

export const HomeAboutKahsh = () => {
  useSharedLink();
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  return (
    <Grid container mt={4} position="relative">
      <Grid
        container
        height={"95%"}
        bgcolor="#232340"
        px={width > 900 ? 12 : 4}
        pt={8}
        pb={12}
        justifyContent="center"
        alignItems="cente"
      >
        <Grid container lg={12} zIndex={1}>
          {width < 900 ? null : (
            <Grid xs={0.5}>
              <img src={kahshLogo} width={38} alt="" />
            </Grid>
          )}

          <Grid container direction="column">
            <Grid container justifyContent="center" alignItems="cente">
              <Grid container md={7} alignItems="center" mt={2}>
                {width > 900 ? null : (
                  <Grid mb={3.8} mr={3}>
                    <img src={kahshLogo} width={38} alt="" />
                  </Grid>
                )}
                <Typography
                  color={"#FFFFFF"}
                  fontSize={18}
                  fontWeight={700}
                  mt={width < 900 ? -4 : -8}
                  ml={4}
                >
                  <em>{t("theKahsh")}</em>
                </Typography>
              </Grid>

              <Grid container xs={12} md={7} justifyContent="center">
                {i18n.language.substring(0, 2) == "pt" ? (
                  <Typography
                    color={"#FFFFFF"}
                    fontSize={width > 900 ? "44px" : "22px"}
                    mt={5}
                  >
                    A <b>primeira criptomoeda</b> que reúne, em um{" "}
                    <b>ecossistema</b>, todo o <b>necessário</b> para a
                    transação de criptoativos.{" "}
                  </Typography>
                ) : (
                  <Typography
                    color={"#FFFFFF"}
                    fontSize={width > 900 ? "44px" : "22px"}
                    mt={5}
                  >
                    <b>The first cryptocurrency</b> that gathers in an{" "}
                    <b>ecosystem</b> all that is <b>necessary</b> for the
                    cryptocurrency transaction.
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          lg={12}
          justifyContent="center"
          mt={5}
          width={width < 620 ? "96%" : "100%"}
          zIndex={1}
        >
          <img
            src={aboutKahshImg}
            alt="Imagem"
            width={width < 900 ? "100%" : "70%"}
          />
        </Grid>

        <Grid
          mt={10}
          item
          xs={12}
          md={8}
          container
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid container item xs={12} md={6} direction="column">
            <Typography fontSize={36} fontWeight={700} color="#FFFFFF">
              {t("beGlobal")}
            </Typography>

            {i18n.language.substring(0, 2) == "pt" ? (
              <Typography
                mt={3}
                fontFamily="Montserrat"
                fontSize="3rem"
                color="#CBCBCB"
                fontWeight={400}
              >
                Kahsh é a transformação da forma de <br />
                comprar e vender produtos e a <br />
                simplificação do universo de criptomoedas.
              </Typography>
            ) : (
              <Typography
                mt={3}
                fontFamily="Montserrat"
                fontSize="3rem"
                color="#CBCBCB"
                fontWeight={400}
              >
                Kahsh is the transformation of the form of <br />
                buy and sell products and the <br />
                simplification of the cryptocurrency universe.
              </Typography>
            )}
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            container
            justifyContent="center"
            mt={width < 900 ? 4 : 0}
          >
            <Button
              size="medium"
              sx={{ padding: "3.5rem" }}
              onClick={() => accountsCallBack("register")}
            >
              <Typography
                fontFamily="Montserrat"
                fontSize={{ xs: "5vw", md: "24px" }}
                color="#FFFFFF"
                fontWeight={700}
              >
                {t("criarContaTrade")}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        height={"100%"}
        position="absolute"
        justifyContent="flex-end"
        zIndex={0.1}
      >
        <img
          src={Trapezium}
          alt=""
          height={"80%"}
          width={width < 600 ? "35%" : "82%"}
        />
      </Grid>
    </Grid>
  );
};
