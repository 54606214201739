import { Container } from "@mui/material";
import { Navigation } from "./../../components/navigation/Navigation";
import { IntroductionCard } from "../../components/kahshCard/introduction/introductionCard";
import { GetKahshCardVisa } from "../../components/kahshCard/getKahshCardVisa/GetKahshCardVisa";
import { ExperienceKahshCard } from "./../../components/kahshCard/experienceKahshCard/experienceKahshCard";
import { KahshCardKahshBack } from "../../components/kahshCard/kahshCardKahshBack/kahshCardKahshBack";
import { KahshCardRewards } from "../../components/kahshCard/KahshCardRewards/KahshCardRewards";
import { CreateNewKahshSmartContact } from "../../components/kahshCard/createNewKahshSmartContracts/CreateNewKahshSmartContracts";
import { ListKahshCard } from "../../components/kahshCard/listKahshCard/ListKahshCard";
import { TranquilityKahshCard } from "../../components/kahshCard/tranquilityKahshCard/tranquilityKahshCard";
import { BenefitsKahshCard } from "../../components/kahshCard/benefitsKahshCard/benefitsKahshCard";
import { Footer } from "../../components/footer/Footer2";
import { KahshCardTables } from "./../../components/kahshCard/kahshCardTables/KahshCardTables";

export const KahshCard = () => {
  return (
    <>
      <Navigation />
      <Container disableGutters maxWidth="xl">
        <IntroductionCard />
        <GetKahshCardVisa />
        <ExperienceKahshCard />
        <KahshCardKahshBack />
        <KahshCardRewards />
        <CreateNewKahshSmartContact />
        <KahshCardTables />
        {/* <ListKahshCard /> */}
        <TranquilityKahshCard />
        <BenefitsKahshCard />
      </Container>
      <Footer />
    </>
  );
};
