// import { useState, useEffect } from 'react'
import { Grid, Typography, Box } from "@mui/material"
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTranslation } from "react-i18next"
import BeneficiosImg from './../../../imagens/beneficiosImg.png'
import { SaibaMaisButton } from "../../buttons/SaibaMaisButton"
import "./KahshStoreBeneficios.styles.css"
import useSharedLink from "../../../hooks/useSharedLink";

export const KahshStoreBeneficios = () => {
  useSharedLink();
  const { t } = useTranslation()
  // const [isWidth, setIsWidth] = useState(false)
  const matches = useMediaQuery('(min-width:1304px)')

  // useEffect(() => {
  //   console.log(matches)
  //   setIsWidth(matches)
  // }, [window.innerWidth])

  return (
    <>
      {!matches ?
        (
          <Grid container justifyContent="center" alignItems="center" id="kahshStoreBeneficios">
            <Grid width={560}>
              <Typography variant="h2" className="typographyOne" >{t("kahshStore")}</Typography>
              <Typography pt={3.5} pb={6} className="typographyTwo">{t("whereTheBenefits")}</Typography>
              <SaibaMaisButton href="#">{t("knowMore")}</SaibaMaisButton>
            </Grid>
            <Grid pt={10} className="imgKahshStoreBeneficios">
              <img src={BeneficiosImg} alt='' />
            </Grid>
          </Grid>
        ) : (
          <Grid container justifyContent="center" alignItems="center" id="kahshStoreBeneficios">
            <Grid className="imgKahshStoreBeneficios">
              <img src={BeneficiosImg} alt='' />
            </Grid>
            <Grid width={560}>
              <Typography variant="h2" align="right" className="typographyOne" >{t("kahshStore")}</Typography>
              <Typography pt={3.5} pb={6} align="right" className="typographyTwo">{t("whereTheBenefits")}</Typography>
              <Box display="flex" justifyContent="flex-end">
                <SaibaMaisButton href="#">{t("knowMore")}</SaibaMaisButton>
              </Box>
            </Grid>
          </Grid>
        )
      }
    </>
  )
}