import React, { useState, useEffect } from "react";
//Material UI
import { Container, Grid } from "@mui/material";
import JsonData from "../data/data.json";
import KahshFoundationIntro from "../components/kahshFoundationNovo/kahshfoundationintro/KahshFoundationIntro";
import { Navigation } from "../components/navigation/Navigation";
import KahshFoundationItems from "../components/kahshFoundationNovo/kahshfoundationitems/KahshFoundationItems";
import { Footer } from "../components/footer/Footer2";

export const KahshFoundation = () => {
  const [landingPageData, setLandingPageData] = useState({});

  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <Grid width="100%">
      <Navigation />
      <Container maxWidth="xl">
        <KahshFoundationIntro data={landingPageData.KahshFoundationIntro} />
        <KahshFoundationItems data={landingPageData.KahshFoundationItems} />
      </Container>
      <Footer />
    </Grid>
  );
};
