import { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  InputLabel,
  TextField,
  Button,
  IconButton,
  InputBase,
  Paper,
  Modal,
  CircularProgress,
} from "@mui/material";
import PropTypes from "prop-types";
import axios from "axios";
import { useHistory } from "react-router-dom";
import QRCode from "qrcode.react";
import { useAuthState } from "react-firebase-hooks/auth";
import CurrencyFormat from "react-currency-format";
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import { useTranslation } from "react-i18next";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { cpf, cnpj } from "cpf-cnpj-validator";
import { validate } from "react-email-validator";

import { LogoAndTranslate } from "../../components/logoAndTranslate/LogoAndTranslate";
import {
  auth,
  logout,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
} from "../../services/firebase";
import PessoasSegurandoKahsh from "../../imagens/PessoasSegurandoKahsh.svg";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import useSharedLink from "../../hooks/useSharedLink";
import {
  urlPix,
  urlPixReturn,
  urlAuthExchange,
  urlRegistroExchange,
} from "../../services/global";
import "./FastBuy.styles.css";

export const FastBuy = (props) => {
  const history = useHistory();

  useSharedLink();
  const { t } = useTranslation();

  const { width } = useWindowDimensions();

  const [kahshCoin, setKahshCoin] = useState(0);
  const [inputValue, setInputValue] = useState();
  const [register, setRegister] = useState(false);
  const [txid, setTxid] = useState("");
  const [timeLimit, setTimeLimit] = useState(null);

  const [username, setUsername] = useState("");
  const [confirmUsername, setConfirmUsername] = useState("");
  const [name, setName] = useState("");
  const [cpfCnpj, setCpfCnpj] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMatch, setpasswordMatch] = useState(false);
  const [loginTrigger] = useState(false);
  const [user] = useAuthState(auth);
  const [qrcode, setQrcode] = useState("");
  const [responseModal, setResponseModal] = useState(false);
  const [dataModal, setDataModal] = useState(false);
  const [errorMessageCPF, setErrorMessageCPF] = useState("");
  const [errorMessageEmail, setErrorMessageEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [tokenExchange, setTokenExchange] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const [setLoginError] = useState("");

  const [mask, setMask] = useState("");

  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });

  const isValidCPF = (cpfCnpjEnter) => {
    let ret = cpf.isValid(cpfCnpjEnter) || cnpj.isValid(cpfCnpjEnter);
    if (ret) {
      setErrorMessageCPF("");
    } else {
      setErrorMessageCPF(t("invalidCPF"));
    }
    return ret;
  };

  const validateEmail = (emailEnter) => {
    let ret = validate(emailEnter);
    if (ret) {
      setErrorMessageEmail("");
    } else {
      setErrorMessageEmail(t("emailInvalid"));
    }
    return ret;
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
  };

  useEffect(() => {
    if (kahshCoin <= 0) {
      const fetchData = async () => {
        const data = await fetch("https://exchange.kahsh.com/cotacao");
        const json = await data.json();

        setKahshCoin(json[2].cotacao);
      };

      fetchData().catch((err) => {
        //Erro
        // console.log("Erro na coleta da cotação", err);
        setKahshCoin(-1);
      });
    } else {
      // console.log("Kahsh R$", kahshCoin);
    }
  }, [kahshCoin]);

  useEffect(() => {
    const interval = setInterval(() => {
      // console.log("valor do txid", txid)
      if (txid) {
        consultPix(txid);
      } else {
        if (localStorage.getItem("txid")) {
          // console.log("localstorage", localStorage.getItem("txid"))
          setTxid(localStorage.getItem("txid"));
        } else {
          // console.log("Nenhum TXID")
        }
      }
    }, 15000);
    return () => clearInterval(interval);
  }, [txid]);

  const consultPix = async (txid) => {
    // console.log("Txid gerado", txid);
    if (txid) {
      axios
        .post(
          urlPixReturn,
          {
            txid: txid,
          },
          {
            headers: {
              "Content-Type": "application/json;charset=utf-8",
              "Access-Control-Allow-Origin": "*",
            },
          }
        )
        .then((response) => {
          // console.log("resposta consulta pix", response);
          if (response.data.transacao == "Pendente") {
            // console.log("Pendente");
          }

          if (response.data.transacao == "Pago") {
            // console.log("Pago");
            setErrorMessage("pixPago");
            setResponseModal(true);
            history.push("/fastbuypaid");
          }

          if (response.data.transacao == "Cancelado") {
            // console.log("Cancelado");
          }
        })
        .catch((error) => {
          // console.log(error);
        });
    } else {
      // console.log("Não tem TXID");
    }
  };

  const handleClickShowRegister = () => {
    setRegister(true);
  };

  const handleClickShowLogin = () => {
    setRegister(false);
  };

  const handleBuy = async () => {
    // console.log("email", username)
    // console.log("password", password)
    if (inputValue > 0) {
      if (!user) {
        setLoading(true);
        logInWithEmailAndPassword(username, password)
          .then((response) => {
            doPix(response.user.accessToken);
          })
          .catch((error) => {
            setLoading(false);
            // console.log("Erro ao logar", error);
            if (error.message == "Firebase: Error (auth/invalid-email).") {
              setErrorMessage("invalidEmailError");
              setResponseModal(true);
            } else if (
              error.message == "Firebase: Error (auth/wrong-password)."
            ) {
              setErrorMessage("wrongPasswordError");
              setResponseModal(true);
            } else if (
              error.message ==
              "Firebase: Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later. (auth/too-many-requests)."
            ) {
              setErrorMessage("accountDisabled");
              setResponseModal(true);
            } else if (
              error.message == "Firebase: Error (auth/user-not-found)."
            ) {
              setErrorMessage("userNotFound");
              setResponseModal(true);
            }
          });
      } else {
        doPix(user.accessToken);
      }

      // } catch (error) {
      // console.log("Erro ao logar", error);
      //   if (error.message == "Firebase: Error (auth/invalid-email).") {
      //     setErrorMessage("invalidEmailError");
      //     setResponseModal(true);
      //   } else if (error.message == "Firebase: Error (auth/wrong-password).") {
      //     setErrorMessage("wrongPasswordError");
      //     setResponseModal(true);
      //   } else if (
      //     error.message ==
      //     "Firebase: Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later. (auth/too-many-requests)."
      //   ) {
      //     setErrorMessage("accountDisabled");
      //     setResponseModal(true);
      //   } else if (error.message == "Firebase: Error (auth/user-not-found).") {
      //     setErrorMessage("userNotFound");
      //     setResponseModal(true);
      //   }
      // }
    } else {
      setErrorMessage("valorZero");
      setResponseModal(true);
    }
  };

  const doPix = async (token) => {
    setLoading(true);
    axios
      .post(
        urlAuthExchange,
        {
          authorizationToken: token,
        },
        {
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        setTokenExchange(response.data.tokenExchange);
        axios
          .post(
            urlPix,
            {
              tokenExchange: response.data.tokenExchange,
              quantidade: inputValue.toString(),
              moeda: "KSH",
            },
            {
              headers: {
                "Content-Type": "application/json;charset=utf-8",
                "Access-Control-Allow-Origin": "*",
              },
            }
          )
          .then((response) => {
            // console.log("resposta pix 295", response);
            setLoading(false);
            if (response.data.success) {
              // console.log("resposta pix 313", response);
              // console.log("Txid novo", response.data.pix[0].txid);
              setTxid(response.data.pix[0].txid);
              setQrcode(response.data.pix[0].qrcode);

              setErrorMessage("pixGerado");
              setResponseModal(true);

              // var dt = new Date();
              // dt.setMinutes(dt.getMinutes() + 15);
              // localStorage.setItem("time_limit", dt);
              // setTimeLimit(dt);
              localStorage.setItem("txid", response.data.pix[0].txid);
            } else {
              if (
                response.data.message ==
                "nome ou cnpj/cpf do usuario na exchange não foram encontrados."
              ) {
                // console.log("Sem nome ou cpf/cnpf");
                setErrorMessage("semNomeOuCpfCnpj");
                setResponseModal(true);
                setDataModal(true);
              } else if (
                response.data.message == "Já existe uma transacao aberta!"
              ) {
                // console.log("Já existe operação em aberto");
                setQrcode(response.data.pix[0].qrcode);
                setInputValue(
                  response.data.pix[0].quantidade.replace(".", ",")
                );
                // console.log(
                //   "Txid se ja existe operacao aberta",
                //   response.data.pix[0].txid
                // );
                setTxid(response.data.pix[0].txid);

                setErrorMessage("existPix");
                setResponseModal(true);
              } else if (
                response.data.message ==
                "Inputs, quantidade,tokenExchange ou moeda não informados."
              ) {
                // console.log("Logar novamente");
                setErrorMessage("LogarNovamente");
                setResponseModal(true);
                callLogout();
              } else {
                // console.log("Erro desconhecido 337")
              }
            }
          })
          .catch((error) => {
            // console.log("Erro pix", error);
            setLoading(false);
          });
      })
      .catch((error) => {
        // console.log(error);
        setLoading(false);
      });
  };

  const handleRegisterFirebase = async () => {
    try {
      if (password.length < 8)
        throw new Error(
          "Firebase: Password should be at least 6 characters (auth/weak-password)."
        );
      setLoading(true);
      var res = await registerWithEmailAndPassword(username, password);
      if (res.name !== "FirebaseError") {
        // console.log("registro efetuado", res);
        // console.log("token", res.accessToken);
        setResponseModal(false);

        axios
          .post(
            urlAuthExchange,
            {
              authorizationToken: res.accessToken,
            },
            {
              headers: {
                "Content-Type": "application/json;charset=utf-8",
                "Access-Control-Allow-Origin": "*",
              },
            }
          )
          .then((response) => {
            // console.log("resposta do auth", response);
            setTokenExchange(response.data.tokenExchange);
            axios
              .post(
                urlRegistroExchange,
                {
                  tokenExchange: response.data.tokenExchange,
                  nome: name,
                  cpfCnpj: cpfCnpj.replace(/\D/g, ""),
                  senha: password,
                  base: 2,
                  codigo2fa: 0,
                },
                {
                  headers: {
                    "Content-Type": "application/json;charset=utf-8",
                    "Access-Control-Allow-Origin": "*",
                  },
                }
              )
              .then((response) => {
                // console.log("resposta login", response);
                setLoading(false);
                if (response.data.success) {
                  // console.log(
                  //   "Registro efetuado e dados atualizados na Exchange"
                  // );
                  setErrorMessage("registroEfetuadoExchange");
                  setResponseModal(true);
                  doPix(user.accessToken);
                } else {
                  setErrorMessage("errorEchangeRegister");
                  setResponseModal(true);
                }
              })
              .catch((error) => {
                // console.log("Erro login", error);
                setLoading(false);
              });
          })
          .catch((error) => {
            // console.log(error);
            setLoading(false);
          });
      } else {
        // console.log("Erro no registro");
        setLoading(false);
      }
    } catch (error) {
      // console.log(error.message);
      setLoading(false);
      if (error.message == "Firebase: Error (auth/email-already-in-use).") {
        setErrorMessage("emailInUseError");
        setResponseModal(true);
      } else if (
        error.message ==
        "Firebase: Password should be at least 6 characters (auth/weak-password)."
      ) {
        setErrorMessage("weakPasswordError");
        setResponseModal(true);
      } else if (error.message == "Firebase: Error (auth/invalid-email).") {
        setErrorMessage("invalidEmailError");
        setResponseModal(true);
      }
      return error;
    }
  };

  const handleNomeCpfCnpj = async (tokenRecebido) => {
    setErrorMessageCPF("");

    if (user) {
      setLoading(false);
      setLoadingModal(true);
      if (isValidCPF(cpfCnpj)) {
        // axios
        // .post(
        //   urlAuthExchange,
        //   {
        //     authorizationToken: user.accessToken,
        //   },
        //   {
        //     headers: {
        //       "Content-Type": "application/json;charset=utf-8",
        //       "Access-Control-Allow-Origin": "*",
        //     },
        //   }
        // )
        // .then((response) => {
        //   console.log("resposta do auth", response);

        axios
          .post(
            urlRegistroExchange,
            {
              // tokenExchange: response.data.tokenExchange,
              tokenExchange: tokenExchange,
              nome: name,
              cpfCnpj: cpfCnpj.replace(/\D/g, ""),
              base: 3,
              codigo2fa: 0,
            },
            {
              headers: {
                "Content-Type": "application/json;charset=utf-8",
                "Access-Control-Allow-Origin": "*",
              },
            }
          )
          .then((response) => {
            // console.log("resposta registro 494", response);
            setResponseModal(false);
            setLoadingModal(false);
            setDataModal(false);

            if (response.data.success) {
              // console.log(
              //   "Dados atualizados na Exchange, efetuar pix"
              // );
              setErrorMessage("registroEfetuadoExchangePixRealizado");
              setResponseModal(true);
              doPix(user.accessToken);
            } else {
              if (
                response.data.message ==
                "Já existe um usuário usando este CPF/CNPJ."
              ) {
                setErrorMessage("cpfCnpjJaExiste");
                setResponseModal(true);
              } else {
                // console.log("Erro desconhecido 515", response)
              }
            }
          })
          .catch((error) => {
            // console.log("Erro registro", error);
            setLoadingModal(false);
          });
        // })
        // .catch((error) => {
        // console.log(error);
        //   setLoadingModal(false);
        // });
      } else {
        // console.log("CPF ou CNPJ invalidos")
        setLoadingModal(false);
      }
    } else {
      // console.log("Usuário não está logado")
      setLoadingModal(false);
    }
  };

  const callLogout = () => {
    logout();
    // console.log("deslogou");
  };

  useEffect(() => {
    if (user) {
      if (window.location.href.split("/authentication")[1] === "")
        history.push("/");
      if (!loginTrigger) return;
      //   if (!responseModal) history.push(query.get("return"));
    }
  }, [user, history, loginTrigger]);

  useEffect(() => {
    checkPwEquals();
  }, [username, confirmUsername, password, confirmPassword]);

  const checkPwEquals = () => {
    if (
      password === confirmPassword &&
      password.length > 0 &&
      username === confirmUsername &&
      username.length > 0
    ) {
      setpasswordMatch(true);
    } else {
      setpasswordMatch(false);
    }
  };

  function formatValues(number) {
    number = number.replaceAll(".", "");
    number = number.replaceAll(",", ".");
    number = Number(number);
    setInputValue(number);

    return number;
  }

  const closeResponseModal = () => {
    setResponseModal(false);
  };

  return (
    <>
      {width < 1140 && width > 720 ? (
        <>
          <LogoAndTranslate />

          <Grid
            pt={5}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            id="fastBuy"
          >
            <Modal
              open={responseModal}
              onClose={closeResponseModal}
              aria-labelledby="parent-modal-title"
              aria-describedby="parent-modal-description"
            >
              <Grid
                className="boxModal"
                sx={{ ...style }}
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
              >
                <Grid
                  className="headerModal"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography variant="h2" id="parent-modal-title">
                    {t("userRegistry")}
                  </Typography>
                </Grid>

                <Typography
                  py={4}
                  className="subtitloModal"
                  id="parent-modal-description"
                >
                  {t(`${errorMessage}`)}
                </Typography>
                {dataModal ? (
                  <Grid
                    pb={5}
                    className="inputsCards"
                    display="flex"
                    flexDirection="column"
                  >
                    <InputLabel id="label" htmlFor="formEmail">
                      Nome completo
                    </InputLabel>
                    <Grid marginBottom={2}>
                      <TextField
                        variant="outlined"
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                    </Grid>
                    <InputLabel id="label" htmlFor="formEmail">
                      CPF ou CNPJ
                    </InputLabel>
                    <Grid>
                      <CpfCnpj
                        className="fastBuyInput"
                        variant="outlined"
                        type="tel"
                        value={cpfCnpj}
                        onChange={(e, type) => {
                          setCpfCnpj(e.target.value);
                          setMask(type === "CPF");
                        }}
                      />
                    </Grid>
                    {errorMessageCPF ? (
                      <Typography className="fastBuyError">
                        {t("cpfCnpjInvalido")}
                      </Typography>
                    ) : null}
                  </Grid>
                ) : null}
                <Grid
                  item
                  container
                  justifyContent="center"
                  className="boxBtnModal"
                  mr={18}
                >
                  <Button onClick={() => closeResponseModal()}>
                    {t("Ok")}
                  </Button>
                </Grid>
              </Grid>
            </Modal>
            {/* container com titulo e imagem */}
            <Grid
              pb={5}
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              className="fastBuyContainerTitle"
            >
              <Typography pb={6.87}>
                {t("buy")}
                <br />
                <span>{t("kahshNow")}</span>
              </Typography>
              <img className="" src={PessoasSegurandoKahsh} alt="" />
            </Grid>

            {/* container com inputs e QRCode*/}
            <Grid className="fastBuyContainerInputs">
              {/* Box com os inputs email e confirm email */}
              {user ? (
                <Grid className="containerfaçaSuaConta">
                  <Typography>
                    Usuário logado com e-mail {user.email}{" "}
                    <a onClick={() => callLogout()}>({t("changeAccount")})</a>
                  </Typography>
                </Grid>
              ) : null}
              <Grid
                pb={5}
                width="98%"
                display="flex"
                justifyContent="space-around"
                alignItems="center"
              >
                {/* email */}
                <Grid>
                  {!user ? (
                    <Grid>
                      <InputLabel id="label" htmlFor="formEmail">
                        {t("email")}
                      </InputLabel>
                      <Paper className="formEmail">
                        <InputBase
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                          placeholder={t("email")}
                        />
                        <IconButton aria-label="Email visibility">
                          <MailOutlineIcon className="iconEmail" />
                        </IconButton>
                      </Paper>
                    </Grid>
                  ) : null}
                  {register && !user ? (
                    <>
                      {/* confirm Email */}
                      <Grid pt={3}>
                        <InputLabel id="label" htmlFor="formEmail">
                          {t("confirmEmail")}
                        </InputLabel>
                        <Paper className="formEmail">
                          <InputBase
                            onChange={(e) => setConfirmUsername(e.target.value)}
                            value={confirmUsername}
                            placeholder={t("confirmEmail")}
                          />
                          <IconButton aria-label="Confirm Email visibility">
                            <MailOutlineIcon className="iconEmail" />
                          </IconButton>
                        </Paper>
                      </Grid>
                      {/* Nome completo */}
                      <Grid pt={3}>
                        <InputLabel id="label" htmlFor="formEmail">
                          {t("fullName")}
                        </InputLabel>
                        <Paper className="formEmail">
                          <InputBase
                            value={name}
                            onChange={(e) => {
                              setName(e.target.value);
                            }}
                            placeholder={t("fullName")}
                          />
                        </Paper>
                      </Grid>
                      <Grid mt={6} width={254} height={40}>
                        <span />
                      </Grid>
                    </>
                  ) : null}
                </Grid>

                {/* senha */}
                <Grid>
                  {!user ? (
                    <Grid className="inputSenhalFastBuy">
                      <InputLabel id="label" htmlFor="formSenha">
                        {t("password")}
                      </InputLabel>
                      <Paper className="formSenha">
                        <InputBase
                          placeholder={t("password")}
                          type={values.showPassword ? "text" : "password"}
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);
                          }}
                        />
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </Paper>
                    </Grid>
                  ) : null}
                  {register && !user ? (
                    <>
                      {/* confirme a senha */}
                      <Grid pt={3} className="inputSenhalFastBuy">
                        <InputLabel id="label" htmlFor="formSenha">
                          {t("confirmPassword")}
                        </InputLabel>
                        <Paper className="formSenha">
                          <InputBase
                            placeholder={t("confirmPassword")}
                            type={values.showPassword ? "text" : "password"}
                            value={confirmPassword}
                            onChange={(e) => {
                              setConfirmPassword(e.target.value);
                            }}
                            variant="outlined"
                          />
                          <IconButton
                            aria-label="toggle Confirm Password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {values.showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </Paper>
                      </Grid>
                      {/*  CPF*/}
                      <Grid pt={3}>
                        <InputLabel id="label" htmlFor="formEmail">
                          {t("cpfCnpj")}
                        </InputLabel>
                        <Paper className="formEmail">
                          <CpfCnpj
                            className="customizedInput"
                            placeholder="Digite um CPF ou CNPJ"
                            type="tel"
                            value={cpfCnpj}
                            onChange={(e, type) => {
                              setCpfCnpj(e.target.value);
                              setMask(type === "CPF");
                            }}
                          />
                        </Paper>
                      </Grid>
                      {/* botão Cadastrar */}
                      <Grid
                        display="flex"
                        justifyContent="end"
                        alignItems="center"
                      >
                        <Grid
                          my={100}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          className="btnCompreKahshFast"
                          style={{
                            backgroundColor:
                              passwordMatch && username.length > 0
                                ? ""
                                : "gray",
                            cursor:
                              passwordMatch && username.length > 0
                                ? "pointer"
                                : "unset",
                          }}
                        >
                          <Button
                            onClick={() =>
                              handleRegisterFirebase(username, password)
                            }
                            disabled={!passwordMatch || username.length == 0}
                          >
                            {t("registerBtn")}
                          </Button>
                        </Grid>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
              </Grid>

              {/* box com os valores e Qrcode */}
              <Grid
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                className="fastBuyContainerQRCodeAndValores"
              >
                {/* box com os valores */}
                <Grid
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                >
                  {/* Valor em R$ */}
                  <Grid pl={2.3}>
                    <InputLabel>{t("valueIn")}</InputLabel>
                    <Grid>
                      <CurrencyFormat
                        style={{
                          paddingLeft: "12px",
                          fontFamily: "Montserrat",
                          fontWeight: 600,
                          color: "black",
                        }}
                        value={inputValue}
                        placeholder={0}
                        thousandSpacing={3}
                        thousandSeparator="."
                        decimalSeparator=","
                        decimalScale={2}
                        onChange={(e) => {
                          formatValues(e.target.value);
                        }}
                      />
                    </Grid>
                  </Grid>

                  {/* Quantidade em KSH */}
                  <Grid pl={2.3} pt={3}>
                    <InputLabel>{t("AmountInKSH")}</InputLabel>
                    <Grid>
                      <CurrencyFormat
                        style={{
                          paddingLeft: "12px",
                          fontFamily: "Montserrat",
                          fontWeight: 600,
                          color: "black",
                        }}
                        placeholder={0}
                        disabled
                        value={
                          inputValue / kahshCoin > 0
                            ? inputValue / kahshCoin
                            : 0
                        }
                        thousandSeparator="."
                        decimalSeparator=","
                        decimalScale={2}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {/* box  Qrcode */}
                <Grid
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  className="fastBuyContainerQRCode"
                >
                  {/* <img src={QRCodeIMG} alt="" /> */}
                  <QRCode value={qrcode} size={150} />
                  <Typography>{t("Pagamento via pix")}</Typography>
                  {/* <Grid onClick={handleClickPixCopyPaste} className="copyAddress">
                    <Grid>
                      <img id="code" src={qrcode} alt="QrCode" />
                    </Grid>
                    <Typography pr={2}> {t("pixCopyPaste")}</Typography>
                  </Grid> */}
                </Grid>
              </Grid>

              {/* box com botão comprar kahsh */}
              <Grid
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                className="containerfaçaSuaConta"
                py={3}
                px={3}
              >
                <Typography>
                  {t("makeQuickAccountHere")}
                  <a onClick={handleClickShowRegister}>{t("here")}</a>
                </Typography>
                <Grid
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  className="btnCompreKahshFast"
                >
                  <Button onClick={handleBuy} disabled={loading}>
                    {!loading ? (
                      t("ComprarKahsh")
                    ) : (
                      <CircularProgress color="inherit" size={20} />
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : width < 720 ? (
        <>
          <Grid
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            id="fastBuy"
          >
            <Modal
              open={responseModal}
              onClose={closeResponseModal}
              aria-labelledby="parent-modal-title"
              aria-describedby="parent-modal-description"
            >
              <Grid
                className="boxModal"
                sx={{ ...style }}
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
              >
                <Grid
                  className="headerModalFastBuy"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography variant="h2" id="parent-modal-title">
                    {t("userRegistry")}
                  </Typography>
                </Grid>
                <Typography
                  py={4}
                  className="subtitloModal"
                  id="parent-modal-description"
                >
                  {t(`${errorMessage}`)}
                </Typography>
                {dataModal ? (
                  // <Grid>
                  //   <InputBase
                  //     value={name}
                  //     onChange={(e) => {
                  //       setName(e.target.value);
                  //     }}
                  //     placeholder={t("fullName")}
                  //   />
                  //   <CpfCnpj
                  //     className="customizedInput"
                  //     placeholder="Digite um CPF ou CNPJ"
                  //     type="tel"
                  //     value={cpfCnpj}
                  //     onChange={(e, type) => {
                  //       setCpfCnpj(e.target.value);
                  //       setMask(type === "CPF");
                  //     }}
                  //   />
                  // </Grid>
                  <Grid
                    pb={5}
                    className="inputsCards"
                    display="flex"
                    flexDirection="column"
                  >
                    <InputLabel id="label" htmlFor="formEmail">
                      Nome completo
                    </InputLabel>
                    <Grid marginBottom={2}>
                      <TextField
                        variant="outlined"
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                    </Grid>
                    <InputLabel id="label" htmlFor="formEmail">
                      CPF ou CNPJ
                    </InputLabel>
                    <Grid>
                      <CpfCnpj
                        className="fastBuyInput"
                        variant="outlined"
                        type="tel"
                        value={cpfCnpj}
                        onChange={(e, type) => {
                          setCpfCnpj(e.target.value);
                          setMask(type === "CPF");
                        }}
                      />
                    </Grid>
                    {errorMessageCPF ? (
                      <Typography className="fastBuyError">
                        {t("cpfCnpjInvalido")}
                      </Typography>
                    ) : null}
                  </Grid>
                ) : null}
                <Grid
                  item
                  container
                  justifyContent="center"
                  className="boxBtnModal"
                  mr={18}
                >
                  <Button onClick={() => closeResponseModal()}>
                    {t("Ok")}
                  </Button>
                </Grid>
              </Grid>
            </Modal>
            <Grid width="100%" display="flex" justifyContent="space-betwen">
              <LogoAndTranslate />
            </Grid>
            <Grid
              pt={5}
              width="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              {/* container com titulo e imagem */}
              <Grid
                pb={5}
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                className="fastBuyContainerTitle"
              >
                <Typography pb={6.87}>
                  {t("buy")}
                  <br />
                  <span>{t("kahshNow")}</span>
                </Typography>
                <img className="" src={PessoasSegurandoKahsh} alt="" />
              </Grid>
              {/* container com inputs e QRCode*/}
              {user ? (
                <Grid className="containerfaçaSuaConta">
                  <Typography>
                    Usuário logado com e-mail
                    <br />
                    {user.email}{" "}
                    <a onClick={() => callLogout()}>({t("changeAccount")})</a>
                  </Typography>
                </Grid>
              ) : null}
              <Grid className="fastBuyContainerInputs">
                {/* Box com os inputs email e confirm email */}
                <Grid
                  pb={5}
                  width="98%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                >
                  {/* email */}
                  {!user ? (
                    <Grid>
                      <InputLabel id="label" htmlFor="formEmail">
                        {t("email")}
                      </InputLabel>
                      <Paper className="formEmail">
                        <InputBase
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                          placeholder={t("email")}
                        />
                        <IconButton aria-label="Email visibility">
                          <MailOutlineIcon className="iconEmail" />
                        </IconButton>
                      </Paper>
                    </Grid>
                  ) : null}
                  {register && !user ? (
                    <>
                      {/* confirm Email */}
                      <Grid pt={3}>
                        <InputLabel id="label" htmlFor="formEmail">
                          {t("confirmEmail")}
                        </InputLabel>
                        <Paper className="formEmail">
                          <InputBase
                            onChange={(e) => setConfirmUsername(e.target.value)}
                            value={confirmUsername}
                            placeholder={t("confirmEmail")}
                          />
                          <IconButton aria-label="Confirm Email visibility">
                            <MailOutlineIcon className="iconEmail" />
                          </IconButton>
                        </Paper>
                      </Grid>
                    </>
                  ) : null}

                  {/* senha */}
                  {!user ? (
                    <Grid pt={3} className="inputSenhalFastBuy">
                      <InputLabel id="label" htmlFor="formSenha">
                        {t("password")}
                      </InputLabel>
                      <Paper className="formSenha">
                        <InputBase
                          placeholder={t("password")}
                          type={values.showPassword ? "text" : "password"}
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);
                          }}
                        />
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </Paper>
                    </Grid>
                  ) : null}
                  {register && !user ? (
                    <>
                      {/* confirme a senha */}
                      <Grid pt={3} className="inputSenhalFastBuy">
                        <InputLabel id="label" htmlFor="formSenha">
                          {t("confirmPassword")}
                        </InputLabel>
                        <Paper className="formSenha">
                          <InputBase
                            placeholder={t("confirmPassword")}
                            type={values.showPassword ? "text" : "password"}
                            value={confirmPassword}
                            onChange={(e) => {
                              setConfirmPassword(e.target.value);
                            }}
                            variant="outlined"
                          />
                          <IconButton
                            aria-label="toggle Confirm Password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {values.showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </Paper>
                      </Grid>

                      {/* Nome completo */}
                      <Grid pt={3} pl={1}>
                        <InputLabel id="label" htmlFor="formEmail">
                          {t("fullName")}
                        </InputLabel>
                        <Paper className="formEmail">
                          <InputBase
                            value={name}
                            onChange={(e) => {
                              setName(e.target.value);
                            }}
                            placeholder={t("fullName")}
                          />
                        </Paper>
                      </Grid>

                      {/*  CPF*/}
                      <Grid pt={3} pl={1}>
                        <InputLabel id="label" htmlFor="formEmail">
                          {t("cpfCnpj")}
                        </InputLabel>
                        <Paper className="formEmail">
                          <CpfCnpj
                            className="customizedInput"
                            placeholder="Digite um CPF ou CNPJ"
                            type="tel"
                            value={cpfCnpj}
                            onChange={(e, type) => {
                              setCpfCnpj(e.target.value);
                              setMask(type === "CPF");
                            }}
                          />
                        </Paper>
                      </Grid>

                      {/* botão Cadastrar */}
                      <Grid
                        display="flex"
                        justifyContent="end"
                        alignItems="center"
                      >
                        <Grid
                          my={3}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          className="btnCompreKahshFast"
                          style={{
                            backgroundColor:
                              passwordMatch && username.length > 0
                                ? ""
                                : "gray",
                            cursor:
                              passwordMatch && username.length > 0
                                ? "pointer"
                                : "unset",
                          }}
                        >
                          <Button
                            onClick={() =>
                              handleRegisterFirebase(username, password)
                            }
                            disabled={!passwordMatch || username.length == 0}
                          >
                            {t("registerBtn")}
                          </Button>
                        </Grid>
                      </Grid>
                    </>
                  ) : null}
                </Grid>

                {/* box com os valores e Qrcode */}
                <Grid
                  display="flex"
                  justifyContent="space-bet"
                  alignItems="center"
                  flexDirection="column"
                  className="fastBuyContainerQRCodeAndValores"
                >
                  {/* box com os valores */}
                  <Grid
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                    className="inputsQRCodeAndValores"
                  >
                    {/* Valor em R$ */}
                    <Grid>
                      <InputLabel>{t("valueIn")}</InputLabel>
                      <Grid>
                        <CurrencyFormat
                          style={{
                            paddingLeft: "12px",
                            fontFamily: "Montserrat",
                            fontWeight: 600,
                            color: "black",
                          }}
                          value={inputValue}
                          placeholder={0}
                          thousandSpacing={3}
                          thousandSeparator="."
                          decimalSeparator=","
                          decimalScale={2}
                          onChange={(e) => {
                            formatValues(e.target.value);
                          }}
                        />
                      </Grid>
                    </Grid>

                    {/* Quantidade em KSH */}
                    <Grid pt={3}>
                      <InputLabel>{t("AmountInKSH")}</InputLabel>
                      <Grid>
                        <CurrencyFormat
                          style={{
                            paddingLeft: "12px",
                            fontFamily: "Montserrat",
                            fontWeight: 600,
                            color: "black",
                          }}
                          placeholder={0}
                          disabled
                          value={
                            inputValue / kahshCoin > 0
                              ? inputValue / kahshCoin
                              : 0
                          }
                          thousandSeparator="."
                          decimalSeparator=","
                          decimalScale={2}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* box  Qrcode */}
                  <Grid
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                    className="fastBuyContainerQRCode"
                  >
                    <QRCode value={qrcode} size={150} />
                    <Typography>{t("Pagamento via pix")}</Typography>
                  </Grid>
                </Grid>

                {/* box  com botão comrpar kahsh */}
                <Grid
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  className="containerfaçaSuaConta"
                  py={3}
                  px={3}
                >
                  <Typography pb={2}>
                    {t("makeQuickAccountHere")}
                    <a onClick={handleClickShowRegister}>
                      {t("here")}
                      {/* {register ? <FastBuy /> : <FastBuyRegister />} */}
                    </a>
                  </Typography>
                  <Grid
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    className="btnCompreKahshFast"
                  >
                    <Button onClick={handleBuy} disabled={loading}>
                      {!loading ? (
                        t("ComprarKahsh")
                      ) : (
                        <CircularProgress color="inherit" size={20} />
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <LogoAndTranslate />
          <Grid
            pt={5}
            display="flex"
            justifyContent="space-around"
            alignItems="center"
            id="fastBuy"
          >
            {/* container com titulo e imagem */}
            <Grid
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              className="fastBuyContainerTitle"
            >
              <Typography pb={6.87}>
                {t("buy")}
                <br />
                <span>{t("kahshNow")}</span>
              </Typography>
              <img className="" src={PessoasSegurandoKahsh} alt="" />
            </Grid>
            <Modal
              open={responseModal}
              onClose={closeResponseModal}
              aria-labelledby="parent-modal-title"
              aria-describedby="parent-modal-description"
            >
              <Grid
                className="boxModal"
                sx={{ ...style }}
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
              >
                <Grid
                  className="headerModal"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography variant="h2" id="parent-modal-title">
                    {t("userRegistry")}
                  </Typography>
                </Grid>

                <Typography
                  py={4}
                  className="subtitloModal"
                  id="parent-modal-description"
                >
                  {t(`${errorMessage}`)}
                </Typography>
                {dataModal ? (
                  <Grid
                    pb={5}
                    className="inputsCards"
                    display="flex"
                    flexDirection="column"
                  >
                    <InputLabel id="label" htmlFor="formEmail">
                      Nome completo
                    </InputLabel>
                    <Grid marginBottom={2}>
                      <TextField
                        variant="outlined"
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                    </Grid>
                    <InputLabel id="label" htmlFor="formEmail">
                      CPF ou CNPJ
                    </InputLabel>
                    <Grid>
                      <CpfCnpj
                        className="fastBuyInput"
                        variant="outlined"
                        type="tel"
                        value={cpfCnpj}
                        onChange={(e, type) => {
                          setCpfCnpj(e.target.value);
                          setMask(type === "CPF");
                        }}
                      />
                    </Grid>
                    {errorMessageCPF ? (
                      <Typography className="fastBuyError">
                        {t("cpfCnpjInvalido")}
                      </Typography>
                    ) : null}
                  </Grid>
                ) : null}
                <Grid
                  item
                  container
                  justifyContent="center"
                  className="boxBtnModal"
                  mr={18}
                >
                  {dataModal ? (
                    <Button
                      onClick={() => handleNomeCpfCnpj()}
                      disabled={loadingModal}
                    >
                      {!loadingModal ? (
                        t("send")
                      ) : (
                        <CircularProgress color="inherit" size={20} />
                      )}
                    </Button>
                  ) : (
                    <Button
                      onClick={() => closeResponseModal()}
                      disabled={loadingModal}
                    >
                      {!loadingModal ? (
                        t("Ok")
                      ) : (
                        <CircularProgress color="inherit" size={20} />
                      )}
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Modal>
            {/* container com inputs e QRCode*/}
            <Grid className="fastBuyContainerInputs">
              {/* Box com os inputs email e confirm email */}
              {user ? (
                <Grid className="containerfaçaSuaConta">
                  <Typography>
                    Usuário logado com e-mail {user.email}{" "}
                    <a onClick={() => callLogout()}>({t("changeAccount")})</a>
                  </Typography>
                </Grid>
              ) : null}
              <Grid
                pb={5}
                width="98%"
                display="flex"
                justifyContent="space-around"
                alignItems="center"
              >
                {/* email e nome completo */}
                <Grid>
                  {/* Email tela widescreen */}
                  {!user ? (
                    <Grid>
                      <InputLabel id="label" htmlFor="formEmail">
                        {t("email")}
                      </InputLabel>
                      <Paper className="formEmail">
                        <InputBase
                          value={username}
                          onChange={(e) => {
                            setUsername(e.target.value);
                          }}
                          placeholder={t("email")}
                        />
                        <IconButton aria-label="Email visibility">
                          <MailOutlineIcon className="iconEmail" />
                        </IconButton>
                      </Paper>
                    </Grid>
                  ) : null}
                  {register && !user ? (
                    <>
                      {/* confirm Email */}
                      <Grid pt={3}>
                        <InputLabel id="label" htmlFor="formEmail">
                          {t("confirmEmail")}
                        </InputLabel>
                        <Paper className="formEmail">
                          <InputBase
                            onChange={(e) => setConfirmUsername(e.target.value)}
                            value={confirmUsername}
                            placeholder={t("confirmEmail")}
                          />
                          <IconButton aria-label="Confirm Email visibility">
                            <MailOutlineIcon className="iconEmail" />
                          </IconButton>
                        </Paper>
                      </Grid>
                      {/* Nome completo */}
                      <Grid pt={3}>
                        <InputLabel id="label" htmlFor="formEmail">
                          {t("fullName")}
                        </InputLabel>
                        <Paper className="formEmail">
                          <InputBase
                            value={name}
                            onChange={(e) => {
                              setName(e.target.value);
                            }}
                            placeholder={t("fullName")}
                          />
                        </Paper>
                      </Grid>
                      <Grid mt={6} width={254} height={40}>
                        <Grid
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          className="containerfaçaSuaConta"
                          py={1}
                          px={3}
                        >
                          <Typography>
                            {t("makeLoginHere")}
                            <a onClick={handleClickShowLogin}>{t("here")}</a>
                          </Typography>
                        </Grid>
                      </Grid>
                    </>
                  ) : null}
                </Grid>

                {/* senha e CPF*/}
                <Grid>
                  {/* Senha */}
                  {!user ? (
                    <Grid>
                      <InputLabel id="label" htmlFor="formSenha">
                        {t("password")}
                      </InputLabel>
                      <Paper className="formSenha">
                        <InputBase
                          placeholder={t("password")}
                          type={values.showPassword ? "text" : "password"}
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);
                          }}
                        />
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </Paper>
                    </Grid>
                  ) : null}
                  {register && !user ? (
                    <>
                      {/* confirme a senha */}
                      <Grid pt={3}>
                        <InputLabel id="label" htmlFor="formSenha">
                          {t("confirmPassword")}
                        </InputLabel>
                        <Paper className="formSenha">
                          <InputBase
                            placeholder={t("confirmPassword")}
                            type={values.showPassword ? "text" : "password"}
                            value={confirmPassword}
                            onChange={(e) => {
                              setConfirmPassword(e.target.value);
                            }}
                            variant="outlined"
                          />
                          <IconButton
                            aria-label="toggle Confirm Password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {values.showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </Paper>
                        {/*  CPF*/}
                        <Grid pt={3}>
                          <InputLabel id="label" htmlFor="formEmail">
                            {t("cpfCnpj")}
                          </InputLabel>
                          <Paper className="formEmail">
                            <CpfCnpj
                              className="customizedInput"
                              placeholder="Digite um CPF ou CNPJ"
                              type="tel"
                              value={cpfCnpj}
                              onChange={(e, type) => {
                                setCpfCnpj(e.target.value);
                                setMask(type === "CPF");
                              }}
                            />
                          </Paper>
                        </Grid>
                      </Grid>

                      {/* botão Cadastrar */}
                      <Grid
                        display="flex"
                        justifyContent="end"
                        alignItems="center"
                      >
                        <Grid
                          my={3}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          className="btnCompreKahshFast"
                          style={{
                            backgroundColor:
                              passwordMatch && username.length > 0
                                ? ""
                                : "gray",
                            cursor:
                              passwordMatch && username.length > 0
                                ? "pointer"
                                : "unset",
                          }}
                        >
                          <Button
                            onClick={() =>
                              handleRegisterFirebase(username, password)
                            }
                            disabled={!passwordMatch || username.length == 0}
                          >
                            {t("registerBtn")}
                          </Button>
                        </Grid>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
              </Grid>

              {/* box com os valores e Qrcode */}
              <Grid
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                className="fastBuyContainerQRCodeAndValores"
              >
                {/* box com os valores */}
                <Grid
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                >
                  {/* Valor em R$ */}
                  <Grid pl={2.3}>
                    <InputLabel>{t("valueIn")}</InputLabel>
                    <Grid>
                      <CurrencyFormat
                        style={{
                          paddingLeft: "12px",
                          fontFamily: "Montserrat",
                          fontWeight: 600,
                          color: "black",
                        }}
                        value={inputValue}
                        placeholder={0}
                        thousandSpacing={3}
                        thousandSeparator="."
                        decimalSeparator=","
                        decimalScale={2}
                        onChange={(e) => {
                          formatValues(e.target.value);
                        }}
                      />
                    </Grid>
                  </Grid>

                  {/* Quantidade em KSH */}
                  <Grid pl={2.3} pt={3}>
                    <InputLabel>{t("AmountInKSH")}</InputLabel>
                    <Grid>
                      <CurrencyFormat
                        style={{
                          paddingLeft: "12px",
                          fontFamily: "Montserrat",
                          fontWeight: 600,
                          color: "black",
                        }}
                        placeholder={0}
                        disabled
                        value={
                          inputValue / kahshCoin > 0
                            ? inputValue / kahshCoin
                            : 0
                        }
                        thousandSeparator="."
                        decimalSeparator=","
                        decimalScale={2}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {/* box  Qrcode */}
                <Grid
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  className="fastBuyContainerQRCode"
                >
                  {/* <img src={QRCodeIMG} alt="" /> */}
                  <QRCode value={qrcode} size={150} />
                  <Typography>{t("Pagamento via pix")}</Typography>
                </Grid>
              </Grid>

              {/* box  com botão comrpar kahsh */}
              <Grid
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                className="containerfaçaSuaConta"
                py={3}
                px={3}
              >
                <Typography>
                  {t("makeQuickAccountHere")}
                  <a onClick={handleClickShowRegister}>{t("here")}</a>
                </Typography>
                <Grid
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  className="btnCompreKahshFast"
                >
                  <Button onClick={handleBuy} disabled={loading}>
                    {!loading ? (
                      t("ComprarKahsh")
                    ) : (
                      <CircularProgress color="inherit" size={20} />
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

FastBuy.propTypes = {
  password: PropTypes.string,
  showPassword: PropTypes.bool,
};
