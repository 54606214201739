import { Grid, Typography, IconButton, Button } from "@mui/material";

import useWindowDimensions from "../../../hooks/useWindowDimensions";

import { useTranslation } from "react-i18next";
import i18n from "i18next";

import SmallCircleButton from "../../../icons/SmallCircleButton";

import Chat from "../../../imagens/Chat.png";
import LogoKahshComCirculo from "../../../imagens/LogoKahshComCirculo.svg";
import AcesseAgoraBackground from "../../../imagens/AcesseAgoraBackground.png";
import ContactAcesseAgora from "../../../imagens/ContactAcesseAgora.png";

import "./AcesseAgora.styles.css";

export const AcesseAgora = (props) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  const AcesseAgoraIMG = {
    height: 1014,
    backgroundImage: `url(${AcesseAgoraBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: "100%",
    borderRadius: 15,
  };

  const AcesseAgoraIMGMobile = {
    height: 1200,
    backgroundImage: `url(${ContactAcesseAgora})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: "100%",
    borderRadius: 15,
  };

  return (
    <>
      {width > 1024 ? (
        <>
          <Grid
            container
            mt={12}
            mb={8}
            justifyContent="center"
            xs={12}
            id="kahshPayAcesseAgora"
          >
            <Grid
              item
              md={10}
              lg={11}
              style={AcesseAgoraIMG}
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              pt={22}
            >
              <Grid
                item
                xs={12}
                pb={width > 1800 ? "14rem" : "25rem"}
                container
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
              >
                <Grid>
                  <img src={LogoKahshComCirculo} alt="Logo Kahsh" />
                </Grid>

                {i18n.language.substring(0, 2) == "pt" ? (
                  <Typography
                    pt={3}
                    pb={3}
                    fontFamily="Montserrat"
                    textAlign="center"
                    color="#FFFFFF"
                    fontWeight={500}
                    fontSize="2.3vw"
                    lineHeight="3vw"
                  >
                    <b>Kahsh Store, </b>compras com
                    <br /> criptomoedas com <b>praticidade.</b>
                  </Typography>
                ) : (
                  <Typography
                    pt={3}
                    pb={3}
                    fontFamily="Montserrat"
                    textAlign="center"
                    color="#FFFFFF"
                    fontWeight={500}
                    fontSize="2.3vw"
                    lineHeight="3vw"
                  >
                    <b>Kahsh Store, </b>shopping with
                    <br /> Cryptocurrencies with <b>practicality.</b>
                  </Typography>
                )}
              </Grid>

              <Grid
                item
                xs={15}
                pb={width > 1800 ? "1rem" : "7rem"}
                display="flex"
              >
                <Grid
                  item
                  xs={6}
                  display="flex"
                  alignItems="end"
                  justifyContent="center"
                  flexDirection="column"
                >
                  {i18n.language.substring(0, 2) == "pt" ? (
                    <Typography
                      pt="4rem"
                      pr="22rem"
                      fontFamily="Montserrat"
                      textAlign="center"
                      color="#FFFFFF"
                      fontWeight={500}
                      fontSize={width > 1200 ? "4rem" : "2vw"}
                      lineHeight={width > 1200 ? "4vw" : "2.5vw"}
                    >
                      Acesse
                      <br />
                      <b>Agora!</b>
                    </Typography>
                  ) : (
                    <Typography
                      pr="26rem"
                      fontFamily="Montserrat"
                      textAlign="center"
                      color="#FFFFFF"
                      fontWeight={500}
                      fontSize={width > 1200 ? "4rem" : "2vw"}
                      lineHeight={width > 1200 ? "4vw" : "2.5vw"}
                    >
                      Access
                      <br />
                      <b>Now!</b>
                    </Typography>
                  )}
                </Grid>

                <Grid
                  item
                  xs={6}
                  display="flex"
                  alignItems="start"
                  justifyContent="center"
                  flexDirection="column"
                >
                  <Grid pt="0rem" pl="12rem" className="chatAcesseAgora">
                    <img src={Chat} alt="Logo Kahsh" />
                  </Grid>

                  {i18n.language.substring(0, 2) == "pt" ? (
                    <>
                      <Grid display="flex">
                        <Typography
                          pl="12rem"
                          mr={22}
                          fontFamily="Montserrat"
                          textAlign="justify"
                          color="#FFFFFF"
                          fontWeight={500}
                          fontSize={width > 1200 ? "3rem" : "2vw"}
                          lineHeight={width > 1200 ? "3vw" : "2.5vw"}
                        >
                          <b>
                            Acesse e confira<br />
                            as variedades e<br />
                            descontos.
                          </b>
                        </Typography>
                        <IconButton className="kahshPayAcesseAgoraIcon">
                          <SmallCircleButton/>
                        </IconButton>
                      </Grid>
                    </>
                  ) : (
                    <Typography
                      pl="12rem"
                      mr={22}
                      fontFamily="Montserrat"
                      textAlign="justify"
                      color="#FFFFFF"
                      fontWeight={500}
                      fontSize={width > 1200 ? "3rem" : "2vw"}
                      lineHeight={width > 1200 ? "3vw" : "2.5vw"}
                    >
                      <b>
                        Log in and check out<br />
                        the varieties and<br />
                        discounts.
                      </b>
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid
            container
            mt={-12}
            mb={-22}
            justifyContent="center"
            xs={12}
            id="kahshPayAcesseAgora"
          >
            <Grid
              item
              md={10}
              lg={11}
              style={AcesseAgoraIMGMobile}
              alignItems="center"
              justifyContent="center"
              display="flex"
              flexDirection="column"
            >
              <Grid>
                <img src={LogoKahshComCirculo} alt="Logo Kahsh" />
              </Grid>

              {i18n.language.substring(0, 2) == "pt" ? (
                <Typography
                  pt={3}
                  pb={10}
                  fontFamily="Montserrat"
                  textAlign="center"
                  color="#FFFFFF"
                  fontWeight={500}
                  fontSize="3.5rem"
                  lineHeight="4rem"
                >
                  A <b>KahshPay</b> te
                  <br />
                  traz a maior <br />
                  <b>praticidade</b> de <br />
                  ingresso no <br /> <b>mundo cripto.</b>
                </Typography>
              ) : (
                <Typography
                  pt={3}
                  fontFamily="Montserrat"
                  textAlign="center"
                  color="#FFFFFF"
                  fontWeight={500}
                  fontSize="3.5rem"
                  lineHeight="4rem"
                >
                  The <b>KahshPay</b> gives you
                  <br />
                  brings the biggest <br />
                  <b>practicality</b> of <br />
                  entry into the <br /> <b>crypto world.</b>
                </Typography>
              )}

              {i18n.language.substring(0, 2) == "pt" ? (
                <Typography
                  fontFamily="Montserrat"
                  textAlign="center"
                  color="#FFFFFF"
                  fontWeight={500}
                  fontSize="2.5rem"
                  lineHeight="3rem"
                >
                  Cadastre-se e comece a <br />
                  aproveitar os benefícios.
                </Typography>
              ) : (
                <Typography
                  pt={5}
                  fontFamily="Montserrat"
                  textAlign="center"
                  color="#FFFFFF"
                  fontWeight={500}
                  fontSize="2.5rem"
                  lineHeight="3rem"
                >
                  Sign up and start <br />
                  enjoy the benefits.
                </Typography>
              )}

              {i18n.language.substring(0, 2) == "pt" ? (
                <Typography
                  pt={5}
                  fontFamily="Montserrat"
                  textAlign="center"
                  color="#FFFFFF"
                  fontWeight={500}
                  fontSize="6rem"
                  lineHeight="6rem"
                >
                  Acesse
                  <br />
                  <b>Agora!</b>
                </Typography>
              ) : (
                <Typography
                  pt={5}
                  fontFamily="Montserrat"
                  textAlign="center"
                  color="#FFFFFF"
                  fontWeight={500}
                  fontSize="6rem"
                  lineHeight="6rem"
                >
                  Access
                  <br />
                  <b>Now!</b>
                </Typography>
              )}

              <Grid mt={5} p="1.5rem" bgcolor="#FFFFFF" borderRadius={3}>
                <Button>
                  <Typography
                    fontFamily="Montserrat"
                    textAlign="center"
                    color="#2522ba"
                    fontWeight={700}
                    fontSize="2rem"
                  >
                    {t("montarCarteira")}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};
