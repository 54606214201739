import { Grid, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";

import kahsh36Web from "../../../imagens/kahsh36Web.png";
import kahsh36mobile from "../../../imagens/kahsh36mobile.png";

import useSharedLink from "../../../hooks/useSharedLink";

import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { NavigationStaking } from "../../navigationStaking/NavigationStaking";
import { SaibaMaisButton } from "../../buttons/SaibaMaisButton";

export const IntroStaking = () => {
  useSharedLink();
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  return (
    <>
      {width > 1024 ? (
        <>
          <Grid container pt="1.5vw">
            <Grid container item xs={12}>
              <Grid container xs={12} pl="1.5vw">
                <img src={kahsh36Web} width={"100%"} alt="" />
              </Grid>

              <Grid container position="absolute">
                <Grid container justifyContent="center" alignItems="center" >
                  <NavigationStaking />
                </Grid>

                <Grid
                  container
                  item
                  xs={10}
                  direction="column"
                  justifyContent="center"
                  alignItems="start"
                >
                  <Grid p={width > 1450 ? "13vw 0 0vw 12vw" : "10vw 0 7vw 12vw"}>
                    <Typography
                      fontSize={"4vw"}
                      color="#ffffff"
                      lineHeight="1.2em"
                      dangerouslySetInnerHTML={{
                        __html: t("newEarnCrypto"),
                      }}
                    />
                    <Typography
                      fontSize={"2.8vw"}
                      fontWeight={700}
                      color="#1AA4FE"
                      lineHeight="1.2em"
                      mt={2}
                      dangerouslySetInnerHTML={{
                        __html: t("newFixedIncome"),
                      }}
                    />
                  </Grid>

                  {width > 1450 ? (
                    <Grid
                      padding="16rem 32rem"
                    >

                      <Grid
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        bgcolor="#4733FF"
                        p="3rem 16rem"
                        borderRadius={8}
                      >
                        <Button
                          onClick={() => (window.location.href = "/authentication?return=/staking&destination=0")}
                        >
                          <Typography
                            fontSize="2rem"
                            color="#FFFFFF"
                            fontWeight={700}
                          >
                            {t("joinNow")}
                          </Typography>
                        </Button>
                      </Grid>

                    </Grid>
                  ) : (
                    <Grid
                      px={width < 1300 ? "13rem" : "16.5rem"}
                    // padding="0rem 17rem"
                    >
                      <Grid
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        bgcolor="#4733FF"
                        p="1.5rem 10rem"
                        borderRadius={5}
                      >
                        <Button
                          onClick={() => (window.location.href = "/authentication?return=/staking&destination=0")}
                        >
                          <Typography
                            fontSize="1.5rem"
                            color="#FFFFFF"
                            fontWeight={700}
                          >
                            {t("joinNow")}
                          </Typography>
                        </Button>
                      </Grid>

                      {/* <SaibaMaisButton
                        width="70%"
                        onClick={() => (window.location.href = "/authentication?return=/staking&destination=0")}
                      >
                        <Typography fontSize={18} fontWeight={700}>
                          {t("joinNow")}
                        </Typography>
                      </SaibaMaisButton> */}

                    </Grid>
                  )}



                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid container>
            <Grid container item xs={12}>
              <Grid container xs={12}>
                <img src={kahsh36mobile} width={"100%"} alt="" />
              </Grid>

              <Grid container position="absolute">
                <Grid
                  container
                  m="9vw 2vw"
                  direction="column"
                  xs={12}
                >
                  <NavigationStaking />
                </Grid>

                <Grid
                  container
                  item
                  xs={10}
                  direction="column"
                  justifyContent="center"
                  alignItems="start"
                  mt="-12vw"
                >
                  <Grid p={"1vw 0 0 8vw"}>
                    <Typography
                      fontSize={"10vw"}
                      color="#ffffff"
                      lineHeight="1.2em"
                      dangerouslySetInnerHTML={{
                        __html: t("newEarnCryptoMobile"),
                      }}
                    />
                    <Typography
                      fontSize={"7vw"}
                      fontWeight={700}
                      color="#1AA4FE"
                      lineHeight="1.2em"
                      mt={2}
                      dangerouslySetInnerHTML={{
                        __html: t("newFixedIncome"),
                      }}
                    />
                  </Grid>

                  <Grid
                    container
                    ml="8.5vw"
                    mt={width >= 500 ? "53rem" : "96vw"}
                  >
                    <SaibaMaisButton
                      width="100%"
                      onClick={() => (window.location.href = "/authentication?return=/staking&destination=0")}
                    >
                      <Typography fontSize={18} fontWeight={700}>
                        {t("joinNow")}
                      </Typography>
                    </SaibaMaisButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )
      }
    </>
  );
};
