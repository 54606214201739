export const Address = [
    { value: "Ana Capri Suzano Sh", label: "Ana Capri Suzano Sh" },
    { value: "Arezzo Center Vale", label: "Arezzo Center Vale" },
    { value: "Arezzo Colinas", label: "Arezzo Colinas" },
    { value: "Arezzo Jac", label: "Arezzo Jac" },
    { value: "Arezzo Mogi Sh", label: "Arezzo Mogi Sh" },
    { value: "Arezzo Vale Sul", label: "Arezzo Vale Sul" },
    { value: "Armazem Bela Vista", label: "Armazem Bela Vista" },
    { value: "Backoffice", label: "Backoffice" },
    { value: "Carioca Ab", label: "Carioca Ab" },
    { value: "Carioca Balneário", label: "Carioca Balneário" },
    { value: "Carioca Bf", label: "Carioca Bf" },
    { value: "Carioca Bom Passo", label: "Carioca Bom Passo" },
    { value: "Carioca Cd", label: "Carioca Cd" },
    { value: "Carioca Continental Park Shop", label: " Carioca Continental Park Shop" },

    { value: "Carioca Ed", label: "Carioca Ed" },
    { value: "Carioca Es", label: "Carioca Es" },
    { value: "Carioca Felipe Cf", label: "Carioca Felipe Cf" },
    { value: "Carioca Felipe Cf3", label: "Carioca Felipe Cf3" },
    { value: "Carioca Itaguaçú", label: "Carioca Itaguaçú" },
    { value: "Carioca Itajai", label: "Carioca Itajai" },
    { value: "Carioca Matriz", label: "Carioca Matriz" },
    { value: "Carioca Palhoça", label: "Carioca Palhoça" },
    { value: "Carioca Sapata", label: "Carioca Sapata" },
    { value: "Carioca Sete De Setembro", label: "Carioca Sete De Setembro" },

    { value: "Carioca Shop Ideal", label: "Carioca Shop Ideal" },
    { value: "Carioca Shop Iguatemi", label: "Carioca Shop Iguatemi" },
    { value: "Carioca Shop Itaguaçú", label: "Carioca Shop Itaguaçú" },
    { value: "Carioca Surfwear", label: "Carioca Surfwear" },
    { value: "Constantino Mogi", label: "Constantino Mogi" },
    { value: "Constantino Sjc", label: "Constantino Sjc" },
    { value: "Jô Araraquara Ct (G)", label: "Jô Araraquara Ct (G)" },
    { value: "Jô Bauru Ct", label: "Jô Bauru Ct" },
    { value: "Jô Catanduva Ct", label: "Jô Catanduva Ct" },
    { value: "Jô Franca Ct", label: "Jô Franca Ct" },
    { value: "Jô Guara Ct", label: "Jô Guara Ct" },
    { value: "Jô Ituiutaba Ct", label: "Jô Ituiutaba Ct" },
    { value: "Jô Jacareí Ct", label: "Jô Jacareí Ct" },
    { value: "Jô Joinville Ct", label: "Jô Joinville Ct" },
    { value: "Jô Ourinhos Ct", label: "Jô Ourinhos Ct" },
    { value: "Jô Presidente Prudente Ct", label: "  Jô Presidente Prudente Ct" },

    { value: "Jô Resende Ct", label: "Jô Resende Ct" },
    { value: "Jô São Carlos Ct", label: "Jô São Carlos Ct" },
    { value: "Jô Satélite Ct", label: "Jô Satélite Ct" },
    { value: "Jô Sjc Cl1 Ct", label: "Jô Sjc Cl1 Ct" },
    { value: "Jô Sjc Cl2 Ct", label: "Jô Sjc Cl2 Ct" },
    { value: "Jô Taubaté Ct", label: "Jô Taubaté Ct" },
    { value: "Jô Taubaté Sh", label: "Jô Taubaté Sh" },
    { value: "Jô Uberaba Ct", label: "Jô Uberaba Ct" },
    { value: "Jô Uberlândia Ct", label: "Jô Uberlândia Ct" },
    { value: "Oscar Araçatuba Ct", label: "Oscar Araçatuba Ct" },
    { value: "Oscar Araraquara", label: "Oscar Araraquara" },
    { value: "Oscar Barretos Ct", label: "Oscar Barretos Ct" },
    { value: "Oscar Bauru", label: "Oscar Bauru" },
    { value: "Oscar Bauru Sh", label: "Oscar Bauru Sh" },
    { value: "Oscar Botucatu Ct", label: "Oscar Botucatu Ct" },
    { value: "Oscar Caçapava Ct", label: "Oscar Caçapava Ct" },
    { value: "Oscar Calçadão Mogi", label: "Oscar Calçadão Mogi" },
    { value: "Oscar Caraguá Sh", label: "Oscar Caraguá Sh" },
    { value: "Oscar Centro Mogi", label: "Oscar Centro Mogi" },
    { value: "Oscar Cl1 Ct", label: "Oscar Cl1 Ct" },
    { value: "Oscar Cl2 Ct", label: "Oscar Cl2 Ct" },
    { value: "Oscar Cn Sh", label: "Oscar Cn Sh" },
    { value: "Oscar Guará Ct", label: "Oscar Guará Ct" },
    { value: "Oscar Guará Sh", label: "Oscar Guará Sh" },
    { value: "Oscar Jac Shop", label: "Oscar Jac Shop" },
    { value: "Oscar Jac2 Ct", label: "Oscar Jac2 Ct" },
    { value: "Oscar Jp Ct", label: "Oscar Jp Ct" },
    { value: "Oscar Js Ct", label: "Oscar Js Ct" },
    { value: "Oscar Jundiaí", label: "Oscar Jundiaí" },
    { value: "Oscar Limeira Ct", label: "Oscar Limeira Ct" },
    { value: "Oscar Lorena Ct", label: "Oscar Lorena Ct" },
    { value: "Oscar Mega Vale Sul", label: "Oscar Mega Vale Sul" },
    { value: "Oscar Mogi Guaçu", label: "Oscar Mogi Guaçu" },
    { value: "Oscar Mogi Mirim (Paraíso)", label: "Oscar Mogi Mirim (Paraíso)" },

    { value: "Oscar Pinda Ct", label: "Oscar Pinda Ct" },
    { value: "Oscar Pinda Sh", label: "Oscar Pinda Sh" },
    { value: "Oscar Piracicaba", label: "Oscar Piracicaba" },
    { value: "Oscar Piracicaba 69", label: "Oscar Piracicaba 69" },
    { value: "Oscar Ribeirão Preto Ct", label: "Oscar Ribeirão Preto Ct" },

    { value: "Oscar Ribeirão Preto Sh", label: "Oscar Ribeirão Preto Sh" },

    { value: "Oscar Rio Preto Ct", label: "Oscar Rio Preto Ct" },
    { value: "Oscar Rio Preto Ct", label: "Oscar Rio Preto Ct" },
    { value: "Oscar Rio Preto Sh", label: "Oscar Rio Preto Sh" },
    { value: "Oscar Sh2 Sh", label: "Oscar Sh2 Sh" },
    { value: "Oscar Shibata Sjc", label: "Oscar Shibata Sjc" },
    { value: "Oscar Shopping Mogi", label: "Oscar Shopping Mogi" },
    { value: "Oscar Shopping Suzano", label: "Oscar Shopping Suzano" },
    { value: "Oscar Sorocaba", label: "Oscar Sorocaba" },
    { value: "Oscar Taubaté Ct", label: "Oscar Taubaté Ct" },
    { value: "Oscar Taubaté Sh", label: "Oscar Taubaté Sh" },
    { value: "Oscar Tênis Calçadão", label: "Oscar Tênis Calçadão" },
    { value: "Oscar Tênis Jacareí Ct", label: "Oscar Tênis Jacareí Ct" },

    { value: "Oscar Tênis Vale Sul", label: "Oscar Tênis Vale Sul" },
    { value: "Oscar Votuporanga Ct", label: "Oscar Votuporanga Ct" },
    { value: "Scarlen Centro Mogi", label: "Scarlen Centro Mogi" },
    { value: "Scarlen Shopping Mogi", label: "Scarlen Shopping Mogi" },
    { value: "Scarlen Suzano Sh", label: "Scarlen Suzano Sh" },
    { value: "Stock Show Braz Cubas Ct", label: "Stock Show Braz Cubas Ct" },

    { value: "Stock Show Poá Ct", label: "Stock Show Poá Ct" },
    { value: "Stock Show Sjc Ct", label: "Stock Show Sjc Ct" },
    { value: "Stock Show Suzano Ct", label: "Stock Show Suzano Ct" },
    { value: "Usaflex Guarulhos Sh", label: "Usaflex Guarulhos Sh" },
    { value: "Usaflex Mogi Sh", label: "Usaflex Mogi Sh" },
    { value: "Usaflex Suzano Sh", label: "Usaflex Suzano Sh" },
    { value: "Usaflex Tatuapé Sh", label: "Usaflex Tatuapé Sh" },
    { value: "Vh Center Vale", label: "Vh Center Vale" },
    { value: "Vh Colinas", label: "Vh Colinas" },
]