import { useState, useEffect } from "react";
import SmoothScroll from "smooth-scroll";

import { Navigation } from "../components/navigation/Navigation";

import { ExchangeComprandoAqui } from "../components/kahshStoreNovo/exchangeComprandoAqui/ExchangeComprandoAqui";
import { KahshStoreItens } from "../components/kahshStoreNovo/kahshStoreItens/KahshStoreItens";
import { UnificandoEcomerce } from "../components/kahshStoreNovo/unificandoEcomerce/UnificandoEcomerce";
import { AcesseAgora } from "../components/kahshStoreNovo/acesseAgora/AcesseAgora";
import { KahshStoreVideo } from "../components/kahshStoreNovo/kahshStoreVideo/KahshStoreVideo";
import { IntroStore } from "../components/kahshStoreNovo/introStore/IntroStore";

import { Footer } from "../components/footer/Footer2";

import JsonData from "../data/data.json";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

export function KahshStoreNovo() {
  const [landingPageData, setLandingPageData] = useState({});

  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Navigation />
      <IntroStore data={landingPageData.IntroStore} />
      <KahshStoreVideo data={landingPageData.KahshStoreVideo} />
      <ExchangeComprandoAqui data={landingPageData.ExchangeComprandoAqui} />
      <UnificandoEcomerce data={landingPageData.UnificandoEcomerce} />
      <KahshStoreItens data={landingPageData.KahshStoreItens} />
      <AcesseAgora data={landingPageData.AcesseAgora} />
      <Footer />
    </div>
  );
}
