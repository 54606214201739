import { Grid } from "@mui/material";
import { useMemo, useEffect } from "react";
import { Navigation } from "../components/navigation/Navigation";
import { Footer } from "../components/footer/Footer2";
import { Calculadora } from "../components/Staking2/calculadora/CalculadoraNova";
import { useLocation, useHistory } from "react-router-dom";

export function InvestNovo() {
  const history = useHistory();
  const location = useLocation();

  const { search } = useLocation();
  const urlParams = useMemo(() => new URLSearchParams(search), [search]);

  useEffect(() => {
    if (urlParams.get("l") === "0") {
      localStorage.setItem("mark", urlParams.get("mark"));
      localStorage.setItem("month", urlParams.get("month"));

      history.push(
        "/authentication?return=" + location.pathname + "&destination=0"
      );
    } else {
    }
  });

  return (
    <Grid width="100%">
      <Navigation />
      <Grid pb="3vw">
        <Calculadora />
      </Grid>
      <Footer />
    </Grid>
  );
}
