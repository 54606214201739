import { useState, useEffect } from "react";
import SmoothScroll from "smooth-scroll";

import { Navigation } from "../components/navigation/Navigation";

import { KahshStoreMaiorClube } from "../components/kahshStore/kahshStoreMaiorClube/KahshStoreMaiorClube";
import { KahshStoreDiversidade } from "../components/kahshStore/kahshStoreDiversidade/KahshStoreDiversidade";
import { KahshStoreProdutos } from "../components/kahshStore/kahshStoreProdutos/KahshStoreProdutos";
import { KahshStoreFacilita } from "../components/kahshStore/kahshStoreFacilita/KahshStoreFacilita";
import { KahshStoreBeneficios } from "../components/kahshStore/kahshStoreBeneficios/KahshStoreBeneficios";
import { KahshStoreNumeros } from "../components/kahshStore/kahshStoreNumeros/KahshStoreNumeros";

import { Footer } from "../components/footer/Footer2";

import JsonData from "../data/data.json";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

export function KahshStore() {
  const [landingPageData, setLandingPageData] = useState({});

  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Navigation />
      <KahshStoreMaiorClube data={landingPageData.KahshStoreMaiorClube} />
      <KahshStoreFacilita data={landingPageData.KahshStoreFacilita} />
      <KahshStoreBeneficios data={landingPageData.KahshStoreBeneficios} />
      <KahshStoreNumeros data={landingPageData.KahshStoreNumeros} />
      <KahshStoreDiversidade data={landingPageData.KahshStoreDiversidade} />
      <KahshStoreProdutos data={landingPageData.KahshStoreProdutos} />
      <Footer />
    </div>
  );
}
