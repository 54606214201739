import { Container, Grid } from "@mui/material";
import { useState, useEffect } from "react";
import SmoothScroll from "smooth-scroll";

import { Navigation } from "../components/navigation/Navigation";

import { KahshPayIntroducao } from "../components/KahshPayNovo/kahshPayIntroducao/KahshPayIntroducao";

import { KahshPayBeneficios } from "../components/KahshPayNovo/kahshPayBeneficios/KahshPayBeneficios";
import { AcesseAgora } from "../components/KahshPayNovo/acesseAgora/AcesseAgora";

import { KahshPayVideo } from "../components/KahshPayNovo/KashPayVideo/KahshPayVideo";
import { Footer } from "../components/footer/Footer2";

import JsonData from "../data/data.json";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

export function KahshPay() {
  const [landingPageData, setLandingPageData] = useState({});

  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <>
      <Navigation />
      <Container maxWidth="xl" disableGutters>
        <KahshPayIntroducao data={landingPageData.KahshPayIntroducao} />
        <KahshPayVideo data={landingPageData.KahshPayVideo} />
        <KahshPayBeneficios data={landingPageData.KahshPayBeneficios} />
        <AcesseAgora data={landingPageData.AcesseAgora} />
      </Container>
      <Footer />
    </>
  );
}
