import { useState, useEffect } from "react";
import SmoothScroll from "smooth-scroll";

import { Navigation } from "../components/navigation/Navigation";

import { KahshExchange } from "../components/kahshExchangeNovo/kahshExchange/KahshExchange";
import { MelhoresVantagens } from "../components/kahshExchangeNovo/melhoresVantagens/MelhoresVantagens";
import { ExchangeVideo } from "../components/kahshExchangeNovo/exchangeVideo/ExchangeVideo";
import { ExchangeCards } from "../components/kahshExchangeNovo/exchangeCards/ExchangeCards";
import { MonteSuaCarteira } from "../components/kahshExchangeNovo/monteSuaCarteira/MonteSuaCarteira";
import { ExchanceProducts } from "../components/kahshExchangeNovo/exchanceProducts/ExchanceProducts";
import { Transacoes } from "../components/kahshExchangeNovo/transacoes/Transacoes";
import { RecebaInformacoes } from "../components/kahshExchangeNovo/RecebaInformacoes/RecebaInformacoes";
import { PrimeirosPassos } from "../components/kahshExchangeNovo/primeirosPassos/PrimeirosPassos";

import { Footer } from "../components/footer/Footer2";

import JsonData from "../data/data.json";
import { Container } from "@mui/material";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

export function Exchange() {
  const [landingPageData, setLandingPageData] = useState({});

  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Navigation />
      <Container maxWidth="xl" disableGutters>
        <KahshExchange data={landingPageData.KahshExchange} />
        <ExchangeVideo data={landingPageData.ExchangeVideo} />
        <MelhoresVantagens data={landingPageData.MelhoresVantagens} />
        <ExchangeCards data={landingPageData.ExchangeCards} />
        <MonteSuaCarteira data={landingPageData.MonteSuaCarteira} />
        <ExchanceProducts data={landingPageData.ExchanceProducts} />
        <Transacoes data={landingPageData.Transacoes} />
        <RecebaInformacoes data={landingPageData.RecebaImformacoes} />
        <PrimeirosPassos data={landingPageData.PrimeirosPassos} />
      </Container>
      <Footer />
    </div>
  );
}
