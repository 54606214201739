import { Divider, Grid, Typography } from "@mui/material";

import useWindowDimensions from "../../../hooks/useWindowDimensions";

import i18n from "i18next";
import { useTranslation } from "react-i18next";

import CasalK from "../../../imagens/CasalK.png";
import casalKMobile from "../../../imagens/casalKMobile.png";
import Desconto50 from "../../../imagens/Desconto50.png";
import desconto50Mobile from "../../../imagens/desconto50Mobile.png";
import HomemK from "../../../imagens/HomemK.png";
import homemKMobile from "../../../imagens/HomemKMobile.png";
import mulherCartaoMobile from "../../../imagens/mulherCartaoMobile.png";
import MulherSegurandoCartao from "../../../imagens/MulherSegurandoCartao.png";

import "./KahshPayBeneficios.styles.css";

export const KahshPayBeneficios = (props) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  return (
    <>
      {width > 1024 ? (
        <>
          <Grid
            xs={12}
            container
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            id="kahshPayBeneficiosContainer"
            mb={14}
          >
            <Grid xs={12}>
              {i18n.language.substring(0, 2) == "pt" ? (
                <Typography
                  pt={2}
                  fontFamily="Montserrat"
                  fontStyle="normal"
                  color="#232340"
                  fontWeight={300}
                  fontSize="70px"
                  lineHeight="85px"
                  letterSpacing="-1px"
                >
                  <b>Benefícios</b> para quem
                  <br />
                  <b>compra</b> e para quem <b>vende</b>
                </Typography>
              ) : (
                <Typography
                  pt={2}
                  fontFamily="Montserrat"
                  fontStyle="normal"
                  color="#232340"
                  fontWeight={300}
                  fontSize="70px"
                  lineHeight="85px"
                  letterSpacing="-1px"
                >
                  <b>Benefits</b> for whom
                  <br />
                  <b>buy</b> and to whom <b>sell</b>
                </Typography>
              )}
            </Grid>

            <Grid pt={10} ml="-34rem" display="flex" alignItems="center">
              <Typography
                pr={2}
                pb={2}
                fontFamily="Montserrat"
                fontStyle="normal"
                color="#232340"
                fontWeight={700}
                fontSize="40px"
                lineHeight="48px"
                letterSpacing="-0.4px"
              >
                <b>{t("lojistas")}</b>
              </Typography>
              <Divider sx={"border: 1px solid #5956E9; width: 26rem"} />
            </Grid>

            <Grid
              item
              display="flex"
              alignItems="center"
              justifyContent="center"
              ml="-4rem"
            >
              <Grid
                item
                display="flex"
                alignItems="start"
                flexDirection="column"
              >
                <Grid>
                  <img src={HomemK} alt="" />
                </Grid>

                <Grid pl="13rem">
                  {i18n.language.substring(0, 2) == "pt" ? (
                    <Typography
                      pt={2}
                      fontFamily="Montserrat"
                      fontStyle="normal"
                      color="#232340"
                      fontWeight={700}
                      fontSize="40px"
                      lineHeight="48px"
                      letterSpacing="-0.4px"
                    >
                      As menores taxas
                      <br />
                      do setor
                    </Typography>
                  ) : (
                    <Typography
                      pt={2}
                      fontFamily="Montserrat"
                      fontStyle="normal"
                      color="#232340"
                      fontWeight={700}
                      fontSize="40px"
                      lineHeight="48px"
                      letterSpacing="-0.4px"
                    >
                      The lowest fees
                      <br />
                      industry
                    </Typography>
                  )}
                  <Typography
                    pt={1}
                    alignSelf="start"
                    fontFamily="Montserrat"
                    fontStyle="normal"
                    color="#606060"
                    fontWeight={400}
                    fontSize="18px"
                    lineHeight="32px"
                  >
                    {t("valorDaTaxa")}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                item
                display="flex"
                alignItems="start"
                flexDirection="column"
                pt="4rem"
              >
                <Grid>
                  <img src={Desconto50} alt="" />
                </Grid>

                <Grid>
                  <Typography
                    pt={4}
                    pl={9}
                    fontFamily="Montserrat"
                    fontStyle="normal"
                    color="#232340"
                    fontWeight={700}
                    fontSize="40px"
                    lineHeight="48px"
                    letterSpacing="-0.4px"
                  >
                    <b>{t("kahshBonus")}</b>
                  </Typography>
                  <Typography
                    pt={4}
                    pl={9}
                    alignSelf="start"
                    fontFamily="Montserrat"
                    fontStyle="normal"
                    color="#606060"
                    fontWeight={400}
                    fontSize="18px"
                    lineHeight="32px"
                  >
                    {t("bonus1")}
                    <br />
                    {t("bonus2")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid pt={6} pb={2} ml="-19rem" display="flex" alignItems="center">
              <Typography
                pr={2}
                pb={2}
                fontFamily="Montserrat"
                fontStyle="normal"
                color="#232340"
                fontWeight={700}
                fontSize="40px"
                lineHeight="48px"
                letterSpacing="-0.4px"
              >
                <b>{t("consumidores")}</b>
              </Typography>
              <Divider sx={"border: 1px solid #00B0FF; width: 26rem"} />
            </Grid>

            <Grid
              item
              display="flex"
              alignItems="center"
              justifyContent="center"
              ml="-1rem"
            >
              <Grid
                item
                display="flex"
                alignItems="start"
                flexDirection="column"
              >
                <Grid>
                  <img src={MulherSegurandoCartao} alt="" />
                </Grid>

                <Grid pl="16rem">
                  {i18n.language.substring(0, 2) == "pt" ? (
                    <Typography
                      pt={5}
                      fontFamily="Montserrat"
                      fontStyle="normal"
                      color="#232340"
                      fontWeight={700}
                      fontSize="40px"
                      lineHeight="48px"
                      letterSpacing="-0.4px"
                    >
                      <b>Transações livres </b>
                      <br />
                      <b>de interferências</b>
                    </Typography>
                  ) : (
                    <Typography
                      pt={5}
                      fontFamily="Montserrat"
                      fontStyle="normal"
                      color="#232340"
                      fontWeight={700}
                      fontSize="40px"
                      lineHeight="48px"
                      letterSpacing="-0.4px"
                    >
                      <b>Free Transactions</b>
                      <br />
                      <b>of interference</b>
                    </Typography>
                  )}

                  {i18n.language.substring(0, 2) == "pt" ? (
                    <Typography
                      pt={1}
                      alignSelf="start"
                      fontFamily="Montserrat"
                      fontStyle="normal"
                      color="#606060"
                      fontWeight={400}
                      fontSize="18px"
                      lineHeight="32px"
                    >
                      Você paga, transaciona e compra com <br />
                      Kahsh em qualquer lugar do mundo.
                    </Typography>
                  ) : (
                    <Typography
                      pt={1}
                      alignSelf="start"
                      fontFamily="Montserrat"
                      fontStyle="normal"
                      color="#606060"
                      fontWeight={400}
                      fontSize="18px"
                      lineHeight="32px"
                    >
                      You pay, transact and buy with <br />
                      Kahsh anywhere in the world.
                    </Typography>
                  )}
                </Grid>
              </Grid>

              <Grid
                item
                display="flex"
                alignItems="start"
                flexDirection="column"
                pt="3rem"
              >
                <Grid>
                  <img src={CasalK} alt="" />
                </Grid>

                <Grid pl={5}>
                  {i18n.language.substring(0, 2) == "pt" ? (
                    <Typography
                      pt="4rem"
                      fontFamily="Montserrat"
                      fontStyle="normal"
                      color="#232340"
                      fontWeight={700}
                      fontSize="40px"
                      lineHeight="48px"
                      letterSpacing="-0.4px"
                    >
                      <b>Cupons de descontos </b>
                      <br />
                      <b>e Kahshback</b>
                    </Typography>
                  ) : (
                    <Typography
                      pt={4}
                      fontFamily="Montserrat"
                      fontStyle="normal"
                      color="#232340"
                      fontWeight={700}
                      fontSize="40px"
                      lineHeight="48px"
                      letterSpacing="-0.4px"
                    >
                      <b>Discount coupons</b>
                      <br />
                      <b>and Kahshback</b>
                    </Typography>
                  )}

                  {i18n.language.substring(0, 2) == "pt" ? (
                    <Typography
                      pt={1}
                      alignSelf="start"
                      fontFamily="Montserrat"
                      fontStyle="normal"
                      color="#606060"
                      fontWeight={400}
                      fontSize="18px"
                      lineHeight="32px"
                    >
                      Quanto mais você usa, mais benefícios <br />
                      você ganha.
                    </Typography>
                  ) : (
                    <Typography
                      pt={1}
                      alignSelf="start"
                      fontFamily="Montserrat"
                      fontStyle="normal"
                      color="#606060"
                      fontWeight={400}
                      fontSize="18px"
                      lineHeight="32px"
                    >
                      The more you use it, the more benefits <br />
                      you win.
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            id="kahshPayBeneficiosContainer"
            mb="-5rem"
          >
            <Grid container justifyContent="center">
              {i18n.language.substring(0, 2) == "pt" ? (
                <Typography
                  width="100%"
                  pt={5}
                  pl={2}
                  fontFamily="Montserrat"
                  fontStyle="normal"
                  color="#232340"
                  fontWeight={300}
                  fontSize="30px"
                >
                  <b>Benefícios</b> para quem <b>compra</b> e para quem{" "}
                  <b>vende</b>
                </Typography>
              ) : (
                <Typography
                  width="100%"
                  pt={5}
                  pl={2}
                  fontFamily="Montserrat"
                  fontStyle="normal"
                  color="#232340"
                  fontWeight={300}
                  fontSize="30px"
                >
                  <b>Benefits</b> for whom <b>buy</b> and to whom <b>sell</b>
                </Typography>
              )}

              <Grid container py={5} alignItems="center">
                <Typography
                  pr={1}
                  pl={4}
                  fontFamily="Montserrat"
                  fontStyle="normal"
                  color="#232340"
                  fontWeight={700}
                  fontSize="28px"
                  lineHeight="48px"
                >
                  <b>{t("lojistas")}</b>
                </Typography>
                <Divider sx={"border: 1px solid #5956E9; flex: 1"} />
              </Grid>
            </Grid>

            <Grid container justifyContent="center">
              <img
                src={homemKMobile}
                alt="smiling shopkeeper with a blue shirt and a black watch"
                width="100%"
                style={{ maxWidth: "350px" }}
              />
            </Grid>

            <Grid container item alignItems="center">
              <Grid container alignItems="center" direction="column" px={2}>
                <Typography
                  pt="3rem"
                  fontFamily="Montserrat"
                  color="#232340"
                  fontWeight={700}
                  fontSize="36px"
                  lineHeight="48px"
                  letterSpacing="-0.4px"
                >
                  {t("lowestFees")}
                </Typography>

                <Typography
                  pt={1}
                  fontFamily="Montserrat"
                  fontStyle="normal"
                  color="#606060"
                  fontWeight={400}
                  fontSize="20px"
                  lineHeight="32px"
                >
                  {t("valorDaTaxa")}
                </Typography>
              </Grid>
            </Grid>

            <Divider className="beneficiosDiviverMobile" />

            <Grid container justifyContent="center">
              <img
                src={desconto50Mobile}
                alt="black card with some opacity and white letters saying get up to 50% discount"
                width="100%"
                style={{ maxWidth: "350px" }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              <Grid container px={2} direction="column" alignItems="center">
                <Typography
                  pt="3rem"
                  fontFamily="Montserrat"
                  color="#232340"
                  fontWeight={700}
                  fontSize="40px"
                  lineHeight="48px"
                  letterSpacing="-0.4px"
                >
                  <b>{t("kahshBonus")}</b>
                </Typography>
                <Typography
                  fontFamily="Montserrat"
                  fontStyle="normal"
                  color="#606060"
                  fontWeight={400}
                  fontSize="20px"
                  lineHeight="32px"
                  pt="2rem"
                >
                  {t("bonusMobile")}
                </Typography>
              </Grid>
            </Grid>

            <Divider className="beneficiosDiviverMobile" />

            <Grid
              container
              item
              my="6rem"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography
                px={2}
                pl={4}
                fontFamily="Montserrat"
                color="#232340"
                fontWeight={700}
                fontSize="28px"
                lineHeight="48px"
              >
                <b>{t("consumidores")}</b>
              </Typography>
              <Divider sx={"border: 1px solid #00B0FF; flex: 1"} />
            </Grid>

            <Grid container justifyContent="center">
              <img
                src={mulherCartaoMobile}
                alt="Mulher segurando cartão"
                width="100%"
                style={{ maxWidth: "350px" }}
              />
            </Grid>

            <Grid item display="flex" alignItems="center">
              <Grid container px={2} justifyContent="center">
                <Typography
                  pt="3rem"
                  fontFamily="Montserrat"
                  color="#232340"
                  fontWeight={700}
                  fontSize="36px"
                  lineHeight="48px"
                  letterSpacing="-0.4px"
                >
                  {t("transactionsFree")}
                </Typography>

                <Typography
                  pt="2rem"
                  fontFamily="Montserrat"
                  fontStyle="normal"
                  color="#606060"
                  fontWeight={400}
                  fontSize="20px"
                  lineHeight="32px"
                >
                  {t("payAndTransactKahsh")}
                </Typography>
              </Grid>
            </Grid>

            <Divider className="beneficiosDiviverMobile2" />

            <Grid container justifyContent="center">
              <img
                src={casalKMobile}
                alt="Casal fazendo compras"
                width="100%"
                style={{ maxWidth: "350px" }}
              />
            </Grid>

            <Grid container px={2} direction="column" alignItems="center">
              <Typography
                pt="3rem"
                fontFamily="Montserrat"
                color="#232340"
                fontWeight={700}
                fontSize="36px"
                lineHeight="48px"
                letterSpacing="-0.4px"
              >
                {t("kahshBackDiscounts")}
              </Typography>

              <Typography
                pt="2rem"
                fontFamily="Montserrat"
                fontStyle="normal"
                color="#606060"
                fontWeight={400}
                fontSize="20px"
                lineHeight="32px"
              >
                {t("moreBenefictsYouEarn")}
              </Typography>
            </Grid>

            <Divider className="beneficiosDiviverMobile2" />
          </Grid>
        </>
      )}
    </>
  );
};
