import { Grid } from "@mui/material";

import { Register } from "../../components/register/Register";
import { LogoAndTranslate } from "../../components/logoAndTranslate/LogoAndTranslate";
import { FooterSmaller } from "../../components/footerSmaller/FooterSmaller";

export function RegisterAccount() {
  return (
    <Grid width={"100%"}>
      <LogoAndTranslate />
      <Register />
      <FooterSmaller />
    </Grid>
  );
}
