import { Grid, Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import imgPlace from "../../../imagens/img-header-mkt-place.png";

import "./IntroStore.styles.css";
import useSharedLink from "../../../hooks/useSharedLink";
import { SaibaMaisButton } from "../../buttons/SaibaMaisButton";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

export const IntroStore = () => {
  useSharedLink();
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  return (
    <Grid
      container
      item
      mb={width < 1200 ? null : -15}
      justifyContent="center"
      xs={20}
    >
      <Grid container item sm={12} lg={11} justifyContent="space-between">
        <Grid xs={12} lg={4} container item direction="column">
          <Grid
            className="universe-container"
            container
            item
            direction="column"
            alignItems="left"
            mt={width < 1200 ? null : 7}
            width="60rem"
          >
            <Typography
              className="universe-text"
              fontSize="5rem"
              color={"#232340"}
            >
              <b>Kahsh</b> Store
            </Typography>
            <Typography
              className="universe-text"
              fontSize="5rem"
              lineHeight="6rem"
              color={"#232340"}
            >
              {t("globalPurchases")}<br />
              {t("globalPurchases2")}
              <b>{t("itHere")}</b>
            </Typography>

            <Typography
              fontSize="2.3rem"
              color={"##606060"}
              lineHeight={"32px"}
              mt={4}
              mr="8rem"
            >
              {t("bigDescriptionStore")}
            </Typography>
          </Grid>
          {width < 1200 ? null : (
            <Grid className="button-container" container my={10}>
              <SaibaMaisButton
                width="65%"
                
                onClick={() => (window.location.href = "/authentication")}
              >
                <Typography fontSize={18} fontWeight={700}>
                  {t("joinNow")}
                </Typography>
              </SaibaMaisButton>
            </Grid>
          )}
        </Grid>
        <Grid
          xs={12}
          lg={8}
          container
          justifyContent="center"
          mt={width < 1200 ? -6 : -16}
          ml={width < 1200 ? -6 : null}
          pt="-15rem"
        >
          <img src={imgPlace} width="100%" alt="" />
        </Grid>
        {width > 1200 ? null : (
          <Grid container justifyContent="center" mt={-8}>
            <SaibaMaisButton width={"85%"}>
              <Typography fontSize={18} fontWeight={700}>
                {t("joinNow")}
              </Typography>
            </SaibaMaisButton>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
