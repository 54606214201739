/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";

import {
  Grid,
  Typography,
  Divider,
  InputBase,
  IconButton,
  Paper,
} from "@mui/material";

import useWindowDimensions from "../../hooks/useWindowDimensions";

import MailchimpSubscribe from "react-mailchimp-subscribe";

import { useTranslation } from "react-i18next";

import packageJson from "../../../package.json";

import Medium from "../../imagens/medium.png";
import Facebook from "../../imagens/Facebook.png";
import Instagram from "../../imagens/Instagram.png";
import logoKKahsh from "../../imagens/logoKKahsh.svg";

import Twitter from "../../icons/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TelegramIcon from "@mui/icons-material/Telegram";
import SmallCircleButton from "../../icons/SmallCircleButton";

import "./Footer2.styles.css";

export const Footer = (props) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  const url =
    "https://kahsh.us20.list-manage.com/subscribe/post?u=54b69f70d58fe07e4622e7074&id=c21c16f685";

  const CustomForm = ({ status, onValidated }) => {
    const [email, setEmail] = useState("");
    const submit = () => {
      email &&
        email.indexOf("@") > -1 &&
        onValidated({
          EMAIL: email,
        });
    };

    return (
      <Paper component="form" className="kahshIoFooterSearch">
        {status === "success" ? (
          <span style={{ color: "green", width: "100%" }}>
            {t("subscribeSuccess")}
          </span>
        ) : status === "pending" ? (
          <span style={{ color: "blue", width: "100%" }}>
            {t("subscribePending")}
          </span>
        ) : status === "error" ? (
          <span style={{ color: "red", width: "100%" }}>
            {t("subscribeError")}
          </span>
        ) : (
          <InputBase
            placeholder={t("informYourEmailFooter")}
            inputProps={{ "aria-label": "search" }}
            type="email"
            className="divInputBase"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
        )}
        <IconButton
          onClick={(e) => submit(e)}
          sx={{ p: "0px" }}
          aria-label="search"
        >
          <SmallCircleButton />
        </IconButton>
      </Paper>
    );
  };

  return (
    <>
      {width > 1024 ? (
        <>
          <Grid mb="-16px" container className="kahshIoFooterContainer">
            <Divider className="kahshIoFooterDividerUp" />

            <Grid className="kahshIoFooterWrapperBtns">
              <Grid pr="8vw" pl="2vw" className="kahshIoFooterLogo">
                <img src={logoKKahsh} alt="" />
              </Grid>

              <Grid className="kahshIoFooterBtns">
                <a href="/">
                  <Typography>{t("ecosystem")}</Typography>
                </a>

                <a href="https:///exchange.kahsh.com" target="_self">
                  <Typography>{t("exchange")}</Typography>
                </a>

                <a href="https://kahsh.medium.com/" target="_blank">
                  <Typography>{t("news")}</Typography>
                </a>

                <a href=" https://www.kahshstore.com.br" target="_blank">
                  <Typography>{t("kahshStore")}</Typography>
                </a>

                <a href="/kahshpay" target="_self">
                  <Typography>{t("kahshPay")}</Typography>
                </a>

                <a href="/foundation" target="_self">
                  <Typography>{t("foundation")}</Typography>
                </a>

                <a href="/staking" target="_self">
                  <Typography>{t("stake")}</Typography>
                </a>

                <a onClick={() => (window.location.href = "/extrato")}>
                  <Typography>{t("extract")}</Typography>
                </a>
              </Grid>
            </Grid>

            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              className="footerDivider"
            />

            <Grid className="kahshIoFooterContato">
              <Typography variant="h2">{t("contact")}</Typography>
              <Typography mt={5.5}>
                Av Cassiano Ricardo, 601 <br />
                Edifício The One - Sala 83
                <br />
                Jardim Aquarius
                <br />
                São José dos Campos - SP
                <br />
                <br />
                CEP 12246-870
                <br />
                Tel 12 3633-2077
              </Typography>
            </Grid>

            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              className="footerDivider"
            />

            <Grid className="kahshIoFooterBoletim">
              <Grid>
                <Typography variant="h2">{t("newsletter")}</Typography>
                <Typography mt={4}>
                  {t("subscribeNewslettervir1")}
                  <br />
                  {t("subscribeNewslettervir2")}
                  <br />
                  {t("subscribeNewslettervir3")}
                  <br />
                  {t("subscribeNewslettervir4")}
                </Typography>

                {/* ====== CAMPO DE BUSCA WEB ======  */}
                <Grid pt={2} pb={1}>
                  <Paper component="form" className="kahshIoFooterSearch">
                    <MailchimpSubscribe
                      url={url}
                      render={({ subscribe, status }) => (
                        <CustomForm
                          status={status}
                          onValidated={(formData) => subscribe(formData)}
                        />
                      )}
                    />
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
            <Divider className="kahshIoFooterCopyrightDivider" />
          </Grid>

          <Grid bgcolor="#FCFCFD" width="100%">
            <Grid mr="18%" pb={2} className="kahshIoFooterRedesSociais">
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                alignSelf="center"
                //mr='10%'

                className="kahshIoFooterDireitos"
              >
                <Typography>
                  &copy; {t("allRightsReserved")} - {packageJson.version}
                </Typography>
              </Grid>

              <a
                href="https://kahsh.medium.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img className="medium" src={Medium} alt="" />
              </a>

              <a href="https://t.me/Kahshcom" target="_blank" rel="noreferrer">
                <TelegramIcon className="svgRedesSociais" />
              </a>

              <a
                href="https://www.youtube.com/channel/UCx4c5IkEieCJ3n7y4scF2eA/featured"
                target="_blank"
                rel="noreferrer"
              >
                <YouTubeIcon className="svgRedesSociais" />
              </a>

              <a
                href="https://www.facebook.com/kahshcom/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="kahshIoFooterRedesSociaisIMG"
                  src={Facebook}
                  alt=""
                />
              </a>

              <a
                href="https://twitter.com/Kahshcom"
                target="_blank"
                rel="noreferrer"
              >
                <Twitter />
              </a>

              <a
                href="https://www.instagram.com/kahshcom/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="kahshIoFooterRedesSociaisIMG"
                  src={Instagram}
                  alt=""
                />
              </a>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            bgcolor="#FCFCFD"
            pt="5vw"
          >
            <Grid pb={3} className="kahshIoFooterBtnsMobile">
              <Grid pb={2}>
                <img src={logoKKahsh} alt="" />
              </Grid>

              <a href="/">
                <Typography>{t("ecosystem")}</Typography>
              </a>

              <a href="https:///exchange.kahsh.com" target="_self">
                <Typography>{t("exchange")}</Typography>
              </a>

              <a href="https://kahsh.medium.com/" target="_blank">
                <Typography>{t("news")}</Typography>
              </a>

              <a href=" https://www.kahshstore.com.br" target="_blank">
                <Typography>{t("kahshStore")}</Typography>
              </a>

              <a href="/kahshpay" target="_self">
                <Typography>{t("kahshPay")}</Typography>
              </a>

              <a href="/foundation" target="_self">
                <Typography>{t("foundation")}</Typography>
              </a>

              <a href="/staking" target="_self">
                <Typography>{t("stake")}</Typography>
              </a>

              <a onClick={() => (window.location.href = "/extrato")}>
                <Typography>{t("extract")}</Typography>
              </a>
            </Grid>

            <Divider pb={5} className="kahshIoFooterCopyrightDividerMobile" />

            <Grid py={3} className="kahshIoFooterContato">
              <Typography variant="h2">{t("contact")}</Typography>
              <Typography>
                Av Cassiano Ricardo, 601 <br />
                Edifício The One - Sala 83
                <br />
                Jardim Aquarius
                <br />
                São José dos Campos - SP
                <br />
                <br />
                CEP 12246-870
                <br />
                Tel 12 3633-2077
              </Typography>
            </Grid>

            <Divider className="kahshIoFooterCopyrightDividerMobile" />

            <Grid py={4} className="kahshIoFooterBoletim">
              <Grid>
                <Typography variant="h2">{t("newsletter")}</Typography>
                <Typography>
                  {t("subscribeNewslettervir1")}
                  <br />
                  {t("subscribeNewslettervir2")}
                  <br />
                  {t("subscribeNewslettervir3")}
                  <br />
                  {t("subscribeNewslettervir4")}
                </Typography>

                {/* ====== CAMPO DE BUSCA WEB ======  */}
                <Grid pt={2} className="kahshIoFooterSearch">
                  <Paper component="form">
                    <MailchimpSubscribe
                      url={url}
                      render={({ subscribe, status }) => (
                        <CustomForm
                          status={status}
                          onValidated={(formData) => subscribe(formData)}
                        />
                      )}
                    />
                  </Paper>
                </Grid>
              </Grid>
            </Grid>

            <Divider className="kahshIoFooterCopyrightDividerMobile" />
          </Grid>

          <Grid
            container
            alignItems="center"
            justifyContent="center"
            width="100%"
            bgcolor="#FCFCFD"
          >
            <Grid pt={3} className="kahshIoFooterDireitos">
              <Typography>
                &copy; {t("allRightsReserved")} - {packageJson.version}
              </Typography>
            </Grid>

            <Grid
              container
              py={2}
              alignItems="center"
              justifyContent="center"
              className="kahshIoFooterRedesSociaisMobile"
            >
              <a
                href="https://kahsh.medium.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img className="medium" src={Medium} alt="" />
              </a>

              <a href="https://t.me/Kahshcom" target="_blank" rel="noreferrer">
                <TelegramIcon className="svgRedesSociais" />
              </a>

              <a
                href="https://www.youtube.com/channel/UCx4c5IkEieCJ3n7y4scF2eA/featured"
                target="_blank"
                rel="noreferrer"
              >
                <YouTubeIcon className="svgRedesSociais" />
              </a>

              <a
                href="https://www.facebook.com/kahshcom/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="kahshIoFooterRedesSociaisIMG"
                  src={Facebook}
                  alt=""
                />
              </a>

              <a
                href="https://twitter.com/Kahshcom"
                target="_blank"
                rel="noreferrer"
              >
                <Twitter />
              </a>

              <a
                href="https://www.instagram.com/kahshcom/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="kahshIoFooterRedesSociaisIMG"
                  src={Instagram}
                  alt=""
                />
              </a>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};
