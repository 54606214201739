import { useState } from "react";
import { Paper, Grid, Box, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import ItemsCarousel from "react-items-carousel";
import { SaibaMaisButton } from "../../buttons/SaibaMaisButton";

import "./KahshStoreProdutos.styles.css";

import conexao from "./../../../imagens/conexao.png";
import Negocie from "./../../../imagens/Negocie.png";
import Grafico from "./../../../imagens/Grafico.png";
import useSharedLink from "../../../hooks/useSharedLink";

export const KahshStoreProdutos = () => {
  useSharedLink();
  // const [isWidth, setIsWidth] = useState(false);
  const matches = useMediaQuery("(min-width:767px)");
  const { t } = useTranslation();
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const chevronWidth = 40;

  // useEffect(() => {
  //   console.log(matches);
  //   setIsWidth(matches);
  // }, [window.innerWidth]);

  // Array provisório enquanto nao temos a API com os produtos,
  const items = [
    {
      id: 1,
      name: "Chocolate ao leite de coco",
      price1: "R$ 18,90",
      price2: "R$ 16,07*",
      src: Negocie,
    },
    {
      id: 2,
      name: "Mini bombom Coco 60g Flormel",
      price1: "R$ 18,90",
      price2: "R$ 16,07*",
      src: Grafico,
    },
    {
      id: 3,
      name: "Biscoito de arroz com chocolate",
      price1: "R$ 18,90",
      price2: "R$ 16,07*",
      src: conexao,
    },
  ];

  return (
    <>
      {!matches ? (
        <Grid
          item
          xs={12}
          container
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          paddingTop="40px"
          paddingBottom="40px"
          className="ProdutosContainer"
        >
          <Box className="ProdutosHeader">
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontSize: "36px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "39px",
                color: "#242526",
              }}
            >
              {t("kahshStoreAlgunsProdutos")}
            </Typography>
          </Box>
          <Box className="ProdutosBody">
            {" "}
            <div>
              <ItemsCarousel
                requestToChangeActive={setActiveItemIndex}
                activeItemIndex={activeItemIndex}
                numberOfCards={1}
                gutter={20}
                leftChevron={<button>{"<"}</button>}
                rightChevron={<button>{">"}</button>}
                infiniteLoop={true}
                chevronWidth={chevronWidth}
              >
                {items.map((e) => {
                  return (
                    <Item
                      key={e.id}
                      name={e.name}
                      price1={e.price1}
                      price2={e.price2}
                      src={e.src}
                    />
                  );
                })}
              </ItemsCarousel>
            </div>
          </Box>
        </Grid>
      ) : (
        <Grid
          item
          xs={12}
          container
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          paddingTop="40px"
          paddingBottom="40px"
          marginBottom="40px"
          className="ProdutosContainer"
        >
          <Box className="ProdutosHeader">
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontSize: "36px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "39px",
                color: "#242526",
              }}
            >
              {t("kahshStoreAlgunsProdutos")}
            </Typography>
            <SaibaMaisButton>
              {t("kahshStoreAlgunsProdutosBotao")}
            </SaibaMaisButton>
          </Box>
          <Box className="ProdutosBody">
            <div>
              <ItemsCarousel
                requestToChangeActive={setActiveItemIndex}
                activeItemIndex={activeItemIndex}
                numberOfCards={3}
                gutter={20}
                leftChevron={<button>{"<"}</button>}
                rightChevron={<button>{">"}</button>}
                infiniteLoop={true}
                chevronWidth={chevronWidth}
              >
                {items.map((e) => {
                  return (
                    <Item
                      key={e.id}
                      name={e.name}
                      price1={e.price1}
                      price2={e.price2}
                      src={e.src}
                    />
                  );
                })}
              </ItemsCarousel>
            </div>
          </Box>
        </Grid>
      )}
    </>
  );
};

const Item = ({ name, price1, price2, src }) => {
  return (
    <>
      <Paper
        className="PaperContainer"
        sx={{ boxShadow: "0px 64px 64px -48px rgba(15, 15, 15, 0.1)" }}
      >
        <Box>
          <img className="PaperImg" src={src} alt="" />
        </Box>
        <Box className="PaperContent">
          <Typography className="PaperName">{name}</Typography>
          <Box className="PaperDescriptionContainer">
            <Typography className="PaperDescription">{price1}</Typography>
            <Typography className="PaperDescription">{price2}</Typography>
          </Box>
        </Box>
      </Paper>
    </>
  );
};
