import { Grid, Typography, Button, IconButton } from "@mui/material";

import useWindowDimensions from "../../../hooks/useWindowDimensions";

import { useTranslation } from "react-i18next";
import i18n from "i18next";

import SmallCircleButton from "../../../icons/SmallCircleButton";

import SacolasDeCompras from "../../../imagens/SacolasDeCompras.png";

export const UnificandoEcomerce = () => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  return (
    <>
      {width > 1024 ? (
        <>
          <Grid
            container
            mt={-8}
            xs={12}
            mb={12}
            display="flex"
            alignItems="center"
            justifyContent="center"
            id="unificandoEcomerce"
          >
            <Grid item sx={6}>
              <Grid
                item
                py={"1rem"}
                xs={5}
                display="flex"
                alignItems="center"
                justifyContent="center"
                bgcolor="#7B76FD"
                borderRadius={8}
              >
                <Button>
                  <Typography
                    color="#FFFFFF"
                    fontWeight={700}
                    fontFamily="Montserrat"
                    fontSize={width < 1200 ? "1.5vw" : "0.7vw"}
                  >
                    {t("featuredProduct")}
                  </Typography>
                </Button>
              </Grid>

              {i18n.language.substring(0, 2) == "pt" ? (
                <Typography
                  mt={3}
                  fontSize="2.5vw"
                  color="#000000"
                  fontWeight={700}
                  fontFamily="Montserrat"
                >
                  Unificando e-commerce
                  <br /> e mundo cripto.
                </Typography>
              ) : (
                <Typography
                  mt={3}
                  fontSize="2.5vw"
                  color="#000000"
                  fontWeight={700}
                  fontFamily="Montserrat"
                >
                  unifying e-commerce
                  <br /> and crypto world.
                </Typography>
              )}

              {i18n.language.substring(0, 2) == "pt" ? (
                <Typography
                  mt={3}
                  fontSize={width < 1200 ? "3vw" : "1.1vw"}
                  color="#606060"
                  fontWeight={400}
                  fontFamily="Montserrat"
                >
                  Não só você compra com Kahsh, mas ainda <br />
                  ganha descontos impressionantes!
                </Typography>
              ) : (
                <Typography
                  mt={3}
                  fontSize={width < 1200 ? "3vw" : "1.1vw"}
                  color="#606060"
                  fontWeight={400}
                  fontFamily="Montserrat"
                >
                  Not only do you shop with Kahsh, but even <br />
                  earn awesome discounts!
                </Typography>
              )}
            </Grid>

            <Grid
              item
              sx={6}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <img src={SacolasDeCompras} width={"80%"} alt="" />
              <Grid>
                <IconButton className="kahshPayAcesseAgoraIcon"
                >
                  <SmallCircleButton/>
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid
            container
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            id="unificandoEcomerce"
          >
            <Grid
              pb={6}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <img src={SacolasDeCompras} width={"100%"} alt="" />
            </Grid>

            <Grid
              p={"1rem 2rem"}
              display="flex"
              alignItems="center"
              justifyContent="center"
              bgcolor="#7B76FD"
              borderRadius={8}
            >
              <Button>
                <Typography
                  color="#FFFFFF"
                  fontWeight={700}
                  fontFamily="Montserrat"
                  fontSize={width < 1200 ? "2.5vw" : "1vw"}
                >
                  {t("featuredProduct")}
                </Typography>
              </Button>
            </Grid>

            {i18n.language.substring(0, 2) == "pt" ? (
              <Typography
                mt={5}
                fontSize="4.5vw"
                color="#000000"
                fontWeight={700}
                fontFamily="Montserrat"
              >
                Unificando <br />
                <b>e-commerce</b> e <b>mundo cripto.</b>
              </Typography>
            ) : (
              <Typography
                mt={3}
                fontSize="4.5vw"
                color="#000000"
                fontWeight={700}
                fontFamily="Montserrat"
              >
                Unifying <br />
                <b>electronic commerce</b> and <b>crypto world.</b>
              </Typography>
            )}

            {i18n.language.substring(0, 2) == "pt" ? (
              <Typography
                mt={3}
                fontSize="4.5vw"
                color="#606060"
                fontWeight={400}
                fontFamily="Montserrat"
              >
                Não só você compra com <br />
                Kahsh, mas ainda ganha <br /> descontos impressionantes!
              </Typography>
            ) : (
              <Typography
                mt={3}
                fontSize="4.5vw"
                color="#606060"
                fontWeight={400}
                fontFamily="Montserrat"
              >
                Not only do you buy with <br />
                Kahsh, but still get <br /> awesome discounts!!
              </Typography>
            )}
          </Grid>
        </>
      )}
    </>
  );
};
