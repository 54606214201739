import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import axios from "axios";
import { initializeApp } from "firebase/app";
import { addDoc, collection, getFirestore } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { firebaseConfig } from "../../services/global";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "./OscarFormulario.styles.css";

import { FieldValues, useForm } from "react-hook-form";
import { Button } from "../../components/Button/Button";
import { DatePicker } from "../../components/inputs/date-picker/datePicker";
import { Select } from "../../components/inputs/select/select.component";
import { TextFieldMask } from "../../components/inputs/text-field-mask/text-field-mask.component";
import { TextField } from "../../components/inputs/text-field/text-field.component";
import { Address } from "../../data/contants/Address";
import { useAlert } from "../../hooks/useAlert";
import { cnpjValidator } from "../../utils/cnpjValidator";
import { cpfValidator } from "../../utils/cpfValidator";

const validator = yup.object().shape({
  name: yup
    .string()
    .required("fullNameRequired")
    .test("test-two-string", function (value) {
      const { path, createError } = this;
      return (
        (value && value?.toString().split(" ").length > 1) ||
        createError({ path, message: "fullNameInvalid" })
      );
    }),
  email: yup.string().required("emailRequired").email("emailInvalid"),
  cpfCnpj: yup
    .string()
    .required("documentRequired")
    .transform((document) => document.replace(/\D+/g, ""))
    .test("test-valid-document", function (value) {
      const { path, createError } = this;
      if (value && value?.length <= 12) {
        return (
          cpfValidator(value) || createError({ path, message: "cpfInvalid" })
        );
      }
      return (
        cnpjValidator(value) || createError({ path, message: "cnpjInvalid" })
      );
    }),
  birthdate: yup
    .date()
    .required("birthDateShopRequired")
    .max(new Date(), "birthDateShopFuture")
    .nullable(),

  loja: yup.string().required("storeRequired"),
});

export function OscarFormulario() {
  const { t } = useTranslation();
  const [userRegister, setUserRegister] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validator),
  });
  const { AlertMessage } = useAlert();
  const [taxidMask, setTaxidMask] = useState("999.999.999-99");

  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);

  useEffect(() => {
    if (userRegister) {
      window.top.location.href = "https://oscar.kahsh.com/sucesso/";
    }
  }, [userRegister]);

  const handleRegister = async (data: FieldValues) => {
    setLoading(true);
    axios
      .post(
        "https://exchange.kahsh.com/api/promoregistro",
        {
          nome_razao_social: data.name,
          cpf_cnpj: data.cpfCnpj,
          email: data.email,
          data_nascimento: data.birthdate,
          api_key: "184589eb09e475ad541eb6c38b15925c",
        },
        {
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          addDoc(collection(db, "promo"), {
            key: "184589eb09e475ad541eb6c38b15925c",
            name: data.name,
            email: data.email,
            cpfCnpj: data.cpfCnpj,
            loja: data.loja,
            birthdate: data.birthdate,
            created: new Date(),
          });
          setUserRegister(true);
        } else {
          if (
            response.data.message ==
            "Esse endereço de e-mail já está registrado na rede kahsh, para entrar na exchange basta utilizar seu e-mail e senha do portal da kahsh.com ou recupere sua senha em nosso site"
          ) {
            setOpen(true);
          } else if (
            response.data.message == "Já existe um usuário usando este CPF/CNPJ"
          ) {
            setOpen(true);
          }
        }
      })
      .catch((error) => {
        window.alert("Erro desconhecido.");
      });
  };

  const handleClose = () => {
    setOpen(false);
    window.location.href = "https://exchange.kahsh.com/login";
  };

  return (
    <>
      <Grid container justifyContent="center" direction="column" gap={3}>
        <TextField
          label={t("fullName")}
          control={control}
          name="name"
          helperText={errors.name && errors.name.message}
        />

        <DatePicker
          label={t("BirthDate")}
          control={control}
          helperText={errors?.birthdate?.message}
          name="birthdate"
          maxDate={new Date()}
          required
        />

        <TextFieldMask
          mask={taxidMask}
          label={t("cpfCnpj")}
          control={control}
          helperText={errors?.cpfCnpj?.message}
          watch={(value) => {
            if (
              value?.replace(/\D+/g, "")?.length > 11 ||
              (value?.replace(/\D+/g, "")?.length === 11 &&
                !cpfValidator(value))
            ) {
              return setTaxidMask("99.999.999/9999-99");
            }
            return setTaxidMask("999.999.999-99");
          }}
          name="cpfCnpj"
          required
        />
        <TextField
          label={t("email")}
          control={control}
          name="email"
          helperText={errors.email && errors.email.message}
        />
        <Select
          autoFocus
          label={t("store")}
          control={control}
          helperText={errors?.loja?.message}
          name="loja"
          options={Address}
          required
        />
        <Button loading={loading} onClick={handleSubmit(handleRegister)}>
          <Typography fontSize="1vw" fontWeight={500}>
            {t("buttonSendEmail")}
          </Typography>
        </Button>
      </Grid>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Typography fontWeight={900} fontSize="2rem" color="#001C57">
            {t("congratulations")}
          </Typography>
        </DialogTitle>

        <Divider color="#F8F8F8" />
        <DialogContent>
          <Typography
            fontWeight={500}
            fontSize="1.5rem"
            color="#232340"
            textAlign="center"
          >
            {t("yourAccountAlreadyExists")}
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button sx={{ width: "1rem" }} size="small" onClick={handleClose}>
            {t("ok")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
