import { useLocation } from 'react-router';
import { useMemo } from 'react';

function useSharedLink(){ 

    const { search  } = useLocation();
    const urlParams = useMemo(() => new URLSearchParams(search), [search]);
    const sharedId = urlParams.get('id');
    
    if(sharedId)  {
        localStorage.setItem("idShared",sharedId);
        //console.log(sharedId);
    }
};

export default useSharedLink;
