import { useState, useEffect, useMemo } from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
  Grid,
  Typography,
  Slider,
  Button,
  InputLabel,
  Divider,
  Modal,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { useAuthState } from "react-firebase-hooks/auth";
import CurrencyFormat from "react-currency-format";
import { BigNumber } from "@0x/utils";
import { useTranslation } from "react-i18next";
import { initializeApp } from "firebase/app";
import i18n from "i18next";

import {
  abi,
  abiStaking,
  coinContract,
  stakingContract,
  urlSignContract,
  firebaseConfig,
} from "../../../services/global";
import {
  getFirestore,
  getDocs,
  collection,
  addDoc,
  query,
  where,
} from "firebase/firestore";
import { auth } from "../../../services/firebase";
import LogoKahshNewPlus from "../../../imagens/LogoKahshNewPlus.svg";
import Web3 from "web3";
import axios from "axios";

import "./Calculadora.styles.css";

const useStyles = makeStyles({
  colorPrimary: {
    color: "#4733FF",
    height: "23px",
    background: "transparent",
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export const Calculadora = () => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const handleClose = () => {
    setOpen(false);
    setButton(false);
  };
  const handleRedirect = () => {
    if (buttonMessage === "goToExtract") {
      window.location.href = "/extrato";
    } else {
      handleClose();
    }
  };
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [button, setButton] = useState(false);
  const [message, setMessage] = useState("");
  const [msg, setMsg] = useState("");
  const [buttonMessage, setbuttonMessage] = useState("ok");

  const marks = [
    { value: 1000, label: "$1.000" },
    { value: 25000, label: "$25.000" },
    { value: 50000, label: "$50.000" },
    { value: 75000, label: "$75.000" },
    { value: 100000, label: "$100.000" },
  ];

  const months = [
    { value: 12, label: "12 meses" },
    { value: 24, label: "24 meses" },
    { value: 36, label: "36 meses" },
  ];

  const { search } = useLocation();
  const urlParams = useMemo(() => new URLSearchParams(search), [search]);

  const classes = useStyles();
  const [markValue, setMarkValue] = useState(
    Number(
      urlParams.get("mark")
        ? urlParams.get("mark")
        : localStorage.getItem("mark")
        ? localStorage.getItem("mark")
        : 1000
    )
  );

  const [valueKSH, setValueKSH] = useState(0);

  const [monthValue, setMonthValue] = useState(
    Number(
      urlParams.get("month")
        ? urlParams.get("month")
        : localStorage.getItem("month")
        ? localStorage.getItem("month")
        : 12
    )
  );
  const [pid, setPid] = useState(0);
  const [kahshCoin, setKahshCoin] = useState(0);
  const [kahshCoin2, setKahshCoin2] = useState(0);
  const [metamesk, setMetamesk] = useState(true);
  const [countMetamesk, setCountMetamesk] = useState(0);
  const [returnValue, setReturnValue] = useState(1);
  const location = useLocation();
  const [user] = useAuthState(auth);
  const [markserror, setmarksError] = useState(false);

  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);

  const checkMarks = () => {
    if (markValue < 1000) {
      setmarksError(true);
      document.body.scrollTo({
        top: document.getElementById("jumpError").offsetTop * 0.8,
        behavior: "smooth",
      });
    } else {
      window.location.href =
        "/invest2?mark=" +
        markValue +
        "&month=" +
        monthValue +
        "&l=" +
        (user ? 1 : 0);
    }
  };

  async function getContract() {
    setOpen(true);
    setMessage("stakingInProgress");
    if (user) {
      if (window.ethereum) {
        window.ethereum
          .enable()
          .then((accounts) => {
            var d = new Date();
            var millisecondssince1970 = d.getTime();
            var newDate = millisecondssince1970 + 15000 * 60; //15 minutes

            var userAccount = Web3.utils.toChecksumAddress(accounts[0]);

            // console.log("Usuario firebase", user.uid);
            // console.log("Carteira", userAccount);

            var wallet = "";
            const walletsDb = collection(db, "wallets");
            const q = query(
              walletsDb,
              where("uid", "==", user.uid),
              where("wallet", "==", userAccount)
            );
            getDocs(q)
              .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  wallet = doc.data().wallet;
                });

                if (!wallet) {
                  addDoc(collection(db, "wallets"), {
                    uid: user.uid,
                    wallet: userAccount,
                  });
                }
              })
              .catch((error) => {});

            var amount = Math.round(markValue / kahshCoin); //6 KSD * 10ˆ18
            var finalAmount = BigNumber(10 ** 18 * amount).toString(); //Convert to string
            const hourNow = newDate;

            const web3 = new Web3(window.ethereum);
            // console.log("web3", web3);
            const FW = new web3.eth.Contract(abi, coinContract); //Open Contract with coinContract
            // console.log("FW", FW);
            FW.methods
              .permitHash(userAccount, stakingContract, finalAmount, hourNow)
              .call(function (error, resPermitHash) {
                if (!error) {
                  // console.log("Res PermitHash", resPermitHash);

                  web3.eth
                    .sign(resPermitHash, userAccount)
                    .then(function (resSign, error) {
                      // console.log("Res Sign", resSign);
                      // console.log("PID", pid);

                      axios
                        .post(urlSignContract, {
                          pool: pid, //PID
                          customer: userAccount,
                          amount: finalAmount,
                          deadline: hourNow,
                          signature: resSign,
                        })
                        .then((response) => {
                          if (
                            response.data.msg ===
                            "execution reverted: Contract already active."
                          ) {
                            setMessage("contractAlreadyActive");
                            setButton(true);
                          } else if (
                            response.data.msg.substring(0, 38) ===
                            "Amount too small. Staking Pool Minimum"
                          ) {
                            setMessage("amountTooSmall");
                            setButton(true);
                          } else if (
                            response.data.msg ===
                            "execution reverted: ERC20: transfer amount exceeds balance"
                          ) {
                            setMessage("amountExceeds");
                            setButton(true);
                          } else if (response.data.msg === "Network Error") {
                            setMessage("networkError");
                            setButton(true);
                          } else if (
                            response.data.msg &&
                            response.data.success
                          ) {
                            setButton(true);
                            setbuttonMessage("goToExtract");
                            setMessage("stakeSuccess");

                            addDoc(collection(db, "txids"), {
                              uid: user.uid,
                              wallet: userAccount,
                              pid: pid,
                              txid: response.data.msg,
                            });
                          } else {
                            setMsg(response.data.msg);
                            setButton(true);
                          }
                        });
                    })
                    .catch((error) => {
                      if (
                        error.message ===
                        "MetaMask Message Signature: User denied message signature."
                      ) {
                        setMessage("metaMaskDeny");
                        setButton(true);
                      }
                    });
                } else {
                  if (
                    error.message ===
                    "Returned values aren't valid, did it run Out of Gas? You might also see this error if you are not using the correct ABI for the contract you are retrieving data from, requesting data from a block number that does not exist, or querying a node which is not fully synced."
                  ) {
                    setMessage("gasError");
                    setButton(true);
                  }
                }
              });
          })
          .catch((error) => {
            if (
              error.message ===
              `invalid BigNumber string (argument="value", value="Infinity", code=INVALID_ARGUMENT, version=bignumber/5.6.0)`
            ) {
              setMessage("metaMaskError");
              setButton(true);
            }
          });
      } else {
        setMetamesk(false);
        setMessage("metameskMessage");
        setButton(true);
        setCountMetamesk(countMetamesk + 1);
      }
    } else {
      history.push(
        "/authentication?return=" + location.pathname + "&destination=0"
      );
    }
  }

  useEffect(() => {
    if (markValue <= 10000) {
      if (monthValue === 12) {
        setReturnValue(1);
        setPid(0);
      }

      if (monthValue === 24) {
        setReturnValue(1.25);
        setPid(1);
      }

      if (monthValue === 36) {
        setReturnValue(1.5);
        setPid(2);
      }
    }

    if (markValue > 10000 && markValue <= 50000) {
      if (monthValue === 12) {
        setReturnValue(1.25);
        setPid(3);
      }

      if (monthValue === 24) {
        setReturnValue(1.5);
        setPid(4);
      }

      if (monthValue === 36) {
        setReturnValue(2);
        setPid(5);
      }
    }

    if (markValue > 50000 && markValue <= 200000) {
      if (monthValue === 12) {
        setReturnValue(1.5);
        setPid(6);
      }

      if (monthValue === 24) {
        setReturnValue(2);
        setPid(7);
      }

      if (monthValue === 36) {
        setReturnValue(2.5);
        setPid(8);
      }
    }
    if (markValue > 200000 && markValue <= 500000) {
      if (monthValue === 12) {
        setReturnValue(2);
        setPid(9);
      }

      if (monthValue === 24) {
        setReturnValue(2.5);
        setPid(10);
      }

      if (monthValue === 36) {
        setReturnValue(3);
        setPid(11);
      }
    }
    if (markValue > 500000 && markValue <= 1000000) {
      if (monthValue === 12) {
        setReturnValue(2.5);
        setPid(12);
      }

      if (monthValue === 24) {
        setReturnValue(3);
        setPid(13);
      }

      if (monthValue === 36) {
        setReturnValue(3.5);
        setPid(14);
      }
    }

    if (markValue > 1000000 && markValue <= 2000000) {
      if (monthValue === 12) {
        setReturnValue(3);
        setPid(15);
      }

      if (monthValue === 24) {
        setReturnValue(3.5);
        setPid(16);
      }

      if (monthValue === 36) {
        setReturnValue(4);
        setPid(17);
      }
    }

    if (markValue > 2000000 && markValue <= 3000000) {
      if (monthValue === 12) {
        setReturnValue(3.5);
        setPid(18);
      }

      if (monthValue === 24) {
        setReturnValue(4);
        setPid(19);
      }

      if (monthValue === 36) {
        setReturnValue(4.5);
        setPid(20);
      }
    }

    if (markValue > 3000000) {
      if (monthValue === 12) {
        setReturnValue(4);
        setPid(21);
      }

      if (monthValue === 24) {
        setReturnValue(4.5);
        setPid(22);
      }

      if (monthValue === 36) {
        setReturnValue(5);
        setPid(23);
      }
    }

    if (kahshCoin <= 0) {
      let cancel = false;
      try {
        const fetchData = () => {
          if (cancel) return;
          axios
            .get("https://exchange.kahsh.com/cotacao/USD")
            .then((res) => {
              const json = res.data;
              setKahshCoin(json[2].cotacao);
              return res.data;
            })
            .catch((err) => {
              setKahshCoin(-1);
            });
          return () => {
            cancel = true;
          };
        };
        fetchData();
      } catch (error) {
        return error;
      }
    } else {
    }

    if (kahshCoin2 <= 0) {
      let cancel = false;
      try {
        const fetchData = () => {
          if (cancel) return;
          axios
            .get("https://exchange.kahsh.com/cotacao")
            .then((res) => {
              const json = res.data;
              setKahshCoin2(json[2].cotacao);
              return res.data;
            })
            .catch((err) => {
              setKahshCoin2(-1);
            });
          return () => {
            cancel = true;
          };
        };
        fetchData();
      } catch (error) {
        return error;
      }
    }

    if (countMetamesk === 2) {
      setCountMetamesk(0);
      window.location.reload(false);
    }
  }, [markValue, monthValue, kahshCoin, kahshCoin2, countMetamesk]);

  function valuetext(value) {
    return `${value}$`;
  }

  return (
    <>
      {width > 1064 ? (
        <>
          <Grid container id="calculadoraNova" pt={7}>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="parent-modal-title"
              aria-describedby="parent-modal-description"
            >
              <Grid
                className="boxModalCalculadoraNova"
                sx={{ ...style }}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <Grid
                  className="headerModalCalculadoraNova"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography variant="h2" id="parent-modal-title">
                    {t("Status")}
                  </Typography>
                </Grid>
                <Grid pt={2} pb={2} justifyContent="center">
                  {message ? (
                    <Typography sx={{ fontSize: "18px" }}>
                      {t(message)}
                    </Typography>
                  ) : (
                    <Typography sx={{ fontSize: "26px" }}>{msg}</Typography>
                  )}
                </Grid>
                {button ? (
                  <Grid
                    mr={17.5}
                    container
                    justifyContent="center"
                    className="boxBtnModalCalculadoraNova"
                  >
                    <Button
                      onClick={handleRedirect}
                      className="boxBtnModalInsideCalculadoraNova"
                    >
                      {t(buttonMessage)}
                    </Button>
                  </Grid>
                ) : null}
              </Grid>
            </Modal>
            <Grid container pl={12} pb={10}>
              <Typography
                mt={3}
                fontSize="3vw"
                color="#000000"
                fontWeight={400}
                fontFamily="Montserrat"
                dangerouslySetInnerHTML={{
                  __html: t("seeHowMuchYouCanEarn"),
                }}
              />
            </Grid>

            {/* ===== CONTAINER COM OS LADO ESQUERDO E DIREITO) ===== */}
            <Grid container alignItems="center" justifyContent="center">
              <Grid
                item
                container
                xs={11}
                p="4rem 6rem"
                border="1px solid #DBD9D9"
                borderRadius="18px"
              >
                {/* ===== CONTAINER COM OS VALORES, MESES E RETORNO (LADO ESQUERDO ) ===== */}
                <Grid xs={6} container direction="column">
                  {/* ===== BOX COM VALORES WEB ===== */}
                  <Grid>
                    <Typography
                      fontSize="2rem"
                      color="#5C5E61"
                      fontWeight={400}
                      fontFamily="Montserrat"
                    >
                      {t("quantoVoceVaiGanhar")}
                    </Typography>

                    <Grid display="flex">
                      <Grid item xs={12}>
                        <Slider
                          aria-label="Custom marks"
                          value={markValue}
                          getAriaValueText={valuetext ? valuetext : markValue}
                          step={1000}
                          valueLabelDisplay="auto"
                          min={1000}
                          max={100000}
                          marks={marks}
                          className={classes.colorPrimary}
                          onChange={(e) => {
                            setMarkValue(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid mt={-2} ml={10} width="30rem">
                        <InputLabel
                          id="jumpError"
                          style={{
                            marginRight: markserror ? 0 : null,
                            color: markserror ? "red" : "",
                          }}
                        >
                          {t(!markserror ? "enterValue" : "valueError")}
                        </InputLabel>
                        <CurrencyFormat
                          className="valorStakeInputCalculadoraNova"
                          value={markValue}
                          displayType={"input"}
                          thousandSeparator={true}
                          decimalSeparator={"."}
                          prefix={"$  "}
                          onChange={(e) => {
                            setmarksError(false);
                            setMarkValue(
                              Number(e.target.value.replace(/\D/g, ""))
                            );
                            setValueKSH(
                              Number(e.target.value.replace(/\D/g, "")) /
                                kahshCoin2
                            );
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* ===== || ===== */}

                  {/* ===== BOX COM MESES WEB ===== */}
                  <Grid pt={12}>
                    <Typography
                      fontSize="2rem"
                      color="#5C5E61"
                      fontWeight={400}
                      fontFamily="Montserrat"
                    >
                      {t("porQualPeriodo")}
                    </Typography>

                    <Grid display="flex">
                      <Grid item xs={12}>
                        <Slider
                          aria-label="Custom marks"
                          value={monthValue}
                          getAriaValueText={valuetext}
                          step={12}
                          valueLabelDisplay="auto"
                          min={12}
                          max={36}
                          marks={months}
                          className={classes.colorPrimary}
                          onChange={(e) => {
                            setMonthValue(e.target.value);
                          }}
                        />
                      </Grid>

                      <Grid
                        ml={10}
                        width="30rem"
                        className="mesStakeBtnCalculadoraNova"
                      >
                        <Button>
                          {monthValue}
                          {monthValue > 0 ? " meses" : " mês"}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* ===== || ===== */}

                  {/* ===== BOX COM RETORNO EM % WEB ===== */}
                  <Grid pt={10}>
                    <Grid display="flex">
                      <Grid item xs={12}>
                        <Typography
                          fontSize="1.2vw"
                          color="#5C5E61"
                          fontWeight={400}
                          fontFamily="Montserrat"
                          dangerouslySetInnerHTML={{
                            __html: t("monthlyReward"),
                          }}
                        />
                      </Grid>
                      <Grid
                        ml={10}
                        width="30rem"
                        className="retornoStakeBtnCalculadoraNova"
                      >
                        <Button>{returnValue.toFixed(2)}%</Button>
                      </Grid>
                    </Grid>
                    {/* ===== || ===== */}

                    <Grid>
                      {Number(kahshCoin) !== -1 ? (
                        location.pathname.substr(1, 6) === "invest2" ? (
                          <>
                            <Grid
                              item
                              xs={4}
                              mt={5}
                              className="retornoStakeBtnAssinarCalculadoraNova"
                            >
                              {user ? (
                                <Button
                                  disableRipple
                                  onClick={() => getContract()}
                                >
                                  {t("subscribeNow")}
                                </Button>
                              ) : (
                                <Button
                                  disableRipple
                                  onClick={() => getContract()}
                                >
                                  {t("connect")}
                                </Button>
                              )}
                            </Grid>
                            {!metamesk ? (
                              <Typography className="metamesk">
                                {t("metameskMessage")}
                                <a
                                  className="linkMetamesk"
                                  href="https://metamask.io/download/"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {t("install")}
                                </a>
                              </Typography>
                            ) : null}
                          </>
                        ) : (
                          <Grid
                            item
                            xs={4}
                            mt={5}
                            className="retornoStakeBtnAssinarCalculadoraNova"
                          >
                            <Button onClick={checkMarks}>
                              {t("queroStake")}
                            </Button>
                          </Grid>
                        )
                      ) : (
                        <Button href="#">{t("noService")}</Button>
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                {/* ===== CONTAINER COM OS GRÁFICO (LADO DIREITO) ===== */}
                <Grid xs={6} container direction="column">
                  <Grid display="flex" justifyContent="end">
                    {/* ===== CONTAINER TOTAL EM STAKE ===== */}
                    <Grid
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      flexDirection="column"
                      textAlign="center"
                    >
                      <Typography
                        // pb={1}
                        fontSize="2rem"
                        color="#5C5E61"
                        fontWeight={400}
                        fontFamily="Montserrat"
                      >
                        {t("youInvest")}
                      </Typography>

                      {Number(kahshCoin2) !== -1 ? (
                        <CurrencyFormat
                          className="valorKshInputCalculadoraNova"
                          value={valueKSH.toFixed(0)}
                          displayType={"input"}
                          thousandSeparator={true}
                          decimalSeparator={"."}
                          prefix={"KSH  "}
                          onChange={(e) => {
                            setValueKSH(
                              Number(e.target.value.replace(/\D/g, ""))
                            );
                            setMarkValue(
                              Number(
                                e.target.value.replace(/\D/g, "") * kahshCoin2
                              )
                            );
                          }}
                        />
                      ) : (
                        <Typography
                          pb={5}
                          fontSize="2rem"
                          color="#5C5E61"
                          fontWeight={400}
                          fontFamily="Montserrat"
                        >
                          {t("noService")}
                        </Typography>
                      )}

                      <Grid
                        height={420}
                        display="flex"
                        alignItems="end"
                        justifyContent="end"
                        flexDirection="column"
                        width="15rem"
                      >
                        <Grid
                          bgcolor="#FAFAFA"
                          border="1px solid #B3B3B3"
                          borderRadius="18px"
                          height="200px"
                          width="100%"
                        />
                      </Grid>
                      <Typography
                        pt={5}
                        pb={1}
                        fontSize="2rem"
                        color="#5C5E61"
                        fontWeight={400}
                        fontFamily="Montserrat"
                      >
                        {t("totalInvested")}
                      </Typography>
                      <Typography
                        fontSize="2rem"
                        color="#5C5E61"
                        fontWeight={400}
                        fontFamily="Montserrat"
                        pl="6rem"
                        dangerouslySetInnerHTML={{
                          __html: t("returnContract"),
                        }}
                      />
                    </Grid>

                    {/* ===== CONTAINER COM O QUE RECEBE/GRÁFICO  ===== */}
                    <Grid ml="10rem" textAlign="center">
                      <Typography
                        fontSize="2rem"
                        color="#5C5E61"
                        fontWeight={400}
                        fontFamily="Montserrat"
                      >
                        {t("youReceive")}
                      </Typography>
                      {Number(kahshCoin) !== -1 ? (
                        <CurrencyFormat
                          value={(
                            ((markValue / kahshCoin) *
                              monthValue *
                              returnValue) /
                            100
                          ).toFixed(0)}
                          displayType={"text"}
                          thousandSeparator={true}
                          decimalSeparator={"."}
                          renderText={(value) => (
                            <Typography
                              fontSize="2.5rem"
                              color="#000000"
                              fontWeight={800}
                              fontFamily="Montserrat"
                            >
                              KSH {value}
                            </Typography>
                          )}
                        />
                      ) : (
                        <Typography
                          fontSize="2rem"
                          color="#5C5E61"
                          fontWeight={400}
                          fontFamily="Montserrat"
                        >
                          {t("noService")}
                        </Typography>
                      )}
                      <Grid
                        pt={5}
                        height={420}
                        display="flex"
                        alignItems="end"
                        justifyContent="end"
                        flexDirection="column"
                        width={150}
                      >
                        <Grid
                          display="flex"
                          justifyContent="center"
                          bgcolor="#4733FF"
                          width="15rem"
                          height={
                            (200 *
                              ((markValue / kahshCoin) *
                                monthValue *
                                returnValue)) /
                            100 /
                            (markValue / kahshCoin)
                          }
                          pt="16px"
                          borderRadius="25px 22px 1px 2px"
                          border="3px solid #B3B3B3"
                        >
                          <img
                            src={LogoKahshNewPlus}
                            alt="Logo Kahsh"
                            height="30px"
                          />
                        </Grid>

                        <Grid
                          bgcolor="#FFFFFF"
                          border="2px solid #EFEFEF"
                          borderRadius=" 0 0 25px 25px"
                          height="200px"
                          width="100%"
                        />
                      </Grid>

                      {Number(kahshCoin) !== -1 ? (
                        <CurrencyFormat
                          value={(markValue / kahshCoin).toFixed(0)}
                          displayType={"text"}
                          thousandSeparator={true}
                          decimalSeparator={"."}
                          renderText={(value) => (
                            <Typography
                              pt={5}
                              fontSize="2rem"
                              color="#000000"
                              fontWeight={600}
                              fontFamily="Montserrat"
                              textAlign="end"
                            >
                              KSH {value}
                            </Typography>
                          )}
                        />
                      ) : (
                        <Typography
                          pt={5}
                          fontSize="2rem"
                          color="#000000"
                          fontWeight={600}
                          fontFamily="Montserrat"
                        >
                          {t("noService")}
                        </Typography>
                      )}

                      <Typography
                        pt={1}
                        fontSize="2rem"
                        color="#000000"
                        fontWeight={600}
                        fontFamily="Montserrat"
                        textAlign="end"
                      >
                        {(returnValue * 12).toFixed(0)}%
                      </Typography>
                    </Grid>
                  </Grid>

                  {/* ===== CONTAINER COM O RETORNO EM $  ===== */}
                  <Grid
                    pt={4}
                    display="flex"
                    alignItems="end"
                    flexDirection="column"
                    // mr="-10rem"
                  >
                    <Typography
                      pr="3rem"
                      fontSize="2rem"
                      color="#000000"
                      fontWeight={800}
                      fontFamily="Montserrat"
                      textAlign="center"
                      dangerouslySetInnerHTML={{
                        __html: t("returnInKSH"),
                      }}
                    />
                  </Grid>
                  <Grid
                    pr="8rem"
                    display="flex"
                    alignItems="end"
                    flexDirection="column"
                  >
                    {Number(kahshCoin) !== -1 ? (
                      <CurrencyFormat
                        value={(
                          ((markValue / kahshCoin) * monthValue * returnValue) /
                          100
                        ).toFixed(0)}
                        displayType={"text"}
                        thousandSeparator={true}
                        decimalSeparator={"."}
                        renderText={(value) => (
                          <Typography
                            fontSize="5rem"
                            color="#000000"
                            fontWeight={800}
                            fontFamily="Montserrat"
                          >
                            KSH {value}
                          </Typography>
                        )}
                      />
                    ) : (
                      <Typography
                        fontSize="2rem"
                        color="#000000"
                        fontWeight={400}
                        fontFamily="Montserrat"
                      >
                        {t("noService")}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid
            pt={12}
            container
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            id="calculadoraNova"
          >
            <Typography
              pb={8}
              fontSize="4.5rem"
              lineHeight="5rem"
              color="#242526"
              fontWeight={300}
              fontFamily="Montserrat"
              dangerouslySetInnerHTML={{
                __html: t("seeHowMuchYouCanEarnMobile"),
              }}
            />

            {/* ===== BOX COM VALORES MOBILE ===== */}
            <Grid width="80%">
              <Typography
                pb={2}
                fontSize="2rem"
                color="#5C5E61"
                fontWeight={400}
                fontFamily="Montserrat"
              >
                {t("quantoVoceVaiGanhar")}
              </Typography>

              <Slider
                aria-label="Custom marks"
                value={markValue}
                getAriaValueText={valuetext ? valuetext : markValue}
                step={1000}
                valueLabelDisplay="auto"
                min={1000}
                max={100000}
                marks={marks}
                className={classes.colorPrimary}
                onChange={(e) => {
                  setMarkValue(e.target.value);
                }}
              />

              <Grid pt={10}>
                <InputLabel
                  id="jumpError"
                  style={{
                    marginRight: markserror ? 0 : null,
                    color: markserror ? "red" : "",
                  }}
                >
                  {t(!markserror ? "enterValue" : "valueError")}
                </InputLabel>

                <CurrencyFormat
                  className="valorStakeInputCalculadoraNova"
                  value={markValue}
                  displayType={"input"}
                  thousandSeparator={true}
                  decimalSeparator={"."}
                  prefix={"$  "}
                  onChange={(e) => {
                    setmarksError(false);
                    setMarkValue(Number(e.target.value.replace(/\D/g, "")));
                    setValueKSH(
                      Number(e.target.value.replace(/\D/g, "")) / kahshCoin2
                    );
                  }}
                />
              </Grid>
            </Grid>

            {/* ===== BOX COM MESES MOBILE ===== */}
            <Grid
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              py="5rem"
              width="80%"
            >
              <Typography
                pb={2}
                fontSize="2rem"
                color="#5C5E61"
                fontWeight={400}
                fontFamily="Montserrat"
              >
                {t("porQualPeriodo")}
              </Typography>

              <Slider
                aria-label="Custom marks"
                value={monthValue}
                getAriaValueText={valuetext}
                step={12}
                valueLabelDisplay="auto"
                min={12}
                max={36}
                marks={months}
                className={classes.colorPrimary}
                onChange={(e) => {
                  setMonthValue(e.target.value);
                }}
              />

              <Grid
                pt={10}
                width="30rem"
                className="mesStakeBtnCalculadoraNova"
              >
                <Button>
                  {monthValue}
                  {monthValue > 0 ? " meses" : " mês"}
                </Button>
              </Grid>
            </Grid>

            {/* ===== BOX COM RETORNO EM % MOBILE ===== */}
            <Grid
              pt={3}
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Typography
                fontSize="2.2rem"
                color="#5C5E61"
                fontWeight={400}
                fontFamily="Montserrat"
                dangerouslySetInnerHTML={{
                  __html: t("monthlyReward"),
                }}
              />
            </Grid>

            <Grid
              py={4}
              width="30rem"
              className="retornoStakeBtnCalculadoraNova"
            >
              <Button>{returnValue.toFixed(2)}%</Button>
            </Grid>

            {/* ===== CONTAINER COM OS GRÁFICO (LADO DIREITO) ===== */}
            <Grid xs={12} container direction="column">
              <Grid display="flex" justifyContent="center">
                {/* ===== CONTAINER TOTAL EM STAKE ===== */}
                <Grid
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  textAlign="center"
                >
                  <Typography
                    // pb={1}
                    fontSize="2rem"
                    color="#5C5E61"
                    fontWeight={400}
                    fontFamily="Montserrat"
                  >
                    {t("youInvest")}
                  </Typography>

                  {Number(kahshCoin2) !== -1 ? (
                    <CurrencyFormat
                      className="valorKshInputCalculadoraNova"
                      value={valueKSH.toFixed(0)}
                      displayType={"input"}
                      thousandSeparator={true}
                      decimalSeparator={"."}
                      prefix={"KSH  "}
                      onChange={(e) => {
                        setValueKSH(Number(e.target.value.replace(/\D/g, "")));
                        setMarkValue(
                          Number(e.target.value.replace(/\D/g, "") * kahshCoin2)
                        );
                      }}
                    />
                  ) : (
                    <Typography
                      pb={5}
                      fontSize="2rem"
                      color="#5C5E61"
                      fontWeight={400}
                      fontFamily="Montserrat"
                    >
                      {t("noService")}
                    </Typography>
                  )}

                  <Grid
                    height={420}
                    display="flex"
                    alignItems="end"
                    justifyContent="end"
                    flexDirection="column"
                    width="100%"
                  >
                    <Grid
                      bgcolor="#FAFAFA"
                      border="1px solid #B3B3B3"
                      borderRadius="25px"
                      height="200px"
                      width="12.75rem"
                    />
                  </Grid>

                  <Typography
                    pt={5}
                    pb={1}
                    fontSize="1.25rem"
                    color="#5C5E61"
                    fontWeight={400}
                    fontFamily="Montserrat"
                  >
                    {t("totalInvested")}
                  </Typography>
                  <Typography
                    fontSize="1.25rem"
                    color="#5C5E61"
                    fontWeight={400}
                    fontFamily="Montserrat"
                    pl="4rem"
                    dangerouslySetInnerHTML={{
                      __html: t("returnContract"),
                    }}
                  />
                </Grid>

                {/* ===== CONTAINER COM O QUE RECEBE/GRÁFICO  ===== */}
                <Grid ml="7rem" textAlign="center">
                  <Typography
                    fontSize="2rem"
                    color="#5C5E61"
                    fontWeight={400}
                    fontFamily="Montserrat"
                  >
                    {t("youReceive")}
                  </Typography>
                  {Number(kahshCoin) !== -1 ? (
                    <CurrencyFormat
                      value={(
                        ((markValue / kahshCoin) * monthValue * returnValue) /
                        100
                      ).toFixed(0)}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalSeparator={"."}
                      renderText={(value) => (
                        <Typography
                          fontSize="2.5rem"
                          color="#000000"
                          fontWeight={800}
                          fontFamily="Montserrat"
                        >
                          KSH {value}
                        </Typography>
                      )}
                    />
                  ) : (
                    <Typography
                      fontSize="2rem"
                      color="#5C5E61"
                      fontWeight={400}
                      fontFamily="Montserrat"
                    >
                      {t("noService")}
                    </Typography>
                  )}
                  <Grid
                    pt={5}
                    pr="1.5rem"
                    height={420}
                    display="flex"
                    alignItems="end"
                    justifyContent="end"
                    flexDirection="column"
                  >
                    <Grid
                      display="flex"
                      justifyContent="center"
                      bgcolor="#4733FF"
                      width="100%"
                      height={
                        (200 *
                          ((markValue / kahshCoin) *
                            monthValue *
                            returnValue)) /
                        100 /
                        (markValue / kahshCoin)
                      }
                      pt="16px"
                      borderRadius="25px 22px 1px 2px"
                    >
                      <img
                        src={LogoKahshNewPlus}
                        alt="Logo Kahsh"
                        height="30px"
                      />
                    </Grid>

                    <Grid
                      bgcolor="#FFFFFF"
                      border="1px solid #EFEFEF"
                      borderRadius=" 0 0 25px 25px"
                      height="200px"
                      width="13rem"
                    />
                  </Grid>

                  {Number(kahshCoin) !== -1 ? (
                    <CurrencyFormat
                      value={(markValue / kahshCoin).toFixed(0)}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalSeparator={"."}
                      renderText={(value) => (
                        <Typography
                          pt={5}
                          pr="1.5rem"
                          fontSize="1.25rem"
                          color="#000000"
                          fontWeight={600}
                          fontFamily="Montserrat"
                          textAlign="end"
                        >
                          KSH {value}
                        </Typography>
                      )}
                    />
                  ) : (
                    <Typography
                      pt={5}
                      fontSize="2rem"
                      color="#000000"
                      fontWeight={600}
                      fontFamily="Montserrat"
                    >
                      {t("noService")}
                    </Typography>
                  )}
                  <Typography
                    pt={1}
                    pr="1.5rem"
                    fontSize="1.25rem"
                    color="#000000"
                    fontWeight={600}
                    fontFamily="Montserrat"
                    textAlign="end"
                  >
                    {(returnValue * 12).toFixed(0)}%
                  </Typography>
                </Grid>
              </Grid>

              <Grid pl="5rem" pr="5rem" pt="3rem">
                <Divider className="stakeCalculadoraDividerMobileCalculadoraNova" />
              </Grid>

              {/* ===== CONTAINER COM O RETORNO EM $  ===== */}
              <Grid
                pt={4}
                display="flex"
                alignItems="center"
                flexDirection="column"
              >
                <Typography
                  fontSize="1.25rem"
                  color="#000000"
                  fontWeight={800}
                  fontFamily="Montserrat"
                  textAlign="center"
                  dangerouslySetInnerHTML={{
                    __html: t("returnInKSH"),
                  }}
                />
              </Grid>
              <Grid display="flex" alignItems="center" flexDirection="column">
                {Number(kahshCoin) !== -1 ? (
                  <CurrencyFormat
                    value={(
                      ((markValue / kahshCoin) * monthValue * returnValue) /
                      100
                    ).toFixed(0)}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalSeparator={"."}
                    renderText={(value) => (
                      <Typography
                        fontSize="3rem"
                        color="#000000"
                        fontWeight={800}
                        fontFamily="Montserrat"
                      >
                        KSH {value}
                      </Typography>
                    )}
                  />
                ) : (
                  <Typography
                    fontSize="2rem"
                    color="#000000"
                    fontWeight={400}
                    fontFamily="Montserrat"
                  >
                    {t("noService")}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};
