let accordionValues = [
  {
    value1: 3000001,
    value2: 5000000,
    value3: "$3.000.001,00",
    value4: "$5.000.000,00",
    value5: "4,00%",
    value6: "4,50%",
    value7: "5,00%",
  },
  {
    value1: 2000001,
    value2: 3000000,
    value3: "$2.000.001,00",
    value4: "$3.000.000,00",
    value5: "3,50%",
    value6: "4,00%",
    value7: "4,50%",
  },
  {
    value1: 1000001,
    value2: 2000000,
    value3: "$1.000.001,00",
    value4: "$2.000.000,00",
    value5: "3,00%",
    value6: "3,50%",
    value7: "4,00%",
  },
  {
    value1: 500001,
    value2: 1000000,
    value3: "$500.001,00",
    value4: "$1.000.000,00",
    value5: "2,50%",
    value6: "3,00%",
    value7: "3,50%",
  },
  {
    value1: 200001,
    value2: 500000,
    value3: "$200.001,00",
    value4: "$500.000,00",
    value5: "2,00%",
    value6: "2,50%",
    value7: "3,00%",
  },
  {
    value1: 50001,
    value2: 200000,
    value3: "$50.001,00",
    value4: "$200.000,00",
    value5: "1,50%",
    value6: "2,00%",
    value7: "2,50%",
  },
  {
    value1: 10001,
    value2: 50000,
    value3: "$10.001,00",
    value4: "$50.000,00",
    value5: "1,25%",
    value6: "1,50%",
    value7: "2,00%",
  },
  {
    value1: 1000,
    value2: 10000,
    value3: "$1.000",
    value4: "$10.000,00",
    value5: "1,00%",
    value6: "1,25%",
    value7: "1,50%",
  },
];

accordionValues = accordionValues.reverse()

export default accordionValues;
