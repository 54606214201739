import React from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function KahshLabsOurTeam() {
  const { t } = useTranslation();

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      pb={4}
      textAlign="center"
    >
      <Typography
        fontSize="45px"
        fontWeight={{ xs: 700, md: 400 }}
        sx={{
          background:
            "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(90.26deg, #8889F1 -85.54%, #1DC9FF -11.67%, #867BFF 62.2%, #D4B2FF 136.06%)",
          backgroundClip: "text",
          WebkitTextFillColor: "transparent",
        }}
      >
        {t("our")} <b>{t("team")}</b>
      </Typography>

      <Typography fontSize="28px" fontWeight={400} color="#333333" py={5}>
        {t("alignedNewRealityITMarket")}
        <br /> {t("alsoWithOurTeam")}
        <br /> {t("teamUnitedStates")}
      </Typography>

      <Typography fontSize="18px" fontWeight={400} color="#333333">
        <b>{t("registrationData")}</b> {t("registeredCnpj")}
        <br /> {t("registeredAddress")}
      </Typography>
    </Grid>
  );
}
