import React from "react";
import { Container, Grid, Fab, Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import iconWhatsApp from "../../imagens/icon-whats-app.png";

import { Navigation } from "../../components/navigation/Navigation";
import IntroductionKahshLabs from "../../components/kahshLabsScreens/introductionKahshLabs/IntroductionKahshLabs";
import KahshLabsServices from "../../components/kahshLabsScreens/kahshLabsServices/KahshLabsServices";
import KahshLabsProjects from "../../components/kahshLabsScreens/kahshLabsProjects/KahshLabsProjects";
import KahshLabsOurTeam from "../../components/kahshLabsScreens/kahshLabsOurTeam/KahshLabsOurTeam";
import { Footer } from "../../components/footer/Footer2";

export default function KahshLabs() {
  const { t } = useTranslation();

  const handleSendMessage = () => {
    window.open(
      `https://wa.me/+5549988835976?text="${t("messageWhatsAppKahshLabs")}"`
    );
  };

  return (
    <Grid container justifyContent="center" position="relative">
      <Navigation />
      <Container maxWidth="xl" disableGutters>
        <IntroductionKahshLabs />
        <KahshLabsServices />
        <KahshLabsProjects />
        <KahshLabsOurTeam />
      </Container>
      <Box
        display="flex"
        position="fixed"
        zIndex={99999999}
        sx={{
          bottom: { xs: 45, md: 35, lg: 80 },
          right: { xs: 18, md: 15 },
        }}
      >
        <Fab onClick={handleSendMessage}>
          <Grid
            container
            height={60}
            sx={{
              backgroundImage: `url(${iconWhatsApp})`,
              backgroundSize: "56px 56px",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          />
        </Fab>
      </Box>
      <Footer />
    </Grid>
  );
}
