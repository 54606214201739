import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import HomeUniverseImg from "../../../imagens/home-universe.png";

import useSharedLink from "../../../hooks/useSharedLink";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { accountsCallBack } from "../../../services/accountsCallBack";
import { Button } from "../../Button/Button";
import "./HomeUniverse.styles.css";

export const HomeUniverse = () => {
  useSharedLink();
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  return (
    <Grid container item pb={10} justifyContent="center">
      <Grid container item sm={12} lg={10} justifyContent="space-between">
        <Grid lg={4} container item direction="column">
          <Grid
            className="universe-container"
            container
            item
            direction="column"
            alignItems="center"
          >
            <Typography
              className="universe-text"
              fontSize={{ xs: "3.75vw", md: "64px" }}
              fontWeight={700}
              lineHeight="7rem"
              color={"#232340"}
              pt={{ xs: 0, md: "6rem" }}
              textAlign={width < 900 ? "center" : "left"}
            >
              {t("universe")}
            </Typography>
            <Typography
              pl={2}
              fontSize={width > 900 ? 16 : 18}
              fontWeight={400}
              color={"##606060"}
              lineHeight={"32px"}
              mt={2}
              ml={width > 900 ? "-1rem" : "none"}
            >
              {t("universeBody")}
            </Typography>
          </Grid>
          {width < 1200 ? null : (
            <Grid className="button-container" container my={5}>
              <Button
                size="large"
                sx={{ padding: "4rem" }}
                onClick={() => accountsCallBack("register")}
              >
                <Typography fontSize={18} fontWeight={700}>
                  {t("accessNow")}
                </Typography>
              </Button>
            </Grid>
          )}
        </Grid>
        <Grid
          xs={12}
          lg={8}
          container
          justifyContent="center"
          mt={width > 900 ? "none" : "none"}
          mr={width > 900 ? "-10rem" : "none"}
        >
          <img alt="" src={HomeUniverseImg} width={"100%"} />
        </Grid>
        {width > 1200 ? null : (
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            mt={2}
            px={1}
          >
            <Button
              size="medium"
              sx={{ maxWidth: "300px" }}
              onClick={() => accountsCallBack("register")}
            >
              <Typography fontSize={18} fontWeight={700}>
                {t("accessNow")}
              </Typography>
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
