import { Grid, Typography } from "@mui/material";
import introductionImage from "../../../imagens/IntroductionImage.png";
import { Button } from "./../../Button/Button";
import { useTranslation } from "react-i18next";

export const IntroductionCard = () => {
  const { t } = useTranslation();
  return (
    <Grid container>
      <Grid
        item
        display="flex"
        md={6}
        xs={12}
        mt={{ md: 6 }}
        ml={{ md: 5 }}
        mx={{ xs: 1 }}
        flexDirection={{ xs: "column" }}
        alignItems={{ xs: "center", md: "flex-start" }}
      >
        <Typography
          fontWeight={700}
          textAlign={{ xs: "center", md: "left" }}
          fontSize={{ xs: "30px", md: "40px", lg: "50px", xl: "54px" }}
          color={"#1C1439"}
          letterSpacing={"-0.02em"}
        >
          <b>Kahsh Card Visa</b>
        </Typography>
        <Typography
          fontFamily={300}
          textAlign={{ xs: "center", md: "left" }}
          fontSize={{ xs: "20px", md: 32 }}
          color={"#1C1439"}
          letterSpacing={"-0.02em"}
        >
          {t("firstCryptoCard")}
        </Typography>
        <Typography
          mt={4}
          mb={5}
          fontWeight={300}
          textAlign={{ xs: "center", md: "left" }}
          fontSize={{ xs: "16px", md: 24 }}
          color={"#1C1439"}
          letterSpacing={"-0.02em"}
        >
          {t("introText1")} <b>Visa</b> {t("and")} <b>Kahsh Exchange</b>
          {t("introText2")}
        </Typography>
        <Button
          onClick={() => (window.location.href = "/cardvisa/request")}
          disableRipple
          className="buttonAsk"
          sx={{
            width: { xs: "90%", md: "100%" },
            maxWidth: 500,
            height: { xs: "65px", md: "80px" },
          }}
        >
          <Typography fontWeight={700} fontSize={"18px"}>
            {t("waitingList")}
          </Typography>
        </Button>
      </Grid>
      <Grid
        item
        minHeight={{ xs: 350, sm: 500, md: 600, lg: 650, xl: 780 }}
        md={6}
        xs={12}
        mt={{ md: 0, lg: -6 }}
        ml={{ md: -8 }}
        mr={1}
        sx={{
          backgroundImage: `url(${introductionImage})`,
          backgroundSize: "100%",
          backgroundRepeat: "no-repeat",
        }}
      />
    </Grid>
  );
};
