import { FieldValues, useForm } from 'react-hook-form';
import { isValidPhoneNumber } from 'react-phone-number-input';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { RegisterStepBase } from '../../components/kahshCard/registerStepsBase/RegisterStepBase';
import CardRegisterPersonal from '../../imagens/cardRegisterPersonal.png';
import { TextField } from '../../components/inputs/text-field/text-field.component';
import { PhoneInput } from '../../components/inputs/phoneInput/PhoneInput';
import { DatePicker } from '../../components/inputs/date-picker/datePicker';
import { TextFieldMask } from '../../components/inputs/text-field-mask/text-field-mask.component';
import { cpfValidator } from '../../utils/cpfValidator';
import { Button } from '../../components/Button/Button';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { format } from 'date-fns';

const validator = yup.object().shape({
  nome_razao_social: yup
    .string()
    .required('nameRequired')
    .matches(/^[a-zA-Z\s]/, 'invalidName')
    .matches(/^[a-zA-Z\s]+\s+[a-zA-Z]/, 'fullNameInvalid'),
  telefone: yup
    .string()
    .required('phoneRequired')
    .test(`test-phone`, function (value) {
      const { path, createError } = this;
      return (
        isValidPhoneNumber(`${value}`) ||
        createError({ path, message: 'phoneInvalid' })
      );
    }),
  cpf_cnpj: yup
    .string()
    .required('cpfRequired')
    .transform(document => document.replace(/\D+/g, ''))
    .test(`test-valid-document`, function (value) {
      const { path, createError } = this;
      return (
        cpfValidator(value) || createError({ path, message: 'cpfInvalid' })
      );
    }),
  data_nascimento: yup
    .date()
    .typeError('invalidDate')
    .required('birthDateRequired')
    .max(new Date(), 'birthDateFuture')
    .nullable()
});

interface KahshCardRegisterStepsProps {
  onSubmit: (stepData: FieldValues) => void;
  onBack: () => void;
  defaultValues: FieldValues | null;
}

export const PersonalData: React.FC<KahshCardRegisterStepsProps> = ({
  onSubmit,
  onBack,
  defaultValues
}) => {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validator)
  });

  const handleNextStep = (data: FieldValues) => {
    data.data_nascimento = format(new Date(data.data_nascimento), 'dd/MM/yyyy');
    onSubmit(data);
  };

  return (
    <RegisterStepBase
      title="Kahsh Card Visa"
      subtitle="registerNow"
      image={CardRegisterPersonal}
      content={
        <>
          <TextField
            label={'name'}
            control={control}
            helperText={errors?.nome_razao_social?.message}
            name="nome_razao_social"
            defaultValue={defaultValues?.nome_razao_social || ''}
            required
            size="small"
          />
          <PhoneInput
            label="phone"
            control={control}
            helperText={errors?.telefone?.message}
            name="telefone"
            defaultValue={defaultValues?.telefone || ''}
            required
            size="small"
          />
          <TextFieldMask
            mask="999.999.999-99"
            label="CPF"
            control={control}
            helperText={errors?.cpf_cnpj?.message}
            defaultValue={defaultValues?.cpf_cnpj || ''}
            name="cpf_cnpj"
            required
            size="small"
          />
          <DatePicker
            label={'birthdate'}
            control={control}
            error={!!errors?.birthdate}
            helperText={errors?.data_nascimento?.message}
            defaultValue={
              defaultValues?.data_nascimento
                ? new Date(defaultValues?.data_nascimento)
                : null
            }
            name="data_nascimento"
            maxDate={new Date()}
            required
            size="small"
          />

          <Box
            display="flex"
            flexDirection={{ md: 'row', xs: 'column-reverse' }}
            justifyContent="flex-end"
            alignItems="center"
            gap={2}
          >
            <Button fullWidth onClick={onBack} variant="outlined">
              {t('cancel')}
            </Button>
            <Button fullWidth onClick={handleSubmit(handleNextStep)}>
              {t('next')}
            </Button>
          </Box>
        </>
      }
    />
  );
};
