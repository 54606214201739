import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import HomeUniverseImg from "../../../imagens/home-universe.png";
import { useLocation } from 'react-router';
import { useMemo } from 'react';

import "./CaptureIntrodution.styles.css";
import useSharedLink from "../../../hooks/useSharedLink";
import { SaibaMaisButton } from "../../buttons/SaibaMaisButton";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

export const CaptureIntrodution = () => {
  useSharedLink();
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  const { search  } = useLocation();
  const urlParams = useMemo(() => new URLSearchParams(search), [search]);

  return (
    <Grid container item pb={10} justifyContent="center" xs={12}>
      <Grid container item sm={12} lg={10} justifyContent="space-between">
        <Grid xs={12} lg={4} container item direction="column">

          <Grid
            className="captureContainer"
            container
            item
            direction="column"
          >
            <Typography
              className="captureText"
              fontSize={"3.7vw"}
              fontWeight={700}
              color={"#232340"}
              lineHeight={"1.2em"}
            >
              {t("capture")}
            </Typography>
            <Typography
              fontSize={18}
              fontWeight={400}
              color={"##606060"}
              lineHeight={"1.2em"}
              mt={2}
              dangerouslySetInnerHTML={{
                __html: t("captureBonus")
              }}
            />
            <Typography
              fontSize={18}
              fontWeight={400}
              color={"##606060"}
              lineHeight={"1.2em"}
              textAlign={"left"}
              mt={2}
              dangerouslySetInnerHTML={{
                __html: t("captureLaunchPrizes")
              }}
            />
          </Grid>
          {width < 1200 ? null : (
            <Grid className="buttonContainerCapture" container my={5}>
              <SaibaMaisButton
                width="90%"
                onClick={() => (window.location.href = "/register?key=" + urlParams.get('key'))}
              >
                <Typography fontSize={18} fontWeight={700}>
                  {t("captureLetsGo")}
                </Typography>
              </SaibaMaisButton>
            </Grid>
          )}
        </Grid>
        <Grid
          xs={12}
          lg={7}
          container
          justifyContent="center"
          mt={2}
        >
          <img src={HomeUniverseImg} width='100%' alt="" />
        </Grid>
        {width > 1200 ? null : (
          <Grid container justifyContent="center" mt={2}>
            <SaibaMaisButton width={"85%"} onClick={() => (window.location.href = "/register?key=" + urlParams.get('key'))}>
              <Typography fontSize={18} fontWeight={700}>
                {t("captureLetsGo")}
              </Typography>
            </SaibaMaisButton>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
