/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
import { Button } from "../../components/Button/Button";
import { RegisterStepBase } from "../../components/kahshCard/registerStepsBase/RegisterStepBase";
import CardRegisterDeposit from "../../imagens/cardRegisterDeposit.png";
import kahshLogoQr from "../../imagens/kahshLogoQr.png";
import logoKKahsh from "../../imagens/logoKKahsh.svg";
import { QRCodeSVG } from "qrcode.react";
import { Box, CircularProgress } from "@mui/material";
import { ContentCopy } from "@mui/icons-material";
import { useAlert } from "../../hooks/useAlert";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { urlKahshCardExchange } from "../../services/global";

export const Deposit: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [qrCode, setQrCode] = useState("");
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const { AlertMessage } = useAlert();

  const txid = useMemo(() => {
    return localStorage.getItem("cardVisaTxId");
  }, []);

  const checkPayment = async () => {
    try {
      const { data } = await axios.get(`${urlKahshCardExchange}?txid=${txid}`);
      if (data?.pix?.qrcode) {
        setQrCode(data?.pix?.qrcode);
      }
      if (data?.success) {
        localStorage.removeItem("cardVisaTxId");
        AlertMessage("success", "depositReceived");
        setTimeout(() => {
          window.location.href = "/cardvisa";
        }, 5000);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      AlertMessage("error", "expiredQrCode", err);
      localStorage.removeItem("cardVisaTxId");
      setTimeout(() => {
        window.location.href = "/cardvisa";
      }, 3000);
    }
  };

  useEffect(() => {
    checkPayment();
    const checkPaymentInterval = setInterval(() => {
      checkPayment();
    }, 15000);
    return () => clearInterval(checkPaymentInterval);
  }, []);

  return (
    <RegisterStepBase
      title="Kahsh Card Visa"
      subtitle="firstCard"
      description="depositNow"
      image={CardRegisterDeposit}
      content={
        <>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            gap={2}
            mx={4}
            border="1px solid #1A263E"
            borderRadius={5}
            py={2}
            px={8}
          >
            <img src={logoKKahsh} alt="Kahsh" />
            <Box border="1px solid #1A263E" borderRadius={5} p={2}>
              {loading ? (
                <Box
                  width={{ md: 256, xs: 200 }}
                  height={{ md: 256, xs: 200 }}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <CircularProgress sx={{ display: "flex", height: 36 }} />
                </Box>
              ) : (
                <QRCodeSVG
                  value={qrCode}
                  size={width < 900 ? 200 : 256}
                  bgColor={"#fff"}
                  fgColor={"#1A263E"}
                  level="H"
                  includeMargin={false}
                  imageSettings={{
                    src: kahshLogoQr,
                    height: 56,
                    width: 56,
                    x: undefined,
                    y: undefined,
                    excavate: true,
                  }}
                />
              )}
            </Box>
            <Button
              variant="outlined"
              size="medium"
              onClick={() => {
                navigator.clipboard.writeText(qrCode);
                AlertMessage("success", "successfullyCopied");
              }}
              endIcon={<ContentCopy />}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                height: 40,
                borderRadius: "15px",
              }}
            >
              {t("copyLink")}
            </Button>
          </Box>
          <Button onClick={() => (window.location.href = "/cardvisa")}>
            {t("finish")}
          </Button>
        </>
      }
    />
  );
};
