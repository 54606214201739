import Web3 from 'web3';
import { abi as abiProduction } from './abiProduction';
import { abi as abiStakingProduction } from './abiStakingProduction';
import { abi as abiTest } from './abiTest';
import { abi as abiStakingTest } from './abiStakingTest';

//Avaliar se deve ser excluido o ResetPassword do portal
export const baseURL = 'https://api.kahshpay.com';
export const resetPasswordLink = baseURL + '/portal/sendlink';
export const resetPassword = baseURL + '/portal/resetpassword';

//Production

export const abi = abiProduction;
export const abiStaking = abiStakingProduction;
export const BASE_URL_KAHSHPAY = 'https://api.kahshpay.com';

export const firebaseConfig = {
  apiKey: 'AIzaSyBexLxaS-mEOffbR1W3z0Qt3NmIRqxn25U',
  authDomain: 'kahshpay.firebaseapp.com',
  databaseURL: 'https://kahshpay-default-rtdb.firebaseio.com',
  projectId: 'kahshpay',
  storageBucket: 'kahshpay.appspot.com',
  messagingSenderId: '439436220162',
  appId: '1:439436220162:web:66a6781ba2d55be5bd941b',
  measurementId: 'G-N9BHXELD2D'
};

export const coinContract = Web3.utils.toChecksumAddress(
  '0x3944ecC8902828d6b930f3841D5c0eb206CBBd28'
);
export const stakingContract = Web3.utils.toChecksumAddress(
  '0x59F28EF2d0A71EEB11ABA959C88243f760D69dc5'
);
export const urlKahshCardExchange =
  'https://exchange.kahsh.com/card/registration';
export const urlSignContract = 'https://pay-api.kahsh.io/v1/staking/open';
export const urlBalance = 'https://pay-api.kahsh.io/v1/drip/balance/';
export const urlUserBalance =
  'https://pay-api.kahsh.io/v1/staking/user/balances/';
export const urlBnbTxid = 'https://bscscan.com/tx/';
export const urlAuthExchange = 'https://exchange.kahsh.com/api/auth';
export const urlLoginExchange = 'https://exchange.kahsh.com/api/login';
export const urlRegistroExchange = 'https://exchange.kahsh.com/api/registro';
export const urlPix = 'https://exchange.kahsh.com/api/pixWS';
export const urlPixReturn = 'https://exchange.kahsh.com/api/pixWSVerifique';
export const URL_ACCOUNTS = 'https://accounts.kahsh.com';
export const BASE_API_URL_ACCOUNTS = 'https://api-accounts.kahsh.com';
export const ACCOUNTS_API_KEY = 'hn22nrs1q9ri8ubo';

export const urlCountries = BASE_URL_KAHSHPAY + '/countries';
export const urlRegions = BASE_URL_KAHSHPAY + '/regions';
export const urlCities = BASE_URL_KAHSHPAY + '/cities';
export const urlDistrict = BASE_URL_KAHSHPAY + '/districts';

// End Production

// Test

// export const abi = abiTest;
// export const abiStaking = abiStakingTest;
// export const BASE_URL_KAHSHPAY = 'https://api-sandbox.kahshpay.com';

// export const firebaseConfig = {
//   apiKey: 'AIzaSyBRjjldY8PUOPfiWlAI4o_DTxoLVeOOTwk',
//   authDomain: 'kahshpaydev.firebaseapp.com',
//   projectId: 'kahshpaydev',
//   storageBucket: 'kahshpaydev.appspot.com',
//   messagingSenderId: '1086784258910',
//   appId: '1:1086784258910:web:b74f395ea991f2336345cf'
// };

// export const coinContract = Web3.utils.toChecksumAddress(
//   '0xc7Fc2B3aabC48aB98ee49BDa6F0BF51d23fDc912'
// );

// export const stakingContract = Web3.utils.toChecksumAddress(
//   '0xd1bcf04AbE58AC054f78b790290563fd4ACFb423'
// );

// export const urlKahshCardExchange =
//   'https://kahshbox.kahsh.com/card/registration';
// export const urlAuthExchange = 'https://rxbrasil.dev.br/api/auth';
// export const urlLoginExchange = 'https://rxbrasil.dev.br/api/login';
// export const urlRegistroExchange = 'https://rxbrasil.dev.br/api/registro';
// export const urlPix = 'https://rxbrasil.dev.br/api/pixWS';
// export const urlPixReturn = 'https://rxbrasil.dev.br/api/pixWSVerifique';
// export const urlAuthExchangeTestandoDev = 'https://rxbrasil.dev.br/api/auth';
// export const urlLoginExchangeTestandoDev = 'https://rxbrasil.dev.br/api/login';
// export const urlRegistroExchangeTestandoDev =
//   'https://rxbrasil.dev.br/api/registro';
// export const urlPixTestandoDev = 'https://rxbrasil.dev.br/api/pixWS';
// export const urlPixReturnTestandoDev =
//   'https://rxbrasil.dev.br/api/pixWSVerifique';
// export const urlSignContract = 'https://pay-api-test.kahsh.io/v1/staking/open';
// export const urlBalance = 'https://pay-api-test.kahsh.io/v1/drip/balance/';
// export const urlUserBalance =
//   'https://pay-api-test.kahsh.io/v1/staking/user/balances/';
// export const urlBnbTxid = 'https://testnet.bscscan.com/tx/';
// export const URL_ACCOUNTS = 'https://accounts-sandbox.kahsh.com';
// export const BASE_API_URL_ACCOUNTS = 'https://api-accounts-sandbox.kahsh.com';
// export const ACCOUNTS_API_KEY = '2b7cievfoi8zw7v9';

// export const urlCountries = BASE_URL_KAHSHPAY + '/countries';
// export const urlRegions = BASE_URL_KAHSHPAY + '/regions';
// export const urlCities = BASE_URL_KAHSHPAY + '/cities';
// export const urlDistrict = BASE_URL_KAHSHPAY + '/districts';

//End Test
