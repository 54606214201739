import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import bg_faca_parte from "../../../imagens/bg_faca_parte.png";
import bg_faca_parte_mobile from "../../../imagens/bg_faca_parte_mobile.png";

import useSharedLink from "../../../hooks/useSharedLink";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { SaibaMaisButton } from "../../buttons/SaibaMaisButton";
import "./HomeMakeUniverse.styles.css";
import { Button } from "../../Button/Button";
import { accountsCallBack } from "../../../services/accountsCallBack";

export const HomeMakeUniverse = () => {
  useSharedLink();
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  return (
    <Grid container item py={10} justifyContent="center">
      <Grid lg={4} container item direction="column">
        <Grid
          className="universe-make-container"
          container
          item
          direction="column"
          alignItems="center"
        >
          <Grid
            position="absolute"
            zIndex={2.0}
            justifyContent="center"
            ml={{ xs: "18%", lg: 64 }}
            width={{ xs: 260, md: 360, lg: 570 }}
            mr={width > 820 ? -30 : 8}
            mt={{ xs: "13vw", lg: 30 }}
          >
            <Typography
              className="universe-make-text"
              fontSize={{ xs: "3.5vw", sm: "64px" }}
              fontWeight={700}
              color={"#232340"}
              lineHeight="1.2em"
              dangerouslySetInnerHTML={{
                __html: t("titleMakePart"),
              }}
            />
            <Typography
              fontSize={18}
              fontWeight={400}
              color={"##606060"}
              lineHeight={"1.5em"}
              mt={2}
            >
              {t("subTitleMakePart")}
            </Typography>
            <Grid
              container
              item
              xs={12}
              sm={8}
              mt={{ xs: "20vw", sm: "10vw", md: "3vw" }}
            >
              <Button
                size="medium"
                sx={{ padding: { xs: "1rem", md: "3.5rem" } }}
                onClick={() => accountsCallBack("register")}
              >
                <Typography fontSize={18} fontWeight={700}>
                  {t("buttonMakePart")}
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        sx={{ display: width < 820 ? "none" : "" }}
        mx={5}
        width={"100%"}
        className="bg-faca-parte"
        container
        justifyContent="center"
      >
        <img src={bg_faca_parte} width={"100%"} alt="" />
      </Grid>
      <Grid
        sx={{ display: width > 820 ? "none" : "" }}
        mx={5}
        container
        className="bg-faca-parte"
        justifyContent="center"
      >
        <img src={bg_faca_parte_mobile} height={"1000px"} alt="" />
      </Grid>
    </Grid>
  );
};
