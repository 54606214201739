import { Grid, Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import bg_faca_parte from "../../../imagens/bg_faca_parte.png";
import bg_faca_parte_mobile from "../../../imagens/bg_faca_parte_mobile.png";

import "./CaptureDigitalWallet.styles.css";
import useSharedLink from "../../../hooks/useSharedLink";
import { SaibaMaisButton } from "../../buttons/SaibaMaisButton";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

export const CaptureDigitalWallet = () => {
  useSharedLink();
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  return (
    <Grid container item pt={10} pb={10} justifyContent="center" xs={12}>
      <Grid xs={12} lg={4} container item direction="column">
        <Grid
          className="captureDigitalWalletContainer"
          container
          item
          direction="column"
          alignItems="center"
        >
          <Grid
            position="absolute"
            zIndex={2.0}
            justifyContent="center"
            mx="15%"
            width={width > 960 ? 490 : 280}
            mr={width > 960 ? -30 : 8}
            mt={10}
          >
            <Typography
              className="captureDigitalWalletText"
              fontSize={"3.8vw"}
              fontWeight={700}
              color={"#232340"}
              lineHeight={"1.2em"}
            >
              {t("captureDigitalWalletTitle")}
            </Typography>
            <Typography
              fontSize={18}
              fontWeight={400}
              color={"##606060"}
              lineHeight={"1.2em"}
              mt={2}
            >
              {t("captureDigitalWalletSubTitle")}
            </Typography>
            <Grid className="buttonCaptureDigitalWalletContainer" container mt={5}>
              <SaibaMaisButton 
                onClick={() => (window.location.href = "/register")}
              >
                <Typography fontSize={18} fontWeight={700}>
                  {t("buttonCaptureClickHere")}
                </Typography>
              </SaibaMaisButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        sx={{ display: width < 820 ? "none" : "" }}
        mx={5}
        width={"100%"}
        className="bgCaptureDigitalWallet"
        container
        justifyContent="center"
      >
        <img src={bg_faca_parte} width={"100%"} alt="" />
      </Grid>
      <Grid
        sx={{ display: width > 820 ? "none" : "" }}
        mx={2}
        container
        className="bgCaptureDigitalWallet"
        justifyContent="center"
      >
        <img src={bg_faca_parte_mobile} width={"100%"} alt="" />
      </Grid>
    </Grid>
  );
};
