import { Grid, Typography } from "@mui/material";

import PackageJson from "../../../package.json";
import Facebook from "../../imagens/Facebook.png";
import Instagram from "../../imagens/Instagram.png";
import Twitter from "../../imagens/twitter.png";

const socialMedia = [
  {
    id: "Facebook",
    img: Facebook,
    href: "https://www.facebook.com/kahshcom/",
  },
  {
    id: "Twitter",
    img: Twitter,
    href: "https://twitter.com/Kahshcom",
  },
  {
    id: "Instagram",
    img: Instagram,
    href: "https://www.instagram.com/kahshcom/",
  },
];

//@ts-ignore
export const FooterMini = (props) => {
  return (
    <Grid container justifyContent="center" py={2} mt="auto" {...props}>
      <Grid
        container
        item
        xs={11}
        justifyContent="space-between"
        mt={{ xs: 5, md: 0 }}
      >
        <Typography
          color="#58595A"
          textAlign={{ xs: "center", md: "start" }}
          mb={{ xs: 5, sm: 0 }}
        >
          © Copyright © 2021 Kahsh. Todos os direitos reservados -{" "}
          {PackageJson.version}
        </Typography>
        <Grid
          display="flex"
          flex={1}
          alignItems="center"
          justifyContent={{ xs: "center", sm: "flex-end" }}
          pb={{ xs: 10, sm: 0 }}
        >
          {socialMedia.map((media, index) => (
            <a
              key={media.id}
              href={media.href}
              target="_blank"
              rel="noreferrer"
              style={{ marginRight: index === 2 ? 0 : 25 }}
            >
              <img src={media.img} alt={media.id} width={24} />
            </a>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};
