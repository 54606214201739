import { useEffect, useState } from "react";
import { Grid, Typography, Button, Box } from "@mui/material";
import { AddCircle, RemoveCircle } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

let TECH = [
  "listKahshCardApple",
  "listKahshCardBeats",
  "listKahshCardBestBuy",
  "listKahshCardBose",
  "listKahshCardMasterclass",
  "listKahshCardNintendo",
  "listKahshCardPlaystation",
  "listKahshCardSonos",
  "listKahshCardSony",
  "listKahshCardXbox",
];
let FITNESS = [
  "listKahshCardAdidas",
  "listKahshCardCannondale",
  "listKahshCardLululemon",
  "listKahshCardNike",
  "listKahshCardOutdoorVoices",
  "listKahshCardPatagonia",
  "listKahshCardPeloton",
  "listKahshCardRogueFitness",
  "listKahshCardREI",
  "listKahshCardTrek",
];

let RETAIL = [
  "listKahshCardAllbirds",
  "listKahshCardAmazon",
  "listKahshCardAntropologie",
  "listKahshCardAritzia",
  "listKahshCardAsos",
  "listKahshCardCasper",
  "listKahshCardCaixaEBarril",
  "listKahshCardCostco",
  "listKahshCardEtsy",
  "listKahshCardEverlane",
  "listKahshCardBrilhante",
  "listKahshCardHomeDepot",
  "listKahshCardIkea",
  "listKahshCardReforma",
  "listKahshCardSephora",
  "listKahshCardSupremo",
  "listKahshCardUniqlo",
  "listKahshCardWarbyParker",
  "listKahshCardWayfair",
];

let TRAVEL = [
  "listKahshCardAirbnb",
  "listKahshCardAlaskaAirlines",
  "listKahshCardAmericanAirlines",
  "listKahshCardDelta",
  "listKahshCardHoteisCom",
  "listKahshCardHotelTonight",
  "listKahshCardSouthwestAirlines",
  "listKahshCardUnitedAirlines",
  "listKahshCardJetBlue",
  "listKahshCardUber",
  "listKahshCardVrbo",
  "listKahshCardZipcar",
];

let TECH_COPY = [...TECH];
let FITNESS_COPY = [...FITNESS];
let RETAIL_COPY = [...RETAIL];
let TRAVEL_COPY = [...TRAVEL];

TECH = TECH.slice(0, 7);
FITNESS = FITNESS.slice(0, 7);
RETAIL = RETAIL.slice(0, 7);
TRAVEL = TRAVEL.slice(0, 7);

export const ListKahshCard = () => {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();

  return (
    <Grid pl={{ xs: 0, md: 5 }}>
      <Grid
        display={{ xs: "block", md: "flex" }}
        alignItems={{ xs: "center", md: "unset" }}
        py={{xs: 0, md: 2}}
        sx={{
          background: show
            ? "transparent"
            : `linear-gradient(to bottom, #000000 55%, rgba(250,250,250,0.8)) 100%`,
          backgroundClip: show ? "unset" : "text",
          WebkitTextFillColor: show ? "currentcolor" : "transparent",
        }}
      >
        <Grid container item >
          <Grid
            container
            direction="column"
            alignItems={{ xs: "center", md: "flex-start" }}
            xs={6}
            md={6}
          >
            <Box display="flex" flexDirection="column" textAlign="left">
              <Typography
                fontSize="22px"
                fontWeight={700}
                sx={{
                  color: "#4733FF !important",
                  WebkitTextFillColor: "currentColor",
                }}
                pb={3}
                textAlign="left"
              >
                {t("listKahshCardTitleTECH")}
              </Typography>
              {(show ? TECH_COPY : TECH).map((tech, index) => (
                <Typography
                  key={index}
                  fontSize={{ xs: "14px", md: "20px" }}
                  fontWeight={600}
                  color="#1C1439"
                  pb={1}
                  whiteSpace="nowrap"
                >
                  {t(tech)}
                </Typography>
              ))}
            </Box>
          </Grid>

          <Grid
            container
            direction="column"
            alignItems={{ xs: "center", md: "flex-start" }}
            xs={6}
            md={6}
          >
            <Box display="flex" flexDirection="column" textAlign="left">
              <Typography
                fontSize="22px"
                fontWeight={700}
                sx={{
                  color: "#4733FF !important",
                  WebkitTextFillColor: "currentColor",
                }}
                pb={3}
              >
                {t("listKahshCardTitleFITNESS")}
              </Typography>
              {(show ? FITNESS_COPY : FITNESS).map((fitness, index) => (
                <Typography
                  key={index}
                  fontSize={{ xs: "14px", md: "20px" }}
                  fontWeight={600}
                  color="#1C1439"
                  pb={1}
                  whiteSpace="nowrap"
                >
                  {t(fitness)}
                </Typography>
              ))}
            </Box>
          </Grid>
        </Grid>

        <Grid container item>
          <Grid
            container
            direction="column"
            alignItems={{ xs: "center", md: "flex-start" }}
            xs={6}
            md={6}
          >
            <Box display="flex" flexDirection="column" textAlign="left">
              <Typography
                fontSize="22px"
                fontWeight={700}
                sx={{
                  color: "#4733FF !important",
                  WebkitTextFillColor: "currentColor",
                }}
                pb={3}
                pt={{ xs: 3, md: 0 }}
              >
                {t("listKahshCardTitleRETAIL")}
              </Typography>
              {(show ? RETAIL_COPY : RETAIL).map((retail, index) => (
                <Typography
                  key={index}
                  fontSize={{ xs: "14px", md: "20px" }}
                  fontWeight={600}
                  color="#1C1439"
                  pb={1}
                  whiteSpace="nowrap"
                >
                  {t(retail)}
                </Typography>
              ))}
            </Box>
          </Grid>
          <Grid
            container
            direction="column"
            alignItems={{ xs: "center", md: "flex-start" }}
            xs={6}
            md={6}
          >
            <Box display="flex" flexDirection="column" textAlign="left">
              <Typography
                fontSize="22px"
                fontWeight={700}
                sx={{
                  color: "#4733FF !important",
                  WebkitTextFillColor: "currentColor",
                }}
                pb={3}
                pt={{ xs: 3, md: 0 }}
              >
                {t("listKahshCardTitleTRAVEL")}
              </Typography>
              {(show ? TRAVEL_COPY : TRAVEL).map((travel, index) => (
                <Typography
                  key={index}
                  fontSize={{ xs: "14px", md: "20px" }}
                  fontWeight={600}
                  color="#1C1439"
                  pb={1}
                  whiteSpace="nowrap"
                >
                  {t(travel)}
                </Typography>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Grid container alignItems="center" justifyContent="center">
        <Button
          variant="text"
          startIcon={
            !show ? (
              <AddCircle sx={{ fontSize: "25px", color: "#4733FF" }} />
            ) : (
              <RemoveCircle sx={{ fontSize: "25px", color: "#4733FF" }} />
            )
          }
          onClick={() => setShow(!show)}
          sx={{
            fontSize: "20px",
            fontWeight: "600",
            color: "#4733FF",
          }}
        >
          {!show
            ? t("listKahshCardButtonPlus")
            : t("listKahshCardButtonRemove")}
        </Button>
      </Grid>
    </Grid>
  );
};
