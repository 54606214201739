import { Grid, MenuItem, Select, Typography } from "@mui/material";

import i18n from "i18next";
import { useTranslation } from "react-i18next";

import logoKKahsh from "../../imagens/logoKKahsh.svg";

import { Us, Br } from "react-flags-select";

import "./LogoAndTranslate.styles.css";

const handleOnclickPT = () => {
  i18n.changeLanguage("pt");
};

const handleOnclickEN = () => {
  i18n.changeLanguage("en");
};

export function LogoAndTranslate() {
  const { i18n } = useTranslation();

  return (
    <>
      <Grid container className="kahshIoNavigationContainerFastbuy">
        {/* ====== BOTÕES DE NAVEGAÇÃO WEB ====== */}
        {/* ====== LOGO WEB ====== */}
        <Grid pb={3} item className="containerNavigationLogoFastbuy">
          <a href="/">
            <img
              // className="logoMobile"
              src={logoKKahsh}
              alt=""
            />
          </a>
        </Grid>

        {/* ====== SELECT COM TRANSLATE WEB ====== */}
        <Grid pb={3} display="flex">
          <Grid className="kahshIoNavigationSelectTranslateFastbuy">
            <Select
              className="selectTranslate"
              labelId="translate"
              id="translate"
              defaultValue={
                i18n.language.substring(0, 2) == "pt" ? "PTB" : "EN"
              }
            >
              <MenuItem
                onClick={handleOnclickPT}
                className="translate"
                value="PTB"
              >
                <Br fontSize="2.5rem" />
                <Typography
                  pl={1}
                  fontSize="1.3rem"
                  fontFamily="Montserrat"
                  fontWeight={700}
                  color="#838383"
                >
                  Português (BR)
                </Typography>
              </MenuItem>

              <MenuItem
                onClick={handleOnclickEN}
                className="translate"
                value="EN"
              >
                <Us fontSize="2.5rem" />
                <Typography
                  pl={1}
                  fontSize="1.3rem"
                  fontFamily="Montserrat"
                  fontWeight={700}
                  color="#838383"
                >
                  English (US)
                </Typography>
              </MenuItem>
            </Select>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
