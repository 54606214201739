import { useEffect, useRef, useState } from "react";

import { FormControl, Grid, MenuItem, Select, Typography } from "@mui/material";

import useWindowDimensions from "../../../hooks/useWindowDimensions";

import { useTranslation } from "react-i18next";

import NumberFormat from "react-number-format";

import axios from "axios";

import BRL from "../../../imagens/Brasil-bandeira.svg";
import ComputadorExchange from "../../../imagens/ComputadorExchange.png";
import ComputadorExchangeMobile from "../../../imagens/ComputadorExchangeMobile.png";
import KahshIcon from "../../../imagens/Kahsh-Icon.svg";

import useSharedLink from "../../../hooks/useSharedLink";

import { Button } from "../../Button/Button";
import "./KahshExchange.styles.css";

export const KahshExchange = () => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  useSharedLink();

  const [crypto, setCrypto] = useState({
    btc: 0,
    eth: 0,
    ksh: 0,
    usdt: 0,
  });

  const [age, setAge] = useState({ coin1: "brl", coin2: "ksh" });
  const [coin1, setCoin1] = useState("");
  const [coin2, setCoin2] = useState("");
  const refInput = useRef("");

  useEffect(() => {
    refInput.current = "";
    fetchData();
    return refInput.current;
  }, []);

  const fetchData = async () => {
    let coins = await axios.get(
      `https://api.coingecko.com/api/v3/coins/markets?vs_currency=brl&per_page=3`
    );

    let ksh = await axios.get(`https://exchange.kahsh.com/cotacao/brl`);
    setCrypto({
      btc: coins.data[0].current_price,
      eth: coins.data[1].current_price,
      ksh: ksh.data[2].cotacao,
      usdt: coins.data[2].current_price,
    });
  };

  const handleChangeCoin1 = (e) => {
    if (e.target.value === "brl") {
      setAge({ coin1: e.target.value, coin2: "ksh" });
      setCoin1("");
      setCoin2("");
      return;
    } else if (e.target.value === age.coin2) {
      setAge({ coin1: e.target.value, coin2: "brl" });
      setCoin1("");
      setCoin2("");
    } else {
      setAge({ coin1: e.target.value, coin2: "brl" });
      setCoin1("");
      setCoin2("");
    }
  };

  const handleChangeCoin2 = (e) => {
    if (e.target.value === "brl") {
      setAge({ coin2: e.target.value, coin1: "ksh" });
      setCoin1("");
      setCoin2("");
      return;
    } else if (e.target.value === age.coin1) {
      setAge({ coin2: e.target.value, coin1: "brl" });
      setCoin1("");
      setCoin2("");
    } else {
      setAge({ coin2: e.target.value, coin1: "brl" });
      setCoin1("");
      setCoin2("");
    }
  };

  const handleValueCoin1Change = (values) => {
    const { formattedValue } = values;

    switch (age.coin1) {
      //moeda selecionada no input 1 = BRL
      case "brl":
        //verifica qual a moeda selecionada no input 2
        switch (age.coin2) {
          case "btc":
            setCoin1(formattedValue);
            setCoin2((parseInt(values.value) / crypto.btc).toFixed(10));
            break;
          case "eth":
            setCoin1(formattedValue);
            setCoin2((parseInt(values.value) / crypto.eth).toFixed(10));
            break;
          case "ksh":
            setCoin1(formattedValue);
            setCoin2((parseInt(values.value) / crypto.ksh).toFixed(10));
            break;
          case "usdt":
            setCoin1(formattedValue);
            setCoin2((parseInt(values.value) / crypto.usdt).toFixed(10));
            break;
          default:
            break;
        } // switch do input 2
        break;

      case "btc":
        setCoin1(values.value);
        setCoin2((formattedValue * crypto.btc).toFixed(2));
        break;
      case "eth":
        setCoin1(values.value);
        setCoin2((formattedValue * crypto.eth).toFixed(2));
        break;
      case "ksh":
        setCoin1(values.value);
        setCoin2((formattedValue * crypto.ksh).toFixed(2));
        break;
      case "usdt":
        setCoin1(values.value);
        setCoin2((formattedValue * crypto.usdt).toFixed(2));
        break;
      default:
        break;
    } // switch do input 1
  };

  const handleValueCoin2Change = (values) => {
    // const { formattedValue } = values;

    switch (age.coin2) {
      case "btc":
        // refInput.current = coin1 ? coin1 * btcValue.current_price : 10;
        break;
      case "eth":
        // setValue({
        //   coin1: formattedValue * ethValue.current_price,
        // });
        break;
      default:
        break;
    }
  };

  return (
    <>
      {width > 900 ? (
        <>
          <Grid
            container
            className="KahshExchangeContainer"
            mt={width <= 1248 ? 3 : -7.8}
          >
            <Grid className="KahshExchangeFirstWrapper">
              <Grid>
                <Typography variant="h2">{t("kahsh")}</Typography>
                <Typography variant="h2">{t("Exchange")}</Typography>
                <Typography pt={2} fontSize={30}>
                  {t("globalTransactions")}
                </Typography>
              </Grid>

              <Grid container className="KahshExchangeBtns">
                <Grid mr={2}>
                  <Button
                    size="medium"
                    sx={{
                      borderRadius: "1.5rem",
                      padding: "0.8rem",
                      width: "200px",
                      textAlign: "center",
                      backgroundColor: "#4733FF",
                      color: "#FFFFFF",
                      fontSize: 16,
                      fontWeight: "600",
                    }}
                    onClick={() => {
                      window.location.href =
                        "https://exchange.kahsh.com/register";
                    }}
                  >
                    {t("registerBtn")}
                  </Button>
                </Grid>

                <Grid>
                  <Button
                    size="medium"
                    sx={{
                      border: "1px solid #4733ff",
                      borderRadius: "1.5rem",
                      padding: "0.8rem",
                      width: "200px",
                      textAlign: "center",
                      backgroundColor: "#FFFFFF",
                      color: "#4733FF",
                      fontSize: 16,
                      fontWeight: "600",
                    }}
                    onClick={() => {
                      window.location.href = "https://exchange.kahsh.com/login";
                    }}
                  >
                    {t("yourAccount")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            {/* ====== IMAGEM + MOEDAS WEB ======  */}
            <Grid>
              <Grid className="kahshExchangeForm">
                <Grid className="KahshExchangeIMG" mt={0}>
                  <img src={ComputadorExchange} alt="Computador Exchange" />
                </Grid>
                <Grid className="kahshExchangeFormWrapper" width="60%">
                  {/* ====== CAMPO TROCAR E COM O PRIMEIRO SELECT WEB ======  */}
                  <Grid className="kahshExchangeMoedas">
                    <Grid className="kahshExchangeContainerInputs">
                      <Typography className="kahshExchangeTrocarPor">
                        {t("exchangeGlobal")}
                      </Typography>
                      <NumberFormat
                        className="KahshExchangeMoedasNumberFormat"
                        decimalSeparator="."
                        placeholder={
                          age.coin1 === "brl" ? "0.00" : "0.00000000"
                        }
                        value={refInput.current ? refInput.current : coin1}
                        thousandSeparator={false}
                        prefix={age.coin1 === "brl" ? "R$ " : ""}
                        onValueChange={(values) =>
                          handleValueCoin1Change(values)
                        }
                      />
                    </Grid>

                    <FormControl className="kahshExchangeMoedasFormControl">
                      <Select
                        className="removeBorder"
                        value={age.coin1}
                        onChange={handleChangeCoin1}
                      >
                        <MenuItem key={"ksh"} value={"ksh"}>
                          <img src={KahshIcon} className="imgLi" alt="" />
                          <b>KSH</b>
                        </MenuItem>
                        <MenuItem key={"brl"} value={"brl"}>
                          <img src={BRL} className="imgLi" alt="" />
                          <b>BRL</b>
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* ====== CAMPO TROCAR COM O SEGUNDO SELECT WEB ======  */}
                  <Grid className="kahshExchangeMoedas">
                    <Grid className="kahshExchangeContainerInputs">
                      <Typography className="kahshExchangeTrocarPor">
                        {t("per")}
                      </Typography>
                      <NumberFormat
                        className="KahshExchangeMoedasNumberFormat"
                        placeholder={
                          age.coin2 === "brl" ? "0.00" : "0.00000000"
                        }
                        value={coin2 ? coin2 : refInput.current.coin2}
                        decimalSeparator="."
                        thousandSeparator={false}
                        prefix={age.coin2 === "brl" ? "R$ " : ""}
                        onValueChange={(values) =>
                          handleValueCoin2Change(values)
                        }
                        disabled
                      />
                    </Grid>
                    <FormControl className="kahshExchangeMoedasFormControl">
                      <Select
                        className="removeBorder"
                        value={age.coin2}
                        onChange={handleChangeCoin2}
                      >
                        <MenuItem key={"ksh"} value={"ksh"}>
                          <img src={KahshIcon} className="imgLi" alt="" />
                          <b>KSH</b>
                        </MenuItem>
                        <MenuItem key={"brl"} value={"brl"}>
                          <img src={BRL} className="imgLi" alt="" />
                          <b>BRL</b>
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Button
                    size="large"
                    sx={{
                      width: "80%",
                      fontSize: 20,
                      fontWeight: "700",
                    }}
                    onClick={() =>
                      (window.location.href =
                        "https://exchange.kahsh.com/login")
                    }
                  >
                    {t("buyHere")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid className="KahshExchangeContainerMobile">
            <Grid className="KahshExchangeFirstWrapperMobile" p={2}>
              <Typography variant="h2">{t("kahsh")}</Typography>
              <br />
              <Typography variant="h2">
                <b>{t("Exchange")}</b>
              </Typography>
              <Typography pt="3rem">{t("globalTransactions")}</Typography>
            </Grid>

            {/* ====== IMAGEM + MOEDAS Mobile ======  */}
            <Grid
              container
              className="kahshExchangeIMGMobile"
              justifyContent="center"
            >
              <img src={ComputadorExchangeMobile} alt="" />
            </Grid>
            <Grid>
              <Grid className="kahshExchangeForm">
                <Grid container justifyContent="space-between">
                  <Grid item xs={6} px={1} pl={2}>
                    <Button
                      size="medium"
                      sx={{
                        borderRadius: "1.5rem",
                        padding: "0.8rem",
                        width: "100%",
                        textAlign: "center",
                        backgroundColor: "#4733FF",
                        color: "#FFFFFF",
                        fontSize: "16px",
                        fontWeight: "600",
                      }}
                      onClick={() => {
                        window.location.href =
                          "https://exchange.kahsh.com/register";
                      }}
                    >
                      {t("registerBtn")}
                    </Button>
                  </Grid>
                  <Grid item xs={6} px={1} pr={2}>
                    <Button
                      size="medium"
                      sx={{
                        border: "1px solid #4733ff",
                        borderRadius: "1.5rem",
                        padding: "0.8rem",
                        width: "100%",
                        textAlign: "center",
                        backgroundColor: "#FFFFFF",
                        color: "#4733FF",
                        fontSize: "16px",
                        fontWeight: "600",
                      }}
                      onClick={() => {
                        window.location.href =
                          "https://exchange.kahsh.com/login";
                      }}
                    >
                      {t("yourAccount")}
                    </Button>
                  </Grid>
                </Grid>

                <Grid container justifyContent="center">
                  <Grid mt="2rem" className="kahshExchangeFormWrapperMobile">
                    {/* ====== CAMPO TROCAR E COM O PRIMEIRO SELECT Mobile ======  */}
                    <Grid mb={3} className="kahshExchangeMoedasMobile">
                      <Grid
                        mr={1}
                        className="kahshExchangeContainerInputsMobile"
                      >
                        <Typography>{t("exchangeGlobal")}</Typography>
                        <NumberFormat
                          className="KahshExchangeMoedasNumberFormat"
                          decimalSeparator="."
                          placeholder={age.coin1 === "brl" ? "0.00" : "0.00"}
                          value={refInput.current ? refInput.current : coin1}
                          thousandSeparator={false}
                          prefix={age.coin1 === "brl" ? "R$" : ""}
                          onValueChange={(values) =>
                            handleValueCoin1Change(values)
                          }
                        />
                      </Grid>
                      <FormControl className="kahshExchangeMoedasFormControlMobile">
                        <Select
                          className="removeBorder"
                          value={age.coin1}
                          onChange={handleChangeCoin1}
                        >
                          <MenuItem key={"ksh"} value={"ksh"}>
                            <img src={KahshIcon} className="imgLi" alt="" />
                            <b>KSH</b>
                          </MenuItem>
                          <MenuItem key={"brl"} value={"brl"}>
                            <img src={BRL} className="imgLi" alt="" />
                            <b>BRL</b>
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    {/* ====== CAMPO TROCAR COM O SEGUNDO SELECT Mobile ======  */}
                    <Grid mb={3} className="kahshExchangeMoedasMobile">
                      <Grid
                        mr={1}
                        className="kahshExchangeContainerInputsMobile"
                      >
                        <Typography>{t("per")}</Typography>
                        <NumberFormat
                          className="KahshExchangeMoedasNumberFormat"
                          decimalSeparator="."
                          placeholder={age.coin1 === "brl" ? "0.00" : "0.00"}
                          value={coin2 ? coin2 : refInput.current.coin2}
                          thousandSeparator={false}
                          prefix={age.coin2 === "brl" ? "R$ " : ""}
                          onValueChange={(values) =>
                            handleValueCoin2Change(values)
                          }
                          disabled
                        />
                      </Grid>
                      <FormControl className="kahshExchangeMoedasFormControlMobile">
                        <Select
                          className="removeBorder"
                          value={age.coin2}
                          onChange={handleChangeCoin2}
                        >
                          <MenuItem key={"ksh"} value={"ksh"}>
                            <img src={KahshIcon} className="imgLi" alt="" />
                            <b>KSH</b>
                          </MenuItem>
                          <MenuItem key={"brl"} value={"brl"}>
                            <img src={BRL} className="imgLi" alt="" />
                            <b>BRL</b>
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Button
                      size="large"
                      sx={{
                        width: "90%",
                        fontSize: "16px",
                        fontWeight: "700",
                      }}
                      onClick={() =>
                        (window.location.href =
                          "https://exchange.kahsh.com/login")
                      }
                    >
                      {t("buyHere")}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};
