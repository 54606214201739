import axios from "axios";

import {
  urlAuthExchange,
  urlAuthExchangeTestandoDev,
  urlLoginExchange,
  urlLoginExchangeTestandoDev,
} from "./global";

export const urlModo = (user, entrada) => {
  if (user) {
    axios
      .post(
        urlAuthExchange,
        // urlAuthExchangeTestandoDev,
        {
          authorizationToken: user.accessToken,
        },
        {
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        var hash = new Date();
        var min =
          hash.getFullYear() * 5 +
          (hash.getMonth() + 1) * 4 +
          hash.getDate() * 3 +
          hash.getHours() * 2 +
          hash.getMinutes() +
          2;
        // var linkC = urlLoginExchangeTestandoDev + "?t=";
        var linkC = urlLoginExchange + "?t=";

        window.open(
          linkC +
            base64_encode(
              "t=" +
                base64_encode(response.data.tokenExchange) +
                "&k=" +
                base64_encode(min) +
                "&m=" +
                base64_encode(entrada)
            ),
          "_blank"
        );
      })
      .catch((error) => {
        return error;
      });
  } else {
    if (entrada == "0") {
      // window.open("https://rxbrasil.dev.br/", '_blank');
      window.open("https://exchange.kahsh.com/", "_blank");
    } else if (entrada == "r") {
      // window.open("https://rxbrasil.dev.br/otc", '_blank');
      window.open("https://exchange.kahsh.com/otc", "_blank");
    } else if (entrada == "p") {
      // window.open("https://rxbrasil.dev.br/market", '_blank');
      window.open("https://exchange.kahsh.com/market", "_blank");
    }
  }
};

var CryptoJS = require("crypto-js");

export const base64_encode = (value) => {
  var wordArray = CryptoJS.enc.Utf8.parse(value);
  var base64 = CryptoJS.enc.Base64.stringify(wordArray);
  return base64;
};
