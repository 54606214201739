import { Stack } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React from "react";
import { useController } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TextFieldStyledProps } from "../text-field/text-field.component";
import { TextFieldStyled } from "../text-field/text-field.styles";

interface DatePickerProps extends TextFieldStyledProps {
  maxDate?: Date;
  minDate?: Date;
  required?: boolean;
}

export const DatePicker: React.FC<DatePickerProps> = ({
  maxDate,
  minDate,
  defaultValue,
  control,
  helperText,
  name,
  label,
  placeholder,
  required,
  size,
  watch,
  value,
  onChange,
  ...rest
}) => {
  const { t } = useTranslation();

  const { field } = useController({
    name: name || "",
    control: control || undefined,
    defaultValue: defaultValue || null
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack sx={{ width: "100%" }}>
        <DesktopDatePicker
          maxDate={maxDate}
          label={label && t(label.toString())}
          minDate={minDate}
          inputFormat={
            t("dateFormat") !== "dateFormat" ? t("dateFormat") : "dd/MM/yyyy"
          }
          value={control ? field.value : value}
          onChange={(event: any) => {
            if (control) {
              watch && watch(event.target.value);
              return field.onChange(event);
            }
            if (onChange) {
              return onChange(event);
            }
          }}
          renderInput={(params) => (
            <TextFieldStyled
              {...rest}
              {...params}
              error={!!helperText}
              helperText={!!helperText && t(helperText.toString())}
              variant={"outlined"}
              name={field.name}
              value={control ? field.value : value}
              size={size}
              fullWidth
            />
          )}
        />
      </Stack>
    </LocalizationProvider>
  );
};
