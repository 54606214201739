import { Container, Grid, Typography } from "@mui/material";

import { useTranslation } from "react-i18next";

import imgkahshpay_1 from "../../../imagens/imgkahshpay_1.png";
import imgkahshpay_2 from "../../../imagens/imgkahshpay_2.png";
import kahshLogoblue from "../../../imagens/kahsh-logo-blue.png";
import KahshPay from "../../../imagens/KahshPay.svg";
import moedas_k from "../../../imagens/moedas_k.png";
import moedas_k_mb from "../../../imagens/moedas_k_mb.png";
import setaBrancaDireita from "../../../imagens/setaBrancaDireita.png";
import setaDireita from "../../../imagens/setaDireita.png";

import useSharedLink from "../../../hooks/useSharedLink";

import useWindowDimensions from "../../../hooks/useWindowDimensions";

import { accountsCallBack } from "../../../services/accountsCallBack";
import { Button } from "../../Button/Button";
import "./KahshPayIntroducao.styles.css";

export const KahshPayIntroducao = () => {
  useSharedLink();
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  return (
    <>
      {width > 900 ? (
        <Grid
          mt={-5}
          sx={{
            background:
              "linear-gradient(180deg, #F5F4FD 0%, rgba(245, 244, 253, 0) 100%)",
          }}
          container
          item
          pb={4}
          justifyContent="center"
          xs={12}
        >
          <Grid container item sm={12} lg={10} justifyContent="space-between">
            <Grid xs={12} md={5.5} container item direction="column">
              <Grid
                className="universe-container"
                container
                item
                direction="column"
                alignItems="left"
              >
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  mb={2}
                  mt={6}
                  justifyContent={width < 1200 ? "center" : "left"}
                >
                  <img src={kahshLogoblue} alt="" />

                  <Typography
                    ml={3}
                    fontSize={20}
                    color="#5468E7"
                    fontWeight="300"
                    fontStyle="italic"
                    dangerouslySetInnerHTML={{
                      __html: t("cryptocurrencyCurrency"),
                    }}
                  />
                </Grid>

                <Grid
                  container
                  justifyContent={width < 1200 ? "center" : "left"}
                  pt={5}
                >
                  <img src={KahshPay} width="90%" alt="" />
                </Grid>

                <Grid
                  display="flex"
                  alignItems="center"
                  justifyContent={width < 1200 ? "center" : "left"}
                >
                  <Typography
                    fontSize={18}
                    fontWeight={400}
                    color={"#606060"}
                    lineHeight={"32px"}
                    mt={2}
                    dangerouslySetInnerHTML={{
                      __html: t("descriptionkahshPay"),
                    }}
                  />
                </Grid>
              </Grid>
              {width < 900 ? null : (
                <Grid className="button-container" container my={5}>
                  <Button
                    size="medium"
                    sx={{
                      width: { md: "45%", lg: "60%" },
                      padding: 4,
                      color: "#FFFFFF",
                      fontWeight: "700",
                      fontSize: { md: 16, lg: 24 },
                    }}
                    onClick={() => accountsCallBack("register")}
                  >
                    {t("accessNow")}
                  </Button>
                </Grid>
              )}
              <Grid
                container
                justifyContent={width < 1200 ? "center" : "left"}
                ml={width < 1200 ? null : "-20rem"}
              >
                <img src={imgkahshpay_1} width="120%" />
              </Grid>
            </Grid>

            {/*========= CONTAINER COM TODOS OS FILHOS INCLUSIVE O GRID AZUL === WEB ===*/}
            <Grid
              mt={4}
              xs={12}
              md={5.5}
              container
              justifyContent="center"
              className="img-container"
              flexDirection="column"
              alignItems="center"
            >
              <Grid
                container
                height={210}
                direction="column"
                justifyContent="center"
                bgcolor="#ffffff"
                pl={8}
                borderRadius={10}
                mb={3}
              >
                <Typography
                  fontSize={32}
                  fontWeight={700}
                  color="#232340"
                  dangerouslySetInnerHTML={{
                    __html: t("transferValues"),
                  }}
                />
                <Typography fontSize={16} color="#606060">
                  {t("freeMediators")}
                </Typography>
                <Grid container mt={1.5}>
                  <img src={setaDireita} width={18} alt="" />
                </Grid>
              </Grid>
              <Grid
                container
                height={210}
                justifyContent="space-between"
                pr={{ md: 2, lg: 1, xl: 3 }}
                pl={{ md: 2, lg: 8 }}
                alignItems="center"
                borderRadius={10}
                mb={3}
                bgcolor="#232340"
              >
                <Grid container direction="column">
                  <Typography
                    fontSize={28}
                    fontWeight={700}
                    color="#ffffff"
                    dangerouslySetInnerHTML={{
                      __html: t("buyWithcurrency"),
                    }}
                  />
                  <Typography fontSize={16} color="#ffffff" mt={1.5} mb={1.5}>
                    {t("freeMediaYourFreDdevaluationtors")}
                  </Typography>
                  <Grid container>
                    <img src={setaBrancaDireita} width={18} alt="" />
                  </Grid>
                </Grid>
                <Grid
                  bgcolor="#fff"
                  maxWidth={150}
                  maxHeight={150}
                  borderRadius={10}
                >
                  <img
                    src={moedas_k}
                    width={width < 999 ? 120 : "100%"}
                    alt=""
                  />
                </Grid>
              </Grid>
              <Grid
                container
                height={210}
                direction="column"
                justifyContent="center"
                bgcolor="#ffffff"
                pl={8}
                borderRadius={10}
                mb={3}
              >
                <Typography fontSize={31} fontWeight={700} color="#232340">
                  {t("useCreditCard")}
                </Typography>
                <Typography fontSize={16} color="#606060" my={1.5}>
                  {t("hereGiveAccessibility")}
                </Typography>
                <Grid container>
                  <img src={setaDireita} width={18} alt="" />
                </Grid>
              </Grid>
              <Grid
                container
                height={210}
                direction="column"
                justifyContent="center"
                bgcolor="#ffffff"
                pl={8}
                borderRadius={10}
                mb={3}
              >
                <Typography fontSize={31} fontWeight={700} color="#232340">
                  {t("unifyPaymentMethods")}
                </Typography>
                <Typography fontSize={16} color="#606060" my={1.2}>
                  {t("allDemandsEcosystem")}
                </Typography>
                <Grid container>
                  <img src={setaDireita} width={18} alt="" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        /*================== MOBILE ================== */
        <>
          <Grid
            mt={-7}
            sx={{
              background:
                "linear-gradient(180deg, #F5F4FD 0%, rgba(245, 244, 253, 0) 100%)",
            }}
            container
            item
            justifyContent="center"
          >
            <Grid container item justifyContent="space-between">
              <Grid container item direction="column">
                <Grid
                  className="universe-container"
                  container
                  item
                  direction="column"
                  alignItems="left"
                >
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    mb={2}
                    mt={6}
                    justifyContent={"center"}
                  >
                    <img src={kahshLogoblue} alt="" />

                    <Typography
                      ml={2}
                      fontSize={18}
                      color="#5468E7"
                      fontWeight="300"
                      fontStyle="italic"
                      textAlign="center"
                      dangerouslySetInnerHTML={{
                        __html: t("cryptocurrencyCurrency"),
                      }}
                    />
                  </Grid>

                  <Grid
                    container
                    justifyContent={width < 600 ? "left" : "center"}
                    pt="1rem"
                    pl="2rem"
                  >
                    <img
                      alt=""
                      src={KahshPay}
                      width="80%"
                      style={{ maxWidth: "330rem" }}
                    />
                  </Grid>
                  <Grid
                    display="flex"
                    alignItems="center"
                    justifyContent={width < 600 ? "left" : "center"}
                  >
                    <Typography
                      fontSize="2rem"
                      fontWeight={400}
                      color={"#606060"}
                      lineHeight="3rem"
                      pt="2rem"
                      pl="2rem"
                      pr="2rem"
                      dangerouslySetInnerHTML={{
                        __html: t("descriptionkahshPayMobile"),
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid>
                  <Grid container justifyContent="center">
                    {width < 600 ? (
                      <img alt="" src={imgkahshpay_2} width="80%" />
                    ) : (
                      <img alt="" src={imgkahshpay_1} width="100%" />
                    )}
                  </Grid>

                  {width > 1200 ? null : (
                    <Grid container justifyContent="center" pt={2}>
                      <Button
                        size="medium"
                        sx={{
                          width: "90%",
                          padding: "4rem",
                          color: "#FFFFFF",
                          fontWeight: "700",
                          fontSize: "5vw",
                        }}
                        onClick={() => accountsCallBack("register")}
                      >
                        {t("accessNow")}
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>

              {/*========= CONTAINER COM TODOS OS FILHOS INCLUSIVE O GRID AZUL === MOBILE ===*/}
              {width > 1200 ? null : (
                <Grid
                  mt={width > 400 && width <= 600 ? 17 : 5}
                  bgcolor="#F6F5FF"
                  container
                  justifyContent="center"
                  className="img-container"
                  flexDirection="column"
                  alignItems="center"
                  pb={2}
                >
                  <Grid
                    width="95%"
                    height="25rem"
                    bgcolor="#FFFFFF"
                    borderRadius={10}
                    alignContent="flex-start"
                    pt="3rem"
                    pl="4rem"
                    mb={5}
                  >
                    <Typography
                      fontSize="3.5rem"
                      fontWeight={700}
                      color="#232340"
                      lineHeight="40px"
                      mb={2}
                      dangerouslySetInnerHTML={{
                        __html: t("transferValues"),
                      }}
                    />
                    <Typography
                      fontSize="18px"
                      lineHeight="40px"
                      color={"#606060"}
                      mb={2}
                    >
                      {t("freeMediators")}
                    </Typography>
                    <Grid container width="2rem">
                      <img src={setaDireita} alt="" />
                    </Grid>
                  </Grid>

                  {/*========= CONTAINER COM O GRID AZUL*/}
                  <Grid
                    width="95%"
                    height="50rem"
                    bgcolor="#232340"
                    borderRadius={10}
                    textAlign="center"
                    container
                    justifyContent="center"
                    pt="3rem"
                    mb={5}
                  >
                    <Grid container justifyContent="center" direction="column">
                      <Typography
                        fontSize="32px"
                        fontWeight={700}
                        color="#ffffff"
                        lineHeight="45px"
                        mb={2}
                        dangerouslySetInnerHTML={{
                          __html: t("buyWithcurrency"),
                        }}
                      />
                      <Typography
                        fontSize="18px"
                        lineHeight="40px"
                        color={"#ffffff"}
                        mb={2}
                      >
                        {t("freeMediaYourFreDdevaluationtors")}
                      </Typography>
                      <Grid container justifyContent="center">
                        <img
                          src={setaBrancaDireita}
                          style={{ maxWidth: "2rem" }}
                          alt=""
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      justifyContent="center"
                      borderRadius={10}
                      bgcolor="#ffffff"
                      width={"95%"}
                      mb={2}
                    >
                      <img
                        src={moedas_k_mb}
                        alt=""
                        style={{ maxHeight: "25rem", maxWidth: "34rem" }}
                      />
                    </Grid>
                  </Grid>

                  {/*========= FIM DO CONTAINER COM O GRID AZUL*/}

                  <Grid
                    width="95%"
                    height="25rem"
                    bgcolor="#FFFFFF"
                    borderRadius={10}
                    alignContent="flex-start"
                    pt="3rem"
                    pl="4rem"
                    mb={5}
                  >
                    <Typography
                      fontSize="32px"
                      fontWeight={700}
                      color="#232340"
                      lineHeight="40px"
                      mb={2}
                      dangerouslySetInnerHTML={{
                        __html: t("useCreditCardMobile"),
                      }}
                    />
                    <Typography
                      fontSize="18px"
                      lineHeight="28px"
                      color={"#606060"}
                      mb={2}
                    >
                      {t("hereGiveAccessibility")}
                    </Typography>
                    <Grid container width="2rem">
                      <img src={setaDireita} alt="" />
                    </Grid>
                  </Grid>

                  <Grid
                    width="95%"
                    bgcolor="#FFFFFF"
                    borderRadius={10}
                    alignContent="flex-start"
                    py="3rem"
                    pl="4rem"
                  >
                    <Typography
                      fontSize="32px"
                      fontWeight={700}
                      color="#232340"
                      lineHeight="40px"
                      mb={1.5}
                    >
                      {t("unifyPaymentMethods")}
                    </Typography>
                    <Typography
                      fontSize="18px"
                      fontWeight={400}
                      lineHeight="28px"
                      color={"#606060"}
                      mb={1.5}
                      dangerouslySetInnerHTML={{
                        __html: t("allDemandsEcosystemMobile"),
                      }}
                    />
                    <Grid container width="2rem">
                      <img src={setaDireita} alt="" />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};
