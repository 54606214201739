import { Box, InputLabel, styled } from "@mui/material";

interface AutoPlacesContainerProps {
  error: boolean;
}

export const AutoPlacesContainer = styled(Box)<AutoPlacesContainerProps>`
  .google-places-autocomplete {
    & > div {
      border-radius: 15px;
      padding: 1px 4px;
      background-color: #fdfdff;
      border-color: ${({ error, theme }) =>
        error ? theme.palette.error.main : "#b4b4c1"};
      z-index: 10;
      color: ${({ theme, error }) =>
        error ? theme.palette.error.main : theme.palette.grey[600]} !important;
    }
  }
`;

interface LabelContainerProps {
  bgcolor?: string;
  error?: string;
}

export const LabelContainer = styled(InputLabel)<LabelContainerProps>`
  margin: 0 ${({ theme }) => theme.spacing(1)};
  padding: 0 ${({ theme }) => theme.spacing(1)};
  background-color: ${({ theme, bgcolor }) =>
    bgcolor ? bgcolor : theme.palette.background.paper};
  z-index: 1;
`;