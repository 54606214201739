import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

import Workers from "../../../imagens/Tabalhadores.png";
import Teacher from "../../../imagens/Professora.png";
import Battery from "../../../imagens/Pilhas.png";
import PhoneWithInfo from "../../../imagens/CelularComInfoKahsh.png";

const KahshFoundationItems = () => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  const items = [
    {
      id: 0,
      image: Workers,
      header: t("ideiasDaNovaGeracao"),
      body: t("ideiasDaNovaGeracaoBody"),
      alt: "",
    },
    {
      id: 1,
      image: Teacher,
      header: t("semeandoHoje"),
      body: t("semeandoHojeBody"),
      alt: "",
    },
    {
      id: 2,
      image: Battery,
      header: t("withUs"),
      body: t("withUsBody"),
      alt: "",
    },
    {
      id: 3,
      image: PhoneWithInfo,
      header: t("serKahsh"),
      body: t("serKahshBody"),
      alt: "",
    },
  ];

  return (
    <Grid container>
      {items.map((item, index) => {
        return (
          <Grid
            key={item.id}
            container
            alignItems="center"
            justifyContent="space-around"
            lg={6}
            border={"1px solid #E3E6E9"}
            borderTop={index > 1 && width > 900 ? "none" : "1px solid #E3E6E9"}
            borderBottom={
              index > 1 && width > 900 ? "none" : "1px solid #E3E6E9"
            }
            pt={10}
            pb={6}
            px={3}
          >
            <img src={item.image} alt={item.alt} />
            <Grid
              container
              item
              lg={7}
              direction="column"
              mt={width < 900 ? 0 : -4}
            >
              <Typography color="#292930" fontSize={24} fontWeight="bold">
                {item.header}
              </Typography>
              <Typography mt={3} color="#838793" fontSize={16}>
                {item.body}
              </Typography>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default KahshFoundationItems;
