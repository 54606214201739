import { Grid, Typography } from "@mui/material";

import { useTranslation } from "react-i18next";
import Trapezium from "../../../imagens/trapezium-background.png";

import i18n from "i18next";
import useSharedLink from "../../../hooks/useSharedLink";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import "./HomeEcossistema.styles.css";

import CircleWithLogo from "../../../imagens/circle-with-logo.png";
import Bags from "../../../imagens/ecosystem-bags.png";
import Exchange from "../../../imagens/ecosystem-exchange.png";
import Globe from "../../../imagens/ecosystem-globe.png";
import CirclesLogo from "../../../imagens/ecosystem-logo.png";
import Terminal from "../../../imagens/ecosystem-terminal.png";
import BgCirlces from "../../../imagens/ecosystemCircles.png";

import PlayArrowIcon from "@mui/icons-material/PlayArrow";

import { useEffect, useState } from "react";

export const HomeEcossistema = () => {
  useSharedLink();
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const [logoPadding, setLogoPadding] = useState({
    right: width / 180,
    bottom: width / 340,
  });

  useEffect(() => {
    setLogoPadding({ right: width / 180, bottom: width / 340 });
  }, [width]);

  const items = [
    {
      id: 1,
      image: Exchange,
      headerStrong: "Kahsh",
      headerRegular: "Exchange",
      body: t("ecoItemKahshExchange"),
      redirect: "/exchange",
    },
    {
      id: 2,
      image: Terminal,
      headerStrong: "Kahsh",
      headerRegular: "Pay",
      body: t("ecoItemKahshPay"),
      redirect: "/kahshpay",
    },
    {
      id: 3,
      image: Bags,
      headerStrong: "Kahsh",
      headerRegular: "Store",
      body: t("ecoItemKahshStore"),
      redirect: "https://www.kahshstore.com.br",
    },
    {
      id: 4,
      image: Globe,
      headerStrong: "Kahsh",
      headerRegular: "Foundation",
      body: t("ecoItemKahshFoundation"),
      redirect: "/foundation",
    },
  ];

  return (
    <Grid
      container
      xs={12}
      mt={width < 600 ? -9 : 5}
      id="ecosystem"
      position="relative"
    >
      <Grid
        container
        xs={12}
        bgcolor={"#232340"}
        sx={{
          background:
            width < 900
              ? "linear-gradient(to bottom, #32325A, #131336, #232340)"
              : "",
        }}
        justifyContent="center"
        pb={25}
      >
        <Grid
          container
          sm={10}
          mt={6}
          zIndex={1}
          justifyContent="space-between"
          alignItems="baseline"
        >
          <Grid container xs={12} lg={5}>
            {width > 600 ? (
              <Typography color="#FFFFFF" fontSize={64} fontWeight={700}>
                {t("kahshEcosystem")}
              </Typography>
            ) : i18n.language.substring(0, 2) === "pt" ? (
              <Typography px={5} color="#FFFFFF" fontSize={64}>
                ECOSSISTEMA <strong>KAHSH</strong>
              </Typography>
            ) : (
              <Typography px={5} color="#FFFFFF" fontSize={40} fontWeight={700}>
                <strong>KAHSH</strong> ECOSYSTEM
              </Typography>
            )}
          </Grid>
          <Grid
            container
            xs={12}
            lg={6}
            px={width < 600 ? 5 : 0}
            mt={width < 600 ? 3 : 0}
          >
            {i18n.language.substring(0, 2) === "pt" ? (
              <Typography color="#FFFFFF" fontSize={width < 600 ? "5vw" : 36}>
                A unificação de todas as demandas{" "}
                <strong>em uma única plataforma.</strong>
              </Typography>
            ) : (
              <Typography color="#FFFFFF" fontSize={width < 600 ? "5vw" : 36}>
                The unification of all demands{" "}
                <strong>on a single platform.</strong>
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid
          mt={{ sm: 24, xl: 10 }}
          container
          sm={12}
          zIndex={1}
          justifyContent="center"
          alignItems="center"
        >
          <img
            src={BgCirlces}
            width={"50%"}
            alt=""
            style={{ display: width < 900 ? "none" : null }}
          />
          <Grid container position={width > 900 ? "absolute" : null}>
            {items.map((item, index) => {
              return (
                <Grid
                  container
                  xs={12}
                  md={index === 0 || index === 2 ? 6 : 5}
                  justifyContent="center"
                  mt={index > 1 ? 7 : width < 900 ? 7 : null}
                  mb={width < 900 && index === 1 ? "70%" : null}
                >
                  <Grid
                    className="circle-box"
                    container
                    xs={width < 900 ? 9 : 6}
                    md={
                      index === 0 || index === 2
                        ? (8300 - width / 2) / width
                        : (7900 + width) / width
                    }
                    xl={6}
                    justifyContent="center"
                  >
                    <Grid
                      container
                      item
                      xs={4}
                      justifyContent="center"
                      maxHeight={"30%"}
                      mt={4}
                    >
                      <img
                        src={item.image}
                        width={"100%"}
                        height={"100%"}
                        alt=""
                      />
                    </Grid>
                    <Grid container item xs={12} justifyContent="center" mt={1}>
                      <Typography
                        color="#FFFFFF"
                        fontSize={25}
                        fontWeight={700}
                      >
                        {item.headerStrong}
                      </Typography>
                      {index !== 1 ? (
                        <Typography>&nbsp;&nbsp;</Typography>
                      ) : null}
                      <Typography
                        color="#FFFFFF"
                        fontSize={25}
                        fontWeight={300}
                      >
                        {item.headerRegular}
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      justifyContent="center"
                      textAlign="center"
                      p={2}
                      color="#F4F4F4"
                    >
                      <Typography fontSize={width < 1000 ? 13 : 16}>
                        {item.body}
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      justifyContent="center"
                      position="relative"
                      mb={-2}
                    >
                      <Grid
                        py={0.5}
                        container
                        className="play-container"
                        justifyContent="center"
                        alignItems="center"
                        onClick={() =>
                          index === 2
                            ? window.open(item.redirect)
                            : (window.location.href = `${item.redirect}`)
                        }
                      >
                        <PlayArrowIcon className="left-arrow" />
                        <PlayArrowIcon className="right-arrow" />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
          <Grid
            container
            position="absolute"
            justifyContent="center"
            pr={{ xs: logoPadding.right, xl: 9 }}
            pb={{ xs: logoPadding.bottom, xl: 5 }}
            pl={{ sm: 4.5, lg: 6.3, xl: 8.2 }}
            pt={{ sm: 2.5, lg: 4, xl: 5.2 }}
            mb={width > 900 ? 0 : width < 500 ? -15 : width < 350 ? -8 : -20}
          >
            {width < 900 ? (
              <img
                src={CircleWithLogo}
                width="70%"
                style={{ marginLeft: "15px" }}
                alt=""
              />
            ) : (
              <img
                className="circles-logo"
                src={CirclesLogo}
                width="20%"
                alt=""
              />
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid
        mt={-9}
        container
        xs={12}
        height={"100%"}
        position="absolute"
        justifyContent="flex-end"
        zIndex={0.1}
        sx={{ display: width < 900 ? "none" : null }}
      >
        <img
          src={Trapezium}
          alt=""
          height={"80%"}
          width={width < 600 ? "35%" : "72%"}
        />
      </Grid>
    </Grid>
  );
};
