import React from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import ImgIntro from "../../../imagens/img-intro-kahsh-labs.png";

export default function IntroductionKahshLabs() {
  const { t } = useTranslation();

  return (
    <Grid container alignItems="center" justifyContent="center" py={4}>
      <Grid
        container
        direction={{ xs: "column", md: "row" }}
        alignItems="center"
        justifyContent={{ xs: "center", md: "space-between" }}
      >
        <Grid
          container
          item
          md={7}
          direction="column"
          textAlign="left"
          px={{ xs: 3, md: 10 }}
        >
          <Typography
            width="100%"
            fontSize={{ xs: "52px", md: "64px" }}
            fontWeight={700}
            color="#1C1439"
          >
            {t("kahshlabsTitle")}
          </Typography>

          <Typography
            width="100%"
            fontSize="19px"
            fontWeight={400}
            color="#333333"
            pt={1}
            pb={{ xs: 3, md: 4 }}
          >
            {t("informationTechnologyCompany")}
            <br /> {t("multidisciplinary")}{" "}
            <b>{t("specialistBuildingSolutions")}</b> {t("of")}
            <br /> {t("physicalVirtualMarket")}
          </Typography>

          <Typography
            width="100%"
            fontSize="19px"
            fontWeight={400}
            color="#333333"
          >
            {t("developmentCryptoassetsAndSmartContracts")}
            <br /> {t("also")} <b>{t("solutionsThatIntegrateBusinesses")}</b>
            <br /> <b>{t("virtualMetaverseCryptoWorld")}</b>
          </Typography>
        </Grid>

        <Grid
          container
          item
          md={5}
          my={{ xs: 8, md: 0 }}
          height={{ xs: 280, md: 417 }}
          sx={{
            backgroundImage: `url(${ImgIntro})`,
            backgroundSize: {
              xs: "281px 280px",
              md: "418px 417px",
            },
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        />
      </Grid>
    </Grid>
  );
}
