import { CircularProgress, Container, Grid, Typography } from "@mui/material";

import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import AlertComponent from "../../components/alertComponent/AlertComponent";
import { Button } from "../../components/Button/Button";
import { FooterMini } from "../../components/footerMini/FooterMini";
import { Navigation } from "../../components/navigation/Navigation";
import Referrer from "../../imagens/referrer.png";
import { auth } from "../../services/firebase";
import TextField from "@material-ui/core/TextField";
import { Box } from "@mui/system";

export default function ReferAFriend() {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [user, loading] = useAuthState(auth);
  const [isNumber, setIsNumber] = useState(false);
  const [phone, setPhone] = useState("");

  const history = useHistory();

  useEffect(() => {
    if (!loading) {
      setTimeout(() => {
        if (!user) {
          history.push("/");
        }
      }, 3000);
    }
  }, [loading]);

  useEffect(() => {
    const intervalId = setTimeout(() => {
      setOpen(false);
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [open]);

  return (
    <Grid position="relative" height="100%">
      <Navigation position={{ xs: "absolute", md: "static" }} />
      {!user ? (
        <CircularProgress
          sx={{ position: "absolute", left: "50%", top: "50%" }}
        />
      ) : (
        <>
          <AlertComponent
            message={t("successfullyCopied")}
            severity="success"
            open={open}
            onClick={() => setOpen(false)}
          />
          <Container disableGutters maxWidth="xl">
            <Grid
              container
              height={{ xs: "100%", md: "80%" }}
              px={{ xs: 3, md: 4 }}
            >
              <Grid
                container
                item
                md={6}
                direction="column"
                justifyContent="center"
                alignItems={{ xs: "center", md: "flex-start" }}
              >
                <Typography
                  color="#1C1439"
                  fontSize={{ xs: 26, md: 40, xl: 48 }}
                  fontWeight={700}
                >
                  {t("referrerAFriend")}
                </Typography>
                <Typography
                  color="#000000"
                  fontSize={{ xs: 16, md: 22, xl: 28 }}
                  fontWeight={400}
                  textAlign={{ xs: "center", md: "left" }}
                >
                  {t("everytimeAFriend")}
                </Typography>
                <Grid container justifyContent="space-between" mt={5}>
                  <Button
                    sx={{ width: "49%" }}
                    onClick={() => {
                      setOpen(true);
                      navigator.clipboard.writeText(
                        `https://accounts.kahsh.com/register?returnUrl=https://kahsh.com&email=${
                          user && user.email
                        }`
                      );
                    }}
                  >
                    <Typography
                      fontSize={{ xs: 14, md: 18, xl: 24 }}
                      fontWeight={700}
                    >
                      {t("copyLink")}
                    </Typography>
                  </Button>
                  {!isNumber ? (
                    <Button
                      onClick={() => {
                        setIsNumber(true);
                      }}
                      sx={{
                        width: "49%",
                        backgroundColor: "#fff",
                        border: "1px solid #4C2FFF",
                        "&: hover": {
                          backgroundColor: "#4C2FFF",
                          ".hoverable": {
                            color: "#fff",
                          },
                        },
                      }}
                    >
                      <Typography
                        className="hoverable"
                        fontSize={{ xs: 14, md: 18, xl: 24 }}
                        fontWeight={700}
                        color="#4C2FFF"
                      >
                        {t("sendWhatsApp")}
                      </Typography>
                    </Button>
                  ) : (
                    <Grid width="49%">
                      <Box
                        component="form"
                        noValidate
                        autoComplete="off"
                        textAlign={"right"}
                      >
                        <TextField
                          variant="outlined"
                          label={t("phone")}
                          required
                          fullWidth
                          value={phone}
                          placeholder={t("phonePlaceholder")}
                          onChange={(e) => {
                            setPhone(e.target.value);
                          }}
                        />
                        <Button
                          sx={{
                            mt: 2,
                            height: 45,
                            width: { lg: "80%", xl: "60%" },
                          }}
                          onClick={() => {
                            window.open(
                              `https://wa.me/${phone}?text="${t(
                                "whatsMessage"
                              )}https://accounts.kahsh.com/register?returnUrl=https://kahsh.com${encodeURIComponent(
                                "&"
                              )}email=${user && user.email}"`
                            );
                          }}
                        >
                          {t("sendWhatsApp")}
                        </Button>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid
                container
                item
                md={6}
                sx={{
                  backgroundImage: `url(${Referrer})`,
                  backgroundSize: {
                    xs: "300px 300px",
                    sm: "50%",
                    md: "85%",
                    lg: "70% 70%",
                  },
                  backgroundPosition: { xs: "center top", md: "center" },
                  backgroundRepeat: "no-repeat",
                }}
              />
            </Grid>
          </Container>
          <FooterMini
            sx={{ position: { xs: "static", md: "absolute" }, bottom: 0 }}
          />
        </>
      )}
    </Grid>
  );
}
