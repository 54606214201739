import { useState } from "react";

import { Button, Grid, Typography } from "@mui/material";
import MobileStepper from "@mui/material/MobileStepper";

import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";

import i18n from "i18next";
import { useTranslation } from "react-i18next";

import useSharedLink from "../../../hooks/useSharedLink";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

import Unity from "../../../imagens/unity.png";
import Shopping from "../../../imagens/shopping.png";
import Usability from "../../../imagens/usability.png";
import Ecosystem from "../../../imagens/ecosystem.png";
import PaymentMethod from "../../../imagens/paymentMethod.png";
import kahshLogoBlack from "../../../imagens/kahsh-logo-black.png";

import "./HomeWhyKahsh.styles.css";

export const HomeWhyKahsh = () => {
  useSharedLink();
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  const items = [
    {
      image: Usability,
      body: t("whyKahshUsability"),
    },
    {
      image: Shopping,
      body: t("whyKahshShopping"),
    },
    {
      image: Ecosystem,
      body: t("whyKahshEcosystem"),
    },
    {
      image: PaymentMethod,
      body: t("whyKahshPayment"),
    },
  ];

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };
  const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
  const descriptions = [
    {
      labelPt:
        "Uma <b>moeda global</b>, um <b>ambiente</b><br/> <b>completo</b>, e para você, o mundo<br/> de escolhas.",
      labelEn:
        "A <b>global currency</b>, a <b>environment</b><br/> <b>complete</b>, and for you, the world<br/> of choices.",
    },
    {
      labelPt:
        "O primeiro marketplace com<br/> pagamento em <b>criptomoedas é</b><br/> <b>na Kahsh Store.</b>",
      labelEn:
        "The first marketplace with<br/> payment in <b>cryptocurrencies is</b><br/> <b>the Kahsh Store.</b>",
    },
    {
      labelPt:
        "Nosso ecossistema traz a<br/> <b>descomplicação</b> para o uso de<br/> criptomoedas.",
      labelEn:
        "Our ecosystem brings<br/> <b>simpleness</b> to the use of<br/> cryptocurrencies.",
    },
    {
      labelPt:
        "Kahsh Pay é um meio de pagamento e<br/> <b>transferências globais.</b><br/><br/>",
      labelEn:
        "Kahsh Pay is a payment method and<br/> <b>global transfers.</b><br/><br/>",
    },
    {
      labelPt:
        "<b>Kahsh Pay</b> é o único<br/> que <b>devolve taxas</b><br/> para os lojistas",
      labelEn:
        "<b>Kahsh Pay</b> is the only one<br/> that <b>returns fees</b><br/> to merchants",
    },
  ];

  const descriptionsMobile = [
    {
      labelPt:
        "Uma <b>moeda global</b>,<br/> um <b>ambiente</b><br/> <b>completo</b>, e para<br/> você, o mundo de<br/> escolhas.",
      labelEn:
        "A <b>global currency</b>,<br/> a <b>environment</b><br/> <b>complete</b>, and for<br/> you, the world of<br/> choices.",
    },
    {
      labelPt:
        "O primeiro marketplace<br/> com pagamento<br/> em <b>criptomoedas é na</b><br/> <b>Kahsh Store.<br/><br/>",
      labelEn:
        "The first marketplace<br/> with payment<br/> in <b>cryptocurrencies is at</b><br/> <b>Kahsh Store.</b>",
    },
    {
      labelPt:
        "Nosso ecossistema traz<br/> a <b>descomplicação</b> para<br/> o uso de criptomoedas.<br/><br/><br/>",
      labelEn:
        "Our ecosystem brings<br/> the <b>simplicity</b> to<br/> the use of cryptocurrencies.",
    },
    {
      labelPt:
        "Kahsh Pay é um meio<br/> de pagamento e<br/> <b>transferências globais.</b><br/><br/><br/>",
      labelEn:
        "Kahsh Pay is a means of<br/> payment and<br/> <b>global transfers.</b>",
    },
    {
      labelPt:
        "<b>Kahsh Pay</b> é o único<br/> que <b>devolve taxas</b><br/> para os lojistas<br/><br/><br/>",
      labelEn:
        "<b>Kahsh Pay</b> is the only one<br/> that <b>returns fees</b><br/> to merchants",
    },
  ];

  const maxSteps = descriptions.length;

  return (
    <Grid
      container
      pt={16}
      sx={{ background: "linear-gradient(to bottom, #EDE5F0, #FFFFFF)" }}
      px={width < 900 ? 5 : 0}
    >
      <Grid container justifyContent="center">
        <Grid container xs={12} md={10}>
          {width < 900 ? null : (
            <Grid xs={2.5}>
              <img src={kahshLogoBlack} alt="" width={38}></img>
            </Grid>
          )}

          <Grid xs={12} md={9} container alignItems="center">
            {width > 900 ? null : (
              <Grid>
                <img src={kahshLogoBlack} alt="" width={38}></img>
              </Grid>
            )}
            <Typography
              ml={width < 900 ? 2 : 0}
              fontSize={18}
              color="#5468E7"
              fontWeight="bold"
              fontStyle="italic"
            >
              {t("whyKahsh")}
            </Typography>
          </Grid>
        </Grid>

        {/* ======= CARROUSSEL ======= */}
        <Grid container md={10}>
          <Grid md={2.5}></Grid>
          <Grid md={6} xs={12}>
            <AutoPlaySwipeableViews
              index={activeStep}
              onChangeIndex={handleStepChange}
              interval={5000}
            >
              {width > 900
                ? descriptions.map((step, index) => (
                    <div key={step.label}>
                      {Math.abs(activeStep - index) <= 2 ? (
                        <Grid>
                          <Typography
                            fontSize={{ xs: "2vw", md: "36px" }}
                            mt={3}
                            color="#232340"
                            lineHeight="1.2em"
                            dangerouslySetInnerHTML={{
                              __html:
                                i18n.language.substring(0, 2) == "pt"
                                  ? step.labelPt
                                  : step.labelEn,
                            }}
                          />
                        </Grid>
                      ) : null}
                    </div>
                  ))
                : descriptionsMobile.map((step, index) => (
                    <div key={step.label}>
                      {Math.abs(activeStep - index) <= 2 ? (
                        <Grid>
                          <Typography
                            fontSize={"6.5vw"}
                            mt={3}
                            color="#232340"
                            lineHeight="1.2em"
                            dangerouslySetInnerHTML={{
                              __html:
                                i18n.language.substring(0, 2) == "pt"
                                  ? step.labelPt
                                  : step.labelEn,
                            }}
                          />
                        </Grid>
                      ) : null}
                    </div>
                  ))}
            </AutoPlaySwipeableViews>
            <MobileStepper
              steps={maxSteps}
              position="static"
              activeStep={activeStep}
              className="stepperHomeWhayKahsh"
            />
          </Grid>
          <Grid md={2} ml={4} container justifyContent="flex-end">
            <Grid item md={6}>
              <Button
                className="button"
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                <Typography className="arrow">←</Typography>
              </Button>
            </Grid>
            <Grid item md={6}>
              <Button
                onClick={handleNext}
                disabled={activeStep === maxSteps - 1}
                className="button"
              >
                <Typography className="arrow">→</Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {/* ======= FIM DO CARROUSSEL ======= */}
      </Grid>
      <Grid container justifyContent="center" lg={12} mt={12}>
        {items.map((e, index) => {
          return (
            <Grid
              lg={2.5}
              px={7}
              py={15}
              bgcolor="#FFFFFF"
              container
              justifyContent="center"
              alignItems={width < 1200 ? "center" : "baseline"}
              textAlign="center"
              flexWrap={width < 900 ? "nowrap" : null}
              mb={width < 900 ? 3 : 0}
              borderRadius={
                width > 900
                  ? index === 0
                    ? "30px 0 0 30px"
                    : index === 3
                    ? "0 30px 30px 0"
                    : null
                  : "30px"
              }
              borderBottom="8px solid #EBE6ED"
              className="items-container"
            >
              <img
                width={width < 600 ? "40%" : null}
                src={e.image}
                alt=""
              ></img>
              <Typography
                ml={width < 900 ? 1 : 0}
                color="#232340"
                fontSize={width < 900 ? 15 : 24}
                fontWeight={700}
              >
                {e.body}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
      <Grid container my={5} justifyContent="center">
        <Grid lg={5} sx={{ display: width < 1200 ? "none" : null }}>
          <img src={Unity} width={"90%"} alt="" />
        </Grid>
        <Grid lg={5} ml={width > 900 ? 5 : 0} mt={15}>
          <Grid
            xs={12}
            lg={10}
            container
            direction={width < 900 ? "row-reverse" : "row"}
            justifyContent={width < 900 ? "flex-end" : "space-between"}
            alignItems="center"
            wrap="nowrap"
            mb={4}
          >
            <Typography
              fontSize={18}
              fontStyle="italic"
              fontWeight={600}
              color="#282890"
              ml={width < 900 ? 3 : 0}
            >
              {t("advantagesKahsh")}
            </Typography>
            <img src={kahshLogoBlack} alt="" className="" />
          </Grid>
          <Grid lg={10}>
            {width < 900 ? (
              i18n.language.substring(0, 2) == "pt" ? (
                <Typography fontSize={width < 1200 ? 30 : 36} color="#000000">
                  <strong>Unindo</strong> o melhor dos{" "}
                  <strong>mercados.</strong>
                </Typography>
              ) : (
                <Typography fontSize={width < 1200 ? 30 : 36} color="#000000">
                  <strong>Uniting</strong> the best of
                  <strong> the markets.</strong>
                </Typography>
              )
            ) : (
              <Typography
                fontSize={width < 1200 ? 30 : 36}
                fontWeight={700}
                color="#000000"
              >
                {t("unitingMarkets")}
              </Typography>
            )}
          </Grid>
          <Grid lg={8} mt={2}>
            <Typography fontSize={18} lineHeight={"32px"} color="#606060">
              {t("unitingMarketsBody")}
            </Typography>
          </Grid>
          <Grid
            xs={12}
            lg={5}
            container
            justifyContent="center"
            mt={3}
            sx={{ display: width > 1200 ? "none" : null }}
          >
            <img
              src={Unity}
              width={"100%"}
              style={{
                maxWidth: width < 600 ? "100%" : "50%",
                minWidth: "300px",
                zIndex: 9999,
              }}
              alt=""
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
