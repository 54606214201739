import { useState, useEffect } from "react";
import SmoothScroll from "smooth-scroll";
import { Navigation } from "../components/navigation/Navigation";
import { NovidadesMedium } from "../components/novidadesMedium/NovidadesMedium";
import { Footer } from "../components/footer/Footer2";
import JsonData from "../data/data.json";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

export function Novidades() {
  const [landingPageData, setLandingPageData] = useState({});

  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Navigation />
      <NovidadesMedium data={landingPageData.NovidadesMedium} />
      <Footer />
    </div>
  );
}
