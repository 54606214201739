import { useState, useEffect } from "react";
import SmoothScroll from "smooth-scroll";

import { FastBuySuccessfulPayment } from "../components/fastBuy/FastBuySuccessfulPayment";
import JsonData from "../data/data.json";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

export function FastBuyPaid() {
  const [landingPageData, setLandingPageData] = useState({});

  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <FastBuySuccessfulPayment
        data={landingPageData.FastBuySuccessfulPayment}
      />
    </div>
  );
}
