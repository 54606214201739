import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  MenuItem,
  Paper,
} from "@mui/material";

import tripleBlueCard from "../../../imagens/tripleBlueCard.png";
import { useTranslation } from "react-i18next";
import { TextFieldSelect } from "../../inputs/text-field/text-field.styles";

export const KahshCardTables = () => {
  const { t } = useTranslation();

  const header = [1, 2, 3, 4, 5, 6];

  const rows = [
    [
      "signalValue",
      "R$ 300,00*",
      "R$ 300,00*",
      "R$ 300,00*",
      "R$ 300,00*",
      "R$ 300,00*",
      "R$ 300,00*",
    ],
    [
      "bonusKahsh",
      `30 %R$ 90,00`,
      "25 %R$ 75,00",
      "20 %R$ 60,00",
      "15 %R$ 45,00",
      "10 %R$ 30,00",
      "0 %R$ 0,00",
    ],
    [
      "kahshToReceive1",
      "R$ 390,00",
      "R$ 375,00",
      "R$ 360,00",
      "R$ 345,00",
      "R$ 330,00",
      "R$ 300,00",
    ],
  ];

  return (
    <Grid container my={15} justifyContent="center">
      <Grid
        item
        md={11}
        position="relative"
        pt={21}
        display={{ xs: "none", md: "inherit" }}
      >
        <Box
          component="img"
          src={tripleBlueCard}
          position="absolute"
          sx={{ top: { xs: 0, lg: 30 }, left: { md: 10 } }}
        />
        <Typography
          fontWeight={600}
          textAlign="center"
          fontSize={24}
          letterSpacing="-0.5px"
          color="#1C1439"
          position="absolute"
          sx={{ top: 60, left: "60%" }}
        >
          {t("card") + " BLUE"}
        </Typography>
        <Typography
          fontWeight={600}
          textAlign="center"
          fontSize={20}
          letterSpacing="-0.5px"
          color="#1C1439"
          position="absolute"
          sx={{ top: 90, left: "40%" }}
        >
          {t("updateCardText")}
        </Typography>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 500 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                {header.map((index) => {
                  return (
                    <TableCell
                      key={`batch` + index}
                      align="center"
                      sx={{
                        backgroundColor:
                          index === 1 ? "#003BB81A" : "transparent",
                      }}
                    >
                      <Typography
                        fontWeight={600}
                        fontSize={20}
                        color="#1C1439"
                        my={2}
                      >
                        {t("batch") + index}
                      </Typography>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row[0]}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row[0] === "kahshToReceive1" ? (
                      <Typography
                        fontWeight={600}
                        fontSize={20}
                        color="#1C1439"
                      >
                        {t(row[0])}
                        <br />
                        {t("kahshToReceive2")}
                      </Typography>
                    ) : (
                      <Typography
                        fontWeight={600}
                        fontSize={20}
                        color="#1C1439"
                        mr={-3}
                      >
                        {t(row[0])}
                      </Typography>
                    )}
                  </TableCell>

                  {row.map((batch, index) => {
                    if (localStorage.getItem("i18nextLng").includes("en")) {
                      batch = batch.replace(",", ".");
                    }
                    let batchSub = batch.split("%");

                    if (index !== 0) {
                      return (
                        <TableCell
                          key={`cel` + index}
                          align="center"
                          sx={{
                            backgroundColor:
                              index === 1 ? "#003BB81A" : "transparent",
                          }}
                        >
                          <Typography
                            my={row[0] === "kahshToReceive" ? 2 : 4}
                            fontWeight={row[0] === "signalValue" ? 700 : 400}
                            fontSize={20}
                            color={
                              row[0] === "signalValue" ? "#003BB8" : "#1C1439"
                            }
                          >
                            {batch.includes("%") ? (
                              <>
                                {batchSub[0]} %
                                <br />
                                <br />
                                {batchSub[1]}
                              </>
                            ) : (
                              batch
                            )}
                          </Typography>
                        </TableCell>
                      );
                    } else return null;
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid
        container
        item
        direction="column"
        justifyContent="center"
        alignItems="center"
        display={{ xs: "inherit", md: "none" }}
      >
        <Grid item mx={3.5}>
          <Typography
            fontWeight={600}
            textAlign="center"
            fontSize={24}
            letterSpacing="-0.5px"
            color="#1C1439"
          >
            {t("card") + " BLUE"}
          </Typography>
          <Typography
            fontWeight={600}
            textAlign="center"
            fontSize={20}
            letterSpacing="-0.5px"
            color="#1C1439"
            mb={4}
          >
            {t("updateCardText")}
          </Typography>
        </Grid>

        {header.map((index) => {
          return (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              key={`batchResponsive` + index}
              mb={4}
              px={4}
            >
              <Grid item xs={12}>
                <Typography
                  fontWeight={600}
                  fontSize={20}
                  textAlign="center"
                  color="#1C1439"
                  mt={2}
                >
                  <b>{t("batch") + index}</b>
                </Typography>
              </Grid>
              {rows.map((row) => {
                if (localStorage.getItem("i18nextLng").includes("en")) {
                  row[index] = row[index].replace(",", ".");
                }
                let rowSub = row[index].split("%");
                return (
                  <>
                    <Grid item xs={6} my={3}>
                      {row[0] === "kahshToReceive1" ? (
                        <Typography
                          fontWeight={600}
                          fontSize={{ xs: 16, sm: 20 }}
                          color="#1C1439"
                        >
                          {t(row[0])}
                          <br />
                          {t("kahshToReceive2")}
                        </Typography>
                      ) : (
                        <Typography
                          fontWeight={600}
                          fontSize={{ xs: 16, sm: 20 }}
                          color="#1C1439"
                        >
                          {t(row[0])}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        fontWeight={row[0] === "signalValue" ? 700 : 400}
                        fontSize={{ xs: 16, sm: 20 }}
                        textAlign="right"
                        color={row[0] === "signalValue" ? "#003BB8" : "#1C1439"}
                      >
                        {row[index].includes("%") ? (
                          <>
                            {rowSub[0]} %
                            <br />
                            {rowSub[1]}
                          </>
                        ) : (
                          row[index]
                        )}
                      </Typography>
                    </Grid>
                  </>
                );
              })}
            </Grid>
          );
        })}
      </Grid>
      {/* <Grid container mx={{ xs: 0, md: 0 }}>
        <Grid
          item
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          md={6}
          xs={12}
          mb={{ md: 8 }}
        >
          <Typography
            fontWeight={700}
            textAlign={{ xs: "center", md: "left" }}
            fontSize={45}
            ml={{ xs: "5%", md: 8, lg: 23 }}
            mr={{ xs: "5%", md: 4, lg: 14 }}
            mt={{ xs: 8, md: 18 }}
            sx={{
              background:
                "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(90.26deg, #8889F1 -85.54%, #1DC9FF -11.67%, #867BFF 62.2%, #D4B2FF 136.06%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
            letterSpacing={"-0.02em"}
          >
            <b>{t("seeWhatWin")}</b>
          </Typography>
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          md={6}
          xs={12}
          my={10}
        >
          <Grid
            container
            direction={{ xs: "column", md: "row" }}
            justifyContent={{ xs: "center", md: "flex-start" }}
            alignItems={{ xs: "center", md: "flex-end" }}
          >
            <Grid
              container
              item
              md={6}
              xs={6}
              direction="column"
              justifyContent="center"
              alignItems="center"
              mt={{ xs: 7, md: 8 }}
            >
              <Typography
                fontWeight={400}
                textAlign="center"
                fontSize={{ xs: 20, md: 16.5, lg: 20 }}
                color={"#5C5E61"}
                mb={2}
              >
                {t("selectCard")}
              </Typography>
              <TextFieldSelect
                select
                size="small"
                labelId="cardSelect"
                id="select"
                value={0}
                mx={{ xs: "auto", md: 0 }}
                onChange={() => {
                  console.log("teste");
                }}
                sx={{
                  borderRadius: "15px",
                  borderColor: "#4733FF !important",
                  width: { xs: "90%", sm: "70%" },
                }}
              >
                <MenuItem value={0}>
                  <Typography fontWeight={500} fontSize={20} color="#3E3E3E">
                    {"Blue"}
                  </Typography>
                </MenuItem>
              </TextFieldSelect>
            </Grid>
            <Grid
              container
              item
              md={6}
              xs={6}
              direction="column"
              justifyContent="center"
              alignItems="center"
              mt={{ xs: 7, md: 8 }}
            >
              <Typography
                fontWeight={400}
                textAlign="center"
                fontSize={{ xs: 20, md: 16.5, lg: 20 }}
                color={"#5C5E61"}
                mb={2}
              >
                {t("selectAnual")}
              </Typography>
              <TextFieldSelect
                size="small"
                select
                labelId="cardSelect"
                id="select"
                value={0}
                onChange={() => {
                  console.log("teste");
                }}
                sx={{
                  borderRadius: "15px",
                  borderColor: "#4733FF !important",
                  width: { xs: "90%", sm: "70%" },
                }}
              >
                <MenuItem value={0}>
                  <Typography fontWeight={500} fontSize={20} color="#3E3E3E">
                    {"R$ 60,000"}
                  </Typography>
                </MenuItem>
              </TextFieldSelect>
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            xs={12}
            mt={8}
          >
            <Grid item md={6} xs={12}>
              <Typography
                fontWeight={400}
                textAlign="center"
                fontSize={20}
                color="#5C5E61"
              >
                {t("kahshBackValue")}
              </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <Box
                mt={{ xs: 3, md: 0 }}
                ml={{ md: 0, lg: -4 }}
                mx={{ xs: "auto", md: 0 }}
                sx={{
                  background:
                    "linear-gradient(90.26deg, #8889F1 -85.54%, #1DC9FF -11.67%, #867BFF 62.2%, #D4B2FF 136.06%), #FFFFFF",
                  border: "0px solid transparent",
                  borderRadius: "20px",
                  width: "168px",
                  height: "45px",
                }}
              >
                <Typography
                  pt={0.5}
                  fontWeight={600}
                  textAlign="center"
                  fontSize={24}
                  color="#FFFFFF"
                >
                  {t("R$ 5,000")}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid> */}
    </Grid>
  );
};
