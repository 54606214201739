import { useState, useEffect } from "react";

import SmoothScroll from "smooth-scroll";

import { FastBuy } from "../components/fastBuy/FastBuy";
import JsonData from "../data/data.json";
// import { LogoAndTranslate } from "../components/logoAndTranslate/LogoAndTranslate";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

export function FastBuyPage() {
  const [landingPageData, setLandingPageData] = useState({});

  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      {/* <LogoAndTranslate /> */}
      <FastBuy data={landingPageData.FastBuy} />
    </div>
  );
}
