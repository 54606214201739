import { Pagination } from "@material-ui/lab";
import { Box, Grid } from "@mui/material";
import Stack from "@mui/material/Stack";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect, useState } from "react";
import { Card, CardBody, CardImg, CardSubtitle, CardTitle } from "reactstrap";
import usePagination from "./Pagination";

import CopiarLink from "../../icons/CopiarLink";

import Baixar from "../../icons/Baixar";
import FacebookRedondo from "../../icons/FacebookRedondo";
import LinkedinRedondo from "../../icons/LinkedinRedondo";
import MoreOptions from "../../icons/MoreOptions";
import TwitterRedondo from "../../icons/TwitterRedondo";

import logoKahshBlogMedium from "../../imagens/logoKahshBlogMedium";

import useSharedLink from "../../hooks/useSharedLink";
import "./NovidadesMedium.styles.css";

var moment = require("moment");

export const NovidadesMedium = () => {
  useSharedLink();
  const matches = useMediaQuery("(min-width:1304px)");
  const [mediumData, setMediumData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (mediumData.length === 0) {
      fetch(
        `https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@kahsh`
      )
        .then((res) => res.json())
        .then((response) => {
          setMediumData(response.items);
          setIsLoading(false);
        })
        .catch((err) => {
          return err;
        });
    }
  }, [mediumData]);

  let [page, setPage] = useState(1);
  const PER_PAGE = 5;

  const count = Math.ceil(mediumData.length / PER_PAGE);
  const _DATA = usePagination(mediumData, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  function removeTags(tags) {
    if (tags === null || tags === "") {
      return "";
    } else {
      return tags.replace(/<img .*?>/g, "");
    }
  }

  return (
    <>
      {!matches ? (
        <Grid
          container
          py={10}
          px={10}
          id="novidadesMedium"
          className="container mt-3"
        >
          {isLoading && <p>Carregando posts...</p>}
          {_DATA.currentData().map((article) => (
            <Grid
              item
              removeTags={article.description}
              container
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              key={article.id}
            >
              <Box
                py={5}
                width={600}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Card>
                  <CardBody>
                    <CardTitle className="titleCard">
                      <a href={article.link}>{article.title}</a>
                    </CardTitle>
                    <Box display="flex" alignItems="center">
                      <a className="linkLogo">
                        <img
                          src={logoKahshBlogMedium}
                          alt="logoKahshBlogMedium"
                          className="imgLogoBlogKahsh"
                        />
                        Kahsh
                      </a>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <CardSubtitle className="subTitleCard">
                          {moment(article.pubDate).format(" MMMM, DD, YYYY")}
                        </CardSubtitle>
                        <Box pb={3} className="imgBoxBlog">
                          <a
                            href="https://twitter.com/intent/tweet?text=Manual%20de%20Swap%20by%20%40KahshCoin%20https%3A%2F%2Flink.medium.com%2FSH4LEJXhunb"
                            target="_blank"
                            rel="noreferrer"
                            className="imgBlogMEdium"
                          >
                            <TwitterRedondo />
                          </a>
                          <a
                            href="https://www.facebook.com/login.php?skip_api_login=1&api_key=542599432471018&signed_next=1&next=https%3A%2F%2Fwww.facebook.com%2Fv6.0%2Fdialog%2Fshare%3Fredirect_uri%3Dhttps%253A%252F%252Fmedium.com%252Fm%252Fshare%252Fsuccess%253FpostId%253D670c260c1cca%26display%3Dpage%26href%3Dhttps%253A%252F%252Flink.medium.com%252FleetYtYhunb%26client_id%3D542599432471018%26ret%3Dlogin&cancel_url=https%3A%2F%2Fmedium.com%2Fm%2Fshare%2Fsuccess%3FpostId%3D670c260c1cca%26error_code%3D4201%26error_message%3DUser%2Bcanceled%2Bthe%2BDialog%2Bflow%23_%3D_&display=page&locale=pt_PT"
                            target="_blank"
                            rel="noreferrer"
                            className="imgBlogMEdium"
                          >
                            <FacebookRedondo />
                          </a>
                          <a
                            href="https://www.linkedin.com/sharing/share-offsite/?mini=true&url=https%3A%2F%2Flink.medium.com%2FtU2ZiIaiunb"
                            target="_blank"
                            rel="noreferrer"
                            className="imgBlogMEdium"
                          >
                            <LinkedinRedondo />
                          </a>
                          <a
                            href="https://www.instagram.com/kahshcom/"
                            target="_blank"
                            rel="noreferrer"
                            className="imgBlogMEdium"
                          >
                            <CopiarLink />
                          </a>
                          <a
                            href="https://medium.com/m/signin?actionUrl=https%3A%2F%2Fmedium.com%2F_%2Fbookmark%2Fp%2F670c260c1cca&operation=register&redirect=https%3A%2F%2Fkahsh.medium.com%2Fmanual-de-swap-670c260c1cca&source=post_actions_header--------------------------bookmark_preview--------------"
                            target="_blank"
                            rel="noreferrer"
                            className="imgBlogMEdium"
                          >
                            <Baixar />
                          </a>
                          <a
                            href="https://www.instagram.com/kahshcom/"
                            target="_blank"
                            rel="noreferrer"
                            className="imgBlogMEdium"
                          >
                            <MoreOptions />
                          </a>
                        </Box>
                      </Box>
                    </Box>
                    <CardImg
                      top
                      width="100%"
                      src={article.thumbnail}
                      alt="img"
                    />
                    <div
                      id="descriptionsBlog"
                      dangerouslySetInnerHTML={{
                        __html:
                          removeTags(article.content).substring(0, 300).trim() +
                          "...",
                      }}
                    />
                    <Grid className="titleCardSaibamais">
                      <a href={article.link} target="_blank" rel="noreferrer">
                        Saiba mais
                      </a>
                    </Grid>
                  </CardBody>
                </Card>
              </Box>
            </Grid>
          ))}
          <Grid
            container
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Stack spacing={2}>
              <Pagination
                count={count}
                page={page}
                color="primary"
                onChange={handleChange}
              />
            </Stack>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          py={10}
          px={10}
          id="novidadesMedium"
          className="container mt-3"
        >
          {isLoading && <p>Carregando posts...</p>}
          {_DATA.currentData().map((article) => (
            <Grid
              item
              removeTags={article.description}
              container
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              key={article.id}
            >
              <Box
                py={5}
                width={600}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Card>
                  <CardBody>
                    {/* título do card */}
                    <CardTitle className="titleCard">
                      <a href={article.link}>{article.title}</a>
                    </CardTitle>

                    {/* Logo da kahsh antes das redes sociais */}
                    <Box display="flex" alignItems="center">
                      <a className="linkLogo">
                        <img
                          src={logoKahshBlogMedium}
                          alt="logoKahshBlogMedium"
                          className="imgLogoBlogKahsh"
                        />
                        Kahsh
                      </a>

                      {/* links das redes sociais */}
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <CardSubtitle className="subTitleCard">
                          {moment(article.pubDate).format(" MMMM, DD, YYYY")}
                        </CardSubtitle>
                        <Box pb={3} className="imgBoxBlog">
                          <a
                            href="https://twitter.com/intent/tweet?text=Manual%20de%20Swap%20by%20%40KahshCoin%20https%3A%2F%2Flink.medium.com%2FSH4LEJXhunb"
                            target="_blank"
                            rel="noreferrer"
                            className="imgBlogMEdium"
                          >
                            <TwitterRedondo />
                          </a>
                          <a
                            href="https://www.facebook.com/login.php?skip_api_login=1&api_key=542599432471018&signed_next=1&next=https%3A%2F%2Fwww.facebook.com%2Fv6.0%2Fdialog%2Fshare%3Fredirect_uri%3Dhttps%253A%252F%252Fmedium.com%252Fm%252Fshare%252Fsuccess%253FpostId%253D670c260c1cca%26display%3Dpage%26href%3Dhttps%253A%252F%252Flink.medium.com%252FleetYtYhunb%26client_id%3D542599432471018%26ret%3Dlogin&cancel_url=https%3A%2F%2Fmedium.com%2Fm%2Fshare%2Fsuccess%3FpostId%3D670c260c1cca%26error_code%3D4201%26error_message%3DUser%2Bcanceled%2Bthe%2BDialog%2Bflow%23_%3D_&display=page&locale=pt_PT"
                            target="_blank"
                            rel="noreferrer"
                            className="imgBlogMEdium"
                          >
                            <FacebookRedondo />
                          </a>
                          <a
                            href="https://www.linkedin.com/sharing/share-offsite/?mini=true&url=https%3A%2F%2Flink.medium.com%2FtU2ZiIaiunb"
                            target="_blank"
                            rel="noreferrer"
                            className="imgBlogMEdium"
                          >
                            <LinkedinRedondo />
                          </a>
                          <a
                            href="https://www.instagram.com/kahshcom/"
                            target="_blank"
                            rel="noreferrer"
                            className="imgBlogMEdium"
                          >
                            <CopiarLink />
                          </a>
                          <a
                            href="https://medium.com/m/signin?actionUrl=https%3A%2F%2Fmedium.com%2F_%2Fbookmark%2Fp%2F670c260c1cca&operation=register&redirect=https%3A%2F%2Fkahsh.medium.com%2Fmanual-de-swap-670c260c1cca&source=post_actions_header--------------------------bookmark_preview--------------"
                            target="_blank"
                            rel="noreferrer"
                            className="imgBlogMEdium"
                          >
                            <Baixar />
                          </a>
                          <a
                            href="https://www.instagram.com/kahshcom/"
                            target="_blank"
                            rel="noreferrer"
                            className="imgBlogMEdium"
                          >
                            <MoreOptions />
                          </a>
                        </Box>
                      </Box>
                    </Box>

                    {/* imagem do card */}
                    <CardImg
                      top
                      width="100%"
                      src={article.thumbnail}
                      alt="img"
                    />

                    {/* informações com "..." */}
                    <Grid
                      id="descriptionsBlog"
                      dangerouslySetInnerHTML={{
                        __html:
                          removeTags(article.content).substring(0, 300).trim() +
                          "...",
                      }}
                    />
                    {/* Link do saiba mais */}
                    <Grid className="titleCardSaibamais">
                      <a href={article.link} target="_blank" rel="noreferrer">
                        Saiba mais
                      </a>
                    </Grid>
                  </CardBody>
                </Card>
              </Box>
            </Grid>
          ))}

          {/* paginação no fim da página */}
          <Grid
            container
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Stack spacing={2}>
              <Pagination
                count={count}
                page={page}
                color="primary"
                onChange={handleChange}
              />
            </Stack>
          </Grid>
        </Grid>
      )}
    </>
  );
};
