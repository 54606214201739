import { useState, useEffect } from "react";
import SmoothScroll from "smooth-scroll";
import { CalculadoraOn } from "../components/StakingPage/calculadoraOn/CalculadoraOn";
import { Footer } from "../components/footer/Footer2";
import JsonData from "../data/data.json";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

export function CalculadoraLogada() {
  const [landingPageData, setLandingPageData] = useState({});

  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <CalculadoraOn data={landingPageData.CalculadoraOn} />
      <Footer />
    </div>
  );
}
