import {
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  Skeleton,
  Typography,
} from "@mui/material";
import axios from "axios";
import { initializeApp } from "firebase/app";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  getFirestore,
  orderBy,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { useEffect, useRef, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import { useHistory, useLocation } from "react-router-dom";
import Web3 from "web3";

import { Navigation } from "../../components/navigation/Navigation";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import Chevron from "../../imagens/chevron.png";
import KahshIcon from "../../imagens/Kahsh-Icon.svg";
import { auth } from "../../services/firebase";
import {
  abiStaking,
  firebaseConfig,
  stakingContract,
  urlBalance,
  urlBnbTxid,
  urlUserBalance,
} from "../../services/global";
import grafico from "./../../assets/img/grafico.png";
import "./Extrato.styles.css";

import useSharedLink from "../../hooks/useSharedLink";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    let id = setInterval(() => {
      savedCallback.current();
    }, delay);
    return () => clearInterval(id);
  }, [delay]);
}

export function Extrato() {
  useSharedLink();
  const history = useHistory();
  const { width } = useWindowDimensions();
  const [getData, setGetData] = useState(false);

  const [balance, setBalance] = useState(null);
  const [pending, setPending] = useState(0);
  const [pendingSwap, setPendingSwap] = useState(0);
  const [totalPending, setTotalPending] = useState(null);
  const [rewards, setRewards] = useState(0);
  const [rewardsSwap, setRewardsSwap] = useState(0);
  const [loadingPage, setLoadingPage] = useState(true);
  const [loadingPageFirebase, setLoadingPageFirebase] = useState(true);
  const [loadingPageSwap, setLoadingPageSwap] = useState(true);

  const [allBalance, setAllBalance] = useState([]);
  const [allSwap, setAllSwap] = useState([]);
  const [changeSeconds, setChangeSeconds] = useState(60);

  const [walletSwap, setWalletSwap] = useState("");
  const [kahshId, setKahshId] = useState("");
  const [user, loading] = useAuthState(auth);
  const location = useLocation();
  const [reloadFirebase, setReloadFirebase] = useState(0);
  const [open, setOpen] = useState(false);
  const [button, setButton] = useState(false);
  const [message, setMessage] = useState("");
  const [connection, setConnection] = useState(true);

  const { t, i18n } = useTranslation();

  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const [kahshCoin, setKahshCoin] = useState(0);

  useEffect(() => {
    if (kahshCoin <= 0) {
      const fetchData = async () => {
        try {
          const data = await fetch("https://exchange.kahsh.com/cotacao/USD");
          const json = await data.json();
          setKahshCoin(json[2].cotacao);
        } catch (error) {
          throw error.message;
        }
      };
      fetchData().catch((err) => {
        return err;
      }); //Erro
    } else {
    }
  }, [kahshCoin]);

  useEffect(() => {
    if (!getData) {
      if (user) {
        setKahshId(user.uid);
        if (allBalance !== []) {
          getContract();
          getSwap();
        }
      } else {
        // console.log("useEffect - Aguardar proxima conexão...");
      }
    } else {
      // console.log("Algo deu errado", getData);
    }
  }, [user]);

  useEffect(() => {
    if (!loading) {
      if (!user)
        history.push(
          "/authentication?return=" + location.pathname + `&destination=0`
        );
    }
  }, [loading]);

  useInterval(() => {
    if (balance !== 0) {
      if (!loadingPage) {
        setTotalPending(totalPending + 0.01);
      }
    }
  }, Math.round(changeSeconds) * 1000);

  useEffect(() => {
    setTotalPending(pending + pendingSwap);
  }, [pending, pendingSwap]);

  useEffect(() => {
    if (reloadFirebase > 0) {
      getContract();
      setReloadFirebase(0);
    }
  }, [reloadFirebase]);

  useEffect(() => {
    if (!navigator.onLine) {
      setOpen(true);
      setMessage("connectionError");
      setButton(true);
    }
  }, [connection]);

  setInterval(() => {
    if (balance !== 0) {
      if (!navigator.onLine) {
        setConnection(false);
      } else {
        setConnection(true);
      }
    }
  }, 15000);

  useEffect(() => {
    setTimeout(() => {
      if (totalPending !== 0) {
        setLoadingPage(false);
      }
    }, 10000);
  }, [totalPending]);

  const getCurrencyFormat = (currency) => {
    return Number(currency)
      .toLocaleString(
        i18n.language.substring(0, 2) == "en"
          ? "en-US"
          : i18n.language.substring(0, 2) == "pt"
          ? "pt-BR"
          : i18n.language,
        {
          style: "currency",
          currency:
            i18n.language.substring(0, 2) == "en"
              ? "USD"
              : i18n.language.substring(0, 2) == "pt"
              ? "BRL"
              : "BRL",
        }
      )
      .split("$")[1];
  };

  function getSwap() {
    if (user) {
      try {
        axios
          .get(
            // "https://admin.kahshcenter.com/admin/api/swap/email?email=" +
            "https://api.kahsh.com/swap?email=" +
            user.email
          )
          .then((res) => {
            if (!res.data.addresses.length) {
              // Avaliar depois
            } else {
              const walletRes = Web3.utils.toChecksumAddress(
                res.data.addresses[0].ethAddress
              );
              setWalletSwap(walletRes.toString());
              axios
                .get(
                  urlBalance +
                    Web3.utils.toChecksumAddress(
                      res.data.addresses[0].ethAddress
                    )
                )
                .then((res) => {
                  setAllSwap(res.data.data);
                  setPendingSwap(Number(res.data.data.remaining_balance));
                  setRewardsSwap(Number(res.data.data.amount_paid));
                  setLoadingPageSwap(false);
                });
              if (allBalance) {
                setLoadingPageFirebase(false);
              }
            }
          });
      } catch (error) {
        throw error.message;
      }
    } else {
      setOpen(true);
    }
    setLoadingPageSwap(false);
  }

  const handleClose = () => {
    setOpen(false);
    setButton(false);
  };

  function getContract() {
    var totalBalanceFirebase = [];
    var balanceTemp = 0;
    var pendingTemp = 0;
    var rewardsTemp = 0;

    const stakingDb = collection(db, "staking");
    const q2 = query(
      stakingDb,
      where("uid", "==", kahshId || user.uid),
      orderBy("start", "desc")
    );

    var velocitySum = 0;

    getDocs(q2).then((querySnapshot) => {
      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          velocitySum =
            velocitySum +
            getReturn(
              doc.data().pid,
              doc.data().staked,
              doc.data().start,
              doc.data().end,
              "velocity"
            );

          totalBalanceFirebase.push(doc.data());
        });

        setChangeSeconds((0.01 * 60) / velocitySum);
        setAllBalance(totalBalanceFirebase);
      } else {
        //setLoadingPage(false);
      }
    });

    var walletArr;
    var totalBalance = [];
    var arr = [];
    var walletRef;
    const walletsDb = collection(db, "wallets");
    const q = query(walletsDb, where("uid", "==", kahshId || user.uid));

    getDocs(q).then(async (querySnapshot) => {
      for (let i = 0; i < querySnapshot.docs.length; i++) {
        walletRef = Web3.utils.toChecksumAddress(
          querySnapshot.docs[i]._document.data.value.mapValue.fields.wallet
            .stringValue
        );
        walletArr = await axios
          .get(urlUserBalance + Web3.utils.toChecksumAddress(walletRef))
          .then((res) => {
            setLoadingPage(true);
            return res.data.data;
          });

        for (let i2 = 0; i2 < Object.entries(walletArr).length; i2++) {
          if (parseInt(walletArr[i2].staked) > 0) {
            totalBalance.push(walletArr[i2]);
          }
        }
      }

      // console.log("totalBalance", totalBalance);

      var totalBalanceFirebaseFiltered = [];

      if (totalBalance && totalBalance.length > 0) {
        for (let i = 0; i < totalBalance.length; i++) {
          // if (Number(totalBalance[i].staked) > 0) {
          balanceTemp = balanceTemp + Number(totalBalance[i].staked);
          pendingTemp = pendingTemp + Number(totalBalance[i].pending_rewards);
          rewardsTemp = rewardsTemp + Number(totalBalance[i].rewards_paid);
          arr.push(totalBalance[i]);
          // }
        }
      }

      totalBalanceFirebaseFiltered = arr.filter(
        (elem) =>
          !totalBalanceFirebase.find(
            (
              { pid, wallet, rewards_paid } //,rewards_paid
            ) =>
              elem.pid === pid &&
              Web3.utils.toChecksumAddress(elem.address) ===
                Web3.utils.toChecksumAddress(wallet) &&
              elem.rewards_paid === rewards_paid //novo
          )
      );

      // console.log("totalBalanceFirebaseFiltered", totalBalanceFirebaseFiltered);

      if (
        totalBalanceFirebaseFiltered &&
        totalBalanceFirebaseFiltered.length > 0
      ) {
        const txidsDb = collection(db, "txids");
        const stakingDb = collection(db, "staking");
        var q = [];
        var docRef = [];
        var dataUpdate = [];

        for (let i = 0; i < totalBalanceFirebaseFiltered.length; i++) {
          // console.log(
          //   "Analisando totalBalanceFirebaseFiltered[" + i + "]",
          //   totalBalanceFirebaseFiltered[i]
          // );
          q[i] = query(
            txidsDb,
            where("uid", "==", kahshId || user.uid),
            where("pid", "==", totalBalanceFirebaseFiltered[i].pid),
            where(
              "wallet",
              "==",
              Web3.utils.toChecksumAddress(
                totalBalanceFirebaseFiltered[i].address
              )
            )
          );
          var txid = [];

          // console.log("1" + i);

          getDocs(q[i]) //Existe Txid registrado dessa linha em questão?
            // eslint-disable-next-line no-loop-func
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                // console.log("Existe, exibir txid" + i, doc.data());
                txid[i] = doc.data().txid;
              });

              // console.log("txid[i]" + i, txid[i]);

              var queryStaking = query(
                stakingDb,
                where("uid", "==", kahshId || user.uid),
                where("pid", "==", totalBalanceFirebaseFiltered[i].pid),
                where(
                  "wallet",
                  "==",
                  Web3.utils.toChecksumAddress(
                    totalBalanceFirebaseFiltered[i].address
                  )
                )
              );

              // console.log("Verificando " + i);

              var stakingExists = [];

              getDocs(queryStaking) //Verifica se ja existe esse PID para essa wallet
                .then((querySnapshotStaking) => {
                  querySnapshotStaking.forEach((docStaking) => {
                    stakingExists[i] = true;
                    docRef[i] = doc(db, "staking", docStaking.id);
                  });

                  if (!stakingExists[i]) {
                    //Se nao existe esse registro, então criar
                    addDoc(collection(db, "staking"), {
                      uid: kahshId || user.uid,
                      wallet: Web3.utils.toChecksumAddress(
                        totalBalanceFirebaseFiltered[i].address
                      ),
                      pid: totalBalanceFirebaseFiltered[i].pid,
                      staked: Number(totalBalanceFirebaseFiltered[i].staked),
                      start:
                        totalBalanceFirebaseFiltered[i].startDate_formatted,
                      end: totalBalanceFirebaseFiltered[i].endDate_formatted,
                      rewards_paid:
                        totalBalanceFirebaseFiltered[i].rewards_paid, //novo
                      txid: typeof txid[i] == "undefined" ? "" : txid[i],
                    });
                  } else {
                    //Se já existe, então atualizar

                    dataUpdate[i] = {
                      rewards_paid:
                        totalBalanceFirebaseFiltered[i].rewards_paid,
                      pending_rewards:
                        totalBalanceFirebaseFiltered[i].pending_rewards,
                    };

                    setDoc(docRef[i], dataUpdate[i], { merge: true })
                      .then((docRefs) => {
                        // console.log(
                        //   "Entire Document has been updated successfully " + i
                        // );
                      })
                      .catch((error) => {
                        return error;
                      });
                  }
                })
                .catch((error) => {
                  return error;
                });
            })
            .catch((error) => {
              return error;
            });
        }

        setReloadFirebase(reloadFirebase + 1);
      }

      setPending(pendingTemp);
      setRewards(rewardsTemp);
      setBalance(balanceTemp);
      setLoadingPageFirebase(false);
    });
  }

  const validaDate = (itemEnd) => {
    if (new Date() > new Date(itemEnd)) {
      return true;
    } else {
      return false;
    }
  };

  const validaDateMobile = (itemPid, itemStaked, itemStart, itemEnd) => {
    if (new Date() > new Date(itemEnd)) {
      return (
        <s>
          <font>0</font>
        </s>
      );
    } else {
      return getCurrencyFormat(
        getReturn(itemPid, itemStaked, itemStart, itemEnd)
      );
    }
  };

  function getReturn(pid, amount, start, end, type) {
    var now = new Date();
    var utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
    var today = Math.abs(utc - new Date(start.replace(/-/g, "/")));
    var years = Math.abs(
      new Date(end.replace(/-/g, "/")) - new Date(start.replace(/-/g, "/"))
    );
    var prop = today / years;

    var returnValue = 0;

    if (pid === 0) {
      returnValue = 1;
    }
    if (pid === 1) {
      returnValue = 1.25;
    }
    if (pid === 2) {
      returnValue = 1.5;
    }
    if (pid === 3) {
      returnValue = 1.25;
    }
    if (pid === 4) {
      returnValue = 1.5;
    }
    if (pid === 5) {
      returnValue = 2;
    }
    if (pid === 6) {
      returnValue = 1.5;
    }
    if (pid === 7) {
      returnValue = 2;
    }
    if (pid === 8) {
      returnValue = 2.5;
    }
    if (pid === 9) {
      returnValue = 2;
    }
    if (pid === 10) {
      returnValue = 2.5;
    }
    if (pid === 11) {
      returnValue = 3;
    }
    if (pid === 12) {
      returnValue = 2.5;
    }
    if (pid === 13) {
      returnValue = 3;
    }
    if (pid === 14) {
      returnValue = 3.5;
    }
    if (pid === 15) {
      returnValue = 3;
    }
    if (pid === 16) {
      returnValue = 3.5;
    }
    if (pid === 17) {
      returnValue = 4;
    }
    if (pid === 18) {
      returnValue = 3.5;
    }
    if (pid === 19) {
      returnValue = 4;
    }
    if (pid === 20) {
      returnValue = 4.5;
    }
    if (pid === 21) {
      returnValue = 4;
    }
    if (pid === 22) {
      returnValue = 4.5;
    }
    if (pid === 23) {
      returnValue = 5;
    }

    var allYears = Math.round(years / 31536000000);
    var returnAllPeriod = (allYears * 12 * amount * returnValue) / 100;
    var velocity = (60 * returnAllPeriod) / (allYears * 31536000); //KSH por 60 segundos

    if (type === "velocity") {
      return velocity;
    } else {
      return prop * returnAllPeriod;
    }
  }

  function hashTx(txid) {
    return txid
      ? txid.substring(0, 5) + "..." + txid.substring(txid.length - 4)
      : "";
  }

  async function getRewards(pidCkeck, walletCheck) {
    if (user) {
      setOpen(true);
      setMessage("stakingRewardsFree");

      if (window.ethereum) {
        window.ethereum.enable().then((accounts) => {
          const web3 = new Web3(window.ethereum);
          // console.log("web3", web3);
          const FW = new web3.eth.Contract(abiStaking, stakingContract);
          // console.log("accounts", accounts);
          // console.log("FW", FW);

          // console.log("PID selecionado", pidCkeck);

          FW.methods.harvestRewards(parseInt(pidCkeck)).send(
            {
              from: Web3.utils.toChecksumAddress(walletCheck),
              gas: 150000,
              contractAddress: stakingContract,
            },
            function (error, resHarvestRewards) {
              if (!error) {
                addDoc(collection(db, "rewards"), {
                  uid: kahshId || user.uid,
                  wallet: Web3.utils.toChecksumAddress(walletCheck),
                  pid: parseInt(pidCkeck),
                  txid: resHarvestRewards,
                  created: new Date(),
                });

                // console.log("Res harvestRewards", resHarvestRewards);
                setOpen(true);
                setMessage("successRewards");
                setButton(true);
              } else {
                // setOpen(false);
                // console.log("Erro harvestRewards", error);
                // console.log("Erro harvestRewards codigo", error.code);
                if (error.code === 4100) {
                  setOpen(true);
                  setMessage("erroRewards4100");
                  setButton(true);
                }
              }
            }
          );
        });
      } else {
        setMessage("metameskMessage");
        setButton(true);
      }
    } else {
      history.push(
        "/authentication?return=" + location.pathname + "&destination=0"
      );
    }
  }

  const TableHeaderSwap = () => {
    return (
      <thead>
        <tr>
          <th align="left"></th>
          <th align="left">
            {" "}
            <font fontFamily={"Montserrat"}>
              {t("wallet")}
              <button>
                <img src={Chevron} alt="" />
              </button>
            </font>
          </th>
          <th align="left">
            {t("pending")}
            <button>
              <img src={Chevron} alt="" />
            </button>
          </th>
          <th align="left" style={{ minWidth: "190px" }}>
            {t("rewardsPaid")}
            <button>
              <img src={Chevron} alt="" />
            </button>
          </th>
        </tr>
      </thead>
    );
  };

  const TableHeaderStaking = () => {
    return (
      <thead>
        <tr>
          <th align="left"></th>
          <th align="left">
            <font fontFamily={"Montserrat"}>
              Wallet
              <button>
                <img src={Chevron} alt="" />
              </button>
            </font>
          </th>
          <th align="left">
            <font fontFamily={"Montserrat"}>
              PID
              <button>
                <img src={Chevron} alt="" />
              </button>
            </font>
          </th>
          <th align="left">
            <font fontFamily={"Montserrat"}>
              Staking
              {/* {t("availableBalance")} */}
              <button>
                <img src={Chevron} alt="" />
              </button>
            </font>
          </th>
          <th align="left">
            {t("startDate")}
            <button>
              <img src={Chevron} alt="" />
            </button>
          </th>
          <th align="left">
            {t("endDate")}
            <button>
              <img src={Chevron} alt="" />
            </button>
          </th>
          <th align="left">
            {t("pending")}
            <button>
              <img src={Chevron} alt="" />
            </button>
          </th>
          <th align="left">
            {t("rewardsPaid")}
            <button>
              <img src={Chevron} alt="" />
            </button>
          </th>
          <th align="left" style={{ minWidth: "190px" }}>
            {t("transactionHash")}
            <button>
              <img src={Chevron} alt="" />
            </button>
          </th>
        </tr>
      </thead>
    );
  };

  const TableBodySwap = () => {
    return (
      <tbody>
        <tr>
          <>
            <td>
              {" "}
              <font style={{ color: "#000000", marginRight: 50 }}>1.</font>
            </td>
            <td>
              {loadingPageSwap ? (
                <></>
              ) : (
                <img
                  src={KahshIcon}
                  alt=""
                  style={{
                    width: 30,
                    height: 30,
                    marginLeft: 5,
                    marginRight: 18,
                  }}
                />
              )}

              <font style={{ color: "#000000", marginRight: 50 }}>
                {loadingPageSwap ? (
                  <Skeleton width={120} />
                ) : (
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href={`https://bscscan.com/address/${walletSwap}`}
                  >
                    {walletSwap}
                  </a>
                )}
              </font>
            </td>
            <td>
              <div
                style={{
                  width: "70%",
                  textAlign: "right",
                }}
              >
                <font style={{ color: "#000000" }}>
                  {loadingPageSwap ? (
                    <Skeleton width={120} />
                  ) : (
                    getCurrencyFormat(pendingSwap)
                  )}
                </font>
              </div>
            </td>
            <td>
              <div
                style={{
                  width: "60%",
                  textAlign: "right",
                }}
              >
                <font style={{ color: "#000000", marginRight: 30 }}>
                  {loadingPageSwap ? (
                    <Skeleton width={120} />
                  ) : (
                    getCurrencyFormat(rewardsSwap)
                  )}
                </font>
              </div>
            </td>
          </>
        </tr>
      </tbody>
    );
  };

  const TableBodyStaking = () => {
    return (
      <tbody>
        {allBalance.map((item, index) => (
          <tr key={index}>
            <td>
              {validaDate(item.end) ? (
                <s>
                  {" "}
                  <font style={{ color: "#000000", marginRight: 50 }}>
                    {allBalance.length - index + "."}
                  </font>
                </s>
              ) : (
                <font style={{ color: "#000000", marginRight: 50 }}>
                  {allBalance.length - index + "."}
                </font>
              )}
            </td>
            <td>
              <font style={{ color: "#000000", marginRight: 30 }}>
                {loadingPageFirebase ? (
                  <Skeleton width={120} />
                ) : (
                  <>
                    {item.wallet.substring(0, 5)}...
                    {item.wallet.substring(
                      item.wallet.length,
                      item.wallet.length - 4
                    )}
                  </>
                )}
              </font>
            </td>
            <td>
              <font style={{ color: "#000000", marginRight: 30 }}>
                {loadingPageFirebase ? (
                  <Skeleton width={120} />
                ) : (
                  <>{item.pid}</>
                )}
              </font>
            </td>
            <td style={{ display: "flex" }}>
              <div
                style={{
                  display: "flex",
                  // width: "30%",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                {validaDate(item.end) ? (
                  <s>
                    <font style={{ color: "#000000", marginLeft: 50 }}>
                      {loadingPageFirebase ? (
                        <Skeleton width={120} />
                      ) : (
                        getCurrencyFormat(item.staked)
                      )}
                    </font>
                  </s>
                ) : (
                  <font
                    style={{
                      color: "#000000",
                      // marginLeft: 50
                    }}
                  >
                    {loadingPageFirebase ? (
                      <Skeleton width={120} />
                    ) : (
                      getCurrencyFormat(item.staked)
                    )}
                  </font>
                )}
              </div>
            </td>
            <td>
              {validaDate(item.end) ? (
                <s>
                  <font style={{ color: "#000000", marginRight: 30 }}>
                    {loadingPageFirebase ? (
                      <Skeleton width={120} />
                    ) : (
                      <Moment format="YYYY/MM/DD">{item.start}</Moment>
                    )}
                  </font>
                </s>
              ) : (
                <font style={{ color: "#000000", marginRight: 30 }}>
                  {loadingPageFirebase ? (
                    <Skeleton width={120} />
                  ) : (
                    <Moment format="YYYY/MM/DD">{item.start}</Moment>
                  )}
                </font>
              )}
            </td>
            <td>
              {validaDate(item.end) ? (
                <s>
                  <font style={{ color: "#000000", marginRight: 30 }}>
                    {loadingPageFirebase ? (
                      <Skeleton width={120} />
                    ) : (
                      <Moment format="YYYY/MM/DD">{item.end}</Moment>
                    )}
                  </font>
                </s>
              ) : (
                <font style={{ color: "#000000", marginRight: 30 }}>
                  {loadingPageFirebase ? (
                    <Skeleton width={120} />
                  ) : (
                    <Moment format="YYYY/MM/DD">{item.end}</Moment>
                  )}
                </font>
              )}
            </td>
            <td>
              <div
                style={{
                  display: "flex",
                  // width: "60%",
                  alignItems: "center",
                }}
              >
                <font
                  style={{
                    color: "#000000",
                    // marginRight: 30
                  }}
                >
                  {loadingPageFirebase ? (
                    <Skeleton width={120} />
                  ) : validaDate(item.end) ? (
                    <s>0</s>
                  ) : (
                    <>
                      <Button sx={{ marginTop: "-8px" }}>
                        <a
                          href="#"
                          onClick={() => getRewards(item.pid, item.wallet)}
                          style={{ fontSize: "14px" }}
                        >
                          {`${
                            i18n.language.substring(0, 2) == "pt"
                              ? "Resgatar"
                              : "Rescue"
                          }\u00A0`}
                        </a>
                        <span style={{ fontSize: "14px" }}>
                          {getCurrencyFormat(
                            getReturn(
                              item.pid,
                              item.staked,
                              item.start,
                              item.end
                            ) - item.rewards_paid
                          )}
                        </span>
                        {/* {getCurrencyFormat(item.rewards_paid)}  */}
                      </Button>
                    </>
                  )}
                </font>
              </div>
            </td>
            <td>
              <div
                style={{
                  display: "flex",
                  // width: "60%",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <font
                  style={{
                    color: "#000000",
                    // marginRight: 30
                  }}
                >
                  {loadingPageFirebase ? (
                    <Skeleton width={120} />
                  ) : validaDate(item.end) ? (
                    <s>0</s>
                  ) : (
                    <>
                      {item.rewards_paid
                        ? getCurrencyFormat(item.rewards_paid)
                        : getCurrencyFormat(0)}
                    </>
                  )}
                </font>
              </div>
            </td>
            <td>
              {validaDate(item.end) ? (
                <s>
                  <font style={{ color: "#000000", marginRight: 30 }}>
                    {loadingPageFirebase ? (
                      <Skeleton width={120} />
                    ) : (
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href={urlBnbTxid + item.txid}
                      >
                        {hashTx(item.txid)}
                      </a>
                    )}
                  </font>
                </s>
              ) : (
                <font style={{ color: "#000000", marginRight: 30 }}>
                  {loadingPageFirebase ? (
                    <Skeleton width={120} />
                  ) : (
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href={urlBnbTxid + item.txid}
                    >
                      {hashTx(item.txid)}
                    </a>
                  )}
                </font>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    );
  };

  return (
    <>
      {width < 1237 ? (
        <>
          <Grid mb={2}>
            <Navigation />
          </Grid>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <Grid
              className="boxModal"
              sx={{ ...style }}
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Grid
                className="headerModal"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="h2" id="parent-modal-title">
                  {t("Status")}
                </Typography>
              </Grid>
              <Grid pt={2} pb={2}>
                <Typography sx={{ fontSize: "26px" }}>{t(message)}</Typography>
              </Grid>

              {/* btn Modal */}
              {button ? (
                <Grid
                  container
                  mr={"30%"}
                  justifyContent="center"
                  className="boxBtnModalExtrato"
                >
                  <Button onClick={handleClose} className="boxBtnModalButton">
                    {t("ok")}
                  </Button>
                </Grid>
              ) : null}
            </Grid>
          </Modal>
          <Grid
            container
            //height="100vh"
            width="100%"
            justifyContent="center"
            alignItems="center"
            mt={-16}
            mb={6}
            pb={20}
            ml={-2}
            className="extratoContainer"
          >
            <Grid
              item
              container
              xs={12}
              justifyContent="center"
              alignItems="center"
              mt={5}
            >
              {/* Primeiro container com valores e gráfico */}
              <Grid
                pb={3}
                mt={10}
                item
                xs={6}
                display="flex"
                ml={"10%"}
                className="boxValores"
              >
                {/* titulo */}
                <Grid
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                  mt={3}
                >
                  <Grid
                    justifyContent="center"
                    alignItems="center"
                    textAlign="center"
                    mb={2}
                  >
                    <Typography
                      color={"#000000"}
                      fontWeight={600}
                      fontSize={16}
                    >
                      {t("availableBalance")}
                    </Typography>
                  </Grid>

                  {/* valorers */}
                  <Grid>
                    <img
                      src={KahshIcon}
                      alt=""
                      style={{ width: 24, height: 24 }}
                    />
                    <Typography
                      color={"#000000"}
                      fontWeight={600}
                      fontSize={26}
                      mt={-3.8}
                      ml={3}
                    >
                      {loadingPage ? (
                        <Skeleton width={120} />
                      ) : (
                        getCurrencyFormat(balance)
                      )}
                    </Typography>
                  </Grid>

                  {/* gráfico versão mobile */}
                  <Grid
                    mt={1}
                    justifyContent="center"
                    alignItems="center"
                    textAlign="center"
                  >
                    <img
                      src={grafico}
                      alt=""
                      style={{ marginTop: 10, width: 150, height: 60 }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/* Segundo container com valores*/}
              <Grid
                pb={3}
                mt={10}
                item
                xs={6}
                display="flex"
                ml={"10%"}
                className="boxValores"
              >
                <Grid display="flex" flexDirection={"column"} mt={3}>
                  <Grid
                    justifyContent="center"
                    alignItems="center"
                    textAlign="center"
                    mb={2}
                  >
                    <Typography
                      color={"#000000"}
                      fontWeight={600}
                      fontSize={16}
                    >
                      {t("totalLocked")}
                    </Typography>
                  </Grid>
                  <Grid>
                    <img
                      src={KahshIcon}
                      alt=""
                      style={{ width: 24, height: 24 }}
                    ></img>
                    <Typography
                      color={"#000000"}
                      fontWeight={600}
                      fontSize={26}
                      mt={-3.8}
                      ml={3}
                    >
                      {loadingPage ? (
                        <Skeleton width={120} />
                      ) : (
                        getCurrencyFormat(totalPending)
                      )}
                    </Typography>
                  </Grid>
                  <Grid
                    mt={1}
                    justifyContent="center"
                    alignItems="center"
                    textAlign="center"
                  >
                    <Typography color={"#000000"} fontSize={16}>
                      {loadingPage ? (
                        <Skeleton width={140} />
                      ) : (
                        `$${getCurrencyFormat(totalPending * kahshCoin)}`
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              {/* Terceiro container com valores*/}
              <Grid
                pb={3}
                my={10}
                item
                xs={6}
                display="flex"
                ml={"10%"}
                className="boxValores"
              >
                <Grid display="flex" flexDirection={"column"} mt={3}>
                  <Grid
                    justifyContent="center"
                    alignItems="center"
                    textAlign="center"
                    mb={2}
                  >
                    <Typography
                      color={"#000000"}
                      fontWeight={600}
                      fontSize={16}
                    >
                      {t("totalRedeemed")}
                    </Typography>
                  </Grid>
                  <Grid>
                    <img
                      src={KahshIcon}
                      alt=""
                      style={{ width: 24, height: 24 }}
                    ></img>
                    <Typography
                      color={"#000000"}
                      fontWeight={600}
                      fontSize={26}
                      mt={-3.8}
                      ml={3}
                    >
                      {loadingPage ? (
                        <Skeleton width={120} />
                      ) : (
                        getCurrencyFormat(rewards + rewardsSwap)
                      )}
                    </Typography>
                  </Grid>
                  <Grid
                    mt={1}
                    justifyContent="center"
                    alignItems="center"
                    textAlign="center"
                  >
                    <Typography color={"#000000"} fontSize={16}>
                      {loadingPage ? (
                        <Skeleton width={140} />
                      ) : (
                        `$${getCurrencyFormat(
                          (rewards + rewardsSwap) * kahshCoin
                        )}`
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {/* </Grid> */}
            </Grid>

            <Grid container justify="center">
              <Grid
                justifyContent="center"
                alignItems="center"
                flex={1}
                ml={10}
                mb={4}
                mt={-2}
                item
              >
                <>
                  <Grid
                    item
                    container
                    width="100%"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                    ml={-4}
                  >
                    <Typography
                      fontFamily={"Montserrat"}
                      color={"#000000"}
                      fontSize={30}
                      mt={3}
                    >
                      Swap
                    </Typography>
                    <Grid py={3}>
                      <Divider />
                    </Grid>
                    <Grid
                      pb={3}
                      item
                      xs={6}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid display="flex" flexDirection={"column"}>
                        {/* ......Primeiro box...... */}
                        <Grid
                          item
                          container
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Typography className="primeiroTypography" pr={3}>
                            {t("wallet")}
                          </Typography>

                          <img src={KahshIcon} alt="" />
                        </Grid>
                        {/* ............ */}

                        {/* ......Segundo box...... */}
                        <Grid
                          item
                          container
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Typography className="typographyCinza">
                            {loadingPageSwap ? (
                              <Skeleton width={120} />
                            ) : (
                              <a
                                rel="noreferrer"
                                target="_blank"
                                href={`https://bscscan.com/address/${walletSwap}`}
                              >
                                {walletSwap}
                              </a>
                            )}
                          </Typography>
                        </Grid>

                        {/* ......Tercero box...... */}
                        <Grid
                          item
                          container
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Typography className="typographyCinza">
                            {t("pending")}
                          </Typography>
                          <Typography className="terceiroTypography">
                            {loadingPageSwap ? (
                              <Skeleton width={120} />
                            ) : (
                              getCurrencyFormat(pendingSwap)
                            )}
                          </Typography>
                        </Grid>

                        {/* ......Quarto box...... */}
                        <Grid
                          item
                          container
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Typography className="typographyCinza">
                            {t("rewardsPaid")}
                          </Typography>
                          <Typography className="terceiroTypography">
                            {loadingPageSwap ? (
                              <Skeleton width={120} />
                            ) : (
                              getCurrencyFormat(rewardsSwap)
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              </Grid>
            </Grid>

            {/* tabela */}
            <Grid
              item
              container
              pt={5}
              xs={12}
              width="100%"
              justifyContent="center"
              alignItems="center"
              ml="10%"
              flexDirection="column"
            >
              <Typography pr={27} className="history">
                {t("history")}
              </Typography>
            </Grid>
            {allBalance.map((item, index) => (
              //tabelaMobile(item);
              <Grid
                key={item.id}
                item
                container
                xs={12}
                width="100%"
                justifyContent="center"
                alignItems="center"
                ml="10%"
                flexDirection="column"
              >
                <Grid py={3}>
                  <Divider />
                </Grid>
                <Grid
                  pb={3}
                  item
                  xs={6}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid display="flex" flexDirection={"column"}>
                    {/* ......Primeiro box...... */}
                    <Grid
                      item
                      container
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography className="primeiroTypography" pr={3}>
                        Staking
                      </Typography>
                      <Typography className="terceiroTypography" pr={1}>
                        {index + 1 + "."}
                      </Typography>
                      {/* <img src={KahshIcon} alt="" /> */}
                      <Typography pl={2} className="terceiroTypography">
                        {loadingPageFirebase ? (
                          <Skeleton width={120} />
                        ) : validaDate(item.end) ? (
                          <s>
                            <font>{getCurrencyFormat(item.staked)}</font>
                          </s>
                        ) : (
                          getCurrencyFormat(item.staked)
                        )}
                      </Typography>
                    </Grid>

                    {/* ......Segundo box...... */}
                    <Grid
                      item
                      container
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography className="typographyCinza">
                        {t("startDate")}
                      </Typography>
                      <Typography className="terceiroTypography">
                        {loadingPageFirebase ? (
                          <Skeleton width={120} />
                        ) : validaDate(item.end) ? (
                          <s>
                            <font>
                              <Moment format="YYYY/MM/DD">{item.start}</Moment>
                            </font>
                          </s>
                        ) : (
                          <Moment format="YYYY/MM/DD">{item.start}</Moment>
                        )}
                      </Typography>
                    </Grid>

                    {/* ......Tercero box...... */}
                    <Grid
                      item
                      container
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography className="typographyCinza">
                        {t("endDate")}
                      </Typography>
                      <Typography className="terceiroTypography">
                        {loadingPageFirebase ? (
                          <Skeleton width={120} />
                        ) : validaDate(item.end) ? (
                          <s>
                            <font>
                              <Moment format="YYYY/MM/DD">{item.end}</Moment>
                            </font>
                          </s>
                        ) : (
                          <Moment format="YYYY/MM/DD">{item.end}</Moment>
                        )}
                      </Typography>
                    </Grid>

                    {/* ......Quarto box...... */}
                    <Grid
                      item
                      container
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography className="typographyCinza">
                        {t("pending")}
                      </Typography>
                      <Typography className="terceiroTypography">
                        {loadingPageFirebase ? (
                          <Skeleton width={120} />
                        ) : validaDate(item.end) ? (
                          <s>0</s>
                        ) : (
                          <>
                            <Button>
                              <a
                                href="#"
                                onClick={() =>
                                  getRewards(item.pid, item.wallet)
                                }
                              >
                                {`${
                                  i18n.language.substring(0, 2) == "pt"
                                    ? "Resgatar"
                                    : "Rescue"
                                }\u00A0`}
                              </a>
                              {getCurrencyFormat(
                                getReturn(
                                  item.pid,
                                  item.staked,
                                  item.start,
                                  item.end
                                ) - item.rewards_paid
                              )}
                              {/* {getCurrencyFormat(item.rewards_paid)}  */}
                            </Button>
                          </>
                        )}
                      </Typography>
                    </Grid>

                    {/* ......Quinto box...... */}
                    <Grid
                      item
                      container
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography className="typographyCinza">
                        {t("rewardsPaid")}
                      </Typography>
                      <Typography className="terceiroTypography">
                        {loadingPageFirebase ? (
                          <Skeleton width={120} />
                        ) : validaDate(item.end) ? (
                          <s>0</s>
                        ) : (
                          <>
                            {item.rewards_paid
                              ? getCurrencyFormat(item.rewards_paid)
                              : getCurrencyFormat(0)}
                          </>
                        )}
                      </Typography>
                    </Grid>
                    {/* ......Sexto box...... */}
                    <Grid
                      item
                      container
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography className="typographyCinza">
                        {t("transactionHash")}
                      </Typography>
                      <Typography className="terceiroTypography">
                        {loadingPageFirebase ? (
                          <Skeleton width={120} />
                        ) : (
                          <a
                            rel="noreferrer"
                            target="_blank"
                            href={urlBnbTxid + item.txid}
                          >
                            {hashTx(item.txid)}
                          </a>
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ))}
            {/* segundo extrato como exemplo */}
          </Grid>
        </>
      ) : (
        <>
          <Grid>
            <Navigation />
          </Grid>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <Grid
              className="boxModal"
              sx={{ ...style }}
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Grid
                className="headerModal"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="h2" id="parent-modal-title">
                  {t("Status")}
                </Typography>
              </Grid>
              <Grid pt={2} pb={2}>
                <Typography sx={{ fontSize: "26px" }}>{t(message)}</Typography>
              </Grid>

              {/* btn Modal */}
              {button ? (
                <Grid
                  container
                  mr={"30%"}
                  justifyContent="center"
                  className="boxBtnModalExtrato"
                >
                  <Button onClick={handleClose} className="boxBtnModalButton">
                    {t("ok")}
                  </Button>
                </Grid>
              ) : null}
            </Grid>
          </Modal>
          <Grid
            container
            height="100vh"
            width="100%"
            justifyContent="center"
            alignItems="center"
            className="extratoContainer"
          >
            <Grid item container pt={5} width="100%">
              {/* terceior bloco */}
              <Box
                display="flex"
                width="25%"
                height={260}
                pb={3}
                mt={10}
                justifyContent="center"
                alignItems="center"
                borderRadius={10}
                borderColor={"#fafafa"}
                border={2}
                ml={"10%"}
              >
                <Box display="flex" flexDirection={"column"} mt={3}>
                  <Box>
                    <img
                      src={KahshIcon}
                      alt=""
                      style={{ width: 30, height: 30 }}
                    ></img>

                    <Typography
                      fontWeight={600}
                      fontFamily={"Inter"}
                      color={"#000000"}
                      fontSize={18}
                      mt={-3.5}
                      ml={5}
                    >
                      {t("availableBalance")}
                    </Typography>
                  </Box>
                  <Box
                    justifyContent="center"
                    alignItems="center"
                    textAlign="center"
                  >
                    <Typography
                      color={"#000000"}
                      fontWeight={600}
                      fontSize={36}
                      mt={3}
                      fontFamily={"Montserrat"}
                    >
                      {loadingPage ? (
                        <Skeleton width={220} />
                      ) : (
                        getCurrencyFormat(balance)
                      )}
                    </Typography>
                    <img
                      src={grafico}
                      alt=""
                      style={{ marginTop: 10, width: 220, height: 60 }}
                    ></img>
                  </Box>
                </Box>
              </Box>

              <Box
                display="flex"
                width="25%"
                height={260}
                pb={3}
                mt={10}
                justifyContent="center"
                alignItems="center"
                borderRadius={10}
                borderColor={"#fafafa"}
                border={2}
                mx={5}
              >
                <Box display="flex" flexDirection={"column"} mt={3}>
                  <Box
                    justifyContent="center"
                    alignItems="center"
                    textAlign="center"
                    mb={2}
                  >
                    <Typography
                      color={"#000000"}
                      fontWeight={600}
                      fontSize={16}
                      fontFamily={"Montserrat"}
                    >
                      {t("totalLocked")}
                    </Typography>
                  </Box>
                  <Box>
                    <img
                      src={KahshIcon}
                      alt=""
                      style={{ width: 24, height: 24 }}
                    ></img>
                    <Typography
                      color={"#000000"}
                      fontWeight={600}
                      fontSize={26}
                      mt={-3.8}
                      mx={3.5}
                      fontFamily={"Montserrat"}
                    >
                      {loadingPage ? (
                        <Skeleton width={120} />
                      ) : (
                        getCurrencyFormat(totalPending)
                      )}
                    </Typography>
                  </Box>
                  <Box
                    mt={1}
                    justifyContent="center"
                    alignItems="center"
                    textAlign="center"
                  >
                    <Typography
                      fontFamily={"Montserrat"}
                      color={"#000000"}
                      fontSize={16}
                    >
                      {loadingPage ? (
                        <Skeleton width={150} />
                      ) : (
                        `$${getCurrencyFormat(totalPending * kahshCoin)}`
                      )}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Box
                display="flex"
                width="25%"
                height={260}
                pb={3}
                mt={10}
                justifyContent="center"
                alignItems="center"
                borderRadius={10}
                borderColor={"#fafafa"}
                border={2}
              >
                <Box display="flex" flexDirection={"column"} mt={3}>
                  <Box
                    justifyContent="center"
                    alignItems="center"
                    textAlign="center"
                    mb={2}
                  >
                    <Typography
                      color={"#000000"}
                      fontWeight={600}
                      fontSize={16}
                      fontFamily={"Montserrat"}
                    >
                      {t("totalRedeemed")}
                    </Typography>
                  </Box>
                  <Box>
                    <img
                      src={KahshIcon}
                      alt=""
                      style={{ width: 24, height: 24 }}
                    ></img>
                    <Typography
                      color={"#000000"}
                      fontWeight={600}
                      fontSize={26}
                      mt={-3.8}
                      mx={3.5}
                      fontFamily={"Montserrat"}
                    >
                      {loadingPage ? (
                        <Skeleton width={120} />
                      ) : (
                        getCurrencyFormat(rewards + rewardsSwap)
                      )}
                    </Typography>
                  </Box>
                  <Box
                    mt={1}
                    justifyContent="center"
                    alignItems="center"
                    textAlign="center"
                  >
                    <Typography
                      fontFamily={"Montserrat"}
                      color={"#000000"}
                      fontSize={16}
                    >
                      {loadingPage ? (
                        <Skeleton width={150} />
                      ) : (
                        `$${getCurrencyFormat(
                          (rewards + rewardsSwap) * kahshCoin
                        )}`
                      )}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>

            {/* tabela swap */}
            <Box
              display="flex"
              item
              width="100%"
              justifyContent="center"
              alignItems="center"
              mb={3}
              pt={8}
            >
              <Box
                display="flex"
                pb={3}
                justifyContent="center"
                alignItems="center"
                className="CoinsContainer"
                borderRadius={10}
                borderColor={"#fafafa"}
                border={2}
                style={{ width: "85%" }}
              >
                <Grid container justify="center">
                  <Grid item md={10}>
                    <>
                      <Typography
                        fontFamily={"Montserrat"}
                        color={"#000000"}
                        fontSize={30}
                        ml={8}
                        mt={3}
                        mb={-8}
                      >
                        Swap
                      </Typography>
                      <Box ml={8} mr={8} style={{ width: "110%" }}>
                        <font face="Montserrat">
                          <table
                            className="Table"
                            fontWeight="normal !important"
                          >
                            <>
                              <TableHeaderSwap />
                              <TableBodySwap />
                            </>
                          </table>
                        </font>
                      </Box>
                    </>
                  </Grid>
                </Grid>
              </Box>
            </Box>

            {/* tabela staking */}
            <Box
              display="flex"
              item
              width="100%"
              justifyContent="center"
              alignItems="center"
              mb={5}
              pt={5}
              pb={5}
            >
              <Box
                display="flex"
                pb={3}
                justifyContent="center"
                alignItems="center"
                className="CoinsContainer"
                borderRadius={10}
                borderColor={"#fafafa"}
                border={2}
                style={{ width: "85%" }}
              >
                <Grid container justify="center">
                  <Grid item md={10}>
                    <>
                      <Typography
                        fontFamily={"Montserrat"}
                        color={"#000000"}
                        fontSize={30}
                        ml={8}
                        mt={3}
                        mb={-8}
                      >
                        {t("history")}
                      </Typography>
                      <Box ml={8} mr={8} style={{ width: "110%" }}>
                        <font face="Montserrat">
                          <table
                            className="Table"
                            fontWeight="normal !important"
                          >
                            <>
                              <TableHeaderStaking />
                              <TableBodyStaking />
                            </>
                          </table>
                        </font>
                      </Box>
                    </>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </>
      )}
    </>
  );
}
