import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

export const TextFieldStyled = styled(TextField)`
  .MuiFormHelperText-root {
    color: ${({ theme }) => theme.palette.error.main} !important;
  }
  .MuiOutlinedInput-input {
    &:-webkit-autofill {
      border-radius: 15px;
      margin: 2px;
      box-shadow: 0 0 0 100px #fdfdff inset;
    }
  }

  .MuiOutlinedInput-notchedOutline {
          border-color: #B4B4C1;
          background-color: #FDFDFF;
          border-radius: 15px;
          z-index: -1
        }

`;


export const TextFieldSelect = styled(TextFieldStyled)`
.MuiOutlinedInput-notchedOutline {
        border-radius: 20px;
        border:  1.7px solid #4733FF;
      }
`;
