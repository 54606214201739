import { Container, Grid, Typography } from "@mui/material";

import useWindowDimensions from "../../../hooks/useWindowDimensions";

import { useTranslation } from "react-i18next";
import i18n from "i18next";

import backgroundEmUmContratoWeb from "../../../imagens/backgroundEmUmContratoWeb.png"
import backgroundEmUmContratoMobile from "../../../imagens/backgroundEmUmContratoMobile.png"

export const EmUmContrato = (props) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  return (
    <Container maxWidth="xl">

      <Grid container alignItems="center" justifyContent="flex-end">
        {width > 900 ? (
          <>
            {/* imagem usada tamanho web e lg */}
            <img src={backgroundEmUmContratoWeb} width="90%" alt="Imagem de Fundo"/>
          </>
        ) : (
          // imagem usada em tamanho mobile
          <Container maxWidth="md">
            <Grid container alignItems="center" justifyContent="center">
              <img src={backgroundEmUmContratoMobile} width="100%" alt="Imagem de Fundo para tamanho Mobile" />
            </Grid>
          </Container>
        )}

        <Grid
          container
          direction="column"
          position="absolute"
          alignItems="center"
          justifyContent="center"
        >
          <Grid
            pl={{
              xs: i18n.language.substring(0,2) == "pt" ? "9vw" : "18vw",
              md: width <= 900 ? null : "41vw",
              lg: "42vw",
              xl: "63vw"
            }}
            pt={{
              xs: "43rem",
              sm: "75rem", 
              md: width <= 900 ? "70rem" : 0,
              lg: 0,
              xl: 0
            }}
          >
            <Typography
              fontWeight={300}
              color="#232340"
              fontSize={{
                xs: i18n.language.substring(0,2) == "pt" ? "9vw" : "8.7vw",
                md: width <= 900 ? null : "3vw"
              }}
              lineHeight="1.2em"
              dangerouslySetInnerHTML={{
                __html: width > 900 ?
                  t("stakingInContract") :
                  t("stakingInContractMobile")
              }}
            />
            <Typography
              color="#444B58"
              fontWeight={400}
              fontSize={{
                xs: i18n.language.substring(0,2) == "pt" ? "4.5vw" : "4.2vw",
                md: width <= 900 ? null : "1vw"
              }}
              lineHeight="1.5em"
              py={{
                xs: "4rem",
                sm: "7rem",
                md: width <= 900 ? "5rem" : "3rem"
              }}
              dangerouslySetInnerHTML={{
                __html: width > 900 ?
                  t("stakingKeptInYourWallet") :
                  t("stakingKeptInYourWalletMobile")
              }}
            />
            <Typography
              color="#444B58"
              fontWeight={400}
              fontSize={{
                xs: i18n.language.substring(0,2) == "pt" ? "4vw" : "3.5vw",
                md: width <= 900 ? null : "1vw"
              }}
              lineHeight="1.5em"
              dangerouslySetInnerHTML={{
                __html: width > 900 ?
                  t("stakingPeriod") :
                  t("stakingPeriodMobile")
              }}
            />
          </Grid>

        </Grid>
      </Grid>
    </Container>
  );
};
