import { useState, useEffect } from "react";
import Web3 from "web3";
import axios from "axios";
import {
  Grid,
  Typography,
  Box,
  Slider,
  Button,
  InputLabel,
  Modal,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { useParams } from "react-router-dom";
import CurrencyFormat from "react-currency-format";
import { useHistory } from "react-router-dom";
import { BigNumber } from "@0x/utils";
import { useAuthState } from "react-firebase-hooks/auth";
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  getDocs,
  collection,
  addDoc,
  query,
  where,
} from "firebase/firestore";

import { auth } from "../../services/firebase";
import LogoKahshNewPlus from "../../imagens/LogoKahshNewPlus.svg";
import "../StakingPage/kahshPayVejaQuantoBlack/KahshPayVejaQuantoBlack.styles.css";
import "./Calculadora.styles.css";
import {
  abi,
  coinContract,
  stakingContract,
  urlSignContract,
  firebaseConfig,
} from "../../services/global";

const useStyles = makeStyles({
  colorPrimary: {
    color: "#4733FF",
    height: "23px",
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export const Calculadora = (props) => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [button, setButton] = useState(false);
  const [message, setMessage] = useState("");
  const [msg, setMsg] = useState("");
  const [buttonMessage, setbuttonMessage] = useState("ok");
  const handleClose = () => {
    setOpen(false);
    setButton(false);
  };
  const marks = [
    { value: 1000, label: "1.000" },
    { value: 25000, label: "25.000" },
    { value: 50000, label: "50.000" },
    { value: 75000, label: "75.000" },
    { value: 100000, label: "$100.000" },
  ];

  const months = [
    { value: 12, label: "12 meses" },
    { value: 24, label: "24 meses" },
    { value: 36, label: "36 meses" },
  ];

  const { t } = useTranslation();
  const { markGet, monthGet } = useParams();
  const matches = useMediaQuery("(min-width:1437px)");
  const classes = useStyles();
  const [markValue, setMarkValue] = useState(Number(markGet ? markGet : 1000));
  const [monthValue, setMonthValue] = useState(
    Number(monthGet ? monthGet : 12)
  );
  const [pid, setPid] = useState(0);
  const [kahshCoin, setKahshCoin] = useState(0);
  const [metamesk, setMetamesk] = useState(true);
  const [countMetamesk, setCountMetamesk] = useState(0);
  const [returnValue, setReturnValue] = useState(1);
  const location = useLocation();
  const [user] = useAuthState(auth);
  const [markserror, setmarksError] = useState(false);

  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);

  const handleRedirect = () => {
    if (buttonMessage === "goToExtract") {
      window.location.href = "/extrato";
    } else {
      handleClose();
    }
  };

  const checkMarks = () => {
    if (markValue < 1000) {
      setmarksError(true);
      document.body.scrollTo({
        top: document.getElementById("jumpError").offsetTop * 0.8,
        behavior: "smooth",
      });
    } else {
      window.location.href = "/invest/" + markValue + "/" + monthValue;
    }
  };

  async function getContract() {
    setOpen(true);
    setMessage("stakingInProgress");
    if (user) {
      if (window.ethereum) {
        window.ethereum
          .enable()
          .then((accounts) => {
            var d = new Date();
            var millisecondssince1970 = d.getTime();
            var newDate = millisecondssince1970 + 15000 * 60; //15 minutes

            var userAccount = Web3.utils.toChecksumAddress(accounts[0]);

            // console.log("Usuario firebase", user.uid);
            // console.log("Carteira", userAccount);

            var wallet = "";
            const walletsDb = collection(db, "wallets");
            const q = query(
              walletsDb,
              where("uid", "==", user.uid),
              where("wallet", "==", userAccount)
            );
            getDocs(q)
              .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  wallet = doc.data().wallet;
                });

                if (!wallet) {
                  addDoc(collection(db, "wallets"), {
                    uid: user.uid,
                    wallet: userAccount,
                  });
                }
              })
              .catch((error) => {});

            var amount = Math.round(markValue / kahshCoin); //6 KSD * 10ˆ18
            var finalAmount = BigNumber(10 ** 18 * amount).toString(); //Convert to string
            const hourNow = newDate;

            const web3 = new Web3(window.ethereum);
            // console.log("web3", web3);
            const FW = new web3.eth.Contract(abi, coinContract); //Open Contract with coinContract
            // console.log("FW", FW);

            FW.methods
              .permitHash(userAccount, stakingContract, finalAmount, hourNow)
              .call(function (error, resPermitHash) {
                if (!error) {
                  // console.log("Res PermitHash", resPermitHash);

                  web3.eth
                    .sign(resPermitHash, userAccount)
                    .then(
                      //Sign Contract
                      function (resSign, error) {
                        // console.log("Res Sign", resSign);
                        // console.log("PID", pid);

                        axios
                          .post(urlSignContract, {
                            pool: pid, //PID
                            customer: userAccount,
                            amount: finalAmount,
                            deadline: hourNow,
                            signature: resSign,
                          })
                          .then((response) => {
                            if (
                              response.data.msg ===
                              "execution reverted: Contract already active."
                            ) {
                              setMessage("contractAlreadyActive");
                              setButton(true);
                            } else if (
                              response.data.msg.substring(0, 38) ===
                              "Amount too small. Staking Pool Minimum"
                            ) {
                              setMessage("amountTooSmall");
                              setButton(true);
                              // console.log("amountTooSmall", response);
                            } else if (
                              response.data.msg ===
                              "execution reverted: ERC20: transfer amount exceeds balance"
                            ) {
                              setMessage("amountExceeds");
                              setButton(true);
                              // console.log("amountExceeds", response);
                            } else if (response.data.msg === "Network Error") {
                              setMessage("networkError");
                              setButton(true);
                            } else if (
                              response.data.msg &&
                              response.data.success
                            ) {
                              setButton(true);
                              setbuttonMessage("goToExtract");
                              setMessage("stakeSuccess");
                              // console.log("Res API", response);

                              addDoc(collection(db, "txids"), {
                                uid: user.uid,
                                wallet: userAccount,
                                pid: pid,
                                txid: response.data.msg,
                              });
                            } else {
                              setMsg(response.data.msg);
                              setButton(true);
                              // console.log("Algo deu errado", response);
                            }
                          });
                      }
                    )
                    .catch((error) => {
                      if (
                        error.message ===
                        "MetaMask Message Signature: User denied message signature."
                      ) {
                        setMessage("metaMaskDeny");
                        setButton(true);
                      }
                      // console.log(error.message);
                    });
                } else {
                  // console.log("Erro", error.message);
                  if (
                    error.message ===
                    "Returned values aren't valid, did it run Out of Gas? You might also see this error if you are not using the correct ABI for the contract you are retrieving data from, requesting data from a block number that does not exist, or querying a node which is not fully synced."
                  ) {
                    setMessage("gasError");
                    setButton(true);
                  }
                }
              });
          })
          .catch((error) => {
            // console.log(error.message);
            if (
              error.message ===
              `invalid BigNumber string (argument="value", value="Infinity", code=INVALID_ARGUMENT, version=bignumber/5.6.0)`
            ) {
              setMessage("metaMaskError");
              setButton(true);
            }
          });
      } else {
        // console.log("Metamask não instalado");
        setMetamesk(false);
        setMessage("metameskMessage");
        setButton(true);
        setCountMetamesk(countMetamesk + 1);
      }
    } else {
      history.push(
        "/authentication/authentication?return=" +
          location.pathname +
          "&destination=0"
      );
    }
  }

  useEffect(() => {
    // let cancel = false;

    if (markValue <= 10000) {
      if (monthValue === 12) {
        setReturnValue(1);
        setPid(0);
      }

      if (monthValue === 24) {
        setReturnValue(1.25);
        setPid(1);
      }

      if (monthValue === 36) {
        setReturnValue(1.5);
        setPid(2);
      }
    }

    if (markValue > 10000 && markValue <= 50000) {
      if (monthValue === 12) {
        setReturnValue(1.25);
        setPid(3);
      }

      if (monthValue === 24) {
        setReturnValue(1.5);
        setPid(4);
      }

      if (monthValue === 36) {
        setReturnValue(2);
        setPid(5);
      }
    }

    if (markValue > 50000 && markValue <= 200000) {
      if (monthValue === 12) {
        setReturnValue(1.5);
        setPid(6);
      }

      if (monthValue === 24) {
        setReturnValue(2);
        setPid(7);
      }

      if (monthValue === 36) {
        setReturnValue(2.5);
        setPid(8);
      }
    }
    if (markValue > 200000 && markValue <= 500000) {
      if (monthValue === 12) {
        setReturnValue(2);
        setPid(9);
      }

      if (monthValue === 24) {
        setReturnValue(2.5);
        setPid(10);
      }

      if (monthValue === 36) {
        setReturnValue(3);
        setPid(11);
      }
    }
    if (markValue > 500000 && markValue <= 1000000) {
      if (monthValue === 12) {
        setReturnValue(2.5);
        setPid(12);
      }

      if (monthValue === 24) {
        setReturnValue(3);
        setPid(13);
      }

      if (monthValue === 36) {
        setReturnValue(3.5);
        setPid(14);
      }
    }

    if (markValue > 1000000 && markValue <= 2000000) {
      if (monthValue === 12) {
        setReturnValue(3);
        setPid(15);
      }

      if (monthValue === 24) {
        setReturnValue(3.5);
        setPid(16);
      }

      if (monthValue === 36) {
        setReturnValue(4);
        setPid(17);
      }
    }

    if (markValue > 2000000 && markValue <= 3000000) {
      if (monthValue === 12) {
        setReturnValue(3.5);
        setPid(18);
      }

      if (monthValue === 24) {
        setReturnValue(4);
        setPid(19);
      }

      if (monthValue === 36) {
        setReturnValue(4.5);
        setPid(20);
      }
    }

    if (markValue > 3000000) {
      if (monthValue === 12) {
        setReturnValue(4);
        setPid(21);
      }

      if (monthValue === 24) {
        setReturnValue(4.5);
        setPid(22);
      }

      if (monthValue === 36) {
        setReturnValue(5);
        setPid(23);
      }
    }

    if (kahshCoin <= 0) {
      let cancel = false;
      try {
        const fetchData = () => {
          if (cancel) return;
          axios
            .get("https://exchange.kahsh.com/cotacao/USD")
            .then((res) => {
              const json = res.data;
              setKahshCoin(json[2].cotacao);
              return res.data;
            })
            .catch((err) => {
              //Erro
              // console.log("Erro na coleta da cotação", err);
              setKahshCoin(-1);
            });
          return () => {
            cancel = true;
          };
        };
        fetchData();
      } catch (error) {
        // console.log(error);
        return error;
      }
    } else {
      // console.log("Kahsh $", kahshCoin);
    }

    if (countMetamesk === 2) {
      setCountMetamesk(0);
      window.location.reload(false);
    }

    // return () => {
    //   cancel(true);
    // };
  }, [markValue, monthValue, kahshCoin, countMetamesk]);

  function valuetext(value) {
    return `${value}$`;
  }

  return (
    <>
      {!matches ? (
        <Grid
          container
          item
          xs={12}
          id="kahshPayVejaQuanto"
          className="imageBackground"
        >
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <Grid
              className="boxModal"
              sx={{ ...style }}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Grid
                className="headerModal"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="h2" id="parent-modal-title">
                  {t("Status")}
                </Typography>
              </Grid>
              <Grid pt={2} pb={2} justifyContent="center">
                {message ? (
                  <Typography sx={{ fontSize: "26px" }}>
                    {t(message)}
                  </Typography>
                ) : (
                  <Typography sx={{ fontSize: "26px" }}>{msg}</Typography>
                )}
              </Grid>
              {button ? (
                <Grid
                  mr={17.5}
                  container
                  justifyContent="center"
                  className="boxBtnModal"
                >
                  <Button onClick={handleRedirect}>{t(buttonMessage)}</Button>
                </Grid>
              ) : null}
            </Grid>
          </Modal>
          <Grid item container height={1200} xs={12}>
            <Grid
              item
              xs={12}
              display="flex"
              flexDirection="column"
              alignItems="center"
              className="graficContainer graficContainerBlack"
              id="invest"
            >
              {/* Box gostaria de investir */}
              <Grid
                className="howMuchBox"
                display="flex"
                flexDirection="column"
              >
                <Typography>{t("howMuchInvest")}</Typography>
                <Box pt={1}>
                  <Slider
                    aria-label="Custom marks"
                    value={markValue}
                    getAriaValueText={valuetext}
                    step={1000}
                    valueLabelDisplay="auto"
                    min={1000}
                    max={100000}
                    marks={marks}
                    className={classes.colorPrimary}
                    onChange={(e) => {
                      setMarkValue(e.target.value);
                    }}
                  />
                </Box>
                <Box display="flex" alignItems="flex-end">
                  <Box flexDirection="start" className="batValueCalc">
                    <CurrencyFormat
                      value={markValue}
                      displayType={"input"}
                      thousandSeparator={true}
                      decimalSeparator={"."}
                      prefix={"$  "}
                      onChange={(e) => {
                        setmarksError(false);
                        setMarkValue(Number(e.target.value.replace(/\D/g, "")));
                      }}
                    />
                  </Box>
                  <div id="jumpError">
                    {markserror ? (
                      <Typography
                        style={{ fontSize: "15px", color: "red" }}
                        ml={3}
                      >
                        {t("valueError")}
                      </Typography>
                    ) : (
                      ""
                    )}
                  </div>
                </Box>
              </Grid>

              {/* Box período você pretende investir */}
              <Grid pl={10} pt={6} className="howMuchBox">
                <Typography>{t("foWhatPeriod")}</Typography>
                <Box pt={1}>
                  <Slider
                    aria-label="Custom marks"
                    value={monthValue}
                    getAriaValueText={valuetext}
                    step={12}
                    valueLabelDisplay="auto"
                    min={12}
                    max={36}
                    marks={months}
                    className={classes.colorPrimary}
                    onChange={(e) => {
                      setMonthValue(e.target.value);
                    }}
                  />
                </Box>
                <Box flexDirection="start" className="batValueCalc">
                  <Button>
                    {monthValue}
                    {monthValue > 0 ? " meses" : " mês"}
                  </Button>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                pt={5}
                justifyContent="center"
                display="flex"
                className="graficContainerBlack"
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  className="boxImg"
                >
                  <Typography className="typographyImg1">
                    {t("youInvest")}
                  </Typography>
                  {Number(kahshCoin) !== -1 ? (
                    <CurrencyFormat
                      value={(markValue / kahshCoin).toFixed(0)}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalSeparator={"."}
                      renderText={(value) => (
                        <Typography pb={9} className="typographyImg2">
                          KSH {value}
                        </Typography>
                      )}
                    />
                  ) : (
                    <Typography pb={9} className="typographyImg2">
                      {t("noService")}
                    </Typography>
                  )}
                  <Box className="boxGrafic1" />
                  <Typography pt={7.5} className="typographyImg1">
                    {t("totalInvested")}
                  </Typography>
                  <Typography pt={2} className="typographyImg1">
                    {t("return")}
                  </Typography>
                </Box>
                <Box
                  pl={7}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  className="boxImg"
                >
                  <Typography className="typographyImg1">
                    {t("youReceive")}
                  </Typography>
                  {Number(kahshCoin) !== -1 ? (
                    <CurrencyFormat
                      value={(
                        ((markValue / kahshCoin) * monthValue * returnValue) /
                        100
                      ).toFixed(0)}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalSeparator={"."}
                      renderText={(value) => (
                        <Typography className="typographyImg2">
                          KSH {value}
                        </Typography>
                      )}
                    />
                  ) : (
                    <Typography className="typographyImg2">
                      {t("noService")}
                    </Typography>
                  )}
                  <Box pt={9.87} className="boxPai">
                    <Box
                      className="boxGrafic2"
                      display="flex"
                      justifyContent="center"
                    >
                      <img src={LogoKahshNewPlus} className="imgLogo2" alt="" />
                    </Box>
                    <Box className="boxGrafic3"></Box>
                  </Box>
                  {Number(kahshCoin) !== -1 ? (
                    <CurrencyFormat
                      value={(markValue / kahshCoin).toFixed(0)}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalSeparator={"."}
                      renderText={(value) => (
                        <Typography pt={7.5} className="typographyImg1">
                          KSH {value}
                        </Typography>
                      )}
                    />
                  ) : (
                    <Typography pt={7.5} className="typographyImg1">
                      {t("noService")}
                    </Typography>
                  )}
                  <Typography pt={2} className="typographyImg1">
                    {(returnValue * 12).toFixed(2)}%
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                container
                pb={2}
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                className="containerRetorno"
              >
                <hr />
                <Typography className="typographyImg1">
                  {t("returnIn")}
                </Typography>
                {Number(kahshCoin) !== -1 ? (
                  <CurrencyFormat
                    value={(
                      ((markValue / kahshCoin) * monthValue * returnValue) /
                      100
                    ).toFixed(0)}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalSeparator={"."}
                    renderText={(value) => (
                      <Typography className="typographyImg2">
                        KSH {value}
                      </Typography>
                    )}
                  />
                ) : (
                  <Typography className="typographyImg2">
                    {t("noService")}
                  </Typography>
                )}
              </Grid>

              <Grid className="boxButton">
                {Number(kahshCoin) !== -1 ? (
                  location.pathname.substr(1, 6) === "invest" ? (
                    <>
                      <Grid
                        alignItems="center"
                        className="assinarButton"
                        display="flex"
                        justifyContent="center"
                      >
                        {user ? (
                          <Button
                            onClick={() => getContract()}
                            disableRipple
                            className="buttonAssinar"
                          >
                            {t("subscribeNow")}
                          </Button>
                        ) : (
                          <Button
                            href={
                              "/authentication?return=" +
                              location.pathname +
                              "&destination=0"
                            }
                            disableRipple
                            className="buttonAssinar"
                          >
                            {t("connect")}
                          </Button>
                        )}
                      </Grid>
                      {!metamesk ? (
                        <Typography className="metamesk">
                          {t("metameskMessage")}{" "}
                          <a
                            className="linkMetamesk"
                            href="https://metamask.io/download/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {t("install")}
                          </a>
                        </Typography>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <Grid
                      className="assinarButton"
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        className="buttonAssinar"
                        style={{
                          width: "100%",
                          height: "100%",
                          fontFamily: "MontSerrat",
                        }}
                        onClick={checkMarks}
                      >
                        {t("subscribeNow")}
                      </Button>
                    </Grid>
                  )
                ) : (
                  <Button href="#">{t("noService")}</Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          item
          xs={12}
          id="kahshPayVejaQuanto"
          className="imageBackground"
          mx={3.5}
          borderRadius={6}
        >
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <Grid
              className="boxModal"
              sx={{ ...style }}
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Grid
                className="headerModal"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="h2" id="parent-modal-title">
                  {t("Status")}
                </Typography>
              </Grid>
              <Grid pt={2} pb={2}>
                <Typography sx={{ fontSize: "26px" }}>{t(message)}</Typography>
              </Grid>

              {/* btn Modal */}
              {button ? (
                <Grid
                  container
                  mr={"30%"}
                  justifyContent="center"
                  className="boxBtnModal"
                >
                  <Button onClick={handleRedirect}>{t(buttonMessage)}</Button>
                </Grid>
              ) : null}
            </Grid>
          </Modal>
          <Grid
            item
            container
            alignItems="center"
            justifyContent="space-around"
            height={800}
            xs={12}
            id="invest"
          >
            <Grid
              item
              xs={6}
              height={800}
              display="flex"
              flexDirection="column"
              className="graficContainer graficContainerBlack"
            >
              {/* box dos valores */}
              <Box pl={5} className="howMuchBox">
                <Typography>{t("howMuchInvest")}</Typography>

                <Box pt={5.75} width="60%">
                  <Slider
                    aria-label="Custom marks"
                    value={markValue}
                    getAriaValueText={valuetext ? valuetext : markValue}
                    step={1000}
                    valueLabelDisplay="auto"
                    min={1000}
                    max={100000}
                    marks={marks}
                    className={classes.colorPrimary}
                    onChange={(e) => {
                      setMarkValue(e.target.value);
                    }}
                  />
                  <Box
                    mt={-7}
                    mr={-30}
                    display="flex"
                    justifyContent="end"
                    alignItems="end"
                    className="batValueCalc"
                  >
                    <Box
                      mt={-7}
                      mr={0}
                      display="flex"
                      justifyContent="end"
                      alignItems="end"
                      flexDirection=" column"
                      className="batValueCalcWeb"
                    >
                      <InputLabel
                        id="jumpError"
                        style={{
                          marginRight: markserror ? 0 : null,
                          color: markserror ? "red" : "",
                        }}
                      >
                        {t(!markserror ? "enterValue" : "valueError")}
                      </InputLabel>
                      <CurrencyFormat
                        value={markValue}
                        displayType={"input"}
                        thousandSeparator={true}
                        decimalSeparator={"."}
                        prefix={"$  "}
                        onChange={(e) => {
                          setmarksError(false);
                          setMarkValue(
                            Number(e.target.value.replace(/\D/g, ""))
                          );
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>

              {/* box do mês */}
              <Box pl={5} pt={15} className="howMuchBox">
                <Typography>{t("foWhatPeriod")}</Typography>
                <Box pt={5.75} width="60%">
                  <Slider
                    aria-label="Custom marks"
                    value={monthValue}
                    getAriaValueText={valuetext}
                    step={12}
                    valueLabelDisplay="auto"
                    min={12}
                    max={36}
                    marks={months}
                    className={classes.colorPrimary}
                    onChange={(e) => {
                      setMonthValue(e.target.value);
                    }}
                  />
                  <Box
                    mt={-7}
                    mr={-30}
                    display="flex"
                    justifyContent="end"
                    className="batValueCalc"
                  >
                    <Button>
                      {monthValue}
                      {monthValue > 0 ? " meses" : " mês"}
                    </Button>
                  </Box>
                </Box>
              </Box>

              {/* último box */}
              <Box pt={15} className="howMuchBox">
                <Grid display="flex" justifyContent="space-around">
                  <Grid>
                    <Typography pb={5.3}>
                      {t("thisIsTheIncomeYouCan1")}
                      <br />
                      {t("thisIsTheIncomeYouCan2")}
                      <br />
                      {t("thisIsTheIncomeYouCan3")}
                    </Typography>
                  </Grid>
                  <Grid className="batValueCalc">
                    <Button>{returnValue.toFixed(2)}%</Button>
                  </Grid>
                </Grid>
                <Grid className="boxButton" pl={4.5}>
                  {Number(kahshCoin) !== -1 ? (
                    location.pathname.substr(1, 6) === "invest" ? (
                      <>
                        <Grid
                          alignItems="center"
                          className="assinarButton"
                          display="flex"
                          justifyContent="center"
                        >
                          {user ? (
                            <Button
                              onClick={() => getContract()}
                              disableRipple
                              className="buttonAssinar"
                            >
                              {t("subscribeNow")}
                            </Button>
                          ) : (
                            <Button
                              href={
                                "/authentication?return=" +
                                location.pathname +
                                "&destination=0"
                              }
                              disableRipple
                              className="buttonAssinar"
                            >
                              {t("connect")}
                            </Button>
                          )}
                        </Grid>
                        {!metamesk ? (
                          <Typography className="metamesk">
                            {t("metameskMessage")}{" "}
                            <a
                              className="linkMetamesk"
                              href="https://metamask.io/download/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              {t("install")}
                            </a>
                          </Typography>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <Grid
                        className="assinarButton"
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          className="buttonAssinar"
                          style={{
                            width: "100%",
                            height: "100%",
                            fontFamily: "MontSerrat",
                          }}
                          onClick={checkMarks}
                        >
                          {t("subscribeNow")}
                        </Button>
                      </Grid>
                    )
                  ) : (
                    <Button href="#">{t("noService")}</Button>
                  )}
                </Grid>
              </Box>
            </Grid>

            {/* lado direito */}
            <Grid
              item
              xs={5}
              ml={5.5}
              height={800}
              justifyContent="center"
              display="flex"
              className="graficContainerBlack"
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                className="boxImg"
              >
                <Typography className="typographyImg1">
                  {t("youInvest")}
                </Typography>
                {Number(kahshCoin) !== -1 ? (
                  <CurrencyFormat
                    value={(markValue / kahshCoin).toFixed(0)}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalSeparator={"."}
                    renderText={(value) => (
                      <Typography pb={9} className="typographyImg2">
                        KSH {value}
                      </Typography>
                    )}
                  />
                ) : (
                  <Typography pb={9} className="typographyImg2">
                    {t("noService")}
                  </Typography>
                )}
                <Box className="boxGrafic1" />
                <Typography pt={7.5} className="typographyImg1">
                  {t("totalInvested")}
                </Typography>
                <Typography pt={2} className="typographyImg1">
                  {t("return")}
                </Typography>
              </Box>
              <Box
                pl={7}
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                className="boxImg"
              >
                <Typography className="typographyImg1">
                  {t("youReceive")}
                </Typography>
                {Number(kahshCoin) !== -1 ? (
                  <CurrencyFormat
                    value={(
                      ((markValue / kahshCoin) * monthValue * returnValue) /
                      100
                    ).toFixed(0)}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalSeparator={"."}
                    renderText={(value) => (
                      <Typography className="typographyImg2">
                        KSH {value}
                      </Typography>
                    )}
                  />
                ) : (
                  <Typography className="typographyImg2">
                    {t("noService")}
                  </Typography>
                )}
                <Box pt={9.87} className="boxPai">
                  <Box
                    className="boxGrafic2"
                    display="flex"
                    justifyContent="center"
                  >
                    <img src={LogoKahshNewPlus} className="imgLogo2" alt="" />
                  </Box>
                  <Box className="boxGrafic3"></Box>
                </Box>
                {Number(kahshCoin) !== -1 ? (
                  <CurrencyFormat
                    value={(markValue / kahshCoin).toFixed(0)}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalSeparator={"."}
                    renderText={(value) => (
                      <Typography pt={7.5} className="typographyImg1">
                        KSH {value}
                      </Typography>
                    )}
                  />
                ) : (
                  <Typography pt={7.5} className="typographyImg1">
                    {t("noService")}
                  </Typography>
                )}
                <Typography pt={2} className="typographyImg1">
                  {(returnValue * 12).toFixed(2)}%
                </Typography>
                <Box
                  className="boxRetorno"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                >
                  <hr />
                  <Typography className="typographyImg1">
                    {t("returnIn")}
                  </Typography>
                  {Number(kahshCoin) !== -1 ? (
                    <CurrencyFormat
                      value={(
                        ((markValue / kahshCoin) * monthValue * returnValue) /
                        100
                      ).toFixed(0)}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalSeparator={"."}
                      renderText={(value) => (
                        <Typography className="typographyImg2">
                          KSH {value}
                        </Typography>
                      )}
                    />
                  ) : (
                    <Typography className="typographyImg2">
                      {t("noService")}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};
