import { Container, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import SmoothScroll from "smooth-scroll";

import { HomeAboutKahsh } from "../components/kahshHome/homeAboutKahsh/HomeAboutKahsh";
import { HomeMakeUniverse } from "../components/kahshHome/homeMakeUniverse/HomeMakeUniverse";
import { HomeUniverse } from "../components/kahshHome/homeUniverse/HomeUniverse";
import { HomeVideoKahsh } from "../components/kahshHome/homeVideoKahsh/HomeVideoKahsh";
import { Navigation } from "../components/navigation/Navigation";
import { Footer } from "../components/footer/Footer2";
import { HomeEcossistema } from "../components/kahshHome/homeEcossistema/HomeEcossistema";
import { HomeWhyKahsh } from "../components/kahshHome/homeWhyKahsh/HomeWhyKahsh";
import JsonData from "../data/data.json";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

export function Home() {
  const [landingPageData, setLandingPageData] = useState({});

  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <Grid container>
      <Navigation />
      <Container maxWidth="xl" disableGutters>
        <HomeUniverse data={landingPageData.Monetize} />
        <HomeVideoKahsh data={landingPageData.HomeVideoKahsh} />
        <HomeAboutKahsh data={landingPageData.HomeAboutKahsh} />
        <HomeWhyKahsh data={landingPageData.HomeWhyKahsh} />
        <HomeEcossistema data={landingPageData.HomeEcossistema} />
        <HomeMakeUniverse data={landingPageData.HomeMakeUniverse} />
      </Container>
      <Footer />
    </Grid>
  );
}
