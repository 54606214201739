import { useState, useEffect } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { RegisterStepBase } from '../../components/kahshCard/registerStepsBase/RegisterStepBase';
import CardRegisterAddress from '../../imagens/cardRegisterAddress.png';
import { TextField } from '../../components/inputs/text-field/text-field.component';
import { TextFieldMask } from '../../components/inputs/text-field-mask/text-field-mask.component';
import { Button } from '../../components/Button/Button';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { GooglePlacesAutocomplete } from '../../components/inputs/googlePlacesAutocomplete/GooglePlacesAutocomplete';

const validator = yup.object().shape({
  pais: yup.string().required('countryRequired'),
  estado: yup.string().required('regionRequired'),
  cidade: yup.string().required('cityRequired'),
  bairro: yup.string().required('districtRequired'),
  cep: yup
    .string()
    .required('cepRequired')
    .transform(document => document.replace(/\D+/g, ''))
    .min(8, 'cepInvalid'),
  endereco: yup.string().required('addressAutoCompleteInvalid'),
  numero: yup.string().required('numeroRequired')
});

interface KahshCardRegisterStepsProps {
  onSubmit: (stepData: FieldValues) => void;
  onBack: () => void;
  defaultValues: FieldValues | null;
  loading?: boolean;
}

export const Address: React.FC<KahshCardRegisterStepsProps> = ({
  onSubmit,
  onBack,
  defaultValues,
  loading = false
}) => {
  const [place, setPlace] = useState<FieldValues | null>(defaultValues);

  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validator)
  });

  const handleNextStep = (data: FieldValues) => {
    onSubmit(data);
  };

  const resetAddress = () => {
    setValue('pais', '');
    setValue('endereco', '');
    setValue('numero', '');
    setValue('cep', '');
    setValue('estado', '');
    setValue('cidade', '');
    setValue('bairro', '');
  };

  useEffect(() => {
    if (place?.country) {
      setValue('pais', place?.country);
    }
    if (place?.address1) {
      setValue('endereco', place?.address1);
    }
    if (place?.addressNumber) {
      setValue('numero', place?.addressNumber);
    }
    if (place?.zipcode) {
      setValue('cep', place?.zipcode);
    }
    if (place?.region) {
      setValue('estado', place?.region);
    }
    if (place?.city) {
      setValue('cidade', place?.city);
    }
    if (place?.district) {
      setValue('bairro', place?.district);
    }
  }, [place, setValue]);

  return (
    <RegisterStepBase
      title="Kahsh Card Visa"
      subtitle="getNow"
      image={CardRegisterAddress}
      content={
        <>
          <GooglePlacesAutocomplete
            autoFocus
            place={place}
            setPlace={selectedPlace => {
              resetAddress();
              setPlace(selectedPlace);
            }}
            placeholder={t('address')}
            error={errors?.endereco?.message}
            required
          />
          {place && (
            <>
              <TextField
                label={t('country')}
                control={control}
                helperText={errors?.pais?.message}
                name="pais"
                size="small"
                required
              />

              <Box
                display="flex"
                alignItems="center"
                flexDirection={{ md: 'row', xs: 'column' }}
                gap={{ md: 2, xs: 4 }}
              >
                <TextField
                  label={t('number')}
                  control={control}
                  name="numero"
                  required
                  helperText={errors?.numero?.message}
                  size="small"
                />
                <TextFieldMask
                  mask={'99.999-999'}
                  label="zipCode"
                  control={control}
                  helperText={errors?.cep?.message}
                  name="cep"
                  required
                  size="small"
                />
              </Box>
              <Box
                display="flex"
                alignItems="center"
                flexDirection={{ md: 'row', xs: 'column' }}
                gap={{ md: 2, xs: 4 }}
              >
                <TextField
                  label={t('region')}
                  control={control}
                  helperText={errors?.estado?.message}
                  name="estado"
                  required
                  size="small"
                />
                <TextField
                  label={t('city')}
                  control={control}
                  helperText={errors?.cidade?.message}
                  name="cidade"
                  required
                  size="small"
                />
              </Box>
              <Box
                display="flex"
                alignItems="center"
                gap={{ md: 2, xs: 4 }}
                flexDirection={{ md: 'row', xs: 'column' }}
              >
                <TextField
                  label={t('district')}
                  control={control}
                  helperText={errors?.bairro?.message}
                  name="bairro"
                  required
                  size="small"
                />
                <TextField
                  label={t('address2')}
                  control={control}
                  helperText={errors?.complemento?.message}
                  name="complemento"
                  size="small"
                />
              </Box>
            </>
          )}
          <Box
            display="flex"
            flexDirection={{ md: 'row', xs: 'column-reverse' }}
            justifyContent="flex-end"
            alignItems="center"
            gap={2}
          >
            <Button fullWidth onClick={onBack} variant="outlined">
              {t('back')}
            </Button>
            <Button
              loading={loading}
              fullWidth
              onClick={handleSubmit(handleNextStep)}
            >
              {t('register')}
            </Button>
          </Box>
        </>
      }
    />
  );
};
