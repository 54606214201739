import Blue from "../../../imagens/BlueCard.png";
import Purple from "../../../imagens/PurpleCard.png";
import Silver from "../../../imagens/SilverCard.png";
import Gold from "../../../imagens/GoldCard.png";
import Platinum from "../../../imagens/PlatinumCard.png";
import Infinite from "../../../imagens/InfinitiCard.png";

export const Cards = [
  {
    id: 0,
    img: Blue,
    text: "BLUE",
    color: "#4A53A1",
  },
  {
    id: 1,
    img: Purple,
    text: "PURPLE",
    color: "#7A1790",
  },
  {
    id: 2,
    img: Silver,
    text: "SILVER",
    color: "#7C8086",
  },
  {
    id: 3,
    img: Gold,
    text: "GOLD",
    color: "#C8AB2E",
  },
  {
    id: 4,
    img: Platinum,
    text: "PLATINUM",
    color: "#535253",
  },
  {
    id: 5,
    img: Infinite,
    text: "INFINITE",
    color: "#0A0A0A"
  },
];
