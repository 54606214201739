import { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  InputLabel,
  Button,
  IconButton,
  InputBase,
  Paper,
} from "@mui/material";

import PropTypes from "prop-types";

import axios from "axios";

import { useHistory } from "react-router-dom";

import { useAuthState } from "react-firebase-hooks/auth";

import CurrencyFormat from "react-currency-format";

import { useTranslation } from "react-i18next";
import { LogoAndTranslate } from "../logoAndTranslate/LogoAndTranslate";

import { urlPix, urlPixReturn, urlAuthExchange } from "../../services/global";

import {
  auth,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
} from "../../services/firebase";

import PessoasSegurandoKahsh from "../../imagens/PessoasSegurandoKahsh.svg";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import useWindowDimensions from "../../hooks/useWindowDimensions";
import useSharedLink from "../../hooks/useSharedLink";

import "./FastBuy.styles.css";

export const FastBuySuccessfulPayment = (props) => {
  const history = useHistory();

  useSharedLink();
  const { t } = useTranslation();

  const { width } = useWindowDimensions();

  const [kahshCoin, setKahshCoin] = useState(0);
  const [inputValue, setInputValue] = useState();
  const [register, setRegister] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [txid, setTxid] = useState("");
  const [timeLimit, setTimeLimit] = useState(null);

  const [username, setUsername] = useState("");
  const [passwordMatch, setpasswordMatch] = useState(false);
  const [loginTrigger] = useState(false);
  const [user] = useAuthState(auth);
  const [userRegister, setUserRegister] = useState(false);
  const [password, setPassword] = useState("");
  const [qrcode, setQrcode] = useState("");

  const [setresponseModal] = useState(false);
  const [seterrorMessage] = useState("");
  const [setloginError] = useState("");

  const [cpfCnpj, setCpfCnpj] = useState("");
  const [mask, setMask] = useState("");

  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });

  const handleChange = (props) => (e) => {
    setValues({ ...values, [props]: e.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (kahshCoin <= 0) {
      const fetchData = async () => {
        const data = await fetch("https://exchange.kahsh.com/cotacao");
        const json = await data.json();

        setKahshCoin(json[2].cotacao);
      };

      fetchData().catch((err) => {
        //Erro
        // console.log("Erro na coleta da cotação", err);
        setKahshCoin(-1);
      });
    } else {
      // console.log("Kahsh R$", kahshCoin);
    }
  }, [kahshCoin]);

  useEffect(() => {
    const interval = setInterval(() => {
      consultPix();
    }, 15000);
    return () => clearInterval(interval);
  }, []);

  function consultPix() {
    axios
      .post(
        urlPixReturn,
        {
          txid: "KH1150354054f7e05f91b282dd94eeb6f1",
        },
        {
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        // console.log("resposta", response);
        if (response.data.statusTransacao == "Pendente") {
          // console.log("Pendente");
        }

        if (response.data.statusTransacao == "Pago") {
          // console.log("Pago");
        }

        if (response.data.statusTransacao == "Cancelado") {
          // console.log("Cancelado");
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  const handleClickShowRegister = () => {
    setRegister(true);
  };

  const handleBuy = async () => {
    try {
      await logInWithEmailAndPassword(username, password);
      axios
        .post(
          urlAuthExchange,
          {
            authorizationToken: user.accessToken,
          },
          {
            headers: {
              "Content-Type": "application/json;charset=utf-8",
              "Access-Control-Allow-Origin": "*",
            },
          }
        )
        .then((response) => {
          axios
            .post(
              urlPix,
              {
                tokenExchange: response.data.tokenExchange,
                quantidade: inputValue.toString(),
                moeda: "KSH",
              },
              {
                headers: {
                  "Content-Type": "application/json;charset=utf-8",
                  "Access-Control-Allow-Origin": "*",
                },
              }
            )
            .then((response) => {
              var dt = new Date();
              dt.setMinutes(dt.getMinutes() + 15);
              localStorage.setItem("time_limit", dt);
              setTimeLimit(dt);
              localStorage.setItem("txid", "teste");
              setTxid("teste");

              setQrcode(response.data.message.qrcode);
            })
            .catch((error) => {
              return error;
            });
        })
        .catch((error) => {
          return error;
        });
    } catch (error) {
      // setresponseModal(true);
      if (error.message == "Firebase: Error (auth/invalid-email).") {
        alert("invalidEmailError");
        // setloginError("invalidEmailError");
      } else if (error.message == "Firebase: Error (auth/wrong-password).") {
        // setloginError("wrongPasswordError");
        alert("wrongPasswordError");
      } else if (
        error.message ==
        "Firebase: Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later. (auth/too-many-requests)."
      ) {
        // setloginError("accountDisabled");
        alert("accountDisabled");
      } else if (error.message == "Firebase: Error (auth/user-not-found).") {
        // seterrorMessage("userNotFound");
        alert("userNotFound");
      }
    }
  };

  useEffect(() => {
    if (user) {
      if (window.location.href.split("/authentication")[1] === "")
        history.push("/");
      if (!loginTrigger) return;
      //   if (!responseModal) history.push(query.get("return"));
    }
  }, [user, history, loginTrigger]);

  useEffect(() => {
    checkPwEquals();
  }, [password, confirmPassword]);

  const checkPwEquals = () => {
    if (
      password === confirmPassword &&
      password.length > 0 &&
      confirmPassword.length > 0
    ) {
      setpasswordMatch(true);
    } else {
      setpasswordMatch(false);
    }
  };

  const handleRegister = async (username, password) => {
    setloginError("");
    try {
      var res = await registerWithEmailAndPassword(username, password);
      if (res.name !== "FirebaseError") {
        setUserRegister(true);
        setresponseModal(true);
      }
    } catch (error) {
      setresponseModal(true);
      if (error.message == "Firebase: Error (auth/email-already-in-use).") {
        seterrorMessage("emailInUseError");
      } else if (
        error.message ==
        "Firebase: Password should be at least 6 characters (auth/weak-password)."
      ) {
        seterrorMessage("weakPasswordError");
      } else if (error.message == "Firebase: Error (auth/invalid-email).") {
        seterrorMessage("invalidEmailError");
      }
      return error;
    }
  };

  function formatValues(number) {
    number = number.replaceAll(".", "");
    number = number.replaceAll(",", ".");
    number = Number(number);
    setInputValue(number);

    return number;
  }

  return (
    <>
      {width < 1140 && width > 720 ? (
        <>
          <LogoAndTranslate />
          <Grid
            pt={5}
            display="flex"
            justifyContent="space-around"
            alignItems="center"
            id="fastBuy"
          >
            {/* container com titulo e imagem */}
            <Grid
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              className="fastBuyContainerTitle"
            >
              <Typography pb={6.87}>
                {t("buy")}
                <br />
                <span>{t("kahshNow")}</span>
              </Typography>
              <img className="" src={PessoasSegurandoKahsh} alt="" />
            </Grid>

            {/* container com inputs e QRCode*/}
            <Grid className="fastBuyContainerInputs">
              {/* box com os valores e Qrcode */}
              <Grid
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                className="fastBuyContainerBuySuccessfulPayment1"
              >
                {/* box com os valores */}
                <Grid
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  className="fastBuyContainerBuySuccessfulPayment"
                >
                  {/* Valor em R$ */}
                  <Grid pl={2.3}>
                    <InputLabel>{t("valueIn")}</InputLabel>
                    <Grid>
                      <CurrencyFormat
                        style={{
                          paddingLeft: "12px",
                          fontFamily: "Montserrat",
                          fontWeight: 600,
                          color: "black",
                        }}
                        value={inputValue}
                        placeholder={0}
                        thousandSpacing={3}
                        thousandSeparator="."
                        decimalSeparator=","
                        decimalScale={2}
                        onChange={(e) => {
                          formatValues(e.target.value);
                        }}
                      />
                    </Grid>
                  </Grid>

                  {/* Quantidade em KSH */}
                  <Grid pl={2.3} pt={3}>
                    <InputLabel>{t("AmountInKSH")}</InputLabel>
                    <Grid>
                      <CurrencyFormat
                        style={{
                          paddingLeft: "12px",
                          fontFamily: "Montserrat",
                          fontWeight: 600,
                          color: "black",
                        }}
                        placeholder={0}
                        disabled
                        value={
                          inputValue / kahshCoin > 0
                            ? inputValue / kahshCoin
                            : 0
                        }
                        thousandSeparator="."
                        decimalSeparator=","
                        decimalScale={2}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {/* box  Qrcode */}
                <Grid
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                >
                  <Grid className="containerExtra">
                    <Grid
                      xs={12}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      flexDirection="column"
                    >
                      <CheckCircleIcon />
                      <Typography pt={1} className="pagamentoefetuado">
                        {t("PaymentSuccessfully")}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography pt={2} className="ovalorque">
                    {t("theValueYou")}
                  </Typography>
                  <Typography pt={1} className="receberaem">
                    {t("willReceiveinKSH")}
                  </Typography>
                  <Typography pt={1} pb={2} className="valorrecebidoem">
                    {t("56.53")}
                    <span>KSH</span>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : width < 720 ? (
        <>
          <Grid
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            id="fastBuy"
          >
            <Grid width="100%" display="flex" justifyContent="space-betwen">
              <LogoAndTranslate />
            </Grid>
            <Grid
              pt={5}
              width="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              {/* container com titulo e imagem */}
              <Grid
                pb={5}
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                className="fastBuyContainerTitle"
              >
                <Typography pb={6.87}>
                  {t("buy")}
                  <br />
                  <span>{t("kahshNow")}</span>
                </Typography>
                <img className="" src={PessoasSegurandoKahsh} alt="" />
              </Grid>

              <Grid
                pt={5}
                display="flex"
                justifyContent="space-around"
                alignItems="center"
                id="fastBuy"
              >
                {/* container com titulo e imagem */}
                <Grid
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  className="fastBuyContainerTitle"
                >
                  <Typography pb={6.87}>
                    {t("buy")}
                    <br />
                    <span>{t("kahshNow")}</span>
                  </Typography>
                  <img className="" src={PessoasSegurandoKahsh} alt="" />
                </Grid>

                {/* container com inputs e QRCode*/}
                <Grid className="fastBuyContainerInputs">
                  {/* box com os valores e Qrcode */}
                  <Grid
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    className="fastBuyContainerBuySuccessfulPayment1"
                  >
                    {/* box com os valores */}
                    <Grid
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      flexDirection="column"
                      className="fastBuyContainerBuySuccessfulPayment"
                    >
                      {/* Valor em R$ */}
                      <Grid pl={2.3}>
                        <InputLabel>{t("valueIn")}</InputLabel>
                        <Grid>
                          <CurrencyFormat
                            style={{
                              paddingLeft: "12px",
                              fontFamily: "Montserrat",
                              fontWeight: 600,
                              color: "black",
                            }}
                            value={inputValue}
                            placeholder={0}
                            thousandSpacing={3}
                            thousandSeparator="."
                            decimalSeparator=","
                            decimalScale={2}
                            onChange={(e) => {
                              formatValues(e.target.value);
                            }}
                          />
                        </Grid>
                      </Grid>

                      {/* Quantidade em KSH */}
                      <Grid pl={2.3} pt={3}>
                        <InputLabel>{t("AmountInKSH")}</InputLabel>
                        <Grid>
                          <CurrencyFormat
                            style={{
                              paddingLeft: "12px",
                              fontFamily: "Montserrat",
                              fontWeight: 600,
                              color: "black",
                            }}
                            placeholder={0}
                            disabled
                            value={
                              inputValue / kahshCoin > 0
                                ? inputValue / kahshCoin
                                : 0
                            }
                            thousandSeparator="."
                            decimalSeparator=","
                            decimalScale={2}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* box  Qrcode */}
                    <Grid
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      flexDirection="column"
                    >
                      <Grid className="containerExtra">
                        <Grid
                          xs={12}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          flexDirection="column"
                        >
                          <CheckCircleIcon />
                          <Typography pt={1} className="pagamentoefetuado">
                            {t("PaymentSuccessfully")}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Typography pt={2} className="ovalorque">
                        {t("theValueYou")}
                      </Typography>
                      <Typography pt={1} className="receberaem">
                        {t("willReceiveinKSH")}
                      </Typography>
                      <Typography pt={1} pb={2} className="valorrecebidoem">
                        {t("56.53")}
                        <span>KSH</span>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <LogoAndTranslate />
          <Grid
            pt={5}
            display="flex"
            justifyContent="space-around"
            alignItems="center"
            id="fastBuy"
          >
            {/* container com titulo e imagem */}
            <Grid
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              className="fastBuyContainerTitle"
            >
              <Typography pb={6.87}>
                {t("buy")}
                <br />
                <span>{t("kahshNow")}</span>
              </Typography>
              <img className="" src={PessoasSegurandoKahsh} alt="" />
            </Grid>

            {/* container com inputs e QRCode*/}
            <Grid className="fastBuyContainerInputs">
              {/* box com os valores e Qrcode */}
              <Grid
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                className="fastBuyContainerBuySuccessfulPayment1"
              >
                {/* box com os valores */}
                <Grid
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  className="fastBuyContainerBuySuccessfulPayment"
                >
                  {/* Valor em R$ */}
                  <Grid pl={2.3}>
                    <InputLabel>{t("valueIn")}</InputLabel>
                    <Grid>
                      <CurrencyFormat
                        style={{
                          paddingLeft: "12px",
                          fontFamily: "Montserrat",
                          fontWeight: 600,
                          color: "black",
                        }}
                        value={inputValue}
                        placeholder={0}
                        thousandSpacing={3}
                        thousandSeparator="."
                        decimalSeparator=","
                        decimalScale={2}
                        onChange={(e) => {
                          formatValues(e.target.value);
                        }}
                      />
                    </Grid>
                  </Grid>

                  {/* Quantidade em KSH */}
                  <Grid pl={2.3} pt={3}>
                    <InputLabel>{t("AmountInKSH")}</InputLabel>
                    <Grid>
                      <CurrencyFormat
                        style={{
                          paddingLeft: "12px",
                          fontFamily: "Montserrat",
                          fontWeight: 600,
                          color: "black",
                        }}
                        placeholder={0}
                        disabled
                        value={
                          inputValue / kahshCoin > 0
                            ? inputValue / kahshCoin
                            : 0
                        }
                        thousandSeparator="."
                        decimalSeparator=","
                        decimalScale={2}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {/* box  Qrcode */}
                <Grid
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                >
                  <Grid className="containerExtra">
                    <Grid
                      xs={12}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      flexDirection="column"
                    >
                      <CheckCircleIcon />
                      <Typography pt={1} className="pagamentoefetuado">
                        {t("PaymentSuccessfully")}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography pt={2} className="ovalorque">
                    {t("theValueYou")}
                  </Typography>
                  <Typography pt={1} className="receberaem">
                    {t("willReceiveinKSH")}
                  </Typography>
                  <Typography pt={1} pb={2} className="valorrecebidoem">
                    {t("56.53")}
                    <span>KSH</span>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

FastBuySuccessfulPayment.propTypes = {
  password: PropTypes.string,
  showPassword: PropTypes.bool,
};
