import { Grid, Typography, Box, Container } from "@mui/material";
import { useTranslation } from "react-i18next";

import kahshPayVideo from "../../../imagens/kahshPayVideo.png";
import fundoMobile from "../../../imagens/fundoMobile.png";

import useWindowDimensions from "../../../hooks/useWindowDimensions";

import "./KahshPayVideo.styles.css";

export const KahshPayVideo = () => {
  const { t } = useTranslation();

  const { width } = useWindowDimensions();

  return (
    <>
      {width > 900 ? (
        <Container>
          <Grid
            container
            item
            justifyContent="center"
            alignItems="center"
            mb="5rem"
          >
            <Grid
              className="kahsh-title-container"
              container
              mb={4}
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                display="inline"
                fontSize={60}
                fontWeight={600}
                color={"#232340"}
                className="kahsh-title"
                lineHeight={"32px"}
              >
                <h1>
                  {t("meetThe")} <b>Kahsh</b>Pay
                </h1>
              </Typography>
            </Grid>
            <Grid className="kahsh-video-container" container direction="row">
              <Grid
                className="video"
                container
                justifyContent="center"
                alignItems="center"
                mt={15}
                ml={-2}
              >
                <Grid>
                  <iframe
                    src="https://player.vimeo.com/video/703991107?autoplay=false"
                    frameBorder="0"
                    allow="fullscreen; picture-in-picture"
                    allowFullScreen
                    title=" "
                  ></iframe>
                </Grid>
              </Grid>
            </Grid>
            <Grid container justifyContent="center" alignItems="center">
              <img src={kahshPayVideo} width="90%" />
            </Grid>
          </Grid>
        </Container>
      ) : (
        <Grid
          container
          item
          justifyContent="center"
          alignItems="center"
          pt={width < 400 ? 2 : 0}
          bgcolor="#F6F5FF"
        >
          <Grid className="kahsh-video-container" container>
            <Grid
              className="kahsh-title-container"
              container
              justifyContent="center"
              alignItems="center"
              pt={width < 400 ? 5 : 0}
            >
              <Typography className="kahsh-title" textAlign={"center"}>
                <h1>
                  {t("meetThe")}
                  <br />
                  <b>Kahsh</b>Pay
                </h1>
              </Typography>
            </Grid>
            <Grid
              className="video"
              container
              justifyContent="center"
              alignItems="center"
            >
              <Grid container pl={3}>
                <iframe
                  src="https://player.vimeo.com/video/703991107?autoplay=false"
                  frameBorder="0"
                  allow="fullscreen; picture-in-picture"
                  allowFullScreen
                  title=" "
                ></iframe>
              </Grid>
            </Grid>
          </Grid>
          <Grid container justifyContent="center" alignItems="center">
            <img src={fundoMobile} width="100%" />
          </Grid>
        </Grid>
      )}
    </>
  );
};
