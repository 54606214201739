import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import BackgroundCreateNewKahsh from "../../../imagens/BackgroundCreateNewKahsh.png";
import BackgroundCreateNewKahshMobile from "../../../imagens/BackgroundCreateNewKahshMobile.png";

import createNewKahshIcon1 from "../../../imagens/createNewKahshIcon1.png";
import createNewKahshIcon2 from "../../../imagens/createNewKahshIcon2.png";

export const CreateNewKahshSmartContact = () => {
  const { t } = useTranslation();
  return (
    <Grid
      container
      sx={{
        backgroundImage: {
          xs: `url(${BackgroundCreateNewKahshMobile})`,
          md: `url(${BackgroundCreateNewKahsh})`,
        },
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
      }}
      my={2}
      height={{ xs: "2070px", sm: "1400px", md: "1057px" }}
      px={{ xs: 2, md: 3, lg: 13, xl: 20 }}
      py={2}
    >
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        gap={{ xs: 1, md: 0 }}
      >
        <Typography
          fontSize={{ xs: "25px", md: "32px" }}
          fontWeight={700}
          textAlign="center"
          sx={{
            background:
              "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(90.26deg, #8889F1 -85.54%, #1DC9FF -11.67%, #867BFF 62.2%, #D4B2FF 136.06%)",
            backgroundClip: "text",
            textFillColor: "transparent",
          }}
        >
          {t("createNewKahshSmartContracts")}
        </Typography>

        <Typography
          fontSize={{ xs: "20px", md: "24px" }}
          color="#FFFFFF"
          textAlign="center"
        >
          {t("newCryptocurrencyKahshCard")}
        </Typography>

        <Typography
          fontSize={{ xs: "18px", md: "20px" }}
          textAlign="center"
          sx={{
            background:
              "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(90.26deg, #8C8DFF -85.54%, #1DC9FF -11.67%, #867BFF 62.2%, #D4B2FF 136.06%)",
            backgroundClip: "text",
            textFillColor: "transparent",
          }}
        >
          {t("earnPassiveIncome")}
        </Typography>
      </Grid>

      <Grid container alignItems="center">
        <Grid
          container
          item
          md={8}
          lg={6}
          py={{ xs: 2, md: 0 }}
          gap={{ xs: 1, md: 0 }}
          justifyContent={{ xs: "center", md: "unset" }}
        >
          <Grid
            height={{
              xs: "245px",
              md: "200px",
              lg: "230px",
              xl: "247px",
            }}
            width={{
              xs: "279px",
              md: "231px",
              lg: "241px",
              xl: "281px",
            }}
            sx={{
              backgroundImage: `url(${createNewKahshIcon1})`,
              backgroundSize: {
                xs: "279px 245px",
                md: "231px 200px",
                lg: "241px 230px",
                xl: "281px 247px",
              },
              backgroundRepeat: "no-repeat",
            }}
          />
          <Grid
            height={{
              xs: "247px",
              md: "200px",
              lg: "230px",
              xl: "247px",
            }}
            width={{
              xs: "282px",
              md: "231px",
              lg: "241px",
              xl: "281px",
            }}
            mt={{ xs: 0, md: 18 }}
            sx={{
              backgroundImage: `url(${createNewKahshIcon2})`,
              backgroundSize: {
                xs: "282px 247px",
                md: "231px 200px",
                lg: "241px 230px",
                xl: "281px 247px",
              },
              backgroundRepeat: "no-repeat",
            }}
          />
        </Grid>
        <Grid item md={4} lg={6}>
          <Typography
            fontSize={{
              xs: "18px",
              md: "15px",
              lg: "18px",
              xl: "20px",
            }}
            color="#FFFFFF"
          >
            {t("newKahshCardInstructions")}
          </Typography>
        </Grid>
      </Grid>

      {/* <Grid>
        <Typography fontSize={{ xs: "18px", md: "20px" }} color="#CCCCCC">
          {t("consult")}{" "}
          <a
            style={{
              color: "#32B0FF",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            href="#table"
          >
            {t("rewardTerms")}
          </a>{" "}
          {t("detailsOnEarningReward")}
        </Typography>
      </Grid> */}
    </Grid>
  );
};
