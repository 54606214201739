import { Grid, Typography, Box, Container } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface RegisterStepBaseProps {
  title: string;
  subtitle: string;
  description?: string;
  image: string;
  content: JSX.Element;
}

export const RegisterStepBase: React.FC<RegisterStepBaseProps> = ({
  title,
  subtitle,
  description,
  image,
  content
}) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Grid container my="auto" spacing={2}>
        <Grid
          item
          xs={12}
          md={6}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box>
            <Typography variant="h2" color="secondary.main" fontWeight="bold">
              {t(title)}
            </Typography>
            <Typography variant="h4" color="secondary.main" mb={4}>
              {t(subtitle)}
            </Typography>
            <Typography mb={4}>{t(description)}</Typography>
          </Box>
          <Box
            display={{ md: 'flex', xs: 'none' }}
            alignItems="center"
            justifyContent="center"
          >
            <img
              src={image}
              alt={t(title)}
              style={{ width: '100%', objectFit: 'contain', maxWidth: '230px' }}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            display="flex"
            width="100%"
            flexDirection="column"
            gap={4}
            my="auto"
            borderRadius={3}
            px={4}
            py={8}
            boxShadow="20px 20px 60px rgba(0,0,0,0.2)"
          >
            {content}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};
