/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react';

import { Grid, Typography } from '@mui/material';

import useWindowDimensions from '../../hooks/useWindowDimensions';

import { useTranslation } from 'react-i18next';

import packageJson from '../../../package.json';

import './FooterSmaller.styles.css';
import Facebook from '../../imagens/Facebook.png';
import Instagram from '../../imagens/Instagram.png';
import Twitter from '../../icons/Twitter';

export const FooterSmaller = props => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  return (
    <>
      {width > 1024 ? (
        <>
          <Grid
            px={5}
            pt={2}
            className="kahshIoFooterSmallerCopyright"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid className="kahshIoFooterSmallerDireitos">
              <Typography>
                &copy; {t('allRightsReserved')} - {packageJson.version}
              </Typography>
            </Grid>

            <Grid className="kahshIoFooterSmallerRedesSociais">
              <a
                href="https://www.facebook.com/kahshcom/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="kahshIoFooterSmallerRedesSociaisIMG"
                  src={Facebook}
                  alt=""
                />
              </a>

              <a
                href="https://twitter.com/Kahshcom"
                target="_blank"
                rel="noreferrer"
              >
                <Twitter />
              </a>

              <a
                href="https://www.instagram.com/kahshcom/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="kahshIoFooterSmallerRedesSociaisIMG"
                  src={Instagram}
                  alt=""
                />
              </a>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid
            marginBottom={5}
            className="kahshIoFooterSmallerCopyrightMobile"
          >
            <Grid pt={3} className="kahshIoFooterSmallerDireitos">
              <Typography>
                &copy; {t('allRightsReserved')} - {packageJson.version}
              </Typography>
            </Grid>

            <Grid py={2} className="kahshIoFooterSmallerRedesSociaisMobile">
              <a
                href="https://www.facebook.com/kahshcom/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="kahshIoFooterSmallerRedesSociaisIMG"
                  src={Facebook}
                  alt=""
                />
              </a>

              <a
                href="https://twitter.com/Kahshcom"
                target="_blank"
                rel="noreferrer"
              >
                <Twitter />
              </a>

              <a
                href="https://www.instagram.com/kahshcom/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="kahshIoFooterSmallerRedesSociaisIMG"
                  src={Instagram}
                  alt=""
                />
              </a>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};
