export const TRANSLATIONS_EN = {
  // ============= Global =============
  currentCountry: "US",
  dateFormat: "MM/dd/yyyy",
  back: "Back",
  next: "Next",
  previous: "Previous",
  phone: "Phone",
  birthdate: "Birth Date",
  knowMore: "Know more",
  allRightsReserved: "Copyright © 2021 UI8 LLC. All rights reserved",
  bePartOfIt: "Be part of it",
  register: "Register",
  registerBtn: "REGISTER",
  user: "User",
  enter: "Enter",
  kahsh: "Kahsh",
  exchangeGlobal: "Exchange",
  per: "Per",
  hireNow: "Hire now",
  contractNow: "Hire now!",
  buyKahshs: "Buy Kahsh",
  support: "Support",
  search: "Search",
  ourNetwork: "Our network",
  home: "Home",
  about: "About",
  kahshStore: "Kahsh Store",
  kahshPay: "KahshPay",
  news: "News",
  subscribeNow: "Subscribe",
  noService: "No service",
  confirmPassword: "Confirm password",
  send: "Send",
  stake: "Stake",
  signOut: "Sign Out",
  enterValue: "Input value",
  valueError: "Value must be higher than $1000.",
  password: "Password",
  wallet: "Wallet",
  connectionError:
    "Error while trying to connect, please check your connection and try again.",
  value: "VALUE",
  months: "MONTHS",
  coinQuantity1: "COINS",
  coinQuantity2: "QUANTITY",
  to: "to",
  email: "Email",
  confirmEmail: "Confirm email",
  buy: "Buy",
  kahshNow: "Kahsh now",
  valueIn: "Value in R$",
  AmountInKSH: "Amount in KSH",
  makeQuickAccountHere: "Make your quick account ",
  here: "here",
  ComprarKahsh: "Buy Kahsh",
  mil: "k",
  milhao: "mi",
  cpfCnpj: "CPF / CNPJ",
  fullName: "Full name",
  PaymentSuccessfully: "Payment made successfully!",
  theValueYou: "THE VALUE YOU",
  willReceiveinKSH: "WILL RECEIVE IN KSH",
  acessarAgoraGlobal: "Access now",
  enviar: "Send",
  nome: "Name",
  message: "Message",
  invalidBirthDate: "Invalid date of birth.",
  emailAdress: "Email address",
  BirthDate: "Birth Date",
  enterCpfOrCnpj: "Enter CPF or CNPJ",
  enterEmail: "Type your e-mail",
  congratulations: "Congratulations!!!",
  yourAccountAlreadyExists: "Your account is already created on Kahsh Exchange",
  ok: "ok",
  fullNameRequired: "Full name is Required.",
  fullNameInvalid: "Invalid Name.",
  birthDateShopRequired: "Date of Birth is Required.",
  birthDateShopFuture:
    "This Date is from the Future, enter a valid Date of Birth.",
  documentRequired: "CPF or CNPJ is mandatory.",
  cpfInvalid: "Invalid CPF.",
  cnpjInvalid: "Invalid CNPJ.",
  emailRequired: "Email is Required.",
  emailInvalid: "Invalid email.",
  storeRequired: "The store is mandatory.",
  registerNow: "Register now",
  getNow: "Get yours right now",
  firstCard: "Brazil's first crypto card",
  depositNow:
    "Deposit R$ 300.00 now, and guarantee the benefits that only the Kahsh Card Visa has!",
  country: "Country",
  address: "Address",
  number: "Address Number",
  zipCode: "Zip Code",
  city: "City",
  district: "District",
  region: "State",
  address2: "Address 2",
  finish: "Finish",
  copyLink: "Copy Link",
  successfullyCopied: "Successfully copied link!",

  // =============== invest ============
  contractAlreadyActive:
    "There is already a staking for this address in progress, please change the address and try again.",
  amountTooSmall: "The minimun staking quantity is $1000.",
  amountExceeds: "Transfer amount exceeds balance.",
  stakingInProgress: "Please wait, we are staking your Kahshs.",
  stakingRewardsFree: "Please wait, we are releasing your mining coins.",
  stakeSuccess: "Successfully staked.",
  metaMaskDeny: "Please allow Metamask to continue staking.",
  gasError:
    "Apparently you are out of gas to run this transaction, please check the values ​​in your wallet and try again.",
  metaMaskError:
    "Error when opening Metamask, please review the values and try again.",
  networkError:
    "Network error, please check your connection and try again in a few seconds.",
  transactionHash: "Transaction",

  // ============= About Kahsh =============

  theKahsh: "Kahsh",
  beGlobal: "Be global with Kahsh!",
  beGlobalBody:
    "Kahsh is the transformation of the way of buying and selling products and the simplification of the cryptocurrency universe.",

  // ============= Ecossistema =============
  kahshEcosystem: "KAHSH ECOSYSTEM",
  ecosystem: "Ecosystem",
  foundation: "Foundation",
  kahshFoundationDetails:
    "A foundation whose mission is to bring to society all the benefits that education and knowledge add.",
  foundationIntroDetails:
    "At our Foundation, the transformation of people needs to go hand in hand with the transformation we bring to the market.",
  exchange: "Exchange",
  exchangeDetails: `Innovative, agile and secure Exchange. Offers all the tools for better experience in the cryptoactives market. Buy,
    sell and invest with us.`,
  pay: "Pay",
  kahshPayDetails:
    "Modern payment method, extremely attractive rates, receive in Kahsh and be part of this financial change.",
  store: "Store",
  kahshStoreDetails:
    "Marketplace with the main brands in the market, several beneficiaries, paying with Kahsh.",
  ecoItemKahshExchange: `On our exchange you take the first step to join our ecosystem. Log in, buy your coins and get to
  know the advantages of the Kahsh universe.`,
  ecoItemKahshPay: `Have a new form of payment in hand, with transfers without fees, use of any card brand, speed and security,
  and even monetize our staking.`,
  ecoItemKahshStore: `The first cryptocurrency marketplace, with a wide scope, no exchange needs, and exclusive discounts,
  which you get just for buying in Kahsh.`,
  ecoItemKahshFoundation: `Kahsh is also concerned with training and including all audiences in the digital and social world.
  That's why we offer educational, cultural, digital and social training.`,

  // ============= Universe =============
  universe1: "More than",
  universe2: "a crypto,",
  universe3: "it's a universe.",
  universe: "More than a crypto, it's a universe.",
  universeBody:
    "Kahsh is the change in the way the world will use money. Whether buying, transferring or monetizing.",
  accessNow: "Access now",

  captureGuaranteeBonus: "To guarantee your bonus, enter your email.",
  captureCheckAccount:
    "Let's check if there is already an account with that address, so you can be directed to the registration area.",

  // ============= Capture =============
  capture: "The Kahsh Ecosystem has arrived!",
  captureBonus:
    "<b>Register</b> your e-mail and already <b>earn a bonus</b> of <b>R$25</b> reais in Kahsh.",
  captureLaunchPrizes: "Also compete for <b>prizes!</b>",
  captureLetsGo: "Lets go",

  captureDigitalWalletTitle: "Kahsh Digital Wallet, it all starts here!",
  captureDigitalWalletSubTitle:
    "The Kahsh Ecosystem is simple, practical and perfect for your everyday life. Start earning now!",
  buttonCaptureClickHere: "Click here!",

  // ============= RegisterAccount =============
  registerData: "Data",
  registerEnterData: "Enter your Name, E-mail, CPF and Date of Birth.",

  registerKahshStore:
    "Would you like to sell your products on the Kahsh Store?",
  registerSales: "Would you like to make sales with crypto in your store?",
  registerMetaverse:
    "Would you like to have a store in the metaverse of Kahsh Land?",

  // =============== Why kahsh =============

  whyKahsh: "Why Kahsh?",
  whyKahshUsability: "Usability and high adhesion.",
  whyKahshShopping: "Worldwide shopping without exchange..",
  whyKahshEcosystem: "Uncomplicated ecosystem.",
  whyKahshPayment: "Versatile payment method.",
  advantagesKahsh: "Kahsh advantages",
  unitingMarkets: "Uniting the best of markets.",
  unitingMarketsBody:
    "It integrates retail into the crypto world, with broad usability in buying and selling products, and is still profitable through Staking. Cryptoassets, gamer world, NFT, metaverse and much more. That's Kash!",

  // ============= Kahsh Connect =============
  communityConnectionChannel: "Your community connection channel.",
  interactWithCommunity: "Interact with our community.",
  kahshRevolution: "Kahsh revolution.",
  kahshConnect: "Kahsh Connect",
  quickTransactions: "Quick transactions",
  RiskFreeRewards: "Risk free rewards",

  // ============= Rodapé =============
  contact: "Contact",
  subscribeNewslettervir1: "Subscribe to our newsletter ",
  subscribeNewslettervir2: "to receive more information ",
  subscribeNewslettervir3: "about the Kahsh Ecosystem, and stay ",
  subscribeNewslettervir4: "inside everything that is to come.",
  newsletter: "Newsletter",

  // ============= ExchangeCompreKahsh =============
  buyKahsh: "Buy Kahsh in seconds",
  bePartOfEcosystem:
    "Be part of an innovative ecosystem and enjoy the many benefits, returns and advantages that only Kahsh can offer. Start now!",
  easyMode: "Easy mode",
  easy: "easy ",
  secure: "secure ",
  way: "way",
  proMode: "Pro mode",
  buyHere: "Buy Here",

  // ============= ExchangePrimeirosPassos =============
  step: "Step",
  acessePlataforma: "Access the platform",
  acesseKahshExchange:
    "Access Kahsh Exchange and create your account in a few clicks!",
  connectYourWallet: "Connect your wallet",
  connectWalletCreateSimpleShape:
    "Connect your wallet or create a new one simply.",
  startTrade: "Start trading",
  tradeMarketAssets:
    "Trade the main assets of the market with security and agility.",
  earnMoney: "Earn money",
  participateTheWorldMostGrowingMarket:
    "Participate in the fastest growing market in the world!",
  startwithfew: "Start interacting with crypto world in just a few clicks!",
  firstSteps: "First steps",

  // ============= ExchangeNegocie =============
  negocieEmQualquerLugar: "Trade anywhere!",
  aquiVoceEncontraTudo:
    "Here you will find everything you need to be a crypto trader.",
  diversosAtivosGlobais: "Various global assets.",
  informaçõesEmTempoReal: "Real-time information.",
  comunidadeAtiva: "Active Community!",

  // ============= ExchangeTrader =============
  compreVendaCripto: "Buy and sell crypto",
  acessoMelhoresOportunidades:
    "Access to the best opportunities in the crypto world.",
  compreCripto: "Buy crypto",
  negocieAtivos: "Trade assets",
  acessoAtivosVariados: "Access to assets from the most varied sectors.",
  tradeCripto: "Trade crypto",
  aprendaSobreCripto: "Learn about crypt",
  acesseConteudoExclusivo: "Access exclusive content about the market.",
  saibaMaisTrade: "Know more",
  sejaUmTraderSegundos: "Be a trader in seconds.",
  suporteNecessarioParaOperacoes:
    "The support you need for your operations in the crypto market!",
  criarContaTrade: "Create account",

  // ============= kahsh exchange Receba Informações =============
  exchangeReceivedInformation: "Receive information<br/> in real time.",
  exchangeFollowAdvantages:
    "Follow the price balances and the best advantages for the<br/> moment. With us, you never have doubts.",
  exchangeFollowAdvantagesMobile:
    "Follow the price balances and the<br/> best advantages for the moment.<br/> With us, you never have any doubts.",
  exchangeReceivedInformationBTN: "TO ACCOMPANY",

  // ============= KahshPayDescomplique =============
  kahshPayUncomplicateA: "Uncomplicate",
  kahshPayUncomplicateB: "your financial",
  kahshPayUncomplicateC: "life",
  kahshPaySubtitle:
    "Start turning costs into investments! Only an integrated ecosystem allows this for you.",
  kahshPayStartNow: "Start now",

  // ============= Exchangeindicadores =============
  name: "Name",
  price: "Price",
  volume: "Volume",
  marketcap: "Marketcap",
  lastDays: "last 7 Days",

  //============== KashPay Feito pra você =============
  usabilidade: "Usability",
  usabilidadeDescricao:
    "Various uses! Value reserve, profitable exchange method!",
  usabilidadeMobile:
    "Various applications: Value Store, exchange methods, account unit",
  escassez: "Scarcity",
  escassezDescricao: "Limited offer, high demand, value ecosystem!",
  escassezMobile: "Limited offer. Multiple decimals. Price reflects demand.",
  escalabilidade: "Scalability",
  escalabilidadeDescricao: "Agility, fast transactions and low cost!",
  escalabilidadeMobile: "Own ecosystem. Fast low transaction cost.",
  seguranca: "Security",
  segurancaDescricao: "Blockchain, decentralized and expert team!",
  segurancaMobile: "Inviolable blockchain. Expert team.",

  // ============== KahshPay KahshValor ==========

  KahshValor: "Kahsh valor",
  KahshValorDescricao:
    "Stability, previsibility and higher buying power via ecosystem incentives for users.Fixed price every 24 hours (defined by the last 24 hours average). Its Kahsh inovating to create solutions!",
  KahshValorDescricaoMobile:
    "Modern payment method, extremely atractive fees, receive in Kahsh and be part of this financial change.",
  KahshValorBotao: "Start now",

  // ============= KahshPayPorQueUsar =============
  whyUseKahshPay: "Why use KahshPay?",
  modern: "Modern",
  stateOfTheArt: "State-of-the-art technology via blockchain",
  cheaper: "Cheaper",
  lowestRatesAndTerms: "Lowest rates and terms on the market",
  faster: "faster",
  financialAgility: "Financial agility",
  decentralizedBlockchain: "Decentralized via blockchain",
  flexibleAndSmart: "Flexible and Smart",
  interchangeableWithVariouAssets: "Interchangeable with various assets",
  kahshValue: "Kahsh Value",
  predictabilityForGreaterUsability: "Predictability for greater usability",

  // ============= KahshPaySuporte =============
  saveTimeAndMoney: "Save time and money",
  aTeamReadyToServeYou: "A team ready to serve you!",

  // ============= KahshPayVejaQuanto =============
  seeHowMuchYouCanEarn1: "See how much you can ",
  seeHowMuchYouCanEarn2: "earn by staking in Kahsh!",
  stakingParagraphOne:
    "When you make your Kahshs available in stake they are acting as transaction validators and, in reward for this service, new units of it are proportionally distributed. Thus You too become a 'Miner'.",
  stakingParagraphTwo:
    "In a staking contract, your coins remain being yours, and are kept in your wallet, being released from Stake at the end of the term selected by you.",
  stakingParagraphThree:
    "During the Stake contract period, new units of Kahsh are 'created' that are available for immediate use each month in your wallet, including being able to make new contracts with them.",
  checkNowBetterContracts: "Check out the best Staking contracts!",
  howMuchInvest: "1. How much would you like to stake?",
  foWhatPeriod: "2. For what period do you intend to stake?",
  thisIsTheIncomeYouCan1: "This is the reward you can",
  thisIsTheIncomeYouCan2: "receive now! Start now!",
  thisIsTheIncomeYouCan3: "Capital protected with insurance.",
  thisIsTheIncome:
    "This is the reward you can get right now! Start now! Capital protected with insurance.",
  youInvest: "You invest",
  youReceive: "You receive",
  totalInvested: "Total staked",
  return: "Return % a.a.",
  returnIn: "Return in",
  returnContract: "Return of the Contract",
  returnInKSH: "Return in KSH at the end<br/> of the contract",
  monthlyReward: "This will be your monthly reward.<br/> <b>Get started!</b>",
  seeHowMuchYouCanEarn:
    "See <b>how much you can </b><br /> <b>earn </b>staking Kahsh!",
  seeHowMuchYouCanEarnMobile:
    "See <b>how much</b><br/> <b>you can </b><br /> <b>earn</b><br/> by staking a<br/> Kahsh!",

  //============= Kahsh store ===================
  login: "Login",
  cadastro: "Register",
  hasRegister: "Already registered?",
  stillNotRegister: "Not registered?",
  clickHere: "Click here",
  welcome: "Welcome",
  welcomeBody: "TO THE MOST COMPLETE BLOCKCHAIN ​​ECOSYSTEM",
  recover: "Recovery",
  recoverBody:
    "Enter the registered email to recover the password for kahsh.com Portal",
  redefinePassword: "Redefine password",
  redefine: "Redefine",
  passwordResetError:
    "An error has occured, please check your information and try again.",
  successfullPasswordChange:
    "Successfull password update, you will be redirected in seconds...",
  passwordChangeError: "Failed to change password, please try again.",
  kahshStoreFacilitaTitulo: "Kahsh Store",
  kahshStoreFacilitaDescricao:
    "The Kahsh Store makes your life easier, while generating savings and benefits for you!",
  kahshStoreFacilitaDescricaoMobile:
    "With Kahsh store you can make your life easier",
  kahshStoreFacilitaBaixoFrete: "Low Shipping",
  kahshStoreFacilitaBaixoFreteDescricao: "Some products with free shipping",
  kahshStoreFacilitaDesconto: "Discounts with Kahsh",
  kahshStoreFacilitaDescontoDescricao:
    "Pay with Kahsh and get instant discounts",
  kahshStoreFacilitaDescontoDescricaoMobile:
    "With Kahsh, you get instant discount",
  kahshStoreFacilitaPix: "Payment with PIX",
  kahshStoreFacilitaPixDescricao: "We accept Pix, credit cards and tickets",
  kahshStoreFacilitaPixDescricaoMobile: "Here you can pay with PIX",
  kahshStoreFacilitaSeguro: "Your money safe",
  kahshStoreFacilitaSeguroDescricao: "Security and transparency for you!",
  kahshStoreNumerosCompras: "Purchases made at Kahsh Store",
  kahshStoreNumerosProdutos: "Products in stock in our system",
  kahshStoreDiversidadeHeader: "Kahsh Store",
  kahshStoreDiversidadeBody: `Diversity of products with two characteristics in common: quality and low price! You can compare...
  You will find everything from chocolates to electric motorcycles, get to know our store!`,
  kahshStoreDiversidadeBotao: "Access Store",
  kahshStoreAlgunsProdutos: "Some Products",
  kahshStoreAlgunsProdutosBotao: "Go to Kahsh Store",

  // ============= KahshStoreMaiorClube =============
  theBigClub1: "The biggest shopping club ",
  theBigClub2: "on the internet",
  theBestPrices:
    "The best prices on the entire internet for those who pay with Kahsh. Advantages that only our Ecosystem generates for you, be part of it!",
  buyWithKahshStore: "Buy with KahshStore",

  // ============= KahshStoreBeneficios =============
  whereTheBenefits:
    "Where the benefits of the Kahsh Ecosystem become products with unbelievable prices for you and your family. Be part of this financial revolution!",

  // ============= KahshFoundationLevarEducacao =============
  bringEducation: "Bring education, access and conditions to ",
  community: "community",
  KnowingTheReality: `Knowing the reality in which millions of people are on the fringes of the digital environment and, often, the financial environment as well. Our mission is to bring education to open the mind, provide access so we can connect and provide conditions for us to develop together! Kahsh Foundation, our commitment to life.`,

  // ============= KahshFoundationComoFazerParte =============
  howToBePart: "How to be part?",
  differentCllaborate: `There are several ways to collaborate with the Kahsh Foundation. If you use the Kahsh ecosystem or have the currency in your portfolio, you already help! Want to collaborate even more? Welcome!`,

  // ============= KahshFoundationPerfilDosProjetos =============
  profileOfTheProjects: "Profile of the projects we support",
  transformationNetwork: "Be part of our transformation network.",
  localImpacts: "Local impacts!",
  social: "Social",
  ProjectsFosterSocial:
    "Projects that foster social development based on technological solutions.",
  technological: "Technological",
  innovateTransform: "Innovate, transform!",
  creativityExpressed:
    "Creativity expressed digitally creates solutions with permanent impacts.",
  educational: "Educational",
  fosterKnowledge: "Foster knowledge!",
  everyTransformation:
    "Every transformation that is based on education will be forever.",
  environmental: "Environmental",
  takingCareOfTomorrow: "taking care of tomorrow",
  theEnvironment:
    "The environment is a concern of everyone who thinks about the future.",

  // ============= KahshFoundationFundaçãoKahsh=============
  KahshFoundationFundationKahsh: "Kahsh Foundation",
  KahshFoundationFundationKahshDescricao: `Ecosystem to build a legacy of social transformation through fostering digital education for new generations and digital entrepreneurship. Be part, together we can do great things!`,

  // ============= KahshFoundationRevolucaoSocial =============
  financialRevolution1: "A financial revolution with ",
  financialRevolution2: "social commitment",
  weCanPromoteGoodPractices:
    "We can encourage good practices, good projects and, with that, help to expand the results of good initiatives!",
  Edicating: "Edicating is the way",
  Promoting: "Promoting education in the community",
  transforming: "Transforming is the goal",
  Support: "Support projects that drive change",
  Changing: "Changing reality is the dream",
  ecosystemThat: "An ecosystem that works for all",
  // ============= Staking Page > Ganhe Cripto =============
  earnSafeCrypto: "Earn crypto in a ",
  earnCrypto: "Earn crypto simply and securely!",
  fixedIncome: "Here we make it simple for you!",
  startNow: "Start now",
  theEasiestWay: "The easiest way to earn crypto",
  newEarnCrypto: "Earn crypto the<br/> <b>easy</b> and <b>safely!</b>",
  newEarnCryptoMobile: "Earn crypto<br/> the <b>easy</b> and <b>safe way!</b>",
  newFixedIncome: "Here we simplify<br/> for you!",

  // ============= Staking Page > Construindo Futuro =============
  weArePrepared: "We are prepared for the future of ",
  finance: "finance ",
  decentralized: "decentralized",
  doASimulation: "do a simulation",
  kahshIsPromoting:
    "Kahsh is promoting a revolution in the way we buy, trade and make money!",
  collectiveInterest: "Collective Interest",
  targetedEcosystem: "targeted ecosystem for community",

  fastTransactions: "Fast Transactions",
  agileFinancial: "Agile financial exchanges and with low cost",
  rewardsForAll: "rewards for all",
  secureReturns: "Secure returns for all members",

  // ============= Staking Page > Sobre a Kahsh =============
  aboutKash: "About Kash",
  integratedFinancial_p1: `Integrated financial and digital ecosystem.
 Supported by decentralized technology via`,
  integratedFinancial_p2: `and with various applications
   of use, from`,
  integratedFinancial_p3: `to payment solutions ensuring usage and demand.
    A currency with usability and security.`,
  readyToHaveKahsh: "Ready to have Kahsh?",
  BeOneOf: `Be one of the many people to integrate this Ecosystem! Purchases with the highest return and low cost! Kahsh is real, it's for you.`,
  CommonQuestions: "Common questions",
  General: "General",
  Supoort: "Supoort",
  Technology: "Technology",
  Products: "Products",
  digitalWallet:
    "If you already have a Metamask wallet, just connect with our platform quickly and simply.",
  createYourWallet: "Create your wallet",
  longerDigitalWallet:
    "If you no longer have a Metamask wallet, just click the button below to create a wallet quickly and simply.",
  connect: "To connect",
  connected: "Connected",
  createWallet: "Create wallet",
  Byproviding: "By providing my data, I agree to the Kahsh contract policy",
  iAgree: "I agree to the terms of use and contract established by Kahsh ",
  metameskMessage: "To stake Kahshs its necessary Metamask installed. ",
  install: "Install now",

  // ============= StakePorqueKahsh =============
  titleTakePart: "Be part of this<br/> Universe",
  subtitleTakePart:
    "The Kahsh Ecosystem is simple, practical and perfect<br /> for your everyday life. Make your staking contract<br /> <b>and start earning now!</b>",

  // ============= StakeEmUmContrato =============
  stakingInContract:
    "In a staking contract<br/> <b>your currencies<b/><br/> <b>remain</b><br/> <b>yours</b>",
  stakingInContractMobile:
    "In a<br/> staking contract <b>your</b><br/> <b>coins</b><br/> <b>continue</b><br/> <b>being yours</b>",
  stakingKeptInYourWallet:
    "And they are <b>kept in your wallet</b>, being released from the Stake at the<br/> end of the period selected by you.",
  stakingKeptInYourWalletMobile:
    "And they are <b>kept in your</b><br/> <b>portfolio</b>, being released from the<br/> Stake at the end of the period<br/> selected by you.",
  stakingPeriod:
    "During the period of the Stake contract, <b>new units of</b><br/> <b>Kahsh</b> are 'created' which are <b>available for immediate use in your</b><br/> <b>portfolio</b>, including being able to make new contracts with them.",
  stakingPeriodMobile:
    "During the period of the Stake contract,<br/> <b>new units of</b><br/> <b>Kahsh</b> are 'created' and are <b>available for</b><br /> <b>immediate use</b> in your wallet,<br/> even being able to make new<br/> contracts with them.",

  // ============= Login =============
  forgotPassword2: "Do you want to recover your password?",
  forgotPassword3: "Recover password!",
  passwordRecovery: "Password recovery",
  registeredEmail: `Enter the registered email to recover the password
from the kahsh.com Portal`,
  passwordMatch: "Passwords do not match",
  userRegistry: "User registry",
  registrySuccessInfo: "User register succesfull. You can login now.",
  registryFailInfo: "Registration fail, please try again later.",
  passwordReset: "Password reset link sent! Please check your email.",
  verificationEmail: "succesessfully sent email recovery.",
  emailInUseError: "Email alredy in use, try again with another email.",
  registroEfetuadoExchange:
    "Registration performed and data linked with Kahsh Exchange.",
  weakPasswordError: "Password must be at least 8 characters.",
  invalidEmailError: "Please enter a valid email.",
  userNotFound: "Email not found.",
  internalLoginError: "Unexpected error, please check your data and try again.",
  changeAccount: "Change Account",
  successfullLogin: "Logged in successfully.",
  wrongPasswordError: "Wrong password.",
  accountDisabled:
    "Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later",

  invalidEmail: "Invalid email",
  //============= Extrato =============

  startDate: "Start",
  endDate: "End",
  erroRewards4100:
    "The requested account and/or method has not been authorized by the user.",
  successRewards: "Reward coins released to your Metamask account.",
  pending: "Released",
  rewardsPaid: "Rescued",
  availableBalance: "Staking Balance",
  totalLocked: "Total to be Released",
  totalRedeemed: "Total Redeemed",
  history: "Staking",
  availableBalanceUpperCase: "AVAILABLE BALANCE",
  erroExchangeAuth: "Exchange authentication error.",
  erroDesconhecido: "Unrecorded error, inform developers.",

  //============= Menu do user =============
  manageKahshAccount: "Manage your kahsh account",
  logoutAccount: "Logout",
  extract: "Extract",
  goToExtract: "Go to Extract.",

  //=============== MAILCHIMP rodapé ============
  subscribeSuccess: "Successfully subscribed!",
  subscribePending: "Subscribing...",
  subscribeError: "Error on subscribe.",
  informYourEmailFooter: "Inform your email",
  valorZero: "The value for purchase by PIX was not entered.",
  makeLoginHere: "Or login again ",
  existPix:
    "There is already an open PIX transaction, it will be displayed below value and QRCode.",
  pixGerado: "PIX generated, make payment.",
  pixPago: "Pix paid successfully!",
  errorEchangeRegister:
    "Registration completed, but something went wrong with your registration on the Exchange platform, log in again.",

  //============== Make part of this Universe ===========
  titleMakePart: "Make part of this Universe",
  subTitleMakePart:
    "The Kahsh Ecosystem is simple, practical and perfect for your everyday life. Start earning now!",
  buttonMakePart: "Create account",

  // ============= kahsh exchange =============
  kahshExchange: "Kahsh \n Exchange",
  globalTransactions: "Global transactions and purchases, it all starts here.",
  yourAccount: "Your account",

  //============= Home Video Kahsh ==================
  informYourEmail: "Inform your email",
  discoverTheNew: "Discover the new",
  kahshUniverse: "kahsh universe",
  readyToStarted: "Ready to get started?",
  signUpEmail: "Sign up with your email",
  buttonSendEmail: "Register",
  yourEmail: " Your email",

  //=============Melhores vantagens ==================
  melhoresVantagensWeb:
    "Access the <b>best</b><br/> <b>advantages</b>, from<br/> anywhere!",
  melhoresVantagensMobile:
    "<b>Access the best</b><br/> <b>advantages, from</b><br/> <b>anywhere!</b>",
  naKahshWeb:
    "At Kahsh Exchange you have easy access to everything that is<br/> happening in the crypto world. With few clicks.",
  naKahshMobile:
    "At Kahsh Exchange you have easy<br/> access to everything that is<br/> happening in the crypto world.<br/> With just a few clicks.",

  //============= Exchange Video Kahsh ==================
  simplePracticalAndSafeWeb: "Simple,<br/> practical and<br/> <b>safe</b>",
  simplePracticalAndSafeMobile: "Simple, practical and <b>safe<b/>",
  exchangeDescriptionWeb:
    "Buy, sell and manage your funds at anytime and from anywhere.",
  exchangeDescriptionMobile:
    "<b>Buy, sell</b> and<br/> <b>manage</b> your funds at<br/> anytime and <b>from<br/> anywhere.</b>",

  //============= MelhoresVantagens ==================
  acompanharGlobal: "To accompany",
  monteSuaCarteira: "Build your Wallet",
  simplicidadePraticidade: "Simplicity, practicality and safety.",
  mountDigitalWallet: "Build your digital wallet.",
  mountDigitalWalletBody: "Start trading or staking.",
  realtimeInfo: "Receive real time information.",
  realtimeInfoBody: "The best cryptocurrency purchase options.",
  simplifyiedTransactions: "Simplified transactions.",
  simplifyiedTransactionsBody:
    "Buying or selling assets with practicality and safety.",
  knowEverything: "Know everything in advance.",
  knowEverythingBody: "Get the latest market news.",

  //============= ExchanceProducts =====================
  buyProductsPayingKahsh: "Buy products <b>paying in kahsh</b>",
  buyProductsPayingKahshMobile:
    "Buy products<br/> <b>paying with</b><br/> <b>kahsh</b>",
  TradeCryptoAssets:
    "<b>Trade crypto assets</b> or even <b>monetize</b><br/> <b>staking</b> at Kahsh Smart.",
  TradeCryptoAssetsMobile:
    "<b>Trade crypto</b> or<br/> still <b>monetize by doing</b><br/> <b>staking</b> at our exchange.",
  DigitalWalletComplete: "A <b>complete</b> digital wallet.",
  DigitalWalletCompleteMobile: "A digital <br/> <b>complete</b> wallet.",
  mountWalletExhange: "MOUNT WALLET",

  comeceEmPoucosCliques:
    "Start interacting with the crypto world in a few clicks!",
  AcesseAPlataforma: "Access the platform",
  ConecteSe: "Log in",
  comeceANegociar: "Start trading",
  ganheDinheiro: "Earn money",

  //============= TransactionsExchange =====================
  uncomplicatedTransactionsExchange: "<b>Simple</b><br/>transactions",
  kahshSimplePraticalSafe: "Kahsh is simple, practical and safe.",

  //============= kahshStoreNovo =====================
  buyingMoneyEarnsMore: "Buying here, your money earns more.",
  descriptionkahshStore:
    "Everything you normally buy on the internet you can find here, but here your purchase is not subject to changes in the real or dollar.",
  btTextkahshStore: "Access now",

  //============= kahshPayNovo =====================
  descriptionkahshPay:
    "With fee-free transfers, use of any<br/> card brand, speed and security, kahshPay<br/> gives you today, what will be a trend in the future.",
  descriptionkahshPayMobile:
    "With fee-free transfers,<br/> any type of card,<br/> speed and security,<br/> kahshPay gives you today, which<br/> will be trending in the future.",
  cryptocurrencyCurrency: "Cryptocurrencies with Real (BRL)",
  transferValues: "Transfer<br/> of values.",
  freeMediators: "Free of mediators",
  buyWithcurrency: "Buy Kahsh<br/> with Real (BRL).",
  freeMediaYourFreDdevaluationtors: "Here your money is more valuable.",
  useCreditCard: "Use any credit card.",
  useCreditCardMobile: "Use any<br/> credit card.",
  hereGiveAccessibility: "Here we give you maximum accessibility.",
  unifyPaymentMethods: "Unify your payment methods.",
  allDemandsEcosystem: "All your demands are in our ecosystem, check it out.",
  allDemandsEcosystemMobile:
    "All your demands are<br/> in our ecosystem, check it out.",
  lojistas: "Shopkeepers",
  consumidores: "Consumers",
  valorDaTaxa: "From 0.99% to 1.99%.",
  lowestFees: "The lowest industry fees",
  kahshBonus: "Kahsh Bonus",
  bonus1: "Bonus that returns between 20% to 50% of ",
  bonus2: "the processing fees.",
  bonusMobile:
    "Bônus that returns between 20%<br/>to 50% of total processing fees.",
  montarCarteira: "Mount Wallet",
  moreBenefictsYouEarn: "The more you use it, the more benefits you win.",
  featuredProduct: "Featured Product",
  meetThe: "Meet ",
  transactionsFree: "Interference free transactions",
  payAndTransactKahsh:
    "You can pay, transact and buy with Kahsh anywhere in the world.",
  kahshBackDiscounts: "Discount coupons and Kahsh back",

  //============= NOVO STAKE =====================
  quantoVoceVaiGanhar: "1. How much staking will you do?",
  porQualPeriodo: "2. For what period do you intend to contribute?",
  queroStake: "I want stake",
  acessarAgoraStaking: "I want to Stake!",

  //============= NOVO FOUNDATION =====================
  ideiasDaNovaGeracao: "New generation ideas.",
  ideiasDaNovaGeracaoBody:
    "We promote the promotion of social projects that stimulate digital inclusion and that give vent to new minds not yet revealed, helping to promote the next generation of citizens and ideas.",
  semeandoHoje: "Sowing today.",
  semeandoHojeBody:
    "We support creative digital-technological ideas that bring changes and ease to people's daily lives. The world is full of good ideas, we contribute to putting them into practice.",
  withUs: "With us, crypto is also environmental ",
  withUsBody:
    "Although we have one of the most ecological systems in the crypto world, for us this is not enough. We also develop projects aimed at preserving and conserving the environment.",
  serKahsh: "Being Kahsh is also supporting.",
  serKahshBody:
    "Having Kahsh coins you are already collaborating with our projects.",
  EarnCrypto: "Earn crypto",
  easily: "easily",
  andStaking: "and",
  securely: "securely!",
  joinNow: "Join now!",

  stakingIs: "Staking",
  stakingIsOf: "is one of",
  bestWays: "best ways",
  toObtain: "to obtain",
  passiveIncome: "passive income",
  usingCryptocurrencies: "using your cryptocurrencies.",
  bigDescriptionStaking:
    "When you make your Kahshs available in stake they are acting as transaction validators and, in reward for this service, new units of it are proportionally distributed. That way you also become a 'Miner'.",
  //============= NOVO STORE =====================

  bigDescriptionStore:
    "Here you buy paying in Kahsh, no matter where the product comes from, and you still get discounts for it.",
  globalPurchases: "Global purchases",
  globalPurchases2: "no exchange, ",
  itHere: "it's here!",

  //============= NOVO CADASTRO =====================
  cadSuccess: "Success",
  cadError: "Error",

  //============= Kahsh Card =======================
  out: "Exit",
  invalidPasswordOrEmail: "Email and password don't match!",
  loginContinue: "Login or create an account to continue",
  dontHaveAccount: "don't have an account?",
  cancel: "Cancel",
  stepLogin: "Sign-In",
  depositReceived: "Deposit received successfully",
  expiredQrCode: "Expired QR-Code",

  stepPersonalData: "Personal Data",
  stepAddress: "Address",
  stepDeposit: "Deposit",
  nameRequired: "Name is required",
  invalidName: "Invalid name",
  phoneRequired: "Phone number is required",
  phoneInvalid: "Invalid phone number",
  cpfRequired: "CPF is required",
  invalidDate: "Invalid date",
  birthDateRequired: "Birth date is required",
  birthDateFuture: "Birth date cannot be in the future",
  addressAutoCompleteInvalid: "The selected address has no street name",
  zipCodeInvalid: "Invalid zip code",
  addressRequired: "Address is required",
  countryRequired: "Country is required",
  addressNumberRequired: "Address number is required",
  zipCodeRequired: "Zip code is required",
  regionRequired: "State is required",
  cityRequired: "City is required",
  districtRequired: "District is required",

  firstCryptoCard: "Using your crypto assets anywhere in the world!",
  introText1: "The giant on the card business ",
  and: "and ",
  introText2:
    " are together to offer, through the practicality and capillarity of the card market, an innovative, simple and safe experience in the adoption of a cryptocurrency for people's daily lives to purchase goods and services in 100 million establishments in 200 countries!",
  askNow: "Ask now",
  waitingList: "Waiting List",
  cepRequired: "CEP Required",
  numeroRequired: "Number Required",
  addOption: "New option",
  defaultMessage: "Something went wrong, please try again later!",

  incomparableExp: "ONE INCOMPARABLE EXPERIENCE",
  cryptoCardNumber: "Your crypto card number is just a tap away.",
  findAnyTransaction: "Find any transaction with an easy and fast research.",
  beNotified: "Be immediately notified about all your purchases.",
  winKahsh: "Win Kahshback on thousand of products.",
  manyPartners: "Many partners registered in different cities and countries.",
  lostYourCard: "Lost your card? Obtain a new one directly on your app.",
  aboardInMinutes: "ON BOARD IN MINUTES",
  seeIfApproved: "See if you were approved right now!",
  startSpending: "Start spending immediately, before your metal card arrives.",

  getOne: "how to get one",
  cardKahshVisa: "Kahsh Card Visa",
  cardPhysicalAndVirtual:
    "Physical and virtual card<br /> Kahshback Up to 5X higher<br/> NO ANNUAL FEES",
  winKahshBack: "Win KahshBack everytime you use the card",
  kahshCardOffers:
    "Kahsh Card Visa offers Kashback every time you use your balance, allowing you to buy thousands of products in stores and brands around the world.",
  newKahshSmartContract: "Creation of new Kahsh through Smart Contracts",
  newUnitsMineration:
    "Have the “mining” of new cryptocurrency units directly on your Kahsh card via Smart Contract on Blockchain.",

  card: "Card",
  updateCardText: "(may be upgraded to another card in due course)",
  signalValue: "Signal Value",
  signalValueText1: "(receives in Kahsh at",
  signalValueText2: "the price of the day of purchase)",
  bonusKahsh: "Bonus",
  kahshToReceive1: "Amount to be received in Kahsh",
  kahshToReceive2: "on the card balance",
  batch: "Batch ",
  seeWhatWin: "See what you can win!",
  selectCard: "Select your card",
  selectAnual: "Select your anual expenses",
  kahshBackValue: "Kahshback value",

  tranquilityTitle: "TRANQUILITY AT HOME AND ABROAD",
  noDelay: "No delay fee",
  noAnual: "No anual fee",
  noForeign: "No Foreign Transaction Fee",

  benefitsAndAdvantages: "BENEFITS AND ADVANTAGES",
  celProtect: "Cellphone Protection",
  travelRefund: "Trip interruption reimbursement",
  buySafety: "Purchase security",
  dmgInspection: "Car Rental Collision Damage Waiver",
  extendedWarranty: "Extended warranty protection",
  devolutionProtect: "Return Protection",
  zeroLiability: "Zero liability protection",
  conciergeService: "Visa Signature Concierge Service",
  travelAssist: "Travel and Emergency Assistance",
  dispatchRoad: "Dispatch on the road",

  createNewKahshSmartContracts: "CREATION OF NEW KAHSH THROUGH SMART CONTRACT",
  newCryptocurrencyKahshCard:
    "Have the “mining” of new cryptocurrency units directly on your Kahsh card through Smart Contract in Blockchain, increasing your balance placed in Stake",
  earnPassiveIncome:
    "Staking is the best way to create new cryptocurrencies through Smart Contract on Blockchain",
  newKahshCardInstructions:
    "When you make the kahsh staking service available, you help balance the proportional circulation of coins in your new savings, and in reward for this kahsh service are distributed units. So You too become a 'Miner'. In a participation agreement, your coins remain yours, and are kept in your wallet, with those selected from participation being released at the end by you. During the period of the participation agreement, new units of Kahsh are 'created' that are available for immediate use in your portfolio, including the possibility of entering into new contracts with them.",
  consult: "Consult our",
  rewardTerms: "REWARD TERMS",
  detailsOnEarningReward:
    "for more details on earning reward points based on your aggregate purchases and spend, as well as other limited-time and promotional offers.",

  rewardsEachPurchase: "UNMATCHED REWARDS ON EVERY PURCHASE",
  getUpToFivePercent:
    "Get up to 5% Kashback on every purchase, regardless of category",
  stakingBenefits: "Staking and non-staking benefits",

  stakeBRL: "KSH Stake (in R$) = “crypto mining” (12 months)",
  stakeUSD: "KSH Stake (in USD) = “crypto mining” (12 months)",
  tableQuantity: "KSH quantity",
  newKshRewardsPercentage: "Rewards in new KSH (% / month)",
  newKshRewardsQuantity: "Rewards in new KSH (quantity / month)",
  chooseCard: "Now just choose the card that best suits your goals",
  usabilityForKahsh: "Usability for your kahsh crypto currencies",
  newCoinsCreation:
    "Creation of new Kahsh coins by Smart Contract on Blockchain",
  kahshbackInAThousand: "Kahshback on thousands of products and services",
  oneHundredMilion: "Use in over 100 million establishments in 200 countries",
  aditionalCard: "Additional card for family members",
  simplicityAndPraticity:
    "Simplicity and practicality in using your Kahsh crypto assets",

  listKahshCardTitleTECH: "TECH",
  listKahshCardTitleFITNESS: "FITNESS",
  listKahshCardTitleRETAIL: "RETAIL",
  listKahshCardTitleTRAVEL: "TRAVEL",
  listKahshCardApple: "Apple",
  listKahshCardBeats: "Beats",
  listKahshCardBestBuy: "Best Buy",
  listKahshCardBose: "Bose",
  listKahshCardMasterclass: "Masterclass",
  listKahshCardNintendo: "Nintendo",
  listKahshCardPlaystation: "Playstation",
  listKahshCardSonos: "Sonos",
  listKahshCardSony: "Sony",
  listKahshCardXbox: "Xbox",
  listKahshCardAdidas: "Adidas",
  listKahshCardCannondale: "Cannondale",
  listKahshCardLululemon: "Lululemon",
  listKahshCardNike: "Nike",
  listKahshCardOutdoorVoices: "Outdoor Voices",
  listKahshCardPatagonia: "Patagonia",
  listKahshCardPeloton: "Peloton",
  listKahshCardRogueFitness: "Rogue Fitness",
  listKahshCardREI: "REI",
  listKahshCardTrek: "Trek",
  listKahshCardAllbirds: "Allbirds",
  listKahshCardAmazon: "Amazon",
  listKahshCardAntropologie: "Antropologie",
  listKahshCardAritzia: "Aritzia",
  listKahshCardAsos: "Asos",
  listKahshCardCasper: "Casper",
  listKahshCardCaixaEBarril: "box and barrel",
  listKahshCardCostco: "Costco",
  listKahshCardEtsy: "Etsy",
  listKahshCardEverlane: "Everlane",
  listKahshCardBrilhante: "Brillant",
  listKahshCardHomeDepot: "Home Depot",
  listKahshCardIkea: "Ikea",
  listKahshCardReforma: "Remodeling",
  listKahshCardSephora: "Sephora",
  listKahshCardSupremo: "Supreme",
  listKahshCardUniqlo: "Uniqlo",
  listKahshCardWarbyParker: "Warby Parker",
  listKahshCardWayfair: "Wayfair",
  listKahshCardAirbnb: "Airbnb",
  listKahshCardAlaskaAirlines: "Alaska Airlines",
  listKahshCardAmericanAirlines: "American Airlines",
  listKahshCardDelta: "Delta",
  listKahshCardHoteisCom: "Hoteis.com",
  listKahshCardHotelTonight: "Hotel Tonight",
  listKahshCardSouthwestAirlines: "Southwest Airlines",
  listKahshCardUnitedAirlines: "United Airlines",
  listKahshCardJetBlue: "JetBlue",
  listKahshCardUber: "Uber",
  listKahshCardVrbo: "Vrbo",
  listKahshCardZipcar: "Zipcar",
  listKahshCardButtonPlus: "Load more!",
  listKahshCardButtonRemove: "Load less!",

  contractModel: "Contract models",
  twelveMonthsContract: "For 12 months contract",
  from: "from ",
  until: "until ",
  stakingSectionTwo:
    "When you make your Kahshs available in stake they are acting as transaction validators and, in reward for this service, new units of it are proportionally distributed. That way you too become a 'Miner'.",
  inAStakingContrat: "In a staking contract",
  yourCoinRemainsYours: "your coins remains being yours",

  referrerAFriend: "Referrer a friend!",
  everytimeAFriend:
    "Every time that your friend makes a deposit in Kahsh Exchange you receive 2% of the value as an indication bonus to spend as you wish.",
  sendWhatsApp: "Send WhatsApp",
  phonePlaceholder: "1 + AREA CODE + NUMBER EX: 15054204294",
  whatsMessage: "To access and register just click on the link: ",

  //============= NEW STAKING PAGE ====================
  yourCoins: "Your coins",
  are: "are",
  keptInYourWallet: "kept in your wallet",
  deadlineSelectedByYou:
    "being released from the Stake at the end of the term selected by you.",

  stakeContractPeriod: "During the period of Stake's contract,",
  createdNewUnitsKahsh: "new units of Kahsh are 'created'",
  thatStay: "that stay",
  availableForUse: "available for immediate use",
  makeNewContracts:
    "in its wallet, including being able to make new contracts with them.",

  chooseContract: "Now it's just a matter of choosing the contract that",
  bestSuitsYourGoals: "best suits your goals",

  quantityOfCoins: "QUANTITY OF COINS",
  valueUs: "VALUE (US$)",
  "12Mounths": "12 MOUNTHS",
  "24Mounths": "24 MOUNTHS",
  "36Mounths": "36 MOUNTHS",

  //============= KAHSH LABS PAGE ====================

  kahshlabsTitle: "KahshLabs",
  informationTechnologyCompany: " We are an information technology company,",
  multidisciplinary: "multidisciplinary and",
  specialistBuildingSolutions: "specialist in building solutions",
  of: "of",
  physicalVirtualMarket: "crypto world for the physical or virtual market.",
  developmentCryptoassetsAndSmartContracts:
    "In addition to the development of cryptoassets and smart contracts,",
  also: "also",
  solutionsThatIntegrateBusinesses:
    "we build the solutions that integrate businesses in the",
  virtualMetaverseCryptoWorld:
    "real world, virtual and metaverse to the crypto world.",

  servicesKahshLabs: "Services",
  SoftwareFactory: "We act as a Software Factory, from the conception of",
  developedSolutions:
    "new business to the implementation of the developed solutions,",
  alwaysDeliveringCustomizedSystems:
    "always delivering customized systems to the specifics of",
  everyDeal: "every deal.",
  creationBlockchainSolutions:
    "The creation of Blockchain solutions such as asset tokenization,",
  creationSmartContracts:
    "creation of Smart Contracts and construction of solutions for DeFi.",
  businessMigrationMetaverse:
    "Business migration to the Metaverse, through the creation of",
  custom3DEnvironments: "custom 3D environments, creating marketing strategies",
  metaverseAndElaboration:
    "for Metaverse and elaboration of the most varied solutions for",
  ecommerceAndEventsMetaverse: "e-commerce and events in the Metaverse.",

  developedProjects: "Projects<br/> developed by <b>Kahsh</b>",
  developedProjectsMobile: "Projects developed<br/> by Kahsh",
  kahshLabsSoftwareFactory:
    "KahshLabs Software Factory, from the conception of new businesses to the",
  implementationSolutionsDeveloped: "implementation of the solutions developed",

  ProjectsTitle1: "Portal Kahsh",
  ProjectsDescription1:
    "Kahsh is the change in the way the world will use money",

  ProjectsTitle2: "KahshPay",
  ProjectsDescription2:
    "Payment method, with benefits for those who buy and sell",

  ProjectsTitle3: "Kahsh Exchange",
  ProjectsDescription3: "manage your funds anytime, anywhere.",

  ProjectsTitle4: "KahshLabs",
  ProjectsDescription4: "Software Factory, solutions for any type of business",

  our: "Our",
  team: "Team",
  alignedNewRealityITMarket:
    "Aligned with the new reality of the IT market, we operate in a decentralized manner",
  alsoWithOurTeam:
    "also with our team, where we have employees from different regions of the country",
  teamUnitedStates: "in addition to a team in the United States.",
  registrationData: "Registration data",
  registeredCnpj: "CNPJ: 44.679.357/0001-13",
  registeredAddress:
    "Address: Av CAssiano Ricardo, 601, Sala 803, Jd. Aquarius, São José dos Campos SP, CEP 12.246-870.",
  messageWhatsAppKahshLabs: "Hi, you got in touch through the Kahshlabs page!",
};
