import { Box, Typography } from "@mui/material";
import { RegisterStepBase } from "../../components/kahshCard/registerStepsBase/RegisterStepBase";
import { TextField } from "../../components/inputs/text-field/text-field.component";
import { Button } from "../../components/Button/Button";
import { FieldValues, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import CardRegisterPersonal from "../../imagens/cardRegisterPersonal.png";
import { logInWithEmailAndPassword } from "../../services/firebase";
import { accountsCallBack } from "../../services/accountsCallBack";
import { useAlert } from "../../hooks/useAlert";
import { useState } from "react";
import axios from "axios";
import { urlKahshCardExchange } from "../../services/global";

const validator = yup.object().shape({
  senha: yup.string().required("passwordRequired"),
  email: yup.string().required("emailRequired").email("emailInvalid"),
});

interface KahshCardRegisterStepsProps {
  onSubmit: (stepData: FieldValues) => void;
  alreadyExists: () => void;
}

export const Login: React.FC<KahshCardRegisterStepsProps> = ({
  onSubmit,
  alreadyExists,
}) => {
  const [loading, setLoading] = useState(false);

  const { AlertMessage } = useAlert();
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validator),
  });

  const handleNextStep = async ({ email, senha }: FieldValues) => {
    try {
      setLoading(true);
      const { user } = await logInWithEmailAndPassword(email, senha);
      if (localStorage.getItem("cardVisaTxId")) {
        const card = await axios.get(urlKahshCardExchange, {
          params: { txid: localStorage.getItem("cardVisaTxId") },
        });
        if (
          card.data.email === email &&
          (card.data.msg === "Pagamento Pendente." ||
            card.data.msg === "Pix gerado com sucesso!")
        ) {
          alreadyExists();
        }
      }

      onSubmit({ email, senha, kahsh_id: user?.uid });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      AlertMessage("error", "invalidPasswordOrEmail");
    }
  };

  return (
    <RegisterStepBase
      title="Kahsh Card Visa"
      subtitle="loginContinue"
      image={CardRegisterPersonal}
      content={
        <>
          <TextField
            label={"email"}
            control={control}
            helperText={errors?.email?.message}
            name="email"
            type="email"
            required
            size="small"
          />

          <TextField
            label={"password"}
            control={control}
            helperText={errors?.senha?.message}
            name="senha"
            type="password"
            required
            size="small"
          />
          <Box>
            <Box
              display="flex"
              flexDirection={{ md: "row", xs: "column-reverse" }}
              justifyContent="flex-end"
              alignItems="center"
              gap={2}
            >
              <Button
                fullWidth
                onClick={() => (window.location.href = "/cardvisa")}
                variant="outlined"
              >
                {t("out")}
              </Button>
              <Button
                fullWidth
                onClick={handleSubmit(handleNextStep)}
                loading={loading}
              >
                {t("next")}
              </Button>
            </Box>
            <Typography
              variant="caption"
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              mt={0.5}
            >
              {t("dontHaveAccount")}{" "}
              <Button
                onClick={() => accountsCallBack("register")}
                sx={{
                  width: "auto",
                  height: "auto",
                  fontSize: (theme) => theme.typography.caption,
                }}
                variant="text"
              >
                {t("register")}
              </Button>
            </Typography>
          </Box>
        </>
      }
    />
  );
};
