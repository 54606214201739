import { Grid, Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import ChartStaking from "../../../imagens/chart_staking.png";

import "./MelhoresFormas.styles.css";
import useSharedLink from "../../../hooks/useSharedLink";
import { SaibaMaisButton } from "../../buttons/SaibaMaisButton";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

export const MelhoresFormas = () => {
  useSharedLink();
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  return (
    <Grid container item 
    pt={16}
    pl={width < 1024 ? 2 : 10}
    pb={10} justifyContent="center" xs={12}>
      <Grid container item sm={12} lg={10} justifyContent="space-between">
        <Grid xs={12} lg={4} container item direction="column">
          <Grid
            className="universe-container"
            container
            item
            direction="column"
            alignItems="center"
            width={width < 1024 ? '90%': '140%'}
          >
            <Typography
              className="universe-text"
              fontSize={"3rem"}
              color={"#232340"}
              width={width < 1024 ? '110%': null}

            >
              <b>{t("stakingIs")}</b> {t("stakingIsOf")} <b>{t("bestWays")}</b> {t("toObtain")} <b>{t("passiveIncome")}</b> {t("usingCryptocurrencies")}
            </Typography>
            <Typography
              fontSize={18}
              fontWeight={400}
              color={"##606060"}
              lineHeight={"32px"}
              mt={2}
            >
              {t("bigDescriptionStaking")}
            </Typography>
          </Grid>

        </Grid>
        <Grid
          xs={12}
          lg={7}
          container
          justifyContent="center"
          mt={width < 1024 ? -10 :-16}
          ml={width < 1024 ? -2 :-16}
        >
          <img src={ChartStaking} 
          width={width < 1024 ? '145%':'74%'} alt="" />
          <Grid container justifyContent="center" mt={-12}
            mb={4}>
            <Grid container justifyContent="center" width={"100%"}
              ml={width < 1024 ? 2 :2}>
              <SaibaMaisButton width={width < 1024 ? "90%" : "40%"}>
                <Typography fontSize={18} fontWeight={700}>
                {t("joinNow")}
                </Typography>
              </SaibaMaisButton>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
    </Grid>
  );
};
