import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Coin1 from "../../../imagens/CoinOneKahshCard.png";
import Coin2 from "../../../imagens/CoinTwoKahshCard.png";
import TransparentCoin from "../../../imagens/TransparentCoin.png";
import BgWeb from "../../../imagens/KahshCardBg.png";

export const KahshCardKahshBack = () => {
  const { t } = useTranslation();
  return (
    <Grid
      container
      height={{ xs: "auto", md: 443 }}
      justifyContent="center"
      position="relative"
      sx={{
        backgroundImage: { xs: `url(${BgWeb})`, md: `url(${BgWeb})` },
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%"
      }}
      py={{ xs: 10, md: 0 }}
      px={{ xs: 4, md: 0 }}
      mb={10}
    >
      <Grid
        container
        item
        direction="column"
        md={6}
        justifyContent="center"
        px={{ md: 5, lg: 13 }}
        gap={3}
        mb={{ xs: 8, md: 0 }}
      >
        <Typography
          fontSize={32}
          fontWeight={700}
          color="#fff"
          sx={{
            background:
              "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(90.26deg, #8889F1 -85.54%, #1DC9FF -11.67%, #867BFF 62.2%, #D4B2FF 136.06%);",
            backgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
          lineHeight={"32.64px"}
        >
          {t("winKahshBack")}
        </Typography>
        <Typography fontSize={20} fontWeight={500} color="#fff">
          {t("kahshCardOffers")}
        </Typography>
      </Grid>
      <Grid
        container
        item
        direction="column"
        md={6}
        justifyContent="center"
        px={{ md: 5, lg: 13 }}
        gap={3}
      >
        <Typography
          fontSize={32}
          fontWeight={700}
          color="#fff"
          sx={{
            background:
              "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(90.26deg, #8889F1 -85.54%, #1DC9FF -11.67%, #867BFF 62.2%, #D4B2FF 136.06%);",
            backgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
          lineHeight={"32.64px"}
        >
          {t("newKahshSmartContract")}
        </Typography>
        <Typography fontSize={20} fontWeight={500} color="#fff">
          {t("newUnitsMineration")}
        </Typography>
      </Grid>

      <Grid
        position="absolute"
        bottom={25}
        left={0}
        height={{ md: 110, lg: 150 }}
        width={{ md: 90, lg: 130 }}
        sx={{
          backgroundImage: `url(${TransparentCoin})`,
          backgroundSize: "100% 100%",
        }}
      />
      <Grid
        position="absolute"
        top={{ md: -85, lg: -105 }}
        left={{ md: 150, lg: 250 }}
        height={{ md: 180, lg: 240 }}
        width={{ md: 192, lg: 252 }}
        sx={{ backgroundImage: `url(${Coin1})`, backgroundSize: "100%" }}
      />
      <Grid
        position="absolute"
        right={40}
        bottom={-70}
        height={{ md: 120, lg: 160 }}
        width={{ md: 110, lg: 150 }}
        sx={{
          backgroundImage: `url(${Coin2})`,
          backgroundSize: "100%",
          backgroundRepeat: "no-repeat",
        }}
      />
    </Grid>
  );
};
