import React from 'react';
import { useController } from 'react-hook-form';
import { TextFieldStyledProps } from '../text-field/text-field.component';
import 'react-phone-number-input/style.css';
import { useTranslation } from 'react-i18next';
import ptBR from 'react-phone-number-input/locale/pt-BR.json';
import en from 'react-phone-number-input/locale/en.json';
import { PhoneInputStyled, PhoneTextField } from './PhoneInput.styles';

export interface PhoneInputProps extends TextFieldStyledProps {
  required?: boolean;
}

const TextFieldComponent = React.forwardRef((props: any, ref) => {
  const { t } = useTranslation();

  return (
      <PhoneTextField
        {...props}
        inputRef={ref}
        error={!!props.helperText}
        label={t(props.label)}
        helperText={!!props.helperText && t(props.helperText)}
        variant={'outlined'}
        name={props.field?.name}
        fullWidth
      />
  );
});

export const PhoneInput: React.FC<PhoneInputProps> = ({
  name,
  control,
  onChange,
  ref,
  defaultValue,
  watch,
  value,
  ...props
}) => {
  const { t } = useTranslation();

  const { field } = useController({
    name: name || '',
    control: control || undefined,
    defaultValue: defaultValue || ''
  });

  const totalProps = { ...props, field };
  return (
    <PhoneInputStyled
      error={props?.helperText?.toString()}
      value={control ? field.value : value}
      onChange={(event: any) => {
        if (control) {
          watch && watch(event?.target?.value);
          return field.onChange(event);
        }
        if (onChange) {
          return onChange(event);
        }
      }}
      defaultCountry={t('currentCountry')}
      labels={t('currentCountry') === 'BR' ? ptBR : en}
      {...totalProps}
      inputComponent={TextFieldComponent}
    />
  );
};
