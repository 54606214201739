import { Grid, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import { Calculadora } from "../../calculadora/Calculadora";
import "./KahshPayVejaQuantoBlackTable.styles.css";
import stakingImg from "../../../imagens/stakingImg.png";
import { useEffect, useState } from "react";
import accordionValues from "./accordionValuesTable";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import upArrow from "../../../imagens/upArrow.png";
import FlechaHorizontal from "../../../imagens/FlechaHorizontal.svg";
import useSharedLink from "../../../hooks/useSharedLink";

export const KahshPayVejaQuantoBlackTable = () => {
  useSharedLink();
  const { t, i18n } = useTranslation();
  const matches = useMediaQuery("(min-width:1271px)");
  const [coin, setCoin] = useState(10000000000000000000);

  useEffect(() => {
    var fetchData = async () => {
      const data = await fetch("https://exchange.kahsh.com/cotacao/USD");
      const json = await data.json();
      setCoin(Number(json[2].cotacao));
    };

    fetchData();
  }, []);

  const getCurrencyFormat = (currency, currencyText) => {
    currency = currency / coin;
    currency = Math.ceil(currency);
    var currencyText = "";
    var currencyAbreviado = 0;

    if (currency < 1000) {
      currency = currency.toString();
      currencyAbreviado = Math.ceil((10 * currency) / 1000) / 1;
      currencyText = `${currencyAbreviado} KSH`;
    } else if (currency < 1000000) {
      currency = currency.toString();
      currencyAbreviado = Math.round((10 * currency) / 1000) / 10;
      currencyText = `${currencyAbreviado} mil`;
    } else if (currency >= 1000000) {
      currency = currency.toString();
      currencyAbreviado = Math.round((10 * currency) / 1000000) / 10;
      currencyText = `${currencyAbreviado} ${t("mi")}`;
    }

    if (i18n.language === "pt") {
      return currencyText.replace(".", ",");
    } else {
      return currencyText;
    }
  };

  return (
    <>
      {!matches ? (
        <Grid
          width="96%"
          borderRadius={6}
          ml={2.2}
          id="ganheCripto"
          container
          item
          xs={12}
        >
          <Grid className="seeHowMuchYouCanEarnBox" mb={5} pl={2} mt={5}>
            {i18n.language === "pt" || i18n.language === "pt-BR" ? (
              <Typography textAlign="center" variant="h2">
                Agora é só escolher o{" "}
                <span style={{ color: "#4733FF" }}>
                  contrato que melhor
                  <br /> atende seus objetivos
                </span>
              </Typography>
            ) : (
              <Typography textAlign="center" variant="h2">
                Now just choose the{" "}
                <span style={{ color: "#4733FF" }}>
                  contract that best meets your goals
                </span>
              </Typography>
            )}
          </Grid>
          <Accordion
            defaultExpanded
            sx={{
              paddingLeft: 2,
              width: "98%",
              marginBottom: 5,
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                background:
                  "linear-gradient(90.16deg, #4733FF 27%, #FFFFFF 125.09%)",
                boxShadow:
                  "0px 1px 2px rgba(97, 97, 97, 0.2), 0px 2px 4px rgba(97, 97, 97, 0.2)",
                borderRadius: "8px",
              }}
            >
              <Typography className="accordion-header">
                {t("checkNowBetterContracts")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                backgroundColor: "#FAFAFA",
                borderRadius: "0px 0px 16px 16px",
              }}
            >
              <Grid display="flex">
                <Grid flex={1} mt={6}>
                  {accordionValues.map((e) => {
                    return (
                      <>
                        <section class="grid grid-template-columns-1">
                          <Typography class="grid-header">
                            {t("coinQuantity1")} {t("coinQuantity2")}
                          </Typography>
                          <Typography class="grid-header">
                            {t("value")} (US$)
                          </Typography>
                          <Typography class="item item-light item1">{`${getCurrencyFormat(
                            e.value1
                          )} ${t("to")} ${getCurrencyFormat(
                            e.value2
                          )}`}</Typography>
                          <Typography class="item item-light item2">{`${
                            e.value3
                          } ${t("to")} ${e.value4}`}</Typography>
                        </section>
                        <section class="grid grid-template-columns-2">
                          <Typography class="grid-header">
                            12 {t("months")}
                          </Typography>
                          <Typography class="grid-header">
                            24 {t("months")}
                          </Typography>
                          <Typography class="grid-header">
                            36 {t("months")}
                          </Typography>
                          <Typography class="item item-bold item3">
                            {e.value5}
                          </Typography>
                          <Typography class="item item-bold item4">
                            {e.value6}
                          </Typography>
                          <Typography class="item item-bold item5">
                            {e.value7}
                          </Typography>
                        </section>
                      </>
                    );
                  })}
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      ) : (
        <Grid
          container
          item
          xs={12}
          pt={7}
          id="ganheCripto"
          justifyContent="center"
        >
          <Grid className="seeHowMuchYouCanEarnBox" width="90%" mb={5}>
            {i18n.language === "pt" || i18n.language === "pt-BR" ? (
              <Typography textAlign="center" variant="h2">
                Agora é só escolher o{" "}
                <span style={{ color: "#4733FF" }}>
                  contrato que melhor
                  <br /> atende seus objetivos
                </span>
              </Typography>
            ) : (
              <Typography textAlign="center" variant="h2">
                Now just choose the{" "}
                <span style={{ color: "#4733FF" }}>
                  contract that best meets your goals
                </span>
              </Typography>
            )}
          </Grid>
          <Accordion
            defaultExpanded
            sx={{
              width: "90%",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                background:
                  "linear-gradient(90.16deg, #4733FF 27%, #FFFFFF 125.09%)",
                boxShadow:
                  "0px 1px 2px rgba(97, 97, 97, 0.2), 0px 2px 4px rgba(97, 97, 97, 0.2)",
                borderRadius: "8px",
              }}
            >
              <Typography className="accordion-header">
                {t("checkNowBetterContracts")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                backgroundColor: "#FAFAFA",
                borderRadius: "0px 0px 16px 16px",
              }}
            >
              <Grid display="flex">
                <Grid flex={1} mt={6}>
                  <section class="grid grid-template-columns-1">
                    <Typography class="grid-header">
                      {t("coinQuantity1")} <br />
                      {t("coinQuantity2")}
                    </Typography>
                    <Typography class="grid-header">
                      {t("value")} (US$)
                    </Typography>
                    <Typography class="grid-header">
                      12 {t("months")}
                    </Typography>
                    <Typography class="grid-header">
                      24 {t("months")}
                    </Typography>
                    <Typography class="grid-header">
                      36 {t("months")}
                    </Typography>
                  </section>
                  {accordionValues.map((e) => {
                    return (
                      <section class="grid grid-template-columns-1">
                        <Typography class="item item-light item1">{`${getCurrencyFormat(
                          e.value1
                        )} ${t("to")} ${getCurrencyFormat(
                          e.value2
                        )}`}</Typography>
                        <Typography class="item item-light item2">{`${
                          e.value3
                        } ${t("to")} ${e.value4}`}</Typography>
                        <Typography class="item item-bold item3">
                          {e.value5}
                        </Typography>
                        <Typography class="item item-bold item4">
                          {e.value6}
                        </Typography>
                        <Typography class="item item-bold item5">
                          {e.value7}
                        </Typography>
                      </section>
                    );
                  })}
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      )}
    </>
  );
};
