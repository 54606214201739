import { Container, Grid, Typography } from "@mui/material";

import useWindowDimensions from "../../../hooks/useWindowDimensions";

import { useTranslation } from "react-i18next";

import bestBenefits from "../../../imagens/Benefits.png";
import benefitsMobile from "../../../imagens/BenefitsMobile.png";

export const MelhoresVantagens = (props) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  return (
    <>
      {width > 900 ? (
        <>
          <Grid container alignItems="center" justifyContent="center" mt="4rem">
            <img
              src={bestBenefits}
              width="90%"
              style={{ maxWidth: "1400px" }}
              alt="Fundo Cinza Web"
            />
            <Grid
              container
              position="absolute"
              alignItems="center"
              justifyContent="center"
            >
              <Grid
                container
                direction="column"
                alignItems="flex-end"
                maxWidth={{ md: 700, lg: 950, xl: 1200 }}
              >
                <Typography
                  fontFamily="Montserrat"
                  fontWeight={400}
                  fontSize={{ md: "3.1vw", xl: 56 }}
                  lineHeight="1.2em"
                  color="#232340"
                  dangerouslySetInnerHTML={{
                    __html: t("melhoresVantagensWeb"),
                  }}
                />
                <Typography
                  pt="2vw"
                  fontFamily="Montserrat"
                  fontWeight={400}
                  fontSize={{ md: "1.05vw", xl: 19 }}
                  color="#8B8B8B"
                  lineHeight="2em"
                  dangerouslySetInnerHTML={{
                    __html: t("naKahshWeb"),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <Container maxWidth="md">
          <Grid container alignItems="center" justifyContent="center" py={5}>
            <img src={benefitsMobile} alt="Fundo Cinza Mobile" />

            <Grid
              container
              direction="column"
              position="absolute"
              padding={{
                xs: "40rem 0rem 0rem 5rem",
                sm: "58rem 0rem 0rem 8rem",
              }}
            >
              <Typography
                fontSize={{ xs: "7.5vw", sm: "7vw" }}
                lineHeight="1.5em"
                fontWeight={700}
                color="#232340"
                dangerouslySetInnerHTML={{
                  __html: t("melhoresVantagensMobile"),
                }}
              />
              <Typography
                fontSize={{ xs: "4.5vw", sm: "4vw" }}
                lineHeight="1.6em"
                fontWeight={400}
                pt="4rem"
                color="#8B8B8B"
                dangerouslySetInnerHTML={{
                  __html: t("naKahshMobile"),
                }}
              />
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
};
