// import { useState, useEffect } from "react";
import { Grid, Box, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";

//import { ButtonBorder } from "../../buttonsBorderWhite/ButtonBorder";

import Atom from "../../../imagens/Atom.png";

import "./KahshStoreNumeros.styles.css";
import useSharedLink from "../../../hooks/useSharedLink";

export const KahshStoreNumeros = () => {
  useSharedLink();
  // const [isWidth, setIsWidth] = useState(false);
  const matches = useMediaQuery("(min-width:767px)");
  const { t } = useTranslation();

  // useEffect(() => {
  //   console.log(matches);
  //   setIsWidth(matches);
  // }, [window.innerWidth]);

  return (
    <>
      {!matches ? (
        <Grid
        item
          xs={12}
          container
          justifyContent="center"
          alignItems="center"
          paddingTop="40px"
          paddingBottom="40px"
          flexDirection="column"
          className="SectionContainer"
        >
          <Box className="BoxContainer">
            <img src={Atom} alt="" />
            <Box className="BoxInsideContainer">
              <Typography className="BoxHeader">{t("14M")}</Typography>
              <Typography className="BoxBody">
                {t("kahshStoreNumerosCompras")}
              </Typography>
            </Box>
            <Box className="BoxInsideContainer">
              <Typography className="BoxHeader">{t("30M")}</Typography>
              <Typography className="BoxBody">
                {t("kahshStoreNumerosProdutos")}
              </Typography>
            </Box>
          </Box>
        </Grid>
      ) : (
        <Grid
        item
          xs={12}
          container
          justifyContent="center"
          alignItems="center"
          paddingTop="40px"
          paddingBottom="40px"
          flexDirection="column"
          className="SectionContainer"
        >
          <Box className="BoxContainer">
            <img src={Atom} alt="" />
            <Box className="BoxInsideContainer">
              <Typography className="BoxHeader">{t("14M")}</Typography>
              <Typography className="BoxBody">
                {t("kahshStoreNumerosCompras")}
              </Typography>
            </Box>
            <Box className="BoxInsideContainer">
              <Typography className="BoxHeader">{t("30M")}</Typography>
              <Typography className="BoxBody">
                {t("kahshStoreNumerosProdutos")}
              </Typography>
            </Box>
          </Box>
        </Grid>
      )}
    </>
  );
};
