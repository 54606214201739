import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import BgProjects from "../../../imagens/bg-kahsh-labs-projects.png";
import BgProjectsMobile from "../../../imagens/bg-kahsh-labs-projects-mobile.png";
import IconKahshProjects from "../../../imagens/icon-kahsh-labs-services.png";

import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

import KahshLabsImage from "../../../imagens/KahshLabs.png";
import KahshPortalImage from "../../../imagens/KahshPortal.png";
import KahshExchangeImage from "../../../imagens/KahshExchange.png";
import KahshPayImage from "../../../imagens/KahshPay.png";
import KahshEcosystemImage from "../../../imagens/KahshEcosystem.png";

function ProjectsDescription({ title, description }) {
  const { t } = useTranslation();
  return (
    <Grid
      container
      item
      md={3}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Grid
        container
        height={64}
        sx={{
          backgroundImage: `url(${IconKahshProjects})`,
          backgroundSize: "64px 64px",
          backgroundPosition: "left",
          backgroundRepeat: "no-repeat",
        }}
      />

      <Grid container direction="column" textAlign="left" pt={3}>
        <Typography fontSize="24px" fontWeight={700} color="#000929">
          {t(title)}
        </Typography>
        <Typography
          width="100%"
          fontSize="16px"
          fontWeight={400}
          color="#4D5461"
          pt={3}
        >
          {t(description)}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default function KahshLabsProjects() {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  const [imagesArray, setImagesArray] = useState([
    {
      src: KahshLabsImage,
      href: "/kahshLabs",
    },
    { src: KahshPortalImage, href: "/" },
    { src: KahshExchangeImage, href: "https://exchange.kahsh.com" },
    { src: KahshPayImage, href: "https://app.kahshpay.com" },
    { src: KahshEcosystemImage, href: "/#ecosystem" },
  ]);

  const configs = {
    0: {
      href: imagesArray[0].href,
      position: "absolute",
      top: { xs: "35%", md: "28%" },
      left: 0,
      zIndex: 1,
      height: 205,
      width: 380,
    },
    1: {
      href: imagesArray[1].href,
      position: "absolute",
      top: "21%",
      left: "8%",
      zIndex: 2,
      height: 290,
      width: "35%",
    },
    2: {
      href: imagesArray[2].href,
      position: "relative",
      zIndex: 3,
      height: 395,
      width: { xs: "70%", md: "45%" },
    },
    3: {
      href: imagesArray[3].href,
      position: "absolute",
      top: "21%",
      right: "8%",
      zIndex: 2,
      height: 290,
      width: "35%",
    },
    4: {
      href: imagesArray[4].href,
      position: "absolute",
      top: { xs: "35%", md: "28%" },
      zIndex: 1,
      height: 205,
      width: 380,
      right: 0,
    },
  };

  const HandleNextImage = () => {
    let tempArr = [...imagesArray];
    let shift = tempArr.shift();
    tempArr.push(shift);
    setImagesArray(tempArr);
  };

  const HandlePreviousImage = () => {
    let tempArr = [...imagesArray];
    let pop = tempArr.pop();
    tempArr.unshift(pop);
    setImagesArray(tempArr);
  };

  const handleImageSelection = (index) => {
    let tempArr = [...imagesArray];

    let el = tempArr.splice(index, 1);
    console.log(el);

    let arr = [];
    arr[2] = el[0];

    console.log(arr);

    [0, 1, 2, 3, 4].forEach((e, index) => {
      if (index === 2) {
      } else if (index > 2) {
        arr[index] = tempArr[index - 1];
      } else {
        arr[index] = tempArr[index];
      }
    });

    setImagesArray(arr);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      HandleNextImage();
    }, 5000);

    return () => {
      clearInterval(timer);
    };
  });

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      height={{ xs: 1647, md: 1300 }}
      my={4}
      sx={{
        backgroundImage: {
          xs: `url(${BgProjectsMobile})`,
          md: `url(${BgProjects})`,
        },
        backgroundSize: {
          xs: "1440px 1647px",
          md: "1440px 1300px",
          lg: "100% 100%",
        },
        backgroundRepeat: "no-repeat",
      }}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        textAlign="center"
        py={{ xs: 1, md: 3 }}
      >
        <Typography
          fontSize={{ xs: "20px", md: "45px" }}
          fontWeight={{ xs: 700, md: 400 }}
          sx={{
            background:
              "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(90.26deg, #8889F1 -85.54%, #1DC9FF -11.67%, #867BFF 62.2%, #D4B2FF 136.06%)",
            backgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
          dangerouslySetInnerHTML={{
            __html:
              width > 900
                ? t("developedProjects")
                : t("developedProjectsMobile"),
          }}
        />

        <Typography
          fontSize={{ xs: "18px", md: "28px" }}
          fontWeight={400}
          color="#1C1439"
          pt={3}
          px={1}
          width="100%"
        >
          {t("kahshLabsSoftwareFactory")}
          <br /> {t("implementationSolutionsDeveloped")}
        </Typography>
      </Grid>

      <Grid
        container
        width={{ xs: "95%" }}
        height={{ xs: 174, md: 501 }}
        position="relative"
        alignItems="center"
        justifyContent="center"
        mt={-10}
        display={{ xs: "none", md: "flex" }}
      >
        {imagesArray.map((image, index) => (
          <Box
            key={configs[index]}
            sx={{
              position: configs[index].position,
              height: configs[index].height,
              width: configs[index].width,
              top: configs[index].top,
              left: configs[index].left,
              right: configs[index].right,
              zIndex: configs[index].zIndex,
              borderRadius: 6,
              cursor: "pointer",
              "&:hover": {
                boxShadow: "0px 0px 15px 5px #1C1439",
              },
            }}
          >
            <img
              src={imagesArray[index].src}
              alt="Kahsh Sites"
              onClick={() => window.open(configs[index].href)}
              style={{ borderRadius: "inherit", width: "100%", height: "100%" }}
            />
          </Box>
        ))}
      </Grid>
      <Grid
        display={{ xs: "none", md: "flex" }}
        container
        mt={-15}
        justifyContent="center"
      >
        <Button
          sx={{ flexDirection: "column" }}
          onClick={() => HandlePreviousImage()}
        >
          <ChevronLeft fontSize={"large"} />
        </Button>
        <Grid
          width={100}
          mt={-1}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          {new Array(5).fill().map((input, index) => (
            <input
              key={input}
              type="radio"
              value={index}
              name="radioAnswer"
              onClick={() => handleImageSelection(index)}
            />
          ))}
        </Grid>
        <Button
          sx={{ flexDirection: "column" }}
          onClick={() => HandleNextImage()}
        >
          <ChevronRight fontSize={"large"} />
        </Button>
      </Grid>

      <Grid
        mt={-15}
        container
        justifyContent="space-between"
        px={{ xs: 5, md: 10 }}
        py={{ xs: 1, md: 5 }}
        gap={{ xs: 2, md: 0 }}
      >
        <ProjectsDescription
          title="ProjectsTitle1"
          description="ProjectsDescription1"
        />
        <ProjectsDescription
          title="ProjectsTitle2"
          description="ProjectsDescription2"
        />
        <ProjectsDescription
          title="ProjectsTitle3"
          description="ProjectsDescription3"
        />
        <ProjectsDescription
          title="ProjectsTitle4"
          description="ProjectsDescription4"
        />
      </Grid>
    </Grid>
  );
}
