import { useState, useEffect } from "react";
import SmoothScroll from "smooth-scroll";

import { GanheCripto } from "../components/StakingPage/ganheCripto/GanheCripto";
import { KahshPayVejaQuantoBlack } from "../components/StakingPage/kahshPayVejaQuantoBlack/KahshPayVejaQuantoBlack";
import { SobreAKahsh } from "../components/StakingPage/sobreaKahsh/SobreAKahsh";
import { ProntoParaTerKahsh } from "../components/StakingPage/prontoParaTerKahsh/ProntoParaTerKahsh";
import { Footer } from "../components/footer/Footer2";
import { Navigation } from "../components/navigation/Navigation";
import JsonData from "../data/data.json";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

export function StakingPage() {
  const [landingPageData, setLandingPageData] = useState({});

  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);
  // .
  return (
    <div>
      <Navigation data={landingPageData.Navigation} />
      <GanheCripto data={landingPageData.GanheCripto} />
      <KahshPayVejaQuantoBlack data={landingPageData.KahshPayVejaQuantoBlack} />
      <SobreAKahsh data={landingPageData.SobreAKahsh} />
      <ProntoParaTerKahsh data={landingPageData.ProntoParaTerKahsh} />
      <Footer data={landingPageData.Footer} />
    </div>
  );
}
