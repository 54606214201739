import React from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import BgDark from "../../../imagens/dark-bg-kahsh-labs.png";
import BgDarkMobile from "../../../imagens/dark-bg-kahsh-labs-mobile.png";
import ImgServices from "../../../imagens/img-kahsh-labs-services.png";

export default function KahshLabsServices() {
  const { t } = useTranslation();

  return (
    <Grid
      container
      height={{ xs: 1250, md: 600 }}
      my={4}
      sx={{
        backgroundImage: { xs: `url(${BgDarkMobile})`, md: `url(${BgDark})` },
        backgroundSize: {
          xs: "1440px 1250px",
          md: "1440px 600px",
          lg: "100% 100%",
        },
        backgroundRepeat: "no-repeat",
      }}
    >
      <Grid
        container
        alignItems="center"
        justifyContent={{ xs: "center", md: "space-between" }}
      >
        <Grid
          container
          item
          xs={12}
          md={6}
          height={{ xs: 330, md: 364, lg: 430 }}
          sx={{
            backgroundImage: `url(${ImgServices})`,
            backgroundSize: {
              xs: "328px 264px",
              md: "428px 364px",
              xl: "534px 430px",
            },
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        />

        <Grid
          container
          item
          xs={12}
          md={6}
          direction="column"
          textAlign={{ xs: "center", md: "left" }}
          px={{ xs: 2, md: 0 }}
        >
          <Typography
            fontSize={{ xs: "52px", md: "64px" }}
            fontWeight={700}
            color="#FFFFFF"
            pb={2}
          >
            {t("servicesKahshLabs")}
          </Typography>

          <Typography
            fontSize={{ xs: "18px", md: "14px", lg: "16px", xl: "18px" }}
            fontWeight={500}
            color="#FFFFFF"
          >
            {t("SoftwareFactory")}
            <br /> {t("developedSolutions")}
            <br /> {t("alwaysDeliveringCustomizedSystems")}
            <br /> {t("everyDeal")}
          </Typography>

          <Typography
            fontSize={{ xs: "18px", md: "14px", lg: "16px", xl: "18px" }}
            fontWeight={500}
            color="#FFFFFF"
            py={3}
          >
            {t("creationBlockchainSolutions")}
            <br />
            {t("creationSmartContracts")}
          </Typography>

          <Typography
            fontSize={{ xs: "18px", md: "14px", lg: "16px", xl: "18px" }}
            fontWeight={500}
            color="#FFFFFF"
          >
            {t("businessMigrationMetaverse")}
            <br />
            {t("custom3DEnvironments")}
            <br />
            {t("metaverseAndElaboration")}
            <br />
            {t("ecommerceAndEventsMetaverse")}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
