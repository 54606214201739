import { useEffect } from "react";
import { Grid, Typography, Button } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import { useAuthState } from "react-firebase-hooks/auth";
import { useLocation } from "react-router-dom";

import { auth } from "../../../services/firebase";
import { Calculadora } from "../../calculadora/Calculadora";
import { Navigation } from "../../navigation/Navigation";
import "./CalculadoraOn.styles.css";
import useSharedLink from "../../../hooks/useSharedLink";


export const CalculadoraOn = () => {
  useSharedLink();

  const { t } = useTranslation();
  const matches = useMediaQuery("(min-width:1279px)");
  const [user, loading] = useAuthState(auth);
  const location = useLocation();
  //const media = useMediaQuery("(max-width:1279)");

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) {
      // history.push("/extrato")
    }
  }, [user, loading]);

  return (
    <>
      {!matches ? (
        <>
          <Grid mb={10}>
            <Navigation />
          </Grid>
          <Grid container item xs={12} id="kahshPayVejaQuanto">
            {/* container dos primeiros boxs*/}
            <Grid
              width="100%"
              className="doisbox"
              alignContent="center"
              alignSelf="center"
              display="flex"
              justifyContent="space-around"
              alignItems="center"
            >
              {/* box conecte sua carteira*/}
              <Grid mb={6} className="box1">
                <Typography pl={1.5} pr={1.5} variant="h2" className="title1">
                  {t("connectYourWallet")}
                </Typography>
                <Typography pl={1.5} pr={1.5} pb={3} className="subtitle1">
                  {t("digitalWallet")}
                </Typography>

                {/* box do botão conecte sua carteira*/}
                <Grid pr={4} display="flex" justifyContent="end">
                  <Grid
                    alignItems="center"
                    className="boxButtonConectWallet"
                    display="flex"
                    justifyContent="center"
                    ml={4}
                  >
                    {user ? (
                      <Button disableRipple href="#invest">
                        {t("subscribeNow")}
                      </Button>
                    ) : (
                      <Button
                        disableRipple
                        href={
                          "/authentication?return=" +
                          location.pathname +
                          "&destination=0"
                        }
                      >
                        {t("connect")}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              {/* boxcrie sua carteira*/}
              <Grid mb={6} className="box2">
                <Typography pl={1.5} pr={1.5} variant="h2" className="title1">
                  {t("createYourWallet")}
                </Typography>
                <Typography pl={1.5} pr={1.5} pb={3} className="subtitle1">
                  {t("longerDigitalWallet")}
                </Typography>

                {/* box do botão conecte sua carteira*/}
                <Grid pr={4} display="flex" justifyContent="end">
                  <Grid
                    alignItems="center"
                    className="boxButtonConectWallet"
                    display="flex"
                    justifyContent="center"
                    ml={4}
                  >
                    <Button
                      href="https://metamask.io/download/"
                      target="_blank"
                    >
                      {t("createWallet")}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* calculador */}
            <Grid width="96%" borderRadius={31} ml={2}>
              <Calculadora />
            </Grid>

            {/* checkbox */}
          </Grid>
        </>
      ) : (
        <>
          <Grid mb={10} mt={-10}>
            <Navigation />
          </Grid>

          <Grid container item xs={12} id="kahshPayVejaQuanto">
            {/* container dos primeiros boxs*/}
            <Grid
              pb={5}
              pt={10}
              width="100%"
              className="doisbox"
              alignContent="center"
              alignSelf="center"
              display="flex"
              justifyContent="space-around"
              alignItems="center"
            >
              {/* box conecte sua carteira*/}
              <Grid mb={6} className="box1">
                <Typography mt={3} pl={3} variant="h2" className="title1">
                  {t("connectYourWallet")}
                </Typography>
                <Typography mt={2} pl={3} pb={3} className="subtitle1">
                  {t("digitalWallet")}
                </Typography>

                {/* box do botão conecte sua carteira*/}
                <Grid mt={2} pr={6} display="flex" justifyContent="end">
                  <Grid
                    alignItems="center"
                    className="boxButtonConectWallet"
                    display="flex"
                    justifyContent="center"
                  >
                    {user ? (
                      <Button disableRipple href="#invest">
                        {t("subscribeNow")}
                      </Button>
                    ) : (
                      <Button
                        disableRipple
                        href={
                          "/authentication?return=" +
                          location.pathname +
                          "&destination=0"
                        }
                      >
                        {t("connect")}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              {/* box crie sua carteira*/}
              <Grid mb={6} className="box2">
                <Typography mt={3} pl={3} variant="h2" className="title1">
                  {t("createYourWallet")}
                </Typography>
                <Typography mt={2} pl={3} pb={3} className="subtitle1">
                  {t("longerDigitalWallet")}
                </Typography>

                {/* box do botão conecte sua carteira*/}
                <Grid mt={2} pr={6} display="flex" justifyContent="end">
                  <Grid
                    alignItems="center"
                    className="boxButtonConectWallet"
                    display="flex"
                    justifyContent="center"
                  >
                    <Button
                      href="https://metamask.io/download/"
                      target="_blank"
                    >
                      {t("createWallet")}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* Calculadora */}
            <Calculadora />

            {/* checkbox */}
          </Grid>
        </>
      )}
    </>
  );
};
