import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { firebaseConfig } from "./global";

// const firebaseConfig = {
//   apiKey: "AIzaSyBexLxaS-mEOffbR1W3z0Qt3NmIRqxn25U",
//   authDomain: "kahshpay.firebaseapp.com",
//   databaseURL: "https://kahshpay-default-rtdb.firebaseio.com",
//   projectId: "kahshpay",
//   storageBucket: "kahshpay.appspot.com",
//   messagingSenderId: "439436220162",
//   appId: "1:439436220162:web:66a6781ba2d55be5bd941b",
//   measurementId: "G-N9BHXELD2D",
// };

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const database = getDatabase(app);

const logInWithEmailAndPassword = async (email, password) => {
  try {
    return await signInWithEmailAndPassword(auth, email, password);
  } catch (error) {
    throw error;
  }
};

const registerWithEmailAndPassword = async (email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;

    await addDoc(collection(db, "users"), {
      uid: user.uid,
      authProvider: "local",
      email,
      provider: localStorage.getItem("idShared"),
    });
    return user;
  } catch (error) {
    throw error;
  }
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
  } catch (err) {
    throw err;
  }
};

const logout = () => {
  signOut(auth);
  localStorage.setItem("isLogin", false);
};

export {
  auth,
  db,
  database,
  signInWithEmailAndPassword,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
};
