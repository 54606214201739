import { Container, Grid, Typography } from "@mui/material";

import useWindowDimensions from "../../../hooks/useWindowDimensions";

import { useTranslation } from "react-i18next";

import RecebaImformacoesIMG from "../../../imagens/RecebaImformacoes.png";
import RecebaInformacoesMbIMG from "../../../imagens/RecebaInformacoesMb.png";
import { Button } from "../../Button/Button";

export const RecebaInformacoes = (props) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  return (
    <>
      {width > 900 ? (
        <Grid container item alignItems="center" justifyContent="center">
          <img src={RecebaImformacoesIMG} width="80%" alt="Imagem de Fundo" />

          <Grid
            container
            position="absolute"
            direction="column"
            alignItems="center"
            justifyContent="center"
            pb={{ md: "33vw", xl: 60 }}
          >
            <Typography
              fontFamily="Montserrat"
              fontWeight={500}
              fontSize={{ md: "3.5vw", xl: 64 }}
              color="#FFFFFF"
              textAlign="center"
              lineHeight="1em"
              dangerouslySetInnerHTML={{
                __html: t("exchangeReceivedInformation"),
              }}
            />

            <Typography
              fontFamily="Montserrat"
              font-weight={400}
              fontSize={{ md: "1.5vw", xl: 24 }}
              textAlign="center"
              color="#FFFFFF"
              lineHeight="1.2em"
              py={{ md: 2.5, lg: 4 }}
              dangerouslySetInnerHTML={{
                __html: t("exchangeFollowAdvantages"),
              }}
            />
            <Button
              size="medium"
              sx={{
                backgroundColor: "#FFFFFF",
                width: { md: "18%", lg: "16%" },
                maxWidth: 400,
                fontWeight: "700",
                color: "#2A288B",
                fontSize: { md: "1vw", xl: 16 },
                "&:hover": {
                  color: "#FFFFFF",
                },
              }}
              onClick={() =>
                (window.location.href = "https://exchange.kahsh.com/login")
              }
            >
              {t("exchangeReceivedInformationBTN")}
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Grid container alignItems="center" justifyContent="center">
          <img
            src={RecebaInformacoesMbIMG}
            width="100%"
            alt="Imagem de Fundo Mobile"
          />

          <Grid
            container
            direction="column"
            position="absolute"
            alignItems="center"
            justifyContent="center"
            pb="85vw"
          >
            <Typography
              fontFamily="Montserrat"
              textAlign="center"
              fontWeight={600}
              fontSize="7.2vw"
              color="#FFFFFF"
              lineHeight="1.2em"
              dangerouslySetInnerHTML={{
                __html: t("exchangeReceivedInformation"),
              }}
            />

            <Typography
              fontWeight={400}
              fontSize="3.3vw"
              color="#FFFFFF"
              textAlign="center"
              lineHeight="1.5em"
              pt={{ xs: "2rem", sm: "4rem" }}
              pb={{ xs: "4rem", sm: "8rem" }}
              dangerouslySetInnerHTML={{
                __html: t("exchangeFollowAdvantagesMobile"),
              }}
            />
            <Button
              size="medium"
              sx={{
                backgroundColor: "#FFFFFF",
                width: { xs: "70%", sm: "60%" },
                padding: { xs: "4rem", sm: "5rem" },
                fontWeight: "700",
                color: "#2A288B",
                fontSize: "4vw",
              }}
              onClick={() =>
                (window.location.href = "https://exchange.kahsh.com/login")
              }
            >
              {t("exchangeReceivedInformationBTN")}
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};
