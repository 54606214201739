import { ButtonProps as MuiButtonProps, CircularProgress } from "@mui/material";
import { ButtonStyled } from "./Button.styles";

interface ButtonProps extends MuiButtonProps {
  loading?: boolean;
}
export const Button: React.FC<ButtonProps> = ({
  loading,
  children,
  variant = "contained",
  size = "large",
  disabled,
  sx,
  ...rest
}) => {
  return (
    <ButtonStyled
      fullWidth
      variant={variant}
      size={size}
      disabled={disabled || loading}
      {...rest}
      sx={sx}
    >
      {loading ? (
        <CircularProgress sx={{ color: "#fff", display: "flex", height: 36 }} />
      ) : (
        children
      )}
    </ButtonStyled>
  );
};
