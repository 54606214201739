import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

import BackgroundGetKahshCardVisa from "../../../imagens/BackgroundGetKahshCardVisa.png";
import BackgroundGetKahshCardVisaMobile from "../../../imagens/BackgroundGetKahshCardVisaMobile.png";
import CardVisaBox from "../../../imagens/cardvisa-box.png";

import "./GetKahshCardVisa.styles.css";

export const GetKahshCardVisa = () => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  return (
    <Grid
      container
      sx={{
        backgroundImage: {
          xs: `url(${BackgroundGetKahshCardVisaMobile})`,
          md: `url(${BackgroundGetKahshCardVisa})`,
        },
        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
      height={{ xs: 1000, sm: 700, md: "450" }}
      px={{ xs: 0, md: 10, lg: 12, xl: 25 }}
      my={2}
    >
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        my={{ xs: 2, md: 0 }}
      >
        <Grid
          container
          item
          height={{ xs: "400px", md: "75%" }}
          width={{ xs: "300px", md: "328px" }}
          xs={12}
          md={6}
          lg={4}
          sx={{
            backgroundImage: `url(${CardVisaBox})`,
            backgroundSize: {
              xs: width < 330 ? "300px 250px" : "380px 300px",
              md: "400px 100%",
            },
            backgroundRepeat: "no-repeat",
            backgroundPosition: { xs: "center" },
          }}
        />

        <Grid
          container
          item
          direction="column"
          alignItems={{ xs: "center", md: "end" }}
          xs={12}
          md={6}
          lg={8}
        >
          <Typography
            width="100%"
            color="#FFFFFF"
            fontSize={{ xs: "32px", md: "40px", lg: "48px" }}
            textAlign={{ xs: "center", md: "end" }}
          >
            {t("getOne")}
          </Typography>
          <Typography
            width="100%"
            fontWeight={700}
            fontSize={{ xs: "40px", md: "50px", lg: "64px" }}
            textAlign={{ xs: "center", md: "end" }}
            sx={{
              background:
                "linear-gradient(90.26deg, #8889F1 -85.54%, #1DC9FF -11.67%, #867BFF 62.2%, #D4B2FF 136.06%)",
              backgroundClip: "text",
              textFillColor: "transparent",
            }}
          >
            {t("cardKahshVisa")}
          </Typography>
          <Typography
            fontSize="24px"
            color="#EBEBEB"
            textAlign={{ xs: "center", md: "end" }}
            dangerouslySetInnerHTML={{
              __html: t("cardPhysicalAndVirtual"),
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
