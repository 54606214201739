import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import Cards from "../../../imagens/Exchange-Cards.png";
import Bell from "../../../imagens/Bell.png";
import Arrows from "../../../imagens/Exchange-Arrows.png";
import Megaphone from "../../../imagens/Megaphone.png";

export const ExchangeCards = () => {
  const { t } = useTranslation();

  const items = [
    {
      id: 0,
      img: Cards,
      header: t("mountDigitalWallet"),
      body: t("mountDigitalWalletBody"),
    },
    {
      id: 1,
      img: Bell,
      header: t("realtimeInfo"),
      body: t("realtimeInfoBody"),
    },
    {
      id: 2,
      img: Arrows,
      header: t("simplifyiedTransactions"),
      body: t("simplifyiedTransactionsBody"),
    },
    {
      id: 3,
      img: Megaphone,
      header: t("knowEverything"),
      body: t("knowEverythingBody"),
    },
  ];

  return (
    <Grid
      container
      mt={5}
      py={10}
      bgcolor="#FAFAFA"
      justifyContent="space-evenly"
      flexWrap="wrap"
      id="exchangeCardsContainer"
    >
      {items.map((item) => (
        <Grid
          key={item.id}
          container
          item
          maxWidth={300}
          bgcolor="#ffffff"
          mt={2}
          flexWrap="wrap"
          border="1px solid #d8d8d8"
          borderRadius="2rem"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          px={1}
          py={4}
        >
          <img src={item.img} alt="" srcset="" />
          <Typography mt={2} color="#001C57" fontWeight={700} fontSize={24}>
            {item.header}
          </Typography>
          <Typography mt={2} color="#8B8B8B" fontWeight={400} fontSize={20}>
            {item.body}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};
