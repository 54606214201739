import { useEffect, useState } from "react";
import {
  Grid,
  InputLabel,
  TextField,
  Typography,
  Button,
  Modal,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";

import axios from "axios";
import { cpf, cnpj } from "cpf-cnpj-validator";
import { validate } from "react-email-validator";
import InputMask from "react-input-mask";
import { useLocation } from "react-router";
import { useMemo } from "react";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { initializeApp } from "firebase/app";

import useWindowDimensions from "../../hooks/useWindowDimensions";
import { firebaseConfig } from "../../services/global";

import "./Register.styles.css";
import moment from "moment";

export function Register() {
  const [name, setName] = useState("");
  const [cpfOrCnpj, setCpfOrCnpj] = useState("");
  const [email, setEmail] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [mask, setMask] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [perguntaVenderProdutos, setPerguntaVenderProdutos] = useState(false);
  const [perguntaVenderCripto, setPerguntaVenderCripto] = useState(false);
  const [perguntaKahshLand, setPerguntaKahshLand] = useState(false);

  const [mesageErrorCPF, setMesageErrorCPF] = useState("");
  const [mesageErrorName, setMesageErrorName] = useState("");
  const [mesageErrorEmail, setMesageErrorEmail] = useState("");
  const [mesageErrorBirthDate, setMesageErrorBirthDate] = useState("");

  const [mensageModal, setMensageModal] = useState("");
  const [registerResult, setRegisterResult] = useState(null);
  const [responseModal, setresponseModal] = useState(false);
  const [waitingData, setWaitingData] = useState(false);

  const { t, i18n } = useTranslation();
  const { width } = useWindowDimensions();

  const { search } = useLocation();
  const urlParams = useMemo(() => new URLSearchParams(search), [search]);

  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);

  const register = async () => {
    axios
      .post(
        // "https://rxbrasil.dev.br/api/promoregistro",
        "https://exchange.kahsh.com/api/promoregistro",
        {
          nome_razao_social: name,
          cpf_cnpj: cpfOrCnpj,
          email: email,
          data_nascimento:
            i18n.language.substring(0, 2) == "pt"
              ? birthDate
              : setBirthDate(
                  `${birthDate.split("/")[1]}/${birthDate.split("/")[0]}/${
                    birthDate.split("/")[2]
                  }`
                ),
          api_key: urlParams.get("key"),
          // api_key: "c0c186eb5b1e8a25dab3fa814fbd7dd8",
          // telefone: "49988835976"
        },
        {
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          addDoc(collection(db, "promo"), {
            key: urlParams.get("key"),
            name: name,
            email: email,
            pergunta_vender_produtos: perguntaVenderProdutos,
            pergunta_vender_cripto: perguntaVenderCripto,
            pergunta_kahsh_land: perguntaKahshLand,
          });
        } else {
        }
        setRegisterResult(response.data.success);
        callResultModal(response.data.success, response.data.message);
      })
      .catch((error) => {
        window.alert("Erro desconhecido.");
      });
  };

  const callResultModal = (result, message) => {
    setRegisterResult(result);
    if (message === "Informe o seu nome por completo") {
      return;
    }
    if (result) {
      setMensageModal("registrySuccessInfo");
      setresponseModal(true);
    } else {
      if (message === "Já existe um usuário usando este CPF/CNPJ") {
        setMensageModal("cpfCnpjJaExiste");
      } else {
        setMensageModal("emailInUseError");
      }
      setresponseModal(true);
    }
  };

  useEffect(() => {
    validateAllForm();
    //console.log(birthDate);
  }, [cpfOrCnpj, email, birthDate, isValid]);

  const validateEmail = (email) => {
    // if (email.includes('@') && email.includes('.com')) {
    //     return;
    // }

    let ret = validate(email);
    if (ret) {
      setMesageErrorEmail("");
    } else {
      setMesageErrorEmail(t("invalidEmail"));
    }
    return ret;
  };

  const isValidCPF = (cpfCnpj) => {
    // if (cpfCnpj.length < 14) {
    //     return;
    // }
    let ret = cpf.isValid(cpfCnpj) || cnpj.isValid(cpfCnpj);
    if (ret) {
      setMesageErrorCPF("");
    } else {
      setMesageErrorCPF(t("cpfCnpjInvalido"));
    }
    return ret;
  };

  const isValidName = (name) => {
    if (name.length < 5) {
      setMesageErrorName(t("nomeInvalido"));
    } else {
      setMesageErrorName("");
    }
    return name;
  };

  const isValidBirthDate = (date) => {
    if (waitingData) {
      let result = moment(date, "DD/MM/YYYY").isValid();
      if (
        i18n.language.substring(0, 2) == "pt"
          ? !moment(date, "DD/MM/YYYY").isValid()
          : !moment(date, "MM/DD/YYYY").isValid()
      ) {
        setMesageErrorBirthDate(t("invalidBirthDate"));
      } else {
        //console.log("result",result );
        setMesageErrorBirthDate("");
      }

      return result;
    }
    setWaitingData(true);
  };

  const validateAllForm = () => {
    if (cpfOrCnpj.length > 0 && email.length > 0) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const handleClose = () => {
    setresponseModal(false);
  };

  const closeResponseModal = () => {
    if (registerResult) {
      window.location.href = "https://kahsh.com/";
    } else {
      setresponseModal(false);
    }
  };

  const checarData = () => {
    if (i18n.language.substring(0, 2) == "pt") {
      let teste = `${birthDate.split("/")[2]}/${birthDate.split("/")[1]}/${
        birthDate.split("/")[0]
      }`;
      let dataDigitada = new Date(teste);
      if (
        dataDigitada instanceof Date &&
        !isNaN(dataDigitada) &&
        dataDigitada < new Date()
      ) {
        register();
      } else {
        setMesageErrorBirthDate(t("invalidBirthDate"));
      }
    } else {
      let teste = `${birthDate.split("/")[2]}/${birthDate.split("/")[0]}/${
        birthDate.split("/")[1]
      }`;
      let dataDigitada = new Date(teste);
      if (
        dataDigitada instanceof Date &&
        !isNaN(dataDigitada) &&
        dataDigitada < new Date()
      ) {
        register();
      } else {
        setMesageErrorBirthDate(t("invalidBirthDate"));
      }
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
  };

  return (
    <>
      {width > 720 ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <Typography
            fontSize="2em"
            fontWeight="bold"
            color="#000000"
            lineHeight="1.2em"
            mb={2}
          >
            {t("registerData")}
          </Typography>

          <Typography
            fontSize="1.25em"
            fontWeight={400}
            color="##1a0e38"
            lineHeight="1.2em"
            textAlign="center"
          >
            {t("registerEnterData")}
          </Typography>

          <Grid
            pt={5}
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            className="inputsCards"
          >
            <Grid
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
            >
              <InputLabel id="label" htmlFor="formEmail">
                {t("fullName")}
              </InputLabel>

              <TextField
                placeholder={
                  i18n.language.substring(0, 2) === "pt"
                    ? t("fullName")
                    : t("fullName")
                }
                variant="outlined"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                onBlur={() => isValidName(name)}
              />
              <span className="Error">{mesageErrorName}</span>
            </Grid>

            <Grid
              py={1}
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
            >
              <InputLabel id="label" htmlFor="formEmail">
                {t("cpfCnpj")}
              </InputLabel>

              <CpfCnpj
                placeholder={
                  i18n.language.substring(0, 2) === "pt"
                    ? t("enterCpfOrCnpj")
                    : t("enterCpfOrCnpj")
                }
                className="fastBuyInput"
                variant="outlined"
                type="tel"
                value={cpfOrCnpj}
                onChange={(e, type) => {
                  setCpfOrCnpj(e.target.value);
                }}
                onBlur={() => isValidCPF(cpfOrCnpj)}
              />
              <span className="Error">{mesageErrorCPF}</span>
            </Grid>

            <Grid
              py={1}
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
            >
              <InputLabel id="label" htmlFor="formEmail">
                {t("emailAdress")}
              </InputLabel>
              <TextField
                placeholder={
                  i18n.language.substring(0, 2) === "pt"
                    ? t("enterEmail")
                    : t("enterEmail")
                }
                variant="outlined"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                onBlur={() => validateEmail(email)}
              />

              <span className="Error">{mesageErrorEmail}</span>
            </Grid>

            <Grid
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
            >
              <InputLabel id="label" htmlFor="formEmail">
                {t("BirthDate")}
              </InputLabel>

              <InputMask
                mask={"99/99/9999"}
                value={
                  i18n.language.substring(0, 2) == "pt"
                    ? birthDate.split("-").join("/")
                    : birthDate
                }
                onChange={(e) => {
                  setBirthDate(e.target.value);
                }}
                onBlur={() => isValidBirthDate(birthDate)}
              >
                <TextField
                  placeholder={
                    i18n.language.substring(0, 2) === "pt"
                      ? t("BirthDate")
                      : t("BirthDate")
                  }
                  value={
                    i18n.language.substring(0, 2) == "pt"
                      ? birthDate.split("/").join("/")
                      : birthDate
                  }
                />
              </InputMask>
              <span className="Error">{mesageErrorBirthDate}</span>
            </Grid>
          </Grid>

          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid pl={7.5}>
              <Grid display="flex">
                <input
                  type={"checkbox"}
                  onChange={() =>
                    setPerguntaVenderProdutos(!perguntaVenderProdutos)
                  }
                />
                <p className="labelCheckBox">{t("registerKahshStore")}</p>
              </Grid>

              <Grid display="flex">
                <input
                  type={"checkbox"}
                  onChange={() =>
                    setPerguntaVenderCripto(!perguntaVenderCripto)
                  }
                />
                <p className="labelCheckBox">{t("registerSales")}</p>
              </Grid>

              <Grid display="flex">
                <input
                  type={"checkbox"}
                  onChange={() => setPerguntaKahshLand(!perguntaKahshLand)}
                />
                <p className="labelCheckBox">{t("registerMetaverse")}</p>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            item
            my={3}
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              display="flex"
              justifyContent="center"
              alignItems="center"
              className="btnCompreKahshFast"
              style={{
                backgroundColor: isValid && email.length > 0 ? "" : "gray",
                cursor: isValid && email.length > 0 ? "pointer" : "unset",
              }}
            >
              <Button
                onClick={() => checarData()}
                // disabled={!isValid || email.length == 0}
              >
                {t("registerBtn")}
              </Button>
            </Grid>
          </Grid>

          <Modal
            open={responseModal}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <Grid
              className="boxModal"
              sx={{ ...style }}
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Grid
                className={registerResult ? "headerModal" : "headerModalRed"}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="h2" id="parent-modal-title">
                  {registerResult ? t("cadSuccess") : t("cadError")}
                </Typography>
              </Grid>

              <Typography
                py={4}
                className="subtitloModal"
                id="parent-modal-description"
              >
                {t(mensageModal)}
              </Typography>

              <Grid
                item
                container
                justifyContent="center"
                className="boxBtnModal"
                mr={18}
              >
                <Button onClick={() => closeResponseModal()}>{t("Ok")}</Button>
              </Grid>
            </Grid>
          </Modal>
        </Grid>
      ) : (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <Typography
            fontSize="2em"
            fontWeight="bold"
            color="#000000"
            lineHeight="1.2em"
            mb={2}
          >
            {t("registerData")}
          </Typography>

          <Typography
            fontSize="1.25em"
            fontWeight={400}
            color="##1a0e38"
            lineHeight="1.2em"
            textAlign="center"
          >
            {t("registerEnterData")}
          </Typography>

          <Grid
            pt={5}
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            className="inputsCards"
          >
            <Grid
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
            >
              <InputLabel id="label" htmlFor="formEmail">
                {t("fullName")}
              </InputLabel>

              <TextField
                placeholder={
                  i18n.language.substring(0, 2) === "pt"
                    ? t("fullName")
                    : t("fullName")
                }
                variant="outlined"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                onBlur={() => isValidName(name)}
              />
              <span className="Error">{mesageErrorName}</span>
            </Grid>

            <Grid
              py={1}
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
            >
              <InputLabel id="label" htmlFor="formEmail">
                {t("cpfCnpj")}
              </InputLabel>

              <CpfCnpj
                placeholder={
                  i18n.language.substring(0, 2) === "pt"
                    ? t("enterCpfOrCnpj")
                    : t("enterCpfOrCnpj")
                }
                className="fastBuyInput"
                variant="outlined"
                type="tel"
                value={cpfOrCnpj}
                onChange={(e, type) => {
                  setCpfOrCnpj(e.target.value);
                }}
                onBlur={() => isValidCPF(cpfOrCnpj)}
              />
              <span className="Error">{mesageErrorCPF}</span>
            </Grid>

            <Grid
              py={1}
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
            >
              <InputLabel id="label" htmlFor="formEmail">
                {t("emailAdress")}
              </InputLabel>
              <TextField
                placeholder={
                  i18n.language.substring(0, 2) === "pt"
                    ? t("enterEmail")
                    : t("enterEmail")
                }
                variant="outlined"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                onBlur={() => validateEmail(email)}
              />
              <span className="Error">{mesageErrorEmail}</span>
            </Grid>

            <Grid
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
            >
              <InputLabel id="label" htmlFor="formEmail">
                {t("BirthDate")}
              </InputLabel>

              <InputMask
                mask={"99/99/9999"}
                value={
                  i18n.language.substring(0, 2) == "pt"
                    ? birthDate.split("-").join("/")
                    : birthDate
                }
                onChange={(e) => {
                  setBirthDate(e.target.value);
                }}
                onBlur={() => isValidBirthDate(birthDate)}
              >
                <TextField
                  placeholder={
                    i18n.language.substring(0, 2) === "pt"
                      ? t("BirthDate")
                      : t("BirthDate")
                  }
                  value={
                    i18n.language.substring(0, 2) == "pt"
                      ? birthDate.split("/").join("/")
                      : birthDate
                  }
                />
              </InputMask>
              <span className="Error">{mesageErrorBirthDate}</span>
            </Grid>
          </Grid>

          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid pl={3} pr={3} width="100%">
              <Grid display="flex">
                <input
                  type={"checkbox"}
                  onChange={() =>
                    setPerguntaVenderProdutos(!perguntaVenderProdutos)
                  }
                />
                <p className="labelCheckBox">{t("registerKahshStore")}</p>
              </Grid>

              <Grid display="flex">
                <input
                  type={"checkbox"}
                  onChange={() =>
                    setPerguntaVenderCripto(!perguntaVenderCripto)
                  }
                />
                <p className="labelCheckBox">{t("registerSales")}</p>
              </Grid>

              <Grid display="flex">
                <input
                  type={"checkbox"}
                  onChange={() => setPerguntaKahshLand(!perguntaKahshLand)}
                />
                <p className="labelCheckBox">{t("registerMetaverse")}</p>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            item
            my={3}
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              display="flex"
              justifyContent="center"
              alignItems="center"
              className="btnCompreKahshFast"
              style={{
                backgroundColor: isValid && email.length > 0 ? "" : "gray",
                cursor: isValid && email.length > 0 ? "pointer" : "unset",
              }}
            >
              <Button
                onClick={() => checarData()}
                // disabled={!isValid || email.length == 0}
              >
                {t("registerBtn")}
              </Button>
            </Grid>
          </Grid>

          <Modal
            open={responseModal}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <Grid
              className="boxModal"
              sx={{ ...style }}
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Grid
                className={registerResult ? "headerModal" : "headerModalRed"}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="h2" id="parent-modal-title">
                  {registerResult ? t("cadSuccess") : t("cadError")}
                </Typography>
              </Grid>

              <Typography
                py={4}
                className="subtitloModal"
                id="parent-modal-description"
              >
                {t(mensageModal)}
              </Typography>

              <Grid
                item
                container
                justifyContent="center"
                className="boxBtnModal"
                mr={18}
              >
                <Button onClick={() => closeResponseModal()}>{t("Ok")}</Button>
              </Grid>
            </Grid>
          </Modal>
        </Grid>
      )}
    </>
  );
}
