// import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Box } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import { SaibaMaisButton } from "../../buttons/SaibaMaisButton";
import Diversidade from "../../../imagens/Diversidade.png";

import "./KahshStoreDiversidade.styles.css";
import useSharedLink from "../../../hooks/useSharedLink";

export const KahshStoreDiversidade = (props) => {
  useSharedLink();
  // const [isWidth, setIsWidth] = useState(false);
  const matches = useMediaQuery("(min-width:767px)");
  const { t } = useTranslation();

  // useEffect(() => {
  //   setIsWidth(matches);
  // }, [window.innerWidth]);

  return (
    <>
      {!matches ? (
        <Grid container className="diversidadeContainer">
          <Box className="BoxDiversidadeContainer">
            <Box className="BoxDiversidadeInsideContainer">
              <Typography class="BoxDiversidadeHeader">
                {t("kahshStoreDiversidadeHeader")}
              </Typography>
              <Typography class="BoxDiversidadeBody">
                {t("kahshStoreDiversidadeBody")}
              </Typography>
              <Box class="BoxDiversidadeBotao">
                <SaibaMaisButton>
                  {t("kahshStoreDiversidadeBotao")}
                </SaibaMaisButton>
              </Box>
            </Box>
            <img src={Diversidade} alt="" className="DiversidadeImage" />
          </Box>
        </Grid>
      ) : (
        <Grid container className="diversidadeContainer">
          <Box className="BoxDiversidadeContainer">
            <Box className="BoxDiversidadeInsideContainer">
              <Typography class="BoxDiversidadeHeader">
                {t("kahshStoreDiversidadeHeader")}
              </Typography>
              <Typography class="BoxDiversidadeBody">
                {t("kahshStoreDiversidadeBody")}
              </Typography>
              <Box class="BoxDiversidadeBotao">
                <SaibaMaisButton>
                  {t("kahshStoreDiversidadeBotao")}
                </SaibaMaisButton>
              </Box>
            </Box>
            <img src={Diversidade} alt="" className="DiversidadeImage" />
          </Box>
        </Grid>
      )}
    </>
  );
};
