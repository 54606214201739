import { useState } from "react";

import { Button, Grid, Typography } from "@mui/material";
import MobileStepper from "@mui/material/MobileStepper";

import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";

import i18n from "i18next";
import { useTranslation } from "react-i18next";

import useSharedLink from "../../../hooks/useSharedLink";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

import BackgroundStakeAzulComCarteira from "../../../imagens/BackgroundStakeAzulComCarteira.png";
import BackgroundStakeAzulComCarteiraLG from "../../../imagens/BackgroundStakeAzulComCarteiraLG.png";
import BackgroundAzulMobile from "../../../imagens/BackgroundAzulMobile.png";
import Shopping from "../../../imagens/shopping.png";
import Usability from "../../../imagens/usability.png";
import Ecosystem from "../../../imagens/ecosystem.png";
import PaymentMethod from "../../../imagens/paymentMethod.png";
import kahshLogoBlack from "../../../imagens/kahsh-logo-black.png";

import "./StakePorqueKahsh.styles.css";

export const StakePorqueKahsh = () => {
  const StakeFundoAzul = {
    height: 725,
    backgroundImage: `url(${BackgroundStakeAzulComCarteira})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: "100%",
    borderRadius: 15,
  };

  const StakeFundoAzulLG = {
    height: 1444,
    backgroundImage: `url(${BackgroundStakeAzulComCarteiraLG})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: 2650,
    borderRadius: 15,
  };

  const StakeFundoAzulMobile = {
    height: 982,
    backgroundImage: `url(${BackgroundAzulMobile})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: "100%",
    borderRadius: 15,
  };

  useSharedLink();
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  const items = [
    {
      image: Usability,
      body: t("whyKahshUsability"),
    },
    {
      image: Shopping,
      body: t("whyKahshShopping"),
    },
    {
      image: Ecosystem,
      body: t("whyKahshEcosystem"),
    },
    {
      image: PaymentMethod,
      body: t("whyKahshPayment"),
    },
  ];

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

  const descriptions = [
    {
      labelPt:
        "Uma <strong>moeda global,</strong> um<br/><strong>ambiente completo,</strong> e para você, o mundo de escolhas.",
      labelEn:
        "A <strong>global currency,</strong> a<br/> <strong>complete environment</strong>, and for you, a world of<br/>choices.",
    },
    {
      labelPt:
        "O primeiro marketplace com<br/>pagamento em criptomoedas<br/>é na <strong>Kahsh Store</strong>.",
      labelEn:
        "The first marketplace with<br/>payment in cryptocurrencies<br/>is at <strong>Kahsh Store</strong>.",
    },
    {
      labelPt:
        "Nosso ecossistema traz a<br/><strong>simplicidade</strong> e <strong>praticidade</strong><br/>no uso de criptomoedas.",
      labelEn:
        "Our ecosystem brings the <br/> <strong>uncomplication </strong>for the use of<br/> cryptocurrencies.",
    },
    {
      labelPt:
        "<strong>KahshPay</strong> realiza<br/>pagamentos tradicionais<br/>e com <strong>criptoativos</strong>.",
      labelEn:
        "Kahsh Pay is a payment method and <br/> <strong>global transfers. </strong>",
    },
    {
      labelPt:
        "<strong>KahshPay</strong> é o único<br/>que <strong>devolve taxas</strong><br/>para os lojistas.",
      labelEn:
        "Kahsh Pay is a payment method and <br/> <strong>global transfers. </strong>",
    },
  ];

  const descriptionsMobile = [
    {
      labelPt:
        "Uma <strong>moeda global,</strong><br/>um <strong>ambiente <br/> completo,</strong> e para <br/>você, o mundo de <br/>escolhas.",
      labelEn:
        "A <strong>global currency,</strong><br/>a complete <br/>environment,</strong> and for <br/>you, the world of <br/>choices.",
    },
    {
      labelPt:
        "O primeiro marketplace<br/>com pagamento em <br/>criptomoedas é na <br/><strong>Kahsh Store.</strong><br/><br/>",
      labelEn:
        "The first marketplace<br/>with payment in <br/>cryptocurrencies is at <br/><strong>Kahsh Store.</strong><br/><br/>",
    },
    {
      labelPt:
        "Nosso ecossistema traz<br/>a <strong>simplicidade</strong><br/>e <strong>praticidade</strong><br/>no uso de criptomoedas.<br/><br/>",
      labelEn:
        "Our ecosystem brings<br/><strong>simplicity</strong><br/>and <strong>practicality</strong><br/>in the use of cryptocurrencies.<br/><br/>",
    },
    {
      labelPt:
        "<strong>Kahsh Pay</strong> realiza<br/>pagamentos tradicionais<br/>e com <strong>criptoativos</strong>.<br/><br/><br/>",
      labelEn:
        "Kahsh Pay is a payment method and <br/> <strong>global transfers. </strong><br/><br/><br/><br/>",
    },
    {
      labelPt:
        "<strong>Kahsh Pay</strong> é o único<br/>que <strong>devolve taxas</strong><br/>para os lojistas.<br/><br/><br/>",
      labelEn:
        "Kahsh Pay is a payment method and <br/> <strong>global transfers. </strong><br/><br/><br/>",
    },
  ];

  const maxSteps = descriptions.length;
  const maxStepsMobile = descriptionsMobile.length;

  return (
    <>
      {width > 1064 ? (
        <Grid
          container
          mt={2}
          pt={16}
          sx={{
            background: "linear-gradient(to bottom, #FCFCFE, #FFFFFF)"
          }}
          px={width < 900 ? 5 : 0}
          id="stakePorqueKahsh"
          flexDirection="column"
          width='130%'
        >
          <Grid container justifyContent="center">
            <Grid container xs={12} md={10}>
              {width < 900 ? null : (
                <Grid xs={2.5}>
                  <img src={kahshLogoBlack} alt="" width={38}></img>
                </Grid>
              )}

              <Grid xs={12} md={9} container alignItems="center">
                {width > 900 ? null : (
                  <Grid>
                    <img src={kahshLogoBlack} alt="" width={38}></img>
                  </Grid>
                )}
                <Typography
                  ml={width < 900 ? 2 : 0}
                  fontSize={18}
                  color="#5468E7"
                  fontWeight="bold"
                  fontStyle="italic"
                >
                  {t("whyKahsh")}
                </Typography>
              </Grid>
            </Grid>

            {/* ======= CARROUSSEL COM TÍTULOS ======= */}
            <Grid container md={width > 1450 ? 6.5 : 7}>
              <Grid md={6} xs={12}
                ml={10}
              //sdsfdfsdfsdfsdfsdfsdfd
              >
                <AutoPlaySwipeableViews
                  index={activeStep}
                  onChangeIndex={handleStepChange}
                  interval={5000}
                >
                  {descriptions.map((step, index) => (
                    <div key={step.label}>
                      {Math.abs(activeStep - index) <= 2 ? (
                        <Grid >
                          <Typography
                            fontSize={width > 1450 ? 40 : 30}
                            mt={3}
                            color="#232340"
                            dangerouslySetInnerHTML={{
                              __html:
                                i18n.language.substring(0, 2) == "pt"
                                  ? step.labelPt
                                  : step.labelEn,
                            }}
                          />
                        </Grid>
                      ) : null}
                    </div>
                  ))}
                </AutoPlaySwipeableViews>
                <MobileStepper
                  steps={maxSteps}
                  position="static"
                  activeStep={activeStep}
                  className="stepperHomeWhayKahsh"
                />
              </Grid>
              <Grid md={2} ml={4} container justifyContent="flex-end">
                <Grid
                  item
                  md={6}
                >
                  <Button
                    className="button"
                    onClick={handleBack}
                    disabled={activeStep === 0}
                  >
                    <Typography className="arrow">←</Typography>
                  </Button>
                </Grid>
                <Grid item md={6}>
                  <Button
                    onClick={handleNext}
                    disabled={activeStep === maxSteps - 1}
                    className="button"
                  >
                    <Typography className="arrow">→</Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            {/* ======= FIM DO CARROUSSEL ======= */}
          </Grid>
          <Grid
            display="flex"
            alignItems="left"
            justifyContent="left"
            flexDirection="center"
            bgcolor='#FDFDFD'
            ml={-8}
            mr={8}
          >
            <Grid container justifyContent="center" xs={10} mt={12}

            >
              {items.map((e, index) => {
                return (
                  <Grid
                    lg={2.5}
                    px={7}
                    py={15}
                    bgcolor="#FFFFFF"
                    container
                    justifyContent="center"
                    alignItems={width < 1200 ? "center" : "baseline"}
                    textAlign="center"
                    flexWrap={width < 900 ? "nowrap" : null}
                    mb={width < 900 ? 3 : 0}
                    borderRadius={
                      width > 900
                        ? index === 0
                          ? "30px 0 0 30px"
                          : index === 3
                            ? "0 30px 30px 0"
                            : null
                        : "30px"
                    }
                    borderBottom="8px solid #EBE6ED"
                    className="items-container"
                  >
                    <img
                      width={width < 600 ? "40%" : null}
                      src={e.image}
                      alt=""
                    ></img>
                    <Typography
                      ml={width < 900 ? 1 : 0}
                      color="#232340"
                      fontSize={width < 900 ? 15 : 24}
                      fontWeight={700}
                    >
                      {e.body}
                    </Typography>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>



          {/* ===== SEGUNDO COMPONENTE ===== */}
          <Grid
            item
            container
            my={12}
            alignItems="center"
            justifyContent="center"
            width='75%'
          >
            {i18n.language.substring(0, 2) == "pt" ? (
              <Typography
                pb={10}
                fontSize="3vw"
                color="#000000"
                fontWeight={400}
                fontFamily="Montserrat"
              >
                Kahsh,{" "}
                <b>
                  a melhor maneira de <br />
                  crescer seu dinheiro
                </b>{" "}
                chegou!
              </Typography>
            ) : (
              <Typography
                pb={10}
                fontSize="3vw"
                color="#000000"
                fontWeight={400}
                fontFamily="Montserrat"
              >
                Kahsh,{" "}
                <b>
                  the best way to <br />
                  grow your money
                </b>{" "}
                has arrived!
              </Typography>
            )}

            {/* ===== DIV VERDE ===== */}
            <Grid container ml={width > 1450 && "6rem"} >

              <Grid
                container
                item
                xs={12}
                style={width > 1450 ? StakeFundoAzulLG : StakeFundoAzul}
              >

                <Grid
                  container
                  alignItems="flex-end"
                  justifyContent="flex-end"
                  direction="column"
                >
                  <Grid
                    p={width > 1450 ? "0rem 32rem 61rem" : "0rem 14rem 23rem"}
                  >
                    <Typography
                      fontSize="4vw"
                      fontWeight={700}
                      color="#FFFFFF"
                      lineHeight="1.2em"
                      dangerouslySetInnerHTML={{
                        __html: t("titleTakePart")
                      }}
                    />

                    <Typography
                      pt="1rem"
                      pb="4rem"
                      fontSize="1.2vw"
                      fontWeight={400}
                      color="#FFFFFF"
                      lineHeight="1.6em"
                      dangerouslySetInnerHTML={{
                        __html: t("subtitleTakePart")
                      }}
                    />

                    <Grid
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      bgcolor="#FFFFFF"
                      width="60%"
                      borderRadius={width > 1450 ? 7.5 : 5}
                      p={width > 1450 ? "3rem" : "1.5rem"}
                    >
                      <Button
                        href="#calculadoraNova"
                      >
                        <Typography
                          fontWeight={700}
                          fontSize="0.8vw"
                        >
                          {t("acessarAgoraStaking")}
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </Grid>
        </Grid>
      ) : (
        <>
          {/* ======= CARROUSSEL COM TÍTULOS ======= */}
          <Grid
            pt={10}
            xs={10}
            container
            alignItems="center"
            justifyContent="center"
            maxWidth="100%"
          >
            <Grid ml="3rem" container xs={12} alignItems="center">
              <img src={kahshLogoBlack} alt="Logo Kahsh" width={38}></img>
              <Typography
                ml="1rem"
                fontSize={18}
                color="#5468E7"
                fontWeight="bold"
                fontStyle="italic"
              >
                {t("whyKahsh")}
              </Typography>
            </Grid>

            <Grid
              // xs={10}
              // maxWidth="30%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              width="100%"
            >
              <AutoPlaySwipeableViews
                index={activeStep}
                onChangeIndex={handleStepChange}
                interval={500000}
                className="stakePorqueKahshCarrousselDiv"
              >
                {descriptionsMobile.map((step, index) => (
                  <div key={step.label}>
                    {Math.abs(activeStep - index) <= 2 ? (
                      <Grid
                        pb={1}
                        // pr="5rem"
                        display="flex"
                        alignItems="center"
                        // justifyContent="center"
                        width="100%"
                      >
                        <Typography
                          fontSize={26}
                          lineHeight="3.5rem"
                          mt={3}
                          ml="3rem"
                          color="#232340"
                          dangerouslySetInnerHTML={{
                            __html:
                              i18n.language.substring(0, 2) == "pt"
                                ? step.labelPt
                                : step.labelEn,
                          }}
                        />
                      </Grid>
                    ) : null}
                  </div>
                ))}
              </AutoPlaySwipeableViews>
              <MobileStepper
                steps={maxStepsMobile}
                position="static"
                activeStep={activeStep}
                className="stepperHomeWhayKahsh"
              />
            </Grid>

            <Grid container justifyContent="flex-end">
              <Grid>
                <Button
                  className="button"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                >
                  <Typography className="arrow">←</Typography>
                </Button>
              </Grid>
              <Grid>
                <Button
                  onClick={handleNext}
                  disabled={activeStep === maxSteps - 1}
                  className="button"
                >
                  <Typography className="arrow">→</Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>

          {/* ======= CARROUSSEL COM ITENS ======= */}
          <Grid
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="center"
            bgcolor='#FDFDFD'
          >
            <Grid container justifyContent="center" xs={10} mt={12}

            >
              {items.map((e, index) => {
                return (
                  <Grid
                    lg={2.5}
                    px={7}
                    py={15}
                    bgcolor="#FFFFFF"
                    container
                    justifyContent="center"
                    alignItems={width < 1200 ? "center" : "baseline"}
                    textAlign="center"
                    flexWrap={width < 900 ? "nowrap" : null}
                    mb={width < 900 ? 3 : 0}
                    borderRadius={
                      width > 900
                        ? index === 0
                          ? "30px 0 0 30px"
                          : index === 3
                            ? "0 30px 30px 0"
                            : null
                        : "30px"
                    }
                    borderBottom="8px solid #EBE6ED"
                    className="items-container"
                  >
                    <img
                      width={width < 600 ? "40%" : null}
                      src={e.image}
                      alt=""
                    ></img>
                    <Typography
                      ml={width < 900 ? 1 : 0}
                      color="#232340"
                      fontSize={width < 900 ? 15 : 24}
                      fontWeight={700}
                    >
                      {e.body}
                    </Typography>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>

          {/* ======= SEGUNDO COMPONENTE ======= */}
          <Grid pt={10} container alignItems="center" justifyContent="center">
            {i18n.language.substring(0, 2) == "pt" ? (
              <Typography
                pb={10}
                fontSize="7vw"
                color="#000000"
                fontWeight={400}
                fontFamily="Montserrat"
              >
                Kahsh,{" "}
                <b>
                  a <br /> melhor <br />
                  maneira de <br />
                  crescer seu <br /> dinheiro
                </b>
                <br />
                chegou!
              </Typography>
            ) : (
              <Typography
                pb={10}
                fontSize="3vw"
                color="#000000"
                fontWeight={400}
                fontFamily="Montserrat"
              >
                Kahsh,{" "}
                <b>
                  the <br /> best <br />
                  way of <br />
                  grow your money
                </b>
                <br />
                has arrived!
              </Typography>
            )}

            <Grid
              container
              style={StakeFundoAzulMobile}
              display="flex"
              justifyContent="start"
              alignItems="center"
              flexDirection="column"
            >
              {i18n.language.substring(0, 2) == "pt" ? (
                <Typography
                  pb={4}
                  pt={5}
                  fontSize="4rem"
                  color="#FFFFFF"
                  fontWeight={800}
                  fontFamily="Montserrat"
                  lineHeight="1.2em"
                  pr={width < 1065 ? "7rem" : 0}
                >
                  Faça parte <br />
                  desse <br /> Universo
                </Typography>
              ) : (
                <Typography
                  pb={4}
                  pt={5}
                  fontSize="4rem"
                  color="#FFFFFF"
                  fontWeight={800}
                  fontFamily="Montserrat"
                  lineHeight="1.2em"
                  pr={width < 1065 ? "7rem" : 0}
                >
                  Be part of <br />
                  this <br /> Universe
                </Typography>
              )}

              {i18n.language.substring(0, 2) == "pt" ? (
                <Typography
                  fontSize="2rem"
                  color="#FFFFFF"
                  fontWeight={400}
                  fontFamily="Montserrat"
                >
                  O Ecossistema Kahsh é <br />
                  simples, prático e perfeito <br />
                  para o seu dia a dia. Faca seu <br />
                  contrato de staking
                  <b>
                    e <br /> comece a ganhar agora!
                  </b>
                </Typography>
              ) : (
                <Typography
                  fontSize="2rem"
                  color="#FFFFFF"
                  fontWeight={400}
                  fontFamily="Montserrat"
                >
                  The Kahsh Ecosystem is <br />
                  simple, practical and perfect <br />
                  for your day to day. Make your <br />
                  staking contract
                  <b>
                    and <br /> start earning now!
                  </b>
                </Typography>
              )}
              <Grid
                mt={5}
                p="1rem 2rem"
                bgcolor="#FFFFFF"
                borderRadius={3}
                className="stakeBtnAcesseAgora"
              >
                <Button width="100%">
                  <Typography
                    fontSize="3.5vw"
                    color="#4733FE"
                    fontWeight={800}
                    fontFamily="Montserrat"
                  >
                    {t("acessarAgoraStaking")}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </>
      )
      }
    </>
  );
};
