import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import "./KahshFoundationIntro.styles.css";

import useWindowDimensions from "../../../hooks/useWindowDimensions";
import DesktopBG from "../../../imagens/kahsh-foundation-bg.png";
import MobileBG from "../../../imagens/kahsh-foundation-bg-mobile.png";

const KahshFoundationIntro = () => {
  const { t, i18n } = useTranslation();
  const { width } = useWindowDimensions();

  const labels = {
    labelPT: "<b>Kahsh</b> Foundation</br> Nós Vislumbramos o <b>amanhã</b>",
    labelEN: "<b>Kahsh</b> Foundation</br> We envision the <b>tomorrow</b>",
  };

  return (
    <Grid
      container
      pb="5rem"
      className="kahshfoundation-intro-container"
      justifyContent="space-around"
      sx={{
        backgroundImage: `url(${width < 900 ? MobileBG : DesktopBG})`,
        backgroundSize: width < 900 ? "85% 80%" : "70% 100%",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Grid
        container
        item
        xs={12}
        md={6}
        py={width < 900 ? 1 : 15}
        pb={width < 900 ? 2 : 20}
        px={width < 900 ? 2 : 0}
        direction="row-reverse"
      >
        <Grid container item lg={9}>
          <Typography
            mb="3rem"
            color="#001C57"
            fontSize={width < 900 ? 30 : 45}
            lineHeight={width < 900 ? "3.5rem" : "5rem"}
            dangerouslySetInnerHTML={{
              __html:
                i18n.language.substring(0, 2) === "pt"
                  ? labels.labelPT
                  : labels.labelEN,
            }}
          />
        </Grid>
        {width > 900 ? (
          <Grid container item lg={9}>
            <Typography color="#232340" fontSize={20}>
              {t("foundationIntroDetails")}
            </Typography>
          </Grid>
        ) : null}
      </Grid>
      <Grid
        container
        item
        sm={6}
        md={7}
        lg={6}
        ml={-5}
        minHeight={400}
        className="introTomorrowBg"
      />
      {width < 900 ? (
        <Grid container item px={2} mt={8}>
          <Typography color="#232340" fontSize={20}>
            {t("foundationIntroDetails")}
          </Typography>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default KahshFoundationIntro;
