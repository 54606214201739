export const TRANSLATIONS_PT = {
  // ============= Global =============
  currentCountry: "BR",
  dateFormat: "dd/MM/yyyy",
  back: "Voltar",
  next: "Proximo",
  previous: "Anterior",
  phone: "Telefone",
  birthdate: "Data de Nascimento",
  knowMore: "Saiba mais",
  allRightsReserved: "Copyright © 2021 UI8 LLC. Todos os direitos reservados",
  bePartOfIt: "Faça parte",
  register: "Cadastrar",
  registerBtn: "CADASTRAR",
  user: "Usuário",
  enter: "Entrar",
  kahsh: "Kahsh",
  exchangeGlobal: "Trocar ",
  per: "Por",
  hireNow: "Contrate já",
  contractNow: "Contrate agora!",
  buyKahshs: "Compre Kahsh",
  support: "Suporte",
  search: "Pesquise",
  ourNetwork: "Nossa rede",
  home: "Início",
  about: "Sobre",
  kahshStore: "Kahsh Store",
  kahshPay: "KahshPay",
  news: "News",
  subscribeNow: "Assinar",
  noService: "Sem serviço",
  confirmPassword: "Confirmar senha",
  stake: "Stake",
  signOut: "Sair",
  changeAccount: "Trocar conta",
  enterValue: "Digite o valor",
  valueError: "Valor precisa ser maior que $1000.",
  password: "Senha",
  wallet: "Carteira",
  connectionError:
    "Erro ao se conectar à internet, por favor cheque sua conexão e tente novamente.",
  erroRewards4100:
    "A conta seleciona não corresponde a assinatura utilizada no contrato de Staking, seleciona a conta correspondente previamente no Metamesk.",
  successRewards: "Moedas de recompensa liberadas na sua conta Metamesk.",
  value: "VALOR",
  months: "MESES",
  coinQuantity1: "QUANTIDADE DE",
  coinQuantity2: "MOEDAS",
  to: "a",
  email: "E-mail",
  confirmEmail: "Confirme e-mail",
  buy: "Compre",
  kahshNow: "Kahsh já",
  valueIn: "Valor em R$",
  AmountInKSH: "Quantidade em KSH",
  makeQuickAccountHere: "Faça sua conta rápida ",
  makeLoginHere: "Ou faça login ",
  existPix:
    "Já existe uma transação PIX em aberto, será exibida abaixo valor e QRCode.",
  pixGerado: "PIX gerado, efetuar pagamento.",
  here: "aqui",
  cpfCnpj: "CPF / CNPJ",
  cpfCnpjInvalido: "CPF ou CNPJ inválido.",
  cpfCnpjJaExiste:
    "Parabéns, você já está cadastrado. Clique abaixo para se logar.",
  emailJaExiste: "Já existe um e-mail vinculado a esta conta.",
  nomeInvalido: "Nome inválido.",
  erroFirebase: "Erro no Firebase.",
  erroDataSucess: "Erro no Data Sucess",
  erroLoginExchange: "Erro no Login na Exchange",
  erroAuthExchange: "Erro no Auth na Exchange",
  fullName: "Nome completo",
  erroCompleteName: "Informe seu nome completo.",
  theValueYou: "O VALOR QUE VOCÊ",
  willReceiveinKSH: "RECEBERÁ EM KSH",
  acompanharGlobal: "Acompanhar",
  acessarAgoraGlobal: "Acessar agora",
  enviar: "Enviar",
  invalidBirthDate: "Data de nascimento inválida.",
  emailAdress: "Endereço de Email",
  BirthDate: "Data de Nascimento",
  enterCpfOrCnpj: "Digite o CPF ou CNPJ",
  enterEmail: "Digite seu email",
  congratulations: "Parabéns!!!",
  yourAccountAlreadyExists: "Sua conta já está criada na Kahsh Exchange",
  ok: "ok",
  fullNameRequired: "Nome completo é Obrigatório.",
  fullNameInvalid: "Nome Inválido.",
  birthDateShopRequired: "A Data de Nascmento é Obrigatória.",
  birthDateShopFuture:
    "Essa Data é do Futuro, digite uma Data de Nascimento válida.",
  documentRequired: "CPF ou CNPJ são Obrigatórios.",
  cpfInvalid: "CPF Inválido.",
  cnpjInvalid: "CNPJ Inválido.",
  emailRequired: "Email é Obrigatório.",
  emailInvalid: "Email Inválido.",
  storeRequired: "A loja é obrigatória.",
  registerNow: "Cadastre-se agora mesmo",
  getNow: "Receba o seu agora mesmo",
  firstCard: "O primeiro cartão cripto do Brasil",
  depositNow:
    "Deposite agora sua aporte de R$ 300,00, e garanta os benefícios que só o Kahsh Card Visa tem!",
  country: "País",
  address: "Endereço",
  number: "Número",
  zipCode: "CEP",
  city: "Cidade",
  district: "Bairro",
  region: "Estado",
  address2: "Complemento",
  finish: "Finalizar",
  copyLink: "Copiar Link",
  successfullyCopied: "Link copiado com sucesso!",

  // =============== invest ============
  contractAlreadyActive:
    "Já existe um stake para este contrato em andamento, por favor realize a troca de contrato e tente novamente.",
  amountTooSmall: "A quantidade mínima de staking é de $1000.",
  amountExceeds: "O valor da transferência excede o saldo.",
  stakingInProgress:
    "Por favor aguarde, estamos realizando o staking das suas Kahshs.",
  stakingRewardsFree:
    "Por favor aguarde, estamos liberando suas moedas mineradas.",
  stakeSuccess: "Stake realizado com sucesso.",
  metaMaskDeny: "Por favor autorize a Metamask para continuar com o staking.",
  gasError:
    "Aparentemente você está sem gás para realizar a transação, por favor verifique os valores em carteira e tente novamente.",
  metaMaskError:
    "Erro ao abrir a Metamask, por favor revise os valores e tente novamente.",
  networkError:
    "Erro de conexão, por favor verifique sua conexão e tente novamente em alguns segundos.",
  transactionHash: "Transação",

  // ============= About Kahsh =============

  theKahsh: "A Kahsh",
  beGlobal: "Seja global com a Kahsh!",
  beGlobalBody:
    "Kahsh é a transformação da forma de comprar e vender produtos e a simplificação do universo de criptomoedas.",

  // ============= Universe =============
  universe1: "Mais que",
  universe2: "uma cripto,",
  universe3: "é um universo.",
  universe: "Mais que uma cripto, é um universo.",
  universeBody:
    "Kahsh é a mudança na forma como o mundo utilizará o dinheiro. Seja comprando, transferindo ou rentabilizando.",
  accessNow: "Acessar agora",

  // ============= Capture =============
  capture: "Chegou o Ecossistema Kahsh!",
  captureBonus:
    "<b>Cadastre</b> seu e-mail e já <b>ganhe um bônus</b> de <b>R$25</b> reais em Kahsh.",
  captureLaunchPrizes: "Também concorra a <b>prêmios!</b>",
  captureLetsGo: "Vamos Lá",

  captureGuaranteeBonus: "Para garantir seu bônus, informe seu e-mail.",
  captureCheckAccount:
    "Vamos conferir se já há uma conta com esse endereço, para você ser direcionado à área de cadastro.",

  captureDigitalWalletTitle: "Carteira Digital Kahsh, tudo começa aqui!",
  captureDigitalWalletSubTitle:
    "O Ecossistema Kahsh é simples, prático e perfeito para o seu dia a dia. Comece a ganhar agora!",
  buttonCaptureClickHere: "Clique Aqui!",

  // ============= RegisterAccount =============
  registerData: "Dados",
  registerEnterData: "Digite seu Nome, E-mail, CPF e Data de Nascimento.",

  registerKahshStore: "Gostaria de vender seus produtos na Kahsh Store?",
  registerSales: "Gostaria de fazer vendas com cripto na sua loja?",
  registerMetaverse: "Gostaria de ter uma loja no metaverso da Kahsh Land?",

  // =============== Why kahsh =============

  whyKahsh: "Por que a Kahsh?",
  whyKahshUsability: "Usabilidade e alta adesão.",
  whyKahshShopping: "Compras no mundo todo com uma única moeda.",
  whyKahshEcosystem: "Ecossistema descomplicado.",
  whyKahshPayment: "Forma de pagamento versátil.",
  advantagesKahsh: "Vantagens da Kahsh",
  unitingMarkets: "Unindo o melhor dos mercados.",
  unitingMarketsBody:
    "Integra todos os mundos, com ampla usabilidade em compra e venda de produtos, e ainda é rentável através de Staking. Kahsh está presente na economia real, na internet e até no metaverso. Kahsh: o ativo de todos os mundos!",

  // ============= Ecossistema =============

  kahshEcosystem: "ECOSSISTEMA KAHSH",
  ecosystem: "Ecossistema",
  kahshFoundationDetails:
    "Uma fundação que tem a missão de levar para a sociedade todos os benefícios que a educação e o conhecimento possam agregar.",
  exchange: "Exchange",
  foundationIntroDetails:
    "Na nossa Foundation, a transformação das pessoas precisa vir junto com a transformação que trazemos para o mercado.",
  exchangeDetails:
    "Exchange inovadora, ágil e segura. Oferece todas as ferramentas para melhor experiência no mercado de criptoativos. Compre, venda ou faça ",
  pay: "Pay",
  kahshPayDetails:
    "Meio de pagamento moderno, taxas extremamente atrativas, receba em Kahsh e faça parte dessa mudança financeira.",
  store: "Loja",
  kahshStoreDetails:
    "Marketplace com as principais marcas do mercado, obtenha diversos benefícios pagando com Kahsh.",
  ecoItemKahshExchange: `Na nossa exchange você dá o primeiro passo para ingressar no nosso ecossistema.
      Faça seu login, adquira suas moedas e comece a conhecer as vantagens do universo Kahsh.`,
  ecoItemKahshPay: `Tenha em mãos uma nova forma de pagamento, com transferências sem taxas, uso de qualquer bandeira de cartão,
      velocidade e segurança, e ainda rentabilize em nosso staking.
      `,
  ecoItemKahshStore: `O primeiro marketplace de criptomoedas, com ampla abrangência, sem
      necessidades de câmbios, e descontos exclusivos,
      que você ganha só por comprar em Kahsh.
      `,
  ecoItemKahshFoundation: `A Kahsh também se preocupa na formação e inclusão de todos os públicos no mundo digital e social.
      Por isso oferecemos formação de âmbito educacional, cultural, digital e social.
      `,

  // ============= Kahsh Connect =============
  foundation: "Fundação",
  communityConnectionChannel: "O seu canal de conexão com a comunidade.",
  interactWithCommunity: "Interaja com a nossa comunidade.",
  kahshRevolution: "Seja a revolução Kahsh.",
  kahshConnect: "Conectar Kahsh",
  quickTransactions: "Transações rápidas",
  RiskFreeRewards: "Recompensas sem riscos",

  // ============= Rodapé =============
  contact: "Contato",
  subscribeNewslettervir1: "Inscreva-se em nossa newsletter ",
  subscribeNewslettervir2: "para receber mais informações ",
  subscribeNewslettervir3: "sobre o Ecossistema Kahsh, e fique ",
  subscribeNewslettervir4: "por dentro de tudo que está por vir.",
  newsletter: "Boletim informativo",

  // ============= ExchangeCompreKahsh =============
  erroExchangeAuth: "Erro de autenticação na Exchange.",
  erroDesconhecido: "Erro não registrado, informar desenvolvedores.",
  buyKahsh: "Compre Kahsh em segundos",
  bePartOfEcosystem:
    "Faça parte de um ecossistema inovador e desfrute de  diversos benefícios, retornos e vantagens que só a Kahsh pode oferecer. Comece já!",
  easyMode: "Modo fácil",
  easy: "fácil ",
  secure: "segura",
  way: "",
  proMode: "Modo profissional",
  buyHere: "Compre aqui",
  ComprarKahsh: "Comprar Kahsh",
  LogarNovamente:
    "O tempo de conexão terminou, logue novamente para continuar.",

  // ============= ExchangePrimeirosPassos =============
  step: "Passo",
  acessePlataforma: "Acesse a plataforma",
  acesseKahshExchange:
    "Acesse a Kahsh Exchange e crie a sua conta em poucos cliques!",
  connectYourWallet: "Conecte-se",
  connectWalletCreateSimpleShape:
    "Conecte sua carteira ou crie uma nova de forma simples.",
  startTrade: "Comece a negociar",
  tradeMarketAssets:
    "Negocie os principais ativos do mercado com segurança e agilidade.",
  earnMoney: "Ganhe dinheiro",
  participateTheWorldMostGrowingMarket:
    "Participe do mercado que mais cresce no mundo!",
  startwithfew: "Comece em poucos cliques a interagir com o mundo cripto!",
  firstSteps: "Primeiros Passos",

  // ============= ExchangeNegocie =============
  negocieEmQualquerLugar: "Negocie em qualquer lugar!",
  aquiVoceEncontraTudo:
    "Aqui você encontra tudo o que precisa para ser um trader de cripto.",
  diversosAtivosGlobais: "Diversos ativos globais.",
  informaçõesEmTempoReal: "Informações em tempo real.",
  comunidadeAtiva: "Comunidade Ativa!",

  // ============= ExchangeTrader =============
  compreVendaCripto: "Compre e venda cripto",
  acessoMelhoresOportunidades:
    "Acesso às melhores oportunidades no mundo cripto.",
  compreCripto: "Compre cripto",
  negocieAtivos: "Negocie ativos",
  acessoAtivosVariados: "Acesso aos ativos dos mais variados setores.",
  tradeCripto: "Negocie cripto",
  aprendaSobreCripto: "Aprenda sobre cripto",
  acesseConteudoExclusivo: "Acesse a conteúdos exclusivos sobre o mercado.",
  saibaMaisTrade: "Saiba mais",
  sejaUmTraderSegundos: "Seja um trader em segundos.",
  suporteNecessarioParaOperacoes:
    "O suporte necessário para suas operações no mercado cripto!",
  criarContaTrade: "Criar conta",

  // ============= KahshPayDescomplique =============
  kahshPayUncomplicateA: "Descomplique",
  kahshPayUncomplicateB: "sua vida",
  kahshPayUncomplicateC: "financeira",
  kahshPaySubtitle: "Comece a transformar custos em ",
  kahshPaySubtitle2: "staking!  ",
  kahshPaySubtitle3:
    " Somente um ecossistema integrado permite isso para você.",
  kahshPayStartNow: "Comece agora",

  // ============== KahshPay KahshValor ==========
  KahshValor: "Kahsh valor",
  KahshValorDescricao:
    "Estabilidade, previsibilidade e aumento do poder de compra via incentivos do ecossistema para os usuários. Preço fixo a cada 24 horas (definido pela média das 24 horas do dia anterior). É a Kahsh inovando para criar soluções!",
  KahshValorDescricaoMobile:
    "Meio de pagamento moderno, taxas extremamente atrativas, receba em Kahsh e faça parte dessa mudança financeira.",

  // ============= Exchangeindicadores =============
  name: "Nome",
  price: "Preço",
  volume: "Volume",
  marketcap: "Valor de mercado",
  lastDays: "Últimos 7 Dias",

  //============== KashPay Feito pra você =============
  usabilidade: "Usabilidade",
  usabilidadeDescricao:
    "Diversos usos! Reserva de valor, meio de troca e rentável!",
  usabilidadeMobile:
    "Diversas aplicações: Reserva de Valor, meio de Troca, unidade de Conta",
  escassez: "Escassez",
  escassezDescricao: "Oferta limitada, alta demanda, ecossistema de valor!",
  escassezMobile: "Oferta Limitada Várias casas decimais Preço reflete Demanda",
  escalabilidade: "Escalabilidade",
  escalabilidadeDescricao: "Agilidade, transações rápidas e baixo custo!",
  escalabilidadeMobile: "Ecossistema próprio. Rápida Baixo custo transação",
  seguranca: "Segurança",
  segurancaDescricao: "Blockchain, descentralizada e equipe especializada!",
  segurancaMobile: "Blockchain Inviolável. Equipe especializada",

  // ============= KahshPayPorQueUsar =============
  whyUseKahshPay: "Por que usar KahshPay?",
  modern: "Moderno",
  stateOfTheArt: "Tecnologia de ponta via blockchain",
  cheaper: "Mais barato",
  lowestRatesAndTerms: "Menores taxas e prazos do mercado",
  faster: "Mais rápido",
  financialAgility: "Agilidade financeira",
  safe: "Seguro",
  decentralizedBlockchain: "Descentralizado via blockchain",
  flexibleAndSmart: "Flexível e Inteligente",
  interchangeableWithVariouAssets: "Intercambiável com diversos ativos",
  kahshValue: "Kahsh Valor",
  predictabilityForGreaterUsability: "Previsibilidade para maior usabilidade",

  // ============= KahshPaySuporte =============
  saveTimeAndMoney: "Economize tempo e dinheiro",
  aTeamReadyToServeYou: "Um time pronto para te atender!",

  // ============= KahshPayVejaQuanto =============
  seeHowMuchYouCanEarn1: "Veja o quanto você pode ",
  seeHowMuchYouCanEarn2: "ganhar fazendo staking na Kahsh!",
  stakingParagraphOne:
    "Quando você disponibiliza suas kahsh em staking você ajuda equilibrar a circulação de moedas no ecossistema e em recompensa por esse serviço, ou seja novas unidades dela são proporcionalmente distribuídas. Assim Você também se torna um 'Minerador'.",
  stakingParagraphTwo:
    "Em um contrato de staking suas moedas continuam sendo suas, e são mantidas em sua carteira, sendo liberadas do Stake ao final do prazo selecionado por você. ",
  stakingParagraphThree:
    "No período do contrato de Stake, são 'criadas' novas unidades de Kahsh que ficam disponíveis para uso imediato a cada mês em sua carteira, inclusive podendo fazer novos contratos com elas.",
  checkNowBetterContracts: "Confira os melhores contratos de Staking!",
  howMuchInvest: "1. Quanto você gostaria de fazer staking?",
  foWhatPeriod: "2. Por qual período você pretende fazer staking?",

  thisIsTheIncomeYouCan1: "Esta é a recompensa que você pode",
  thisIsTheIncomeYouCan2: "receber a partir de agora! Comece já!",
  thisIsTheIncomeYouCan3: " ",
  thisIsTheIncome:
    "Esta é a recompensa que você pode receber a partir de agora! Comece já!",
  youInvest: "Você investe",
  youReceive: "Você recebe",
  totalInvested: "Total investido",
  return: "Retorno % a.a.",
  returnIn: "Retorno em KSH",
  returnContract: "Retorno do Contrato",
  returnInKSH: "Retorno em KSH ao final do<br/> contrato",
  monthlyReward: "Esta será sua recompensa mensal.<br/> <b>Comece já!</b>",
  seeHowMuchYouCanEarn:
    "Veja o <b>quanto você pode </b><br /> <b>ganhar </b>staking a Kahsh!",
  seeHowMuchYouCanEarnMobile:
    "Veja o <b>quanto</b><br/> <b>você pode </b><br /> <b>ganhar</b><br/> staking a<br/> Kahsh!",

  //============= Kahsh store ===================

  kahshStoreFacilitaTitulo: "Kahsh Store",
  kahshStoreFacilitaDescricao:
    "A Kahsh Store facilita sua vida, enquanto gera economias e vantagens para você!",
  kahshStoreFacilitaDescricaoMobile:
    "Com a Kahsh store você pode transformar sua vida mais fácil",
  kahshStoreFacilitaBaixoFrete: "Baixo Frete",
  kahshStoreFacilitaBaixoFreteDescricao: "Alguns produtos com frete grátis",
  kahshStoreFacilitaDesconto: "Desconto com kahsh",
  kahshStoreFacilitaDescontoDescricao:
    "Pague com Kahsh e ganhe descontos instantâneos",
  kahshStoreFacilitaDescontoDescricaoMobile:
    "Com kahsh, você ganha desconto instantâneo",
  kahshStoreFacilitaPix: "Pagamento com PIX",
  kahshStoreFacilitaPixDescricao: "Nós aceitamos Pix, cartões e boletos",
  kahshStoreFacilitaPixDescricaoMobile: "Aqui você poderá pagar com PIX",
  kahshStoreFacilitaSeguro: "Seu dinheiro seguro",
  kahshStoreFacilitaSeguroDescricao: "Segurança e transparência para você!",
  kahshStoreNumerosCompras: "Compras realizadas na Kahsh Store",
  kahshStoreNumerosProdutos: "Produtos em estoque no nosso sistema",
  kahshStoreDiversidadeHeader: "Kahsh Store",
  kahshStoreDiversidadeBody: `Diversidade de produtos com duas carcterísticas em comum: qualidade e preço baixo! Pode comparar...
  Você vai encontrar desde chocolates até moto elétrica, conheça nossa loja!`,
  kahshStoreDiversidadeBotao: "Acesse a Loja",
  erroLojaSelecionada: "Você não selecionou nenhuma loja.",
  kahshStoreAlgunsProdutos: "Alguns Produtos",
  kahshStoreAlgunsProdutosBotao: "Ir para a Kahsh Store",

  // ============= KahshStoreMaiorClube =============
  theBigClub1: "O maior clube de ",
  theBigClub2: "compras da internet",
  theBestPrices:
    "Os melhores preços de toda internet para quem paga com Kahsh. Vantagens que só o nosso Ecossistema gera para você, faça parte!",
  buyWithKahshStore: "Compre com a Kash Store",

  // ============= KahshStoreBeneficios =============
  whereTheBenefits:
    "Onde os benefícios do Ecossistema Kahsh viram produtos com preços inacredtiáveis para você e para sua família. Faça parte dessa revolução financeira!",

  // ============= KahshFoundationLevarEducacao =============
  bringEducation: "Levar educação, acesso e condições para ",
  community: "comunidade",
  KnowingTheReality: `Sabendo da realidade em que milhões de pessoas estão à margem do meio digital e ,muitas vezes, do meio financeiro também. Nossa  missão é levar educação para abrir a mente, possibilitar acesso para podermos  nos conectar e prover condições para que possamos desenvolver juntos! Fundação Kahsh, nosso compromisso com a vida.`,

  // ============= KahshFoundationComoFazerParte =============
  howToBePart: "Como fazer parte?",
  differentCllaborate: `Existem diversas formas de colaborar com a Fundação  Kahsh. Se você utilizar o ecossistema Kahsh ou tem a moeda em seu portfólio,  você já ajuda! Quer colaborar ainda mais? Seja bem-vindo!`,

  // ============= KahshFoundationPerfilDosProjetos =============
  profileOfTheProjects: "Perfil dos projetos que apoiamos",
  transformationNetwork: "Faça parte da nossa rede de transformação.",
  social: "Social",
  localImpacts: "Impactos locais!",
  ProjectsFosterSocial:
    "Projetos que fomentem o desenvolvimento social com base em solução tecnológica.",
  technological: "Tecnológico",
  innovateTransform: "Innovate, transform!",
  creativityExpressed:
    "A criatividade expressa digitalmente cria soluções com impactos permanentes.",
  educational: "Educacional",
  fosterKnowledge: "Fomente o saber!",
  everyTransformation:
    "Toda transformação que tem como base a educação será para sempre.",
  environmental: "Ambiental",
  takingCareOfTomorrow: "Cuidando do amanhã",
  theEnvironment:
    "O meio ambiente é uma preocupação de todos que pensam no futuro.",

  // ============= KahshFoundationFundaçãoKahsh=============
  KahshFoundationFundationKahsh: "Fundação Kahsh",
  KahshFoundationFundationKahshDescricao: `Ecossistema para construir um legado de transformação  social por meio dos fomentos à educação digital para as novas gerações e ao  empreendedorismo digital. Faça parte, juntos podemos fazer grandes coisas!`,

  // ============= KahshFoundationRevolucaoSocial =============
  financialRevolution1: "Uma revolução financeira com ",
  financialRevolution2: "compromisso social",
  weCanPromoteGoodPractices:
    "Podemos fomentar boas práticas, bons projetos e, com  isso, ajudarmos a ampliar os resultados das boas iniciativas!",
  Promoting: "Educar é o caminho",
  Edicating: "Fomentar a educação na comunidade",
  transforming: "Transformar é o objetivo",
  Support: "Dar suporte a projetos que promovam mudanças",
  Changing: "Mudar a realidade é o sonho",
  ecosystemThat: "Um ecossistema que atue por todos",

  // ============= Staking Page > Ganhe Cripto =============
  earnSafeCrypto: "Ganhe Cripto de forma ",
  earnCrypto: "Ganhe cripto de forma simples e segura!",
  fixedIncome: "Aqui simplificamos para você!",
  startNow: "Comece já",
  theEasiestWay: "The easiest way to earn crypto",
  newEarnCrypto: "Ganhe cripto de<br/> forma <b>fácil</b> e <b>segura!</b>",
  newEarnCryptoMobile:
    "Ganhe cripto<br/> de forma<br/> <b>fácil</b> e <b>segura!</b>",
  newFixedIncome: "Aqui simplificamos<br/> para você!",

  // ============= Staking Page > Construindo Futuro =============
  weArePrepared: "Estamos construindo o futuro das ",
  finance: "finanças ",
  decentralized: "descentralizadas",
  doASimulation: "Faça uma simulação",
  kahshIsPromoting:
    "Kahsh está promovedo uma revolução na forma que compramos, negociamos e ganhamos dinheiro!",
  collectiveInterest: "Interesse Coletivo",
  targetedEcosystem: "Ecossistema direcionado para comunidade",

  fastTransactions: "Transações Rápidas",
  agileFinancial: "Trocas financeiras ágeis e com baixo custo",
  rewardsForAll: "Recompensas para Todos",
  secureReturns: "Retornos seguros para todos integrantes",

  // ============= Staking Page > Sobre a Kahsh =============
  aboutKash: "Sobre a Kahsh",
  integratedFinancial_p1: `Ecossistema financeiro e digital integrado.
  Suportado por tecnologia descentralizada via`,
  integratedFinancial_p2: `e com diversas aplicações
  de uso, desde`,
  integratedFinancial_p3: `até soluções de pagamento garatindo uso e demanda.
  Uma moeda com usabilidade e segurança.`,
  readyToHaveKahsh: "Pronto para ter Kahsh?",
  BeOneOf: `Seja uma das muitas pessoas a integrarem esse Ecossistema!`,
  BeOneOf2: `Compre com baixo custo e maior retorno.`,
  BeOneOf3: `A Kahsh é real, é pra você.`,
  CommonQuestions: "Perguntas frequentes",
  General: "Geral",
  Supoort: "Suporte",
  Technology: "Tecnologia",
  Products: "Produtos",
  digitalWallet:
    "Se você já possuiu uma carteira Metamask basta somente logar em nossa plataforma, e assinar o valor de forma rápida e simples.",
  createYourWallet: "Crie sua carteira",
  longerDigitalWallet:
    "Caso você já não possua uma carteira Metamask basta clique no botão abaixo para criar uma cateira de forma rápida e simples.",
  connect: "Conectar",
  connected: "Conectado",
  createWallet: "Criar carteira",
  Byproviding:
    "Ao informar meus dados, eu concordo com a politica de contratos da Kahsh ",
  iAgree: "Concordo com os termos uso e contrato estabelecido pela Kahsh ",
  metameskMessage: "Para realizar o staking, é necessário instalar o Metamask.",
  install: "Instalar agora",

  // ============= StakePorqueKahsh =============
  titleTakePart: "Faça parte desse<br/> Universo",
  subtitleTakePart:
    "O Ecossistema Kahsh é simples, prático e perfeito<br /> para o seu dia a dia. Faca seu contrato de staking<br /> <b>e comece a ganhar agora!</b>",

  // ============= StakeEmUmContrato =============
  stakingInContract:
    "Em um contrato de<br/> staking <b>suas moedas<b/><br/> <b>continuam sendo</b><br/> <b>suas</b>",
  stakingInContractMobile:
    "Em um<br/> contrato de<br/> staking <b>suas</b><br/> <b>moedas</b><br/> <b>continuam</b><br/> <b>sendo suas</b>",
  stakingKeptInYourWallet:
    "E são <b>mantidas em sua carteira</b>, sendo liberadas do Stake ao<br/> final do prazo selecionado por você.",
  stakingKeptInYourWalletMobile:
    "E são <b>mantidas em sua</b><br/> <b>carteira</b>, sendo liberadas do<br/> Stake ao final do prazo<br/> selecionado por você.",
  stakingPeriod:
    "No período do contrato de Stake, <b>são 'criadas' novas unidades de</b><br/> <b>Kahsh</b> que ficam <b>disponíveis para uso imediato em sua</b><br/> <b>carteira</b>, inclusive podendo fazer novos contratos com elas.",
  stakingPeriodMobile:
    "No período do contrato de Stake,<br/> <b>são 'criadas' novas unidades de</b><br/> <b>Kahsh</b> que ficam <b>disponíveis para</b><br/> <b>uso imediato</b> em sua carteira,<br/> inclusive podendo fazer novos<br/> contratos com elas.",

  // ============= Login =============
  login: "Login",
  cadastro: "Cadastro",
  hasRegister: "Já possui cadastro?",
  stillNotRegister: "Ainda não cadastrou?",
  clickHere: "Clique aqui",
  welcome: "Bem-Vindo!",
  welcomeBody: "AO MAIS COMPLETO ECOSSISTEMA DE BLOCKCHAIN",
  recover: "Recuperar",
  recoverBody:
    "Digite o email cadastrado para a recuperar a senha do portal kahsh.com",
  redefinePassword: "Redefinir senha",
  redefine: "Redefinir",
  passwordResetError:
    "Um erro aconteceu, por favor verifique suas informações e tente novamente.",
  successfullPasswordChange:
    "Sua senha foi alterada com sucesso! Você será redirecionado em segundos...",
  passwordChangeError:
    "Erro ao efetuar troca de senha, por favor tente novamente.",
  forgotPassword2: "Deseja recuperar sua senha?",
  forgotPassword3: "Recuperar senha!",
  passwordRecovery: "Recuperação de senha",
  registeredEmail:
    "Digite o email cadastrado para a recuperar a senha do portal kahsh.com",
  send: "Enviar",
  passwordMatch: "As senhas não conferem.",
  userRegistry: "Registro de usuário",
  registrySuccessInfo:
    "Cadastro de usuário realizado com sucesso. Por favor realizar o login",
  registryFailInfo:
    "Cadastro não realizado, por favor tente novamente mais tarde.",
  authErrorLocalhost:
    "API não liberada em localhost, alternar para link de teste.",
  passwordReset:
    "Encaminhado email para reset de senha, por favor verifique seu email.",
  verificationEmail: "Email de redefinição encaminhado com sucesso.",
  emailInUseError:
    "Email já utilizado, tente novamente com um email diferente.",
  registroEfetuadoExchange:
    "Registro efetuado e dados vinculados com a Kahsh Exchange.",
  registroEfetuadoExchangePixRealizado:
    "Dados vinculados com a Kahsh Exchange e QRCode gerado, aguarde a geraçào do QR Code.",
  semNomeOuCpfCnpj:
    "Não encontramos um nome ou um CNPF/CNPJ válido vinculado a esta conta, por favor insira corretamente para geração do PIX.",
  weakPasswordError: "A senha precisa ter no mínimo 8 caracteres.",
  invalidEmailError: "Por favor digite um email válido.",
  userNotFound: "E-mail não encontrado.",
  internalLoginError:
    "Erro inesperado, por favor revise os dados e tente novamente.",
  successfullLogin: "Login realizado com sucesso.",
  wrongPasswordError: "Senha incorreta.",
  accountDisabled:
    "O acesso a esta conta foi temporariamente desativado devido a muitas tentativas de login com falha. Você pode restaurá-lo imediatamente redefinindo sua senha ou pode tentar novamente mais tarde",
  invalidEmail: "E-mail inválido.",
  nome: "Nome",
  message: "Mensagem",
  //============= Extrato =============

  startDate: "Inicial",
  endDate: "Final",
  pending: "Resgatar",
  rewardsPaid: "Resgatado",
  availableBalance: "Saldo em Staking",
  totalLocked: "Total a ser Liberado",
  totalRedeemed: "Total Resgatado",
  history: "Staking",
  availableBalanceUpperCase: "SALDO DISPONÍVEL",

  //============= Menu do user =============
  manageKahshAccount: "Gerenciar sua conta do kahsh",
  logoutAccount: "Sair",
  extract: "Extrato",
  goToExtract: "Ir para o Extrato.",
  valorZero: "Não foi inserido o valor para compra por PIX.",

  //=============== MAILCHIMP rodapé ============
  subscribeSuccess: "Assinado com sucesso!",
  subscribePending: "Assinando Mailchimp...",
  subscribeError: "Erro ao assinar.",
  informYourEmailFooter: "Informe seu e-mail",
  pixPago: "Pix pago com sucesso!",
  errorEchangeRegister:
    "Cadastro realizado, mas algo deu errado com seu cadastro na plataforma da Exchange, efetue novamente o login.",

  //============== Make part of this Universe ===========
  titleMakePart: "Faça parte desse Universo",
  subTitleMakePart:
    "O Ecossistema Kahsh é simples, prático e perfeito para o seu dia a dia. Comece a ganhar agora!",
  buttonMakePart: "Criar conta",

  // ============= kahsh exchange =============
  kahshExchange: "Troca de \n Kahsh",
  globalTransactions: "Transações e compras globais, tudo começa aqui. ",
  yourAccount: "SUA CONTA",

  // ============= kahsh exchange Receba Informações =============
  exchangeReceivedInformation: "Receba informações<br/> em tempo real.",
  exchangeFollowAdvantages:
    "Acompanhe os balanços de preços e as melhores vantagens para o<br/> momento. Conosco, você nunca tem dúvidas.",
  exchangeFollowAdvantagesMobile:
    "Acompanhe os balanços de preços e as<br/> melhores vantagens para o momento.<br/> Conosco, você nunca tem dúvidas.",
  exchangeReceivedInformationBTN: "ACOMPANHAR",

  //============= Home Video Kahsh ==================
  informYourEmail: "Informe seu e-mail",
  discoverTheNew: "Descubra o novo",
  kahshUniverse: "universo kahsh",
  readyToStarted: "Pronto para começar?",
  signUpEmail: "Inscreva-se com seu e-mail",
  buttonSendEmail: "Cadastrar",
  yourEmail: " Seu email",

  //=============Melhores vantagens ==================
  melhoresVantagensWeb:
    "Acesse as <b>melhores</b><br/> <b>vantagens</b>, de<br/> qualquer lugar!",
  melhoresVantagensMobile:
    "<b>Acesse as melhores</b><br/> <b>vantagens, de</b><br/> <b>qualquer lugar!</b>",
  naKahshWeb:
    "Na Kahsh Exchange você tem fácil acesso a tudo o que está<br/> acontecendo no mundo cripto. Com poucos cliques.",
  naKahshMobile:
    "Na Kahsh Exchange você tem fácil<br/> acesso a tudo o que está<br/> acontecendo no mundo cripto.<br/> Com poucos cliques.",

  compreVendaGerenciae1: "Compre, venda e gerencie ",
  compreVendaGerenciae2: "os seus fundos a qualquer momento e ",
  compreVendaGerenciae3: " de qualquer lugar.",
  acesseAsMelhoresVantagens: "Acesse as melhores vantagens, de qualquer lugar!",
  acesseAsMelhoresVantagens2:
    "Na nossa exchange você tem fácil acesso a tudo o que está acontecendo no mundo cripto. Com poucos cliques.",

  //============= Exchange Video Kahsh ==================
  simplePracticalAndSafeWeb: "Simples,<br/> prático e<br/> <b>seguro</b>",
  simplePracticalAndSafeMobile: "Simples, prático e <b>seguro<b/>",
  exchangeDescriptionWeb:
    "Compre, venda e gerencie os seus fundos a qualquer momento e de qualquer lugar.",
  exchangeDescriptionMobile:
    "<b>Compre, venda</b> e<br/> <b>gerencie</b> os seus fundos a<br/> qualquer momento e <b>de<br/> qualquer lugar.</b>",

  //============= MelhoresVantagens ==================
  monteSuaCarteira: "Monte sua Carteira",
  simplicidadePraticidade: "Simplicidade, praticidade e segurança.",
  mountDigitalWallet: "Monte sua carteira digital.",
  mountDigitalWalletBody: "Comece a transacionar ou faça staking.",
  realtimeInfo: "Receba informações em tempo real.",
  realtimeInfoBody: "As melhores opções de compra de criptoativos.",
  simplifyiedTransactions: "Transações simplificadas.",
  simplifyiedTransactionsBody:
    "Compra ou venda ativos com praticidade e segurança.",
  knowEverything: "Saiba tudo com antecedência.",
  knowEverythingBody: "Receba as últimas novidades do mercado.",

  //============= ExchanceProducts =====================
  buyProductsPayingKahsh: "Compre produtos <b>pagando em kahsh</b>",
  buyProductsPayingKahshMobile:
    "Compre produtos<br/> <b>pagando em</b><br/> <b>kahsh</b>",
  TradeCryptoAssets:
    "<b>Transacione criptoativos</b> ou ainda <b>rentabilize</b><br/> <b>fazendo staking</b> na Kahsh Smart.",
  TradeCryptoAssetsMobile:
    "<b>Transacione criptoativos</b> ou<br/> ainda <b>rentabilize fazendo</b><br/> <b>staking</b> na Kahsh Smart.",
  DigitalWalletComplete: "Uma carteira digital <b>completa</b>.",
  DigitalWalletCompleteMobile: "Uma carteira digital<br/> <b>completa</b>.",
  mountWalletExhange: "MONTAR CARTEIRA",

  comeceEmPoucosCliques:
    "Comece em poucos cliques a interagir com o mundo cripto!",
  AcesseAPlataforma: "Acesse a plataforma",
  ConecteSe: "Conecte-se",
  comeceANegociar: "Comece a negociar",
  ganheDinheiro: "Ganhe dinheiro",

  //============= TransactionsExchange =====================
  uncomplicatedTransactionsExchange: "Transações<br/> <b>descomplicadas.</b>",
  kahshSimplePraticalSafe: "Kahsh é simples, prática e<br/> segura.",

  //============= kahshStoreNovo =====================
  buyingMoneyEarnsMore: "Comprando aqui, seu dinheiro rende mais.",
  descriptionkahshStore:
    "Tudo o que você compra normalmente na internet você encontra aqui, mas aqui sua compra não está sujeita às alterações do real ou dólar.",
  btTextkahshStore: "Acesse já",

  //============= kahshPayNovo =====================
  descriptionkahshPay:
    "Com transferências sem taxas, uso de qualquer<br/> bandeira de cartão, velocidade e segurança, o kahshPay<br/> te dá hoje, o que será tendência no futuro.",
  descriptionkahshPayMobile:
    "Com transferências sem taxas,<br/> uso de qualquer bandeira de<br/> cartão, velocidade e segurança,<br/> o kahshPay te dá hoje, o que<br/> será tendência no futuro.",
  cryptocurrencyCurrency: "<b>Criptomoedas</b> com o <b>Real</b>",
  transferValues: "Transferência<br/> de valores.",
  freeMediators: "Livre de mediadores",
  buyWithcurrency: "Compre Kahsh<br/> com Real (BRL).",
  freeMediaYourFreDdevaluationtors: "Aqui seu dinheiro vale mais.",
  useCreditCard: "Use qualquer cartão de crédito.",
  useCreditCardMobile: "Use qualquer<br/> cartão de crédito.",
  hereGiveAccessibility: "Aqui te damos o máximo de acessibilidade.",
  unifyPaymentMethods: "Unifique suas formas de pagamentos.",
  allDemandsEcosystem:
    "Todas as suas demandas estão no nosso ecossistema. Confira!",
  allDemandsEcosystemMobile:
    "Todas as suas demandas estão<br/> no nosso ecossistema. Confira!",
  lojistas: "Lojistas",
  consumidores: "Consumidores",
  valorDaTaxa: "Entre em contato e comprove.",
  lowestFees: "As menores taxas do setor",
  kahshBonus: "Kahsh Bônus",
  bonus1: "Bônus que retorna entre 20% a 50%",
  bonus2: "do valor das taxas de processamento.",
  bonusMobile:
    "Bônus que retorna entre 20% a 50% do valor das taxas de processamento.",
  montarCarteira: "Montar Carteira",
  moreBenefictsYouEarn: "Quanto mais você usa, mais benefícios você ganha.",
  featuredProduct: "Produto em destaque",
  meetThe: "Conheça o ",
  transactionsFree: "Transações livres de interferência",
  payAndTransactKahsh:
    "Você paga, transaction e compra com Kahsh em qualquer lugar do mundo.",

  kahshBackDiscounts: "Cupons de desconto e Kahsh back",

  //============= NOVO STAKE =====================
  quantoVoceVaiGanhar: "1. Quanto de staking irá efetuar?",
  porQualPeriodo: "2. Por qual período você pretende contribuir?",
  queroStake: "Quero Stake",
  acessarAgoraStaking: "Quero Stake!",

  //============= NOVO FOUNDATION =====================
  ideiasDaNovaGeracao: "Ideias da nova geração.",
  ideiasDaNovaGeracaoBody:
    "Promovemos o fomento de projetos sociais que estimulam a inclusão digital e que dão vazão a novas mentes ainda não reveladas, ajudando a promover a proxima geração de cidadãos e ideias.",
  semeandoHoje: "Semeando hoje.",
  semeandoHojeBody:
    "Apoiamos ideias criativas de cunho digital-tecnológico, que trazem mudanças e facilidades ao cotidiano das pessoas. O mundo está cheio de ideias boas, nós contribuímos para pô-las em prática.",
  withUs: "Conosco, cripto também é ambiental. ",
  withUsBody:
    "Apesar de termos um dos sistemas mais ecológicos do mundo cripto, para nós isso só não basta. Também desenvolvemos projetos que visam a preservação e conservação do meio ambiente.",
  serKahsh: "Ser Kahsh também é apoiar.",
  serKahshBody:
    "Tendo moedas Kahsh você já está colaborando com os nossos projetos.",
  EarnCrypto: "Ganhe cripto de forma",
  easily: "fácil",
  andStaking: "e",
  securely: "segura!",
  joinNow: "Acesse já!",

  stakingIs: "Staking",
  stakingIsOf: "é uma das",
  bestWays: "melhores formas",
  toObtain: "de obter",
  passiveIncome: "renda passiva",
  usingCryptocurrencies: "utilizando suas criptomoedas.",
  bigDescriptionStaking:
    "Quando você disponibiliza suas Kahshs em stake elas estão agindo como validadoras de transação e, em recompensa por este serviço, novas unidades dela são proporcionalmente distribuídas. Assim Você também se torna um 'Minerador'.",

  //============= NOVO STORE =====================

  bigDescriptionStore:
    "Aqui você compra pagando em Kahsh, não onde importa a origem do produto, e ainda ganha descontos por isso.",
  globalPurchases: "Compras globais",
  globalPurchases2: "sem câmbio, ",
  itHere: "é aqui!",

  //============= NOVO CADASTRO ====================
  cadSuccess: "Sucesso",
  cadError: "Erro",
  //================== Kahsh CARD =====================
  out: "Sair",
  invalidPasswordOrEmail: "Email e senha não confere!",
  loginContinue: "Faça login ou crie uma conta para continuar",
  dontHaveAccount: "não tem uma conta?",
  cancel: "Cancelar",
  stepLogin: "Login",
  depositReceived: "Depósito recebido com sucesso",
  expiredQrCode: "QR-Code expirado",

  stepPersonalData: "Dados Pessoais",
  stepAddress: "Endereço",
  stepDeposit: "Aporte",
  nameRequired: "Nome é obrigatório",
  invalidName: "Nome invalido",
  phoneRequired: "Telefone é obrigatório",
  phoneInvalid: "Telefone invalido",
  cpfRequired: "CPF é obrigatório",
  invalidDate: "Data invalida",
  birthDateRequired: "Data de nascimento é obrigatória",
  birthDateFuture: "Data de nascimento não pode ser no futuro",

  addressAutoCompleteInvalid: "O endereço selecionado não possui nome da rua",
  zipCodeInvalid: "CEP invalido!",
  addressRequired: "Endereço é obrigatório",
  countryRequired: "País é obrigatório",
  addressNumberRequired: "Numero é obrigatório",
  zipCodeRequired: "CEP é obrigatório",
  regionRequired: "Estado é obrigatório",
  cityRequired: "Cidade é obrigatória",
  districtRequired: "Bairro é obrigatório",

  firstCryptoCard: "Utilizando seus criptoativos em qualquer lugar do mundo!",
  introText1: "A gigante do mercado de cartões ",
  and: "e a ",
  introText2:
    " estão juntas para oferecer através da praticidade e capilaridade do mercado de cartões uma experiência inovadora, simples e segura na adoção de uma criptomoeda para no dia a dia das pessoas para compra de bens e serviços em 100 milhões de estabelecimentos em 200 países!",
  askNow: "Peça agora",
  waitingList: "Lista de espera",
  cepRequired: "CEP Obrigatório",
  numeroRequired: "Número obrigatório",
  addOption: "Nova opção",
  defaultMessage: "Algo deu errado! Tente novamente mais tarde",

  getOne: " Como conseguir um",
  cardKahshVisa: "Kahsh Card Visa",
  cardPhysicalAndVirtual:
    " Cartão físico e virtual<br /> Kahshback até de 5X MAIS ALTO<br/> SEM TAXA DE ANUIDADE",

  incomparableExp: "UMA EXPERIÊNCIA INCOMPARÁVEL",
  cryptoCardNumber:
    "O número do seu cartão cripto está a apenas um toque de distância.",
  findAnyTransaction:
    "Encontre qualquer transação com uma pesquisa rápida e fácil.",
  beNotified: "Seja notificado imediatamente sobre todas as suas compras.",
  winKahsh: "Ganhe Kahshback em milhares de produtos.",
  manyPartners: "Muitos parceiros cadastrados em diversas cidades e países.",
  lostYourCard: "Perdeu seu cartão? Obtenha um novo diretamente no aplicativo.",
  aboardInMinutes: "A BORDO EM MINUTOS",
  seeIfApproved: "Veja se você foi aprovado agora mesmo!",
  startSpending:
    "Comece a gastar  imediatamente, antes que seu cartão de metal chegue.",

  winKahshBack: "Ganhe cashback toda vez  que usar o seu cartão",
  kahshCardOffers:
    "O Kahsh Card Visa oferece Kashback toda vez que utilizar seu saldo, podendo comprar milhares de produtos em lojas e marcas de todo o mundo.",
  newKahshSmartContract: "Criação de novas Kahsh por meio de Smart Contracts",
  newUnitsMineration:
    "Tenha a “mineração” de novas unidades de criptomoedas diretamente em seu cartão Kahsh por meio de Smart Contract em Blockchain.",

  card: "Cartão",
  updateCardText:
    "(poderá ser feito upgrade para outro cartão em data oportuna)",
  signalValue: "Valor do Sinal",
  signalValueText1: "(recebe em Kahsh ao",
  signalValueText2: "preço do dia da compra)",
  bonusKahsh: "Bônus",
  kahshToReceive1: "Valor a receber em Kahsh",
  kahshToReceive2: "no saldo do cartão",
  batch: "Lote ",
  seeWhatWin: "Veja o que você pode ganhar!",
  selectCard: "Selecione seu cartão",
  selectAnual: "Selecione seu gasto anual",
  kahshBackValue: "Valor do Kahshback",

  tranquilityTitle: "TRANQUILIDADE EM CASA E NO EXTERIOR",
  noDelay: "Sem taxa de atraso",
  noAnual: "Sem taxa anual",
  noForeign: "Sem Taxa de Transação Estrangeira",

  benefitsAndAdvantages: "BENEFÍCIOS E VANTAGENS",
  celProtect: "Proteção de Celular",
  travelRefund: "Reembolso de interrupção de viagem",
  buySafety: "Segurança de compra",
  dmgInspection: "Isenção de Danos por Colisão de Aluguel de Automóveis",
  extendedWarranty: "Proteção de garantia estendida",
  devolutionProtect: "Proteção de devolução",
  zeroLiability: "Proteção de responsabilidade zero",
  conciergeService: "Serviço de Concierge Visa Signature",
  travelAssist: "Assistência de viagem e emergência",
  dispatchRoad: "Despacho na estrada",

  createNewKahshSmartContracts:
    "CRIAÇÃO DE NOVAS KAHSH POR MEIO DE SMART CONTRACT",
  newCryptocurrencyKahshCard:
    "Tenha a “mineração” de novas unidades de criptomoedas diretamente em seu cartão Kahsh por meio de Smart Contract em Blockchain, aumentando seu saldo colocado em Stake",
  earnPassiveIncome:
    "Staking é a melhor forma de criar novos criptoativos por meio de Smart Contrat em Blockchain",
  newKahshCardInstructions:
    "Quando você disponibiliza suas kahsh em staking, você ajuda equilibrar a circulação de moedas no ecossistema e em recompensa por esse serviço, novas unidades de Kahsh são proporcionalmente distribuídas. Assim Você também se torna um 'Minerador'. Em um contrato de staking suas moedas continuam sendo suas, e são mantidas em sua carteira, sendo liberadas do Stake ao final do prazo selecionado por você. No período do contrato de Stake são 'criadas' novas unidades de Kahsh que ficam disponíveis para uso imediato em sua carteira, inclusive podendo fazer novos contratos com elas.",
  consult: "Consulte os nossos",
  rewardTerms: "TERMOS DE RECOMPENSA",
  detailsOnEarningReward:
    "para mais detalhes sobre como ganhar pontos de recompensa com base em suas compras e gastos agregados, bem como outras ofertas promocionais e por tempo limitado.",

  rewardsEachPurchase: "RECOMPENSAS INCOMPARÁVEIS EM CADA COMPRA",
  getUpToFivePercent:
    "Receba até 5% de Kahsback em cada compra, independentemente da categoria",
  stakingBenefits: "Benefícios Staking e Non-staking",

  stakeBRL: "Stake KSH (em R$) = “mineração cripto” (12 meses)",
  stakeUSD: "Stake KSH (em USD) = “mineração cripto”",
  tableQuantity: "Quantidade de KSH",
  newKshRewardsPercentage: "Recompensa em novas KSH (% / mês)",
  newKshRewardsQuantity: "Recompensa em novas KSH (quantidade / mês)",
  chooseCard:
    "Agora é só escolher o cartão que melhor atende aos seus objetivos",
  usabilityForKahsh: "Usabilidade para seus criptoativos Kahsh",
  newCoinsCreation:
    "Criação de novas moedas Kahsh por Smart Contract em Blockchain",
  kahshbackInAThousand: "Kahshback em milhares de produtos e serviços",
  oneHundredMilion:
    "Uso em mais de 100 milhões de estabelecimentos em 200 países",
  aditionalCard: "Cartão adicional para familiares",
  simplicityAndPraticity:
    "Simplicidade e praticidade no uso de seus criptoativos Kahsh",

  listKahshCardTitleTECH: "TECH",
  listKahshCardTitleFITNESS: "FITNESS",
  listKahshCardTitleRETAIL: "VAREJO",
  listKahshCardTitleTRAVEL: "VIAGEM",
  listKahshCardApple: "Apple",
  listKahshCardBeats: "Beats",
  listKahshCardBestBuy: "Best Buy",
  listKahshCardBose: "Bose",
  listKahshCardMasterclass: "Masterclass",
  listKahshCardNintendo: "Nintendo",
  listKahshCardPlaystation: "Playstation",
  listKahshCardSonos: "Sonos",
  listKahshCardSony: "Sony",
  listKahshCardXbox: "Xbox",
  listKahshCardAdidas: "Adidas",
  listKahshCardCannondale: "Cannondale",
  listKahshCardLululemon: "Lululemon",
  listKahshCardNike: "Nike",
  listKahshCardOutdoorVoices: "Outdoor Voices",
  listKahshCardPatagonia: "Patagonia",
  listKahshCardPeloton: "Peloton",
  listKahshCardRogueFitness: "Rogue Fitness",
  listKahshCardREI: "REI",
  listKahshCardTrek: "Trek",
  listKahshCardAllbirds: "Allbirds",
  listKahshCardAmazon: "Amazon",
  listKahshCardAntropologie: "Antropologie",
  listKahshCardAritzia: "Aritzia",
  listKahshCardAsos: "Asos",
  listKahshCardCasper: "Casper",
  listKahshCardCaixaEBarril: "Caixa e barril",
  listKahshCardCostco: "Costco",
  listKahshCardEtsy: "Etsy",
  listKahshCardEverlane: "Everlane",
  listKahshCardBrilhante: "Brilhante",
  listKahshCardHomeDepot: "Home Depot",
  listKahshCardIkea: "Ikea",
  listKahshCardReforma: "Reforma",
  listKahshCardSephora: "Sephora",
  listKahshCardSupremo: "Supremo",
  listKahshCardUniqlo: "Uniqlo",
  listKahshCardWarbyParker: "Warby Parker",
  listKahshCardWayfair: "Wayfair",
  listKahshCardAirbnb: "Airbnb",
  listKahshCardAlaskaAirlines: "Alaska Airlines",
  listKahshCardAmericanAirlines: "American Airlines",
  listKahshCardDelta: "Delta",
  listKahshCardHoteisCom: "Hoteis.com",
  listKahshCardHotelTonight: "Hotel Tonight",
  listKahshCardSouthwestAirlines: "Southwest Airlines",
  listKahshCardUnitedAirlines: "United Airlines",
  listKahshCardJetBlue: "JetBlue",
  listKahshCardUber: "Uber",
  listKahshCardVrbo: "Vrbo",
  listKahshCardZipcar: "Zipcar",
  listKahshCardButtonPlus: "Carregar mais!",
  listKahshCardButtonRemove: "Carregar menos!",

  contractModel: "Modelos de contrato",
  twelveMonthsContract: "Para contratos de 12 meses",
  from: "de ",
  until: "até ",
  stakingSectionTwo:
    "Quando você disponibiliza suas Kahshs em stake elas estão agindo como validadoras de transação e, em recompensa por este serviço, novas unidades dela são proporcionalmente distribuídas. Assim Você também se torna um 'Minerador'.",
  inAStakingContrat: "Em um contrato de staking",
  yourCoinRemainsYours: "suas moedas continuam sendo suas",

  referrerAFriend: "Indique um amigo!",
  everytimeAFriend:
    "Sempre que seu amigo fizer um depósito na Kahsh Exchange você recebe 2% do valor como bônus de indicação para gastar como quiser.",
  sendWhatsApp: "Enviar WhatsApp",
  phonePlaceholder: "55 + DDD + Número, EX: 5543987874343",
  whatsMessage: "Para acessar e cadastrar basta clicar no link: ",

  //============= NEW STAKING PAGE ====================
  yourCoins: "Suas moedas",
  are: "são",
  keptInYourWallet: "mantidas em sua carteira",
  deadlineSelectedByYou:
    "sendo liberadas do Stake ao final do prazo selecionado por você.",

  stakeContractPeriod: "No período do contrato de Stake,",
  createdNewUnitsKahsh: "são 'criadas' novas unidades de Kahsh",
  thatStay: "que ficam",
  availableForUse: "disponíveis para uso imediato",
  makeNewContracts:
    "em sua carteira, inclusive podendo fazer novos contratos com elas.",

  chooseContract: "Agora é so escolher o contrato que",
  bestSuitsYourGoals: "melhor atende ao seus objetivos",

  quantityOfCoins: "QUANTIDADE DE MOEDAS",
  valueUs: "VALOR (US$)",
  "12Mounths": "12 MESES",
  "24Mounths": "24 MESES",
  "36Mounths": "36 MESES",

  //============= KAHSH LABS PAGE ====================

  kahshlabsTitle: "KahshLabs",
  informationTechnologyCompany:
    " Somos uma empresa de tecnologia da informação,",
  multidisciplinary: "multidisciplinar e",
  specialistBuildingSolutions: "especialista na construção de soluções",
  of: "do",
  physicalVirtualMarket: "mundo cripto para o mercado físico ou virtual.",
  developmentCryptoassetsAndSmartContracts:
    "Além do desenvolvimento de criptoativos e smart contracts,",
  also: "também",
  solutionsThatIntegrateBusinesses:
    "construímos as soluções que integram negócios no",
  virtualMetaverseCryptoWorld:
    "mundo real, virtual e metaverso ao mundo cripto.",

  servicesKahshLabs: "Serviços",
  SoftwareFactory: "Atuamos como uma Fábrica de Software, desde a concepção de",
  developedSolutions:
    "novos negócios até a implantação das soluções desenvolvidas,",
  alwaysDeliveringCustomizedSystems:
    "sempre entregando sistemas personalizados as especificidades de",
  everyDeal: "cada negócio.",
  creationBlockchainSolutions:
    "A criação de soluções em Blockchain como tokenização de ativos,",
  creationSmartContracts:
    "criação de Smart Contracts e construção de soluções para DeFi.",
  businessMigrationMetaverse:
    "Migração de negócios para o Metaverso, através da criação de",
  custom3DEnvironments:
    "ambientes 3D personalizados, criação de estratégias de marketing",
  metaverseAndElaboration:
    "para Metaverso e elaborações das mais variadas soluções para",
  ecommerceAndEventsMetaverse: "e-commerce e eventos no Metaverso.",

  developedProjects: "Projetos<br/> desenvolvidos pela <b>Kahsh</b>",
  developedProjectsMobile: "Projetos desenvolvidos<br/> pela Kahsh",
  kahshLabsSoftwareFactory:
    "KahshLabs Fábrica de Software, desde a concepção de novos negócios até a",
  implementationSolutionsDeveloped: "implantação das soluções desenvolvidas",

  ProjectsTitle1: "Portal Kahsh",
  ProjectsDescription1:
    "Kahsh é a mudança na forma como o mundo utilizará o dinheiro",

  ProjectsTitle2: "KahshPay",
  ProjectsDescription2:
    "Meio de pagamento, com beneficios para quem compra e vende",

  ProjectsTitle3: "Kahsh Exchange",
  ProjectsDescription3:
    "gerencie os seus fundos a qualquer momento e de qualquer lugar.",

  ProjectsTitle4: "KahshLabs",
  ProjectsDescription4:
    "Fábrica de Softwares, soluções para qualquer tipo negócio",

  our: "Nosso",
  team: "Time",
  alignedNewRealityITMarket:
    "Alinhados à nova realidade do mercado de TI atuamos de forma descentralizada",
  alsoWithOurTeam:
    "também com a nossa equipe, onde temos colaboradores de diversas regiões do país",
  teamUnitedStates: "além de uma equipe nos Estados Unidos.",
  registrationData: "Dados Cadastrais",
  registeredCnpj: "CNPJ: 44.679.357/0001-13",
  registeredAddress:
    "Endereço: Av CAssiano Ricardo, 601, Sala 803, Jd. Aquarius, São José dos Campos SP, CEP 12.246-870.",

  messageWhatsAppKahshLabs:
    "Olá, você entrou em contato pela pagina da Kahshlabs!",
};
