import { Grid, Typography, Box, Button } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import { useAuthState } from "react-firebase-hooks/auth";

import {
  auth,
} from "../../../services/firebase";
import { urlModo } from "../../../services/loginExchange";
import "./ProntoParaTerKahsh.styles.css";
import useSharedLink from "../../../hooks/useSharedLink";
import ComputadorCafe from "./../../../imagens/computador&Cafe.svg";
import computadorCafeMobile from "./../../../imagens/computadorCafeMobile.svg";

export const ProntoParaTerKahsh = (props) => {
  useSharedLink();
  const [user] = useAuthState(auth);
  const matches = useMediaQuery("(min-width:1169px)");
  const { t } = useTranslation();

  return (
    <>
      {!matches ? (
        <Grid
          ml={2.3}
          mr={10}
          borderRadius={6}
          width="96%"
          container
          item
          xs={12}
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          id="prontoParaTerKahsh"
        >
          <Box
            px={3}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            width="96%"
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              className="computadorCafeMobile"
              pt={5}
            >
              <img
                src={computadorCafeMobile}
                alt=""
                className="imgKahshPaySuporte"
              />
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              className="boxTitlesProntoPraTerKahsh"
              width={360}
            >
              <Typography variant="h2" className="titleWhite700">
                {t("readyToHaveKahsh")}
              </Typography>

              <Typography py={2} className="titleWhite400">
                {t("BeOneOf")}
              </Typography>
            </Box>
            <Grid zIndex={-2} pt={3} className="buttonProntoPraTerKahsh">
              <Button onClick={() => urlModo(user,"r")}>
                {t("buyKahshs")}
              </Button>
            </Grid>
          </Box>
        </Grid>
      ) : (
        <Grid
          container
          item
          xs={12}
          height={627}
          justifyContent="space-around"
          alignItems="center"
          id="prontoParaTerKahsh"
          width="96%"
          ml={3.8}
          mr={10}
          borderRadius={6}
        >
          <Grid
            item
            xs={6}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            pl={7.5}
          >
            {/*
            <Typography variant="h2" className="titleWhite700">
              {t("readyToHaveKahsh")}
            </Typography> */}

            <Grid
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              width={630}
            >
              <Typography variant="h2" className="titleWhite700">
                {t("readyToHaveKahsh")}
              </Typography>

              <Typography py={2} className="titleWhite400">
                {t("BeOneOf")}
              </Typography>
              <Typography py={2} className="titleWhite400">
                {t("BeOneOf2")}
              </Typography>
              <Typography py={2} className="titleWhite400">
                {t("BeOneOf3")}
              </Typography>
            </Grid>

            <Grid pt={5}>
              <Grid
                zIndex={-2}
                display="flex"
                justifyContent="center"
                className="ganheCriptoButton ganheCriptoButtonA "
              >
                <Button onClick={() => urlModo(user,"r")}>
                  {t("buyKahshs")}
                </Button>
              </Grid>
            </Grid>
          </Grid>

          {/* segundo box */}
          <Grid
            item
            xs={6}
            display="flex"
            justifyContent="center"
            alignItems="center"
            className="imgKahshPaySuporte"
          >
            <img src={ComputadorCafe} alt="" />
          </Grid>
        </Grid>
      )}
    </>
  );
};
