/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import { Navigation } from "../../navigation/Navigation";

import "./GanheCripto.styles.css";
import LogoKahshNewColor from "./../../../imagens/LogoKahshNewColor.svg";
import { Button } from "reactstrap";
import useSharedLink from "../../../hooks/useSharedLink";

export const GanheCripto = (props) => {
  useSharedLink();
  const [isWidth, setIsWidth] = useState(false);
  const matches = useMediaQuery("(min-width:1139px)");
  const { t } = useTranslation();

  useEffect(() => {
    setIsWidth(matches);
  }, [matches]);

  return (
    <>
      {!matches ? (
        <>
          {/* <Grid mb={20}>
            <Navigation />
          </Grid> */}

          <Grid
            id="homeStakingPage"
            display="flex"
            justifyContent="center"
            alignItems="center"
            borderRadius="20px"
            container
            item
            pb={25}
            className="ganheCripto"
          >
            <Grid
              width="98%"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Grid
                item
                xs={1}
                ml={-8}
                pt={3}
                display="flex"
                flexDirection="column"
              >
                <Grid width={340}>
                  <Typography variant="h2"> {t("earnCrypto")} </Typography>
                </Grid>
                <Grid width={340}>
                  <Typography pt={2} pb={3}>
                    {t("fixedIncome")}
                  </Typography>
                </Grid>
                <Grid
                  display="flex"
                  justifyContent="center"
                  className="ganheCriptoButton"
                >
                  <Button href="#ganheCripto">{t("startNow")}</Button>
                </Grid>
              </Grid>
              <Grid
                item
                mt={-10}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <img className="logoK" src={LogoKahshNewColor} />
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          {/* <Grid pt={17} /> */}
          <Grid
            id="homeStakingPage"
            height={20}
            borderRadius="20px"
            container
            item
            xs={12}
            pb={25}
            className="ganheCripto"
          >
            <Grid
              px={4}
              width="98%"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid
                item
                width="98%"
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                mt={10}
              >
                <Grid width={725}>
                  <Typography variant="h2"> {t("earnCrypto")} </Typography>
                </Grid>
                <Grid width={725}>
                  <Typography pt={1} pb={6.5}>
                    {t("fixedIncome")}
                  </Typography>
                </Grid>
                <Grid
                  display="flex"
                  ml={-50}
                  justifyContent="center"
                  className="ganheCriptoButton"
                >
                  <Button href="#kahshPayVejaQuanto">{t("startNow")}</Button>
                </Grid>
                <Grid
                  item
                  mt={-52}
                  ml={136}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img className="logoK" src={LogoKahshNewColor} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};
