import { Grid, Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import "./ExchangeComprandoAqui.styles.css";
import useSharedLink from "../../../hooks/useSharedLink";
import { SaibaMaisButton } from "../../buttons/SaibaMaisButton";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

export const ExchangeComprandoAqui = () => {
  useSharedLink();
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  return (
    <Grid container item 
    marginTop="5rem"
    pb={10} justifyContent="center" xs={12}>
      <Grid container item sm={12} lg={10} justifyContent="center" flexDirection="column">
        <Grid 
        justifyContent="center"

         container item flexDirection="row"> 
          <Grid
            width='64%'
            marginLeft="4rem"
            marginRight="-1em"
            className="universe-container"
            container
            item
            //width={800}
            alignItems="center"
          >
            <Typography
              className="universe-text"
              // left={"5"}
              fontSize={"3.4vw"}
              fontWeight={700}
              color={"#232340"}
            >
              {t("buyingMoneyEarnsMore")}
            </Typography>
            <Typography
              fontSize={18}
              fontWeight={400}
              color={"##606060"}
              lineHeight={"32px"}
              mt={2}
            >
                {t("descriptionkahshStore")}
            </Typography>
          </Grid>
          <Grid
          width={280}
          ml={10}
          mt={3}
          >
          <SaibaMaisButton width={"100%"}>
              <Typography fontSize={18} fontWeight={700}>
              {t("btTextkahshStore")}
              </Typography>
            </SaibaMaisButton>
            </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
