import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import blurBottom from "../../../imagens/benefitsBottomBlur.png";
import blurRight from "../../../imagens/benefitsRightBlur.png";
import bagSVG from "../../../imagens/BenefitsSVGs/bagSVG.svg";
import documentSVG from "../../../imagens/BenefitsSVGs/documentSVG.svg";
import phoneSVG from "../../../imagens/BenefitsSVGs/phoneSVG.svg";
import planeSVG from "../../../imagens/BenefitsSVGs/planeSVG.svg";
import returnSVG from "../../../imagens/BenefitsSVGs/returnSVG.svg";
import ticketSVG from "../../../imagens/BenefitsSVGs/ticketSVG.svg";
import Card from "../../../imagens/card.png";
import kahshCard from "../../../imagens/cardWithShadow.png";
import Check from "../../../imagens/checkcard.png";
import DollarSign from "../../../imagens/dollarsigncard.png";
import Family from "../../../imagens/family.png";
import Hand from "../../../imagens/hand.png";
import Rocket from "../../../imagens/rocketcard.png";
import { Button } from "./../../Button/Button";

import "./benefitsKahshCard.styles.css";

export const BenefitsKahshCard = () => {
  const { t } = useTranslation();
  const list = [
    {
      alt: "phone",
      image: phoneSVG,
      text: "celProtect",
    },
    {
      alt: "plane",
      image: planeSVG,
      text: "travelRefund",
    },
    {
      alt: "ticket",
      image: ticketSVG,
      text: "buySafety",
    },
    {
      alt: "usabilityForKahsh",
      image: Hand,
      text: "usabilityForKahsh",
    },
    {
      alt: "document",
      image: documentSVG,
      text: "extendedWarranty",
    },
    {
      alt: "oneHundredMilion",
      image: Check,
      text: "oneHundredMilion",
    },
    {
      alt: "return",
      image: returnSVG,
      text: "devolutionProtect",
    },
    {
      alt: "newCoinsCreation",
      image: DollarSign,
      text: "newCoinsCreation",
    },
    {
      alt: "kahshbackInAThousand",
      image: Card,
      text: "kahshbackInAThousand",
    },
    {
      alt: "bag",
      image: bagSVG,
      text: "travelAssist",
    },
    {
      alt: "aditionalCard",
      image: Family,
      text: "aditionalCard",
    },
    {
      alt: "simplicityAndPraticity",
      image: Rocket,
      text: "simplicityAndPraticity",
    },
  ];

  return (
    <Grid
      container
      bgcolor="#EFEFEF"
      mb={15}
      sx={{
        backgroundImage: { xs: `url(${blurBottom})`, md: `url(${blurRight})` },
        backgroundSize: { xs: "100% 50%", md: "40% 100%" },
        backgroundPositionX: { xs: "center", md: "right" },
        backgroundPositionY: { xs: "bottom", md: "center" },
        backgroundRepeat: "no-repeat",
        /* transformBackgroundImage: { xs:"rotate(90deg)", md: 0} */
      }}
    >
      <Grid
        item
        container
        direction="row"
        minHeight={{ md: 900, lg: 800 }}
        md={6}
        xs={12}
        justifyContent="center"
        alignItems="flex-end"
        my={{ xs: 4, md: 0 }}
        pb={{ xs: 0, md: 20, xl: 15 }}
      >
        <Grid
          item
          display="flex"
          justifyContent="center"
          alignItems="center"
          xs={12}
        >
          <Box
            className="card-animation"
            component="img"
            src={kahshCard}
            /* {xs: "100% 350px", sm: "100% auto", md: "100% 600px",lg: 700,xl: 800}, */
            width={{ xs: 200, md: 250 }}
            height={{ xs: "auto", md: 450 }}
            alt="Rotating kahsh metla card"
          />
        </Grid>
        <Grid
          item
          display={{ xs: "none", md: "flex" }}
          justifyContent="center"
          alignItems="center"
          xs={12}
        >
          <Button
            onClick={() => (window.location.href = "/cardvisa/request")}
            sx={{ width: "60%", height: "60px" }}
          >
            <Typography fontWeight={700} fontSize={"18px"}>
              {t("waitingList")}
            </Typography>
          </Button>
        </Grid>
      </Grid>
      <Grid
        item
        display="flex"
        md={6}
        xs={12}
        mt={{ md: 10 }}
        flexDirection={{ xs: "column" }}
        alignItems={{ xs: "center", md: "flex-start" }}
      >
        <Typography
          fontWeight={700}
          textAlign={{ xs: "center", md: "left" }}
          fontSize={{ xs: "30px", md: "40px", lg: "50px", xl: "54px" }}
          color={"#5871CC"}
          letterSpacing={"-0.02em"}
        >
          <b>{t("benefitsAndAdvantages")}</b>
        </Typography>
        <List>
          {list.map((item) => (
            <ListItem>
              <ListItemIcon>
                <img alt={item.alt} height="25" width="25" src={item.image} />
              </ListItemIcon>
              <ListItemText>
                <Typography fontWeight={400} fontSize={"20px"} color="#070707">
                  {t(item.text)}
                </Typography>
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </Grid>
      <Grid
        item
        display={{ xs: "flex", md: "none" }}
        justifyContent="center"
        alignItems="center"
        xs={12}
        my={6}
      >
        <Button
          fullWidth
          onClick={() => (window.location.href = "/cardvisa/request")}
          sx={{ width: "80%", height: "60px" }}
        >
          <Typography fontWeight={700} fontSize={"18px"}>
            {t("waitingList")}
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
};
