import { SvgIcon } from "@material-ui/core";

const SmallCircleButton = () => (
  <SvgIcon
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" width="32" height="32" rx="16" fill="none" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.5909 11.2652C18.9968 10.8906 19.6294 10.9159 20.004 11.3217L23.2348 14.8217C23.5884 15.2047 23.5884 15.7952 23.2348 16.1782L20.004 19.6783C19.6294 20.0841 18.9968 20.1094 18.591 19.7348C18.1851 19.3602 18.1598 18.7276 18.5344 18.3217L20.216 16.5L10.5 16.5C9.94771 16.5 9.5 16.0523 9.5 15.5C9.5 14.9477 9.94771 14.5 10.5 14.5L20.216 14.5L18.5344 12.6783C18.1598 12.2725 18.1851 11.6398 18.5909 11.2652Z"
      fill="#FFFFFF"
    />
  </SvgIcon>
);

export default SmallCircleButton;
