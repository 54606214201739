import {
  Grid,
  Typography,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import worldSVG from "../../../imagens/worldSVG.svg";
import calendarSVG from "../../../imagens/calendarSVG.svg";
import clockSVG from "../../../imagens/ExperienciaSVGs/clockSVG.svg";

export const TranquilityKahshCard = () => {
  const { t } = useTranslation();


  const list = [
    {
      key: 0,
      alt: "world",
      image: worldSVG,
      text: "noDelay",
    },
    {
      key: 1,
      alt: "calendar",
      image: calendarSVG,
      text: "noAnual",
    },
    {
      key: 2,
      alt: "clock",
      image: clockSVG,
      text: "noForeign",
    },
  ];
  const listXS = Math.floor(12 / list.length);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography
          fontWeight={700}
          textAlign="center"
          fontSize={{ xs: "30px", md: "40px", lg: "50px", xl: "54px" }}
          sx={{
            background:
              "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(90.26deg, #8889F1 -85.54%, #1DC9FF -11.67%, #867BFF 62.2%, #D4B2FF 136.06%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
          letterSpacing={"-0.02em"}
          mt={7}
          mb={8}
          mx={{ xs: 2, md: 20, lg: 30 }}
        >
          <b>{t("tranquilityTitle")}</b>
        </Typography>
      </Grid>
      <Grid
        container
        justifyContent="space-evenly"
        alignItems={{ xs: "center", md: "flex-start" }}
        mb={{ xs: 10, md: 20 }}
        mx={{ xs: 1, md: 4 }}
      >
        {list.map((item) => {
          return (
            <Grid
              container
              item
              xs={12}
              md={listXS}
              key={item.key}
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <img
                alt={item.calendar}
                height={105}
                width={105}
                src={item.image}
              />
              <Typography
                mt={6}
                mb={{ xs: 12, md: 0 }}
                fontWeight={400}
                textAlign="center"
                fontSize={20}
                color="#070707"
              >
                {t(item.text)}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};
