import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";

import cardSVG from "../../../imagens/ExperienciaSVGs/cardSVG.svg";
import lupaSVG from "../../../imagens/ExperienciaSVGs/lupaSVG.svg";
import bellSVG from "../../../imagens/ExperienciaSVGs/bellSVG.svg";
import clockSVG from "../../../imagens/ExperienciaSVGs/clockSVG.svg";
import flagSVG from "../../../imagens/ExperienciaSVGs/flagSVG.svg";
import arrowsSVG from "../../../imagens/ExperienciaSVGs/arrowsSVG.svg";
import womanImg from "../../../imagens/ExperienciaSVGs/womanImg.png";
import arrowImg from "../../../imagens/ExperienciaSVGs/arrowImg.png";
import phoneImg from "../../../imagens/ExperienciaSVGs/phoneImg.png";
import tripleImgMobile from "../../../imagens/ExperienciaSVGs/tripleImgMobile.png";

import { useTranslation } from "react-i18next";

export const ExperienceKahshCard = () => {
  const { t } = useTranslation();
  const listA = [
    {
      alt: "card",
      image: cardSVG,
      text: "cryptoCardNumber",
    },
    {
      alt: "plane",
      image: lupaSVG,
      text: "findAnyTransaction",
    },
    {
      alt: "bell",
      image: bellSVG,
      text: "beNotified",
    },
  ];
  const listB = [
    {
      alt: "clock",
      image: clockSVG,
      text: "winKahsh",
    },
    {
      alt: "flag",
      image: flagSVG,
      text: "manyPartners",
    },
    {
      alt: "arrows",
      image: arrowsSVG,
      text: "lostYourCard",
    },
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography
          fontWeight={700}
          textAlign="center"
          fontSize={{ xs: "30px", md: "40px", lg: "50px", xl: "54px" }}
          sx={{
            background:
              "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(90.26deg, #8889F1 -85.54%, #1DC9FF -11.67%, #867BFF 62.2%, #D4B2FF 136.06%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
          letterSpacing={"-0.02em"}
          mt={7}
          mb={8}
        >
          <b>{t("incomparableExp")}</b>
        </Typography>
      </Grid>
      <Grid
        container
        mx={{ xs: 1, md: 2 }}
        ml={{ xs: 2, md: 0 }}
        justifyContent="space-around"
        alignItems="center"
      >
        <Grid item md={5} xs={12}>
          <List>
            {listA.map((item) => (
              <ListItem sx={{ mb: { xs: 1, md: 7 } }}>
                <ListItemIcon>
                  <img alt={item.alt} height="45" width="45" src={item.image} />
                </ListItemIcon>
                <ListItemText>
                  <Typography
                    fontWeight={400}
                    fontSize={{ xs: "16px", md: "24px" }}
                    color="#070707"
                    ml={{ xs: 4, md: 2 }}
                  >
                    {t(item.text)}
                  </Typography>
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item md={5} xs={12}>
          <List>
            {listB.map((item) => (
              <ListItem sx={{ mb: { xs: 1, md: 7 } }}>
                <ListItemIcon>
                  <img alt={item.alt} height="45" width="45" src={item.image} />
                </ListItemIcon>
                <ListItemText>
                  <Typography
                    fontWeight={400}
                    fontSize={{ xs: "16px", md: "24px" }}
                    color="#070707"
                    ml={{ xs: 4, md: 2 }}
                  >
                    {t(item.text)}
                  </Typography>
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
      {/* <Grid container>
        <Grid item xs={12}>
          <Typography
            fontWeight={700}
            textAlign="center"
            fontSize={{ xs: "30px", md: "40px", lg: "50px", xl: "54px" }}
            sx={{
              background:
                "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(90.26deg, #8889F1 -85.54%, #1DC9FF -11.67%, #867BFF 62.2%, #D4B2FF 136.06%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
            letterSpacing={"-0.02em"}
            mt={7}
            mb={8}
          >
            <b>{t("aboardInMinutes")}</b>
          </Typography>
        </Grid>
        <Grid
          container
          display={{ xs: "none", md: "flex" }}
          height={500}
          direction="row"
          justifyContent="space-evenly"
          alignItems="flex-start"
          ml={{md: 8,lg:20}}
        >
          <Grid item md={8}>
            <Grid item display="flex">
              <Grid item>
                <img alt="Woman with cellphone" src={womanImg} />
              </Grid>
              <Grid item ml={2} mt={7}>
                <img alt="arrow" height={68} width="100%" src={arrowImg} />
              </Grid>
            </Grid>
            <Grid item>
              <Typography
                ml={-6}
                mt={4}
                color="#001C57"
                fontWeight={400}
                fontSize={20}
                textAlign="left"
              >
                {t("seeIfApproved")}
              </Typography>
            </Grid>
          </Grid>
          <Grid item md={4} mt={15} ml={{md:0,lg:-40,xl:-60}}>
            <img alt="cellphone with marketplace" src={phoneImg} />
            <Typography
              ml={{md:-5,lg:-12,xl:-25}}
              mt={4}
              color="#001C57"
              fontWeight={400}
              fontSize={20}
              textAlign="center"
            >
              {t("startSpending")}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          display={{ xs: "flex", md: "none" }}
          xs={12}
          height={{ xs: 700}}
          justifyContent={{ xs: "space-around"}}
          alignItems="center"
          direction={{ xs: "column", md: "row" }}
          sx={{
            backgroundImage: `url(${tripleImgMobile})`,
            backgroundRepeat: "no-repeat",
            backgroundPositionY: "top 30px",
            backgroundPositionX: "center",
            backgroundSize: { xs: "auto 500px", md: "700px" },
          }}
        >
          <Typography
            mt={{ xs: 5, md: 5 }}
            ml={{ md: "15%", lg: "20%" }}
            color="#001C57"
            fontWeight={400}
            fontSize={20}
            textAlign="center"
          >
            {t("seeIfApproved")}
          </Typography>
          <Typography
            mt={{ xs: 15, md: 25 }}
            mr={{ md: 15 }}
            color="#001C57"
            fontWeight={400}
            fontSize={20}
            textAlign="center"
          >
            {t("startSpending")}
          </Typography>
        </Grid>
      </Grid> */}
    </Grid>
  );
};
