import { Divider, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "./../../Button/Button";

import axios from "axios";

import useWindowDimensions from "./../../../hooks/useWindowDimensions/index";
import { Cards } from "./cards.js";

export const KahshCardRewards = () => {
  const { width } = useWindowDimensions();
  const { t, i18n } = useTranslation();
  const colors = {
    0: "#4A53A1",
    1: "#7A1790",
    2: "#7C8086",
    3: "#C8AB2E",
    4: "#535253",
    5: "#0A0A0A",
  };

  const [BRL, setBRL] = useState(0);
  const [USD, setUSD] = useState(0);
  const [tableInfo, setTableInfo] = useState([]);

  useEffect(() => {
    async function fetchValues() {
      try {
        await axios.get("https://exchange.kahsh.com/cotacao").then((res) => {
          setBRL(res.data[2].cotacao);
          return res.data[2].cotacao;
        });
        await axios
          .get("https://exchange.kahsh.com/cotacao/USD")
          .then((res) => {
            setUSD(res.data[2].cotacao);
            return res.data[2].cotacao;
          });
      } catch (err) {
        return err;
      }
    }

    fetchValues();

    setTableInfo([
      {
        content: "stakeUSD",
        blue: "0",
        purple: "1000",
        silver: "3000",
        gold: "10000",
        platinum: "30000",
        infinite: "60000",
      },
      {
        content: "tableQuantity",
        blue: "0",
        purple: "400",
        silver: "1200",
        gold: "4000",
        platinum: "12000",
        infinite: "24000",
      },
      {
        content: "stakeBRL",
        blue: "0",
        purple: `${((BRL * 1000) / USD).toFixed(2)}`,
        silver: `${((BRL * 3000) / USD).toFixed(2)}`,
        gold: `${((BRL * 10000) / USD).toFixed(2)}`,
        platinum: `${((BRL * 30000) / USD).toFixed(2)}`,
        infinite: `${((BRL * 60000) / USD).toFixed(2)}`,
      },
      {
        content: "newKshRewardsPercentage",
        blue: "0.00",
        purple: "1.00",
        silver: "1.00",
        gold: "1.25",
        platinum: "1.50",
        infinite: "2.00",
      },
      {
        content: "newKshRewardsQuantity",
        blue: "0",
        purple: "4",
        silver: "12",
        gold: "50",
        platinum: "180",
        infinite: "480",
      },
      {
        content: "Kahsh Back",
        blue: "0.00",
        purple: "0.50",
        silver: "1.00",
        gold: "2.00",
        platinum: "3.00",
        infinite: "5.00",
      },
    ]);
  }, [BRL, USD]);

  const getCurrencyFormat = (currency) => {
    return Number(currency)
      .toLocaleString(
        i18n.language.substring(0, 2) == "en"
          ? "en-US"
          : i18n.language.substring(0, 2) == "pt"
          ? "pt-BR"
          : i18n.language,
        {
          style: "currency",
          currency:
            i18n.language.substring(0, 2) == "en"
              ? "USD"
              : i18n.language.substring(0, 2) == "pt"
              ? "BRL"
              : "BRL",
        }
      )
      .split("$")[1];
  };

  const tableCoins = {
    0: "$",
    1: "",
    2: "R$",
  };

  const tablePercentage = {
    3: "%",
    4: "",
    5: "%",
  };

  return (
    <Grid container direction="column" px={{ xs: 1.5, sm: 0 }}>
      <Grid display="flex" width="100%" justifyContent="center">
        <Typography
          fontSize={{ xs: 28, md: 45 }}
          fontWeight={700}
          width={{ xs: "100%", md: "90%", lg: "55%" }}
          textAlign="center"
          sx={{
            background:
              "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(90.26deg, #8889F1 -85.54%, #1DC9FF -11.67%, #867BFF 62.2%, #D4B2FF 136.06%);",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          {t("rewardsEachPurchase")}
        </Typography>
      </Grid>
      <Typography
        my={5}
        fontSize={{ xs: 20, md: 28 }}
        fontWeight={500}
        textAlign="center"
        width="100%"
        color="#1C1439"
      >
        {t("getUpToFivePercent")}
      </Typography>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        direction={{ md: "column", lg: "row" }}
      >
        <Typography
          display={{ xs: "block", lg: "none" }}
          fontSize={{ xs: 20, xl: 32 }}
          maxWidth={{ xs: 900, md: "auto", lg: 300, xl: 380 }}
          textAlign="center"
          color="#1C1439"
        >
          {t("stakingBenefits")}
        </Typography>
        <Grid
          display={{ xs: "none", md: "flex" }}
          gap={{ md: 1, xl: 2.5 }}
          width="100%"
          mt={6}
          justifyContent={{
            md: "flex-end",
            lg: "space-between",
          }}
          alignItems="center"
        >
          <Typography
            display={{ md: "none", lg: "block" }}
            fontSize={{ xs: 24, xl: 28 }}
            maxWidth={{ md: "auto", lg: 300 }}
            textAlign="center"
            color="#1C1439"
          >
            {t("stakingBenefits")}
          </Typography>
          {Cards &&
            Cards.map((card) => (
              <Grid id="table">
                <Grid
                  item
                  width={{ md: width > 1100 ? 140 : 120, lg: 145, xl: 160 }}
                  height={width > 1100 ? 235 : 200}
                  sx={{
                    backgroundImage: `url(${card.img})`,
                    backgroundSize: "100% 100%",
                  }}
                />
                <Typography
                  mt={1.5}
                  width="100%"
                  textAlign="center"
                  fontSize={16}
                  fontWeight={600}
                  color={card.color}
                >
                  {card.text}
                </Typography>
              </Grid>
            ))}
        </Grid>
      </Grid>
      <Grid container px={0.5} my={4.5} gap={3}>
        {/* table MOBILE */}

        {tableInfo &&
          tableInfo.map((row, outsideIndex) => {
            return (
              <Grid
                display={{ xs: "flex", md: "none" }}
                container
                justifyContent="center"
                px={{ xs: 1.5, sm: 0 }}
              >
                <Typography
                  fontWeight={600}
                  fontSize={18}
                  textAlign="center"
                  color="#1C1439"
                  mb={2}
                >
                  {t(`${row["content"]}`)}
                </Typography>
                <Grid container justifyContent="center">
                  <Grid container item xs={6} sm={5} textAlign="left">
                    {Cards &&
                      Cards.map((card, insideIndex) => {
                        return (
                          <Typography
                            fontWeight={700}
                            color={Cards[insideIndex]}
                            width="100%"
                            textAlign="left"
                            fontSize={16}
                          >
                            {card.text}
                          </Typography>
                        );
                      })}
                  </Grid>
                  <Grid container item xs={6} sm={5} textAlign="right">
                    {Object.values(row).map((rowContent, index) => {
                      if (index !== 0)
                        return (
                          <Typography
                            fontWeight={600}
                            color={Cards[index]}
                            width="100%"
                            textAlign="right"
                            fontSize={16}
                          >
                            {`${
                              outsideIndex < 3 ? tableCoins[outsideIndex] : ""
                            } ${getCurrencyFormat(rowContent)} ${
                              outsideIndex > 2
                                ? tablePercentage[outsideIndex]
                                : ""
                            }`}
                          </Typography>
                        );
                      return null;
                    })}
                  </Grid>
                </Grid>
              </Grid>
            );
          })}

        {/* table WEB */}

        {tableInfo &&
          tableInfo.map((row, index) => (
            <>
              <Grid
                container
                display={{ xs: "none", md: "flex" }}
                flexWrap="no-wrap"
                px={{ md: 0.5, lg: 2 }}
              >
                <Grid
                  item
                  md={3}
                  lg={2.8}
                  xl={2}
                  display={{ xs: "none", md: "flex" }}
                >
                  <Typography
                    color="#1C1439"
                    fontSize={{ md: 13, lg: 16 }}
                    fontWeight={index <= 1 ? 300 : 600}
                  >
                    {t(`${row.content}`)}
                  </Typography>
                </Grid>
                <Grid
                  container
                  md={9}
                  lg={9.2}
                  xl={10}
                  alignItems="center"
                  justifyContent="center"
                  wrap="no-wrap"
                >
                  {Object.values(row).map((valueInsideRow, rowIndex) => {
                    if (rowIndex === 0) {
                      return null;
                    } else {
                      return (
                        <Typography
                          display={{ md: "block" }}
                          flex={{ md: 1, xl: 1 }}
                          width={{ md: 125, lg: 145, xl: 175 }}
                          justifyContent={{ md: "center" }}
                          textAlign={{
                            md: "center",
                            lg: "center",
                            xl: "right",
                          }}
                          color={colors[rowIndex - 1]}
                          fontWeight={index <= 1 ? 300 : 600}
                          fontSize={{ md: 14, lg: 20 }}
                          pl={rowIndex}
                        >
                          {t(
                            `${
                              index < 3 ? tableCoins[index] : ""
                            }${getCurrencyFormat(valueInsideRow)}
                            ${index > 2 ? tablePercentage[index] : ""}
                            `
                          )}
                        </Typography>
                      );
                    }
                  })}
                </Grid>
              </Grid>
              <Divider
                sx={{
                  backgroundColor: "rgba(28, 20, 57, 0.3)",
                  width: "100%",
                  display: { xs: "none", md: "flex" },
                }}
                height={2}
              />
            </>
          ))}
      </Grid>
      <Grid container justifyContent="center" mt={5} mb={10}>
        <Typography
          fontSize={{ xs: 28, md: 45 }}
          fontWeight={700}
          width={{ xs: "100%", md: "80%" }}
          textAlign="center"
          sx={{
            background:
              "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(90.26deg, #8889F1 -85.54%, #1DC9FF -11.67%, #867BFF 62.2%, #D4B2FF 136.06%);",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          {t("chooseCard")}
        </Typography>
        <Button
          sx={{ width: { xs: "80%", md: 400, xl: 450 }, marginTop: 6 }}
          onClick={() => (window.location.href = "/cardvisa/request")}
        >
          <Typography fontSize={{ xs: 14, md: 20 }} fontWeight={700}>
            {t("waitingList")}
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
};
