import { FormControl, FormHelperText, Box } from "@mui/material";
import GPlacesAutocomplete, {
  geocodeByPlaceId
} from "react-google-places-autocomplete";
import { formatGoogleAutoCompleteResponse } from "../../../utils/formatGoogleAutoCompleteResponse";
import {
  AutoPlacesContainer,
  LabelContainer
} from "./GooglePlacesAutocomplete.styles";
import { useTranslation } from "react-i18next";

import { useState } from "react";

interface GooglePlacesAutocompleteProps {
  place: any;
  setPlace: (place: any) => void;
  placeholder: string;
  error?: any;
  required?: boolean;
  autoFocus?: boolean;
}
export const GooglePlacesAutocomplete: React.FC<
  GooglePlacesAutocompleteProps
> = ({ place, setPlace, placeholder, error, required, autoFocus }) => {
  const [focused, setFocused] = useState(false);
  const { t } = useTranslation();

  const handleChange = async (data: any) => {
    const geo = await geocodeByPlaceId(data?.value.place_id);
    if (geo?.length) {
      const result = formatGoogleAutoCompleteResponse(geo[0]);
      setPlace(result);
    }
  };
  return (
    <Box>
      {!!placeholder && (focused || place) && (
        <LabelContainer
          required
          variant="standard"
          sx={{
            color: focused
              ? (theme) => theme.palette.primary.main
              : "rgba(0,0,0,0.6)",
            mb: "-10px",
            zIndex: 20,
            fontSize: 14,
            width: t(placeholder || "").length * 9 + (required ? 10 : 0),
            backgroundColor: "transparent",
            backgroundImage:
              "linear-gradient(0deg, rgba(253, 253, 255,1) 60%, transparent 40%)"
          }}
        >
          {t(placeholder || "")}
        </LabelContainer>
      )}
      <FormControl error={!!error} fullWidth>
        <AutoPlacesContainer error={!!error} position="relative">
          <GPlacesAutocomplete
            selectProps={{
              place,
              onChange: handleChange,
              autoFocus: !!autoFocus,
              className: "google-places-autocomplete",
              placeholder: focused ? " " : t(placeholder || ""),
              onFocus: () => setFocused(true),
              onBlur: () => setFocused(false)
            }}
            apiKey="AIzaSyBexLxaS-mEOffbR1W3z0Qt3NmIRqxn25U"
          />
        </AutoPlacesContainer>
        {!!error && <FormHelperText>{t(error)}</FormHelperText>}
      </FormControl>
    </Box>
  );
};