/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";

//Material UI
import {
  Grid,
  TextField,
  InputLabel,
  Typography,
  Divider,
  Slide,
  Stack,
  Alert,
  IconButton,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

// AXIOS
import axios from "axios";

// i18n
import { useTranslation } from "react-i18next";

//firebase functions
import { getAuth } from "firebase/auth";
import {
  auth,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
} from "../../services/firebase";
import { useAuthState } from "react-firebase-hooks/auth";

//custom hooks
import useWindowDimensions from "../../hooks/useWindowDimensions";
import useSharedLink from "../../hooks/useSharedLink";

//css and images
import "./Autentication2.styles.css";
import GlobeWeb from "../../imagens/globeautenticationweb.png";
import GlobeMobile from "../../imagens/globeautenticationmobile.png";
import AuthCircle from "../../imagens/auth-circle.png";
import AuthCircleLogoBlack from "../../imagens/auth-circle-logo.png";
import LogoKahshBlack from "../../imagens/kahsh-logo-black.png";
import CircularProgress from "@mui/material/CircularProgress";

//components
import { SaibaMaisButton } from "../../components/buttons/SaibaMaisButton";

//links
import { urlAuthExchange, urlRegistroExchange } from "../../services/global";

import { useHistory, useLocation } from "react-router-dom";

interface IScreenChoice {
  isLogin: boolean;
  isRegister: boolean;
  isRecoverPassword: boolean;
  isReset: boolean;
}

type AlertColor = "success" | "info" | "warning" | "error";

interface IAlert {
  open: boolean;
  severity: AlertColor;
}

export function Autentication2() {
  useSharedLink();

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const { t, i18n } = useTranslation();
  const { width } = useWindowDimensions();
  const history = useHistory();
  let query = useQuery();
  const [user] = useAuthState(auth);
  const [destination, setDestination] = useState<string>("0");
  const [email, setEmail] = useState<string>("");
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<
    String | Number | Symbol
  >("");
  const [passwordMatch, setPasswordMatch] = useState<boolean>(true);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [alert, setAlert] = useState<IAlert>({
    open: false,
    severity: "error",
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [loginTrigger, setloginTrigger] = useState<boolean>(false);
  const [screen, setScreen] = useState<IScreenChoice>({
    isLogin:
      new URLSearchParams(window.location.search).get("destination") == "0" ||
      !new URLSearchParams(window.location.search).get("destination")
        ? true
        : false,
    isRegister:
      new URLSearchParams(window.location.search).get("destination") == "1"
        ? true
        : false,
    isRecoverPassword: false,
    isReset: false,
  });

  useEffect(() => {
    if (user) {
      if (window.location.href.split("/authentication")[1] == "")
        history.push("/");
      if (!loginTrigger) return;
      history.push(query.get("return") ? query.get("return") : "/");
    }
  }, [user, history, loginTrigger, query]);

  useEffect(() => {
    if (password != confirmPassword) return setPasswordMatch(false);

    return setPasswordMatch(true);
  }, [password, confirmPassword]);

  const handleResetPassword = async () => {
    try {
      localStorage.setItem("emailToRetrieve", email);
      await sendPasswordReset(email);
      // setEmailSent(true);
      setAlert({ open: true, severity: "success" });
      setAlertMessage(t("passwordReset"));
    } catch (error) {
      setAlert({ open: true, severity: "error" });
      setAlertMessage("invalidEmailError");
    }
  };

  useEffect(() => {
    const time = setTimeout(() => {
      setAlert({ ...alert, open: false });
    }, 5000);
    return () => clearInterval(time);
  }, [alert.open]);

  const handleLogin = async () => {
    setAlertMessage("");
    setLoading(true);
    try {
      var res: any = await logInWithEmailAndPassword(email, password);
      if (res.name !== "FirebaseError") {
        axios
          .post(
            urlAuthExchange,
            {
              authorizationToken: res.user.accessToken,
              acesso: "v6uiqQj6ibbjrITQy2Eq0Q5ywacYPzRIu7l6DcTDxXlUzjF799",
            },
            {
              headers: {
                "Content-Type": "application/json;charset=utf-8",
                "Access-Control-Allow-Origin": "*",
              },
            }
          )
          .then((response) => {
            axios
              .post(
                urlRegistroExchange,
                {
                  tokenExchange: response.data.tokenExchange,
                  senha: password,
                  base: 1,
                  codigo2fa: 0,
                },
                {
                  headers: {
                    "Content-Type": "application/json;charset=utf-8",
                    "Access-Control-Allow-Origin": "*",
                  },
                }
              )
              .then((response) => {
                if (response.data.success) {
                  setloginTrigger(true);
                } else {
                  setAlert({ open: true, severity: "error" });
                  setAlertMessage(t("authErrorLocalhost"));
                }
                setLoading(false);
              })
              .catch((error) => {
                setloginTrigger(true);
                setLoading(false);
              });
          })
          .catch((error) => {});
      }
    } catch (error: any) {
      setAlert({ open: true, severity: "error" });
      if (error.message == "Firebase: Error (auth/invalid-email).") {
        setAlertMessage("invalidEmailError");
      } else if (error.message == "Firebase: Error (auth/wrong-password).") {
        setAlertMessage("wrongPasswordError");
      } else if (
        error.message ==
        "Firebase: Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later. (auth/too-many-requests)."
      ) {
        setAlertMessage("accountDisabled");
      } else if (error.message == "Firebase: Error (auth/user-not-found).") {
        setAlertMessage("userNotFound");
      } else if (
        error.message == "FirebaseError: Firebase: Error (auth/internal-error)."
      ) {
        setAlertMessage("internalLoginError");
      }
      setLoading(false);
    }
  };

  const handleRegister = async () => {
    try {
      if (password.length < 8)
        throw new Error(
          "Firebase: Password should be at least 6 characters (auth/weak-password)."
        );
      var res: any = await registerWithEmailAndPassword(email, password);
      if (res.name !== "FirebaseError") {
        axios
          .post(
            urlAuthExchange,
            {
              authorizationToken: res.accessToken,
              acesso: "v6uiqQj6ibbjrITQy2Eq0Q5ywacYPzRIu7l6DcTDxXlUzjF799",
            },
            {
              headers: {
                "Content-Type": "application/json;charset=utf-8",
                "Access-Control-Allow-Origin": "*",
              },
            }
          )
          .then((response) => {
            axios
              .post(
                urlRegistroExchange,
                {
                  tokenExchange: response.data.tokenExchange,
                  senha: password,
                  base: 1,
                  codigo2fa: 0,
                },
                {
                  headers: {
                    "Content-Type": "application/json;charset=utf-8",
                    "Access-Control-Allow-Origin": "*",
                  },
                }
              )
              .then((response) => {
                if (response.data.success) {
                  setAlert({ open: true, severity: "success" });
                  setAlertMessage(t("registrySuccessInfo"));
                  setDestination("0");
                } else {
                  setAlert({ open: true, severity: "error" });
                  setAlertMessage(t("registryFailInfo"));
                }
              })
              .catch((error) => {
                return error;
              });
          })
          .catch((error) => {
            return error;
          });
      }
    } catch (error: any) {
      setAlert({ ...alert, open: true });
      if (error.message == "Firebase: Error (auth/email-already-in-use).") {
        setAlertMessage("emailInUseError");
      } else if (
        error.message ==
        "Firebase: Password should be at least 6 characters (auth/weak-password)."
      ) {
        setAlertMessage("weakPasswordError");
      } else if (error.message == "Firebase: Error (auth/invalid-email).") {
        setAlertMessage("invalidEmailError");
      }
      setTimeout(() => {
        setAlert({ ...alert, open: false });
      }, 5000);
      return error;
    }
  };

  return (
    <Grid container item height={{ md: "100vh" }} md={12} bgcolor="#F5F5F8">
      <Grid container item md={7}>
        <Grid
          container
          item
          xs={width > 900 ? null : 12}
          justifyContent="center"
          alignItems="center"
        >
          {width > 900 ? null : (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              py={5}
              mb={10}
            >
              <img src={LogoKahshBlack} width="60rem" alt="" />
              <Typography
                ml={1}
                fontSize="4rem"
                fontWeight={600}
                color="#1C1439"
              >
                {" "}
                kahsh
              </Typography>
            </Grid>
          )}
          <Grid
            container
            item
            xl={4.5}
            lg={6}
            md={8}
            xs={10}
            justifyContent="center"
            alignItems="center"
            zIndex={2}
            direction="column"
            rowSpacing={4}
            bgcolor="rgba(255,255,255,0.5)"
            borderRadius="20px"
            p={width < 900 ? 4 : 4}
            pt={screen.isRegister && 0}
          >
            <Slide in={alert.open} direction="right" mountOnEnter unmountOnExit>
              <Stack
                id="alertComponent"
                sx={{
                  maxWidth: "20em",
                  width: "calc(100% - 2em)",
                  position: "fixed",
                  top: "4em",
                  left: "1em",
                  zIndex: 99999,
                }}
                spacing={2}
              >
                <Alert
                  severity={alert.severity}
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => setAlert({ ...alert, open: false })}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  <Typography fontSize={16}>{t(alertMessage)}</Typography>
                </Alert>
              </Stack>
            </Slide>
            {screen.isLogin ? (
              <>
                <Grid container item md={12} justifyContent="space-between">
                  <Typography ml={1.5} fontSize={30} color="#505050">
                    {t("login")}
                  </Typography>
                  <Grid pt="1rem">
                    <img src={LogoKahshBlack} height="30rem" alt="" />
                  </Grid>
                </Grid>
                <Grid container item md={12}>
                  <InputLabel className="input-label">Email</InputLabel>
                  <TextField
                    autoFocus
                    fullWidth
                    onKeyPress={(e) => {
                      if (e.key === "Enter") return handleLogin();
                    }}
                    value={email}
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    InputProps={{
                      className: "input-field-autentication",
                    }}
                  />
                </Grid>
                <Grid container item md={12}>
                  <InputLabel className="input-label">
                    {t("password")}
                  </InputLabel>
                  <TextField
                    fullWidth
                    onKeyPress={(e) => {
                      if (e.key === "Enter") return handleLogin();
                    }}
                    value={password}
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                    InputProps={{
                      className: "input-field-autentication",
                    }}
                  />
                </Grid>
                <Grid container item md={12}>
                  <Typography
                    pb="1rem"
                    ml={1.5}
                    color="#9BA0B1"
                    fontSize={16}
                    onClick={() =>
                      setScreen({
                        ...screen,
                        isLogin: false,
                        isRecoverPassword: true,
                      })
                    }
                    sx={{ cursor: "pointer" }}
                  >
                    {t("forgotPassword2")}
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  // md={12}
                  width="100%"
                  height="6rem"
                  bgcolor="#4733FF"
                  borderRadius={5}
                  justifyContent="center"
                  alignItems="center"
                  sx={{ paddingTop: "0 !important" }}
                >
                  <Button
                    sx={{ width: "100%", height: "100%" }}
                    onClick={() => handleLogin()}
                  >
                    <Typography
                      color="#FFFFFFFF"
                      fontSize="16px"
                      // letterSpacing="-0.5px"
                      fontWeight={800}
                    >
                      {t("enter")}
                    </Typography>
                  </Button>
                </Grid>
                <Grid
                  container
                  item
                  md={12}
                  justifyContent="center"
                  pb={width < 900 ? 5 : 0}
                >
                  <Typography
                    color="#1C1439"
                    fontSize={12}
                    letterSpacing="-0.5px"
                  >
                    {t("stillNotRegister")}
                  </Typography>
                  <Typography
                    ml={0.5}
                    color="#4733FF"
                    fontSize={12}
                    letterSpacing="-0.5px"
                    fontWeight={700}
                    onClick={() =>
                      setScreen({
                        ...screen,
                        isLogin: false,
                        isRegister: true,
                      })
                    }
                    sx={{ cursor: "pointer" }}
                  >
                    {t("clickHere")}
                  </Typography>
                </Grid>
              </>
            ) : screen.isRegister || destination == "1" ? (
              <>
                <Grid container item md={12} justifyContent="space-between">
                  <Typography ml={1.5} fontSize={30}>
                    {t("register")}
                  </Typography>
                  <img src={LogoKahshBlack} height="50%" alt="" />
                </Grid>
                <Grid container item md={12}>
                  <InputLabel className="input-label">Email</InputLabel>
                  <TextField
                    autoFocus
                    fullWidth
                    onKeyPress={(e) => {
                      if (e.key === "Enter") return handleRegister();
                    }}
                    value={email}
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    InputProps={{
                      className: "input-field-autentication",
                    }}
                  />
                </Grid>
                <Grid container item md={12}>
                  <InputLabel className="input-label">
                    {t("password")}
                  </InputLabel>
                  <TextField
                    fullWidth
                    onKeyPress={(e) => {
                      if (e.key === "Enter") return handleRegister();
                    }}
                    value={password}
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                    InputProps={{
                      className: "input-field-autentication",
                    }}
                  />
                </Grid>
                <Grid container item md={12}>
                  <InputLabel className="input-label">
                    {t("confirmPassword")}
                  </InputLabel>
                  <TextField
                    fullWidth
                    onKeyPress={(e) => {
                      if (e.key === "Enter") return handleRegister();
                    }}
                    value={confirmPassword}
                    type="password"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    InputProps={{
                      className: "input-field-autentication",
                    }}
                  />
                  {!passwordMatch ? (
                    <Typography mt={2} fontSize={16} color="red">
                      {t("passwordMatch")}
                    </Typography>
                  ) : null}
                </Grid>

                <Grid container item md={12}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    bgcolor="#4733FF"
                    width="100%"
                    height="6rem"
                    borderRadius={5}
                  >
                    <Button
                      sx={{ width: "100%", height: "100%" }}
                      onClick={() => handleRegister()}
                    >
                      <Typography
                        color="#FFFFFFFF"
                        fontSize="16px"
                        fontWeight={800}
                      >
                        {t("register")}
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  md={12}
                  justifyContent="center"
                  pb={width < 900 ? 5 : 0}
                >
                  <Typography color="#1C1439" fontSize={16}>
                    {t("hasRegister")}
                  </Typography>
                  <Typography
                    ml={1.5}
                    color="#4733FF"
                    fontSize={16}
                    fontWeight={700}
                    onClick={() =>
                      setScreen({
                        ...screen,
                        isLogin: true,
                        isRegister: false,
                      })
                    }
                    sx={{ cursor: "pointer" }}
                  >
                    {t("clickHere")}
                  </Typography>
                </Grid>
              </>
            ) : screen.isRecoverPassword ? (
              <>
                <Grid container item md={12} justifyContent="space-between">
                  <Typography ml={1.5} fontSize={30}>
                    {t("recover")}
                  </Typography>
                  <img src={LogoKahshBlack} height="50%" alt="" />
                </Grid>
                <Grid container item md={12} justifyContent="space-between">
                  <Typography ml={1.5} fontSize={20}>
                    {t("recoverBody")}
                  </Typography>
                </Grid>
                <Grid container item md={12}>
                  <InputLabel className="input-label">Email</InputLabel>
                  <TextField
                    autoFocus
                    fullWidth
                    onKeyPress={(e) => {
                      if (e.key === "Enter") return handleResetPassword();
                    }}
                    value={email}
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    InputProps={{
                      className: "input-field-autentication",
                    }}
                  />
                </Grid>
                <Grid container item md={12}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    bgcolor="#4733FF"
                    width="100%"
                    height="6rem"
                    borderRadius={5}
                  >
                    <Button
                      sx={{ width: "100%", height: "100%" }}
                      onClick={() => handleResetPassword()}
                    >
                      <Typography
                        color="#FFFFFFFF"
                        fontSize="16px"
                        fontWeight={800}
                      >
                        {t("recover")}
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  md={12}
                  justifyContent="center"
                  pb={width < 900 ? 5 : 0}
                >
                  <Typography color="#1C1439" fontSize={16}>
                    {t("hasRegister")}
                  </Typography>
                  <Typography
                    ml={1.5}
                    color="#4733FF"
                    fontSize={16}
                    fontWeight={700}
                    onClick={() =>
                      setScreen({
                        ...screen,
                        isLogin: true,
                        isRecoverPassword: false,
                      })
                    }
                    sx={{ cursor: "pointer" }}
                  >
                    {t("clickHere")}
                  </Typography>
                </Grid>
              </>
            ) : null}
          </Grid>

          {/* ===== IMG-Globe (Web) : (Mobile) ===== */}
          {width > 900 ? (
            <>
              {width > 1450 ? (
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  position="absolute"
                >
                  <img
                    src={GlobeWeb}
                    style={{ opacity: 0.5 }}
                    width="28%"
                    alt="Imagem"
                  />
                </Grid>
              ) : (
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  position="absolute"
                >
                  <img
                    src={GlobeWeb}
                    style={{ opacity: 0.5 }}
                    width="45%"
                    alt="Imagem"
                  />
                </Grid>
              )}
            </>
          ) : (
            <>
              {width <= 900 && width > 600 ? (
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  position="absolute"
                  pt="16rem"
                >
                  <img
                    src={GlobeMobile}
                    style={{ opacity: 0.5 }}
                    width="69%"
                    alt="Imagem"
                  />
                </Grid>
              ) : (
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  position="absolute"
                  mt="16rem"
                >
                  <img
                    src={GlobeMobile}
                    style={{ opacity: 0.5 }}
                    width="100%"
                    alt="Imagem"
                  />
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        item
        md={5}
        xs={12}
        direction={width < 900 ? "row" : "column"}
        bgcolor="#1C1439"
        px={width > 900 ? 10 : 0}
        py={width < 900 ? 2 : 0}
        mt={width < 900 ? 5 : 0}
        justifyContent={width < 900 ? "space-around" : "center"}
        alignItems={width < 900 ? "center" : ""}
      >
        {width < 900 ? null : (
          <Grid container item alignItems="center" pt={5}>
            <img src={AuthCircleLogoBlack} alt="" />
            <Typography ml={1} color="#1C1439" fontSize={40}>
              kahsh
            </Typography>
          </Grid>
        )}
        <Grid container item mt={width > 900 ? 3 : 0} xs={5} md={"auto"}>
          <Typography
            fontFamily="Montserrat"
            fontSize={width > 900 ? "4rem" : "1.4rem"}
            color="#FFFFFF"
            letterSpacing="1px"
            lineHeight={width > 900 ? "6rem" : "2rem"}
            ml={width > 900 ? "null" : "1.5rem"}
          >
            {t("welcome")}
          </Typography>
          <Typography
            fontFamily="Barlow"
            fontSize={width > 900 ? "3.5rem" : "1.5rem"}
            color="#FFFFFF"
            fontWeight={600}
            letterSpacing="-0.5px"
            ml={width > 900 ? "null" : "1.5rem"}
            lineHeight={width > 900 ? "5rem" : "2.5rem"}
            dangerouslySetInnerHTML={{
              __html: t("welcomeBody"),
            }}
          />
        </Grid>
        <Divider
          sx={{
            backgroundColor: "#4A4361",
            margin: width > 900 ? "20px 0" : 0,
            height: width > 900 ? "null" : "10rem",
          }}
          orientation={width < 900 ? "vertical" : "horizontal"}
        />
        <Grid
          container
          item
          alignItems="center"
          xs={5}
          md={"auto"}
          py={width > 900 ? 0 : 2}
        >
          <img src={AuthCircle} width="100%" alt="" />
        </Grid>
      </Grid>
    </Grid>
  );
}
